import {
  MenuItem,
  TextField,
  FormGroup,
  Checkbox,
  Alert,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const PaymentMethod = ({
  cardData,
  checkbox,
  handleCheckboxChange,
  handleCardDataFieldChange,
  cardDataErrors,
}) => {
  return (
    <div>
      <p style={{ fontSize: "1.3rem" }}>Payment Method</p>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <CreditCardIcon style={{ color: "#3B86E9" }} />
        <p style={{ fontSize: "1rem" }}>Debit or Credit Card</p>
      </div>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Card Holder Name"
                variant="outlined"
                name="holderName"
                size="normal"
                disabled
                value={cardData.holderName}
                onChange={(e) =>
                  handleCardDataFieldChange("holderName", e.target.value)
                }
                error={cardDataErrors.holderName}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Card Number"
                variant="outlined"
                name="cardNumber"
                size="normal"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  ),
                }}
                value={
                  cardData.cardNumber
                    ? `*`.repeat(Math.max(0, cardData.cardNumber.length - 4)) +
                    cardData.cardNumber.slice(-4)
                    : ""
                }
                // value={cardData.cardNumber}
                onChange={(e) =>
                  handleCardDataFieldChange("cardNumber", e.target.value)
                }
                error={cardDataErrors.cardNumber}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Expiration Month"
                variant="outlined"
                placeholder="MM"
                name="month"
                size="normal"
                helperText={"Enter expiration month (01-12)"}
                value={cardData.month}
                disabled
                onChange={(e) =>
                  handleCardDataFieldChange("month", e.target.value)
                }
                error={cardDataErrors.month}
                inputProps={{
                  maxLength: 2,
                }}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Expiration Year"
                variant="outlined"
                name="year"
                placeholder="YYYY"
                disabled
                size="normal"
                value={cardData.year}
                onChange={(e) =>
                  handleCardDataFieldChange("year", e.target.value)
                }
                error={cardDataErrors.year}
                helperText={"Enter expiration year (e.g., 2024)"}
                inputProps={{
                  maxLength: 4,
                }}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="CVC"
                variant="outlined"
                name="cardCode"
                size="normal"
                value={
                  cardData.cardCode
                    ? `*`.repeat(Math.max(0, cardData.cardCode.length))
                    : ""
                }
                disabled
                onChange={(e) =>
                  handleCardDataFieldChange("cardCode", e.target.value)
                }
                error={cardDataErrors.cardCode}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkbox}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Save data for next time"
              />
            </FormGroup>
          </Grid>
          {/* {showAlert && (
            <Alert variant="filled" severity="error">
              Please agree to terms & conditions
            </Alert>
          )}
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={SaveInfoCheckbox}
                    onChange={handleSaveInfoCheckboxChange}
                  />
                }
                label="Save information for the next time"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              onClick={placeOrder}
              style={{
                width: "100%",
                padding: "0.7rem 0rem",
                fontSize: "1.1rem",
                fontWeight: "bold",
                textTransform: "none",
                marginTop: "1rem",
              }}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
            >
              <span>Place Order</span>
            </LoadingButton>
          </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentMethod;

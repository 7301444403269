import React, { useEffect } from "react";
import { Button, Card, Grid, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useNavigate, useLocation } from "react-router-dom";
import { setLoading, showToast } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import { generateClient } from "aws-amplify/api";
import {
  listUserInfos,
  listTeamInvitations,
  getWorkspaceInfo,
} from "../graphql/queries";
import { useMediaQuery } from "@mui/material";
import "../Css/AddOrganization.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { createSamRushProject } from "../utills/SamRushApis/samrushApi";

const Organization = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const cardStyle = {
    margin: isMobile ? "1rem auto" : "0rem auto",
    width: isMobile ? "90%" : "60%",
    textAlign: "center",
    height: !isMobile && "100vh",
    display: !isMobile && "flex",
    justifyContent: !isMobile && "center",
    flexDirection: !isMobile && "column",
    alignItems: !isMobile && "center",
  };
  const workSpaceCard = {
    margin: "0rem auto",
    width: "100%",
    textAlign: "center",
  };
  const organizationCard = {
    margin: "0.5rem auto",
    width: isMobile ? "90%" : "40%",
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    borderRadius: "10px",
  };
  const imageStyle = {
    height: "500px",
    width: "100%",
  };
  const [invitationDetail, setInvitationDetail] = React.useState(null);
  const [workspaceDetailByInvitation, setWorkspaceByInvitation] =
    React.useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const email = state?.email;
  const workSpaceUrl = state?.workSpaceUrl;
  const cartItems = state?.cartItems;
  const dispatch = useDispatch();
  const client = generateClient();
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [editedUrl, setEditedUrl] = React.useState(workSpaceUrl);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

  useEffect(() => {
    fetchInvitation();
  }, []);

  const fetchInvitation = async () => {
    dispatch(setLoading(true));
    try {
      const variables = {
        filter: {
          email: {
            eq: email,
          },
        },
      };

      const invitationData = await client.graphql({
        query: listTeamInvitations,
        variables: variables,
      });
      const result = invitationData.data.listTeamInvitations.items;
      const requiredResult = result?.filter((item) => item.status !== false);
      const uniqueWorkspaceMap = new Map();

      requiredResult.forEach((item) => {
        if (!uniqueWorkspaceMap.has(item.workspace_id)) {
          uniqueWorkspaceMap.set(item.workspace_id, item);
        }
      });

      const uniqueWorkspaceData = Array.from(uniqueWorkspaceMap.values());
      setInvitationDetail(uniqueWorkspaceData);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error creating todo:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (invitationDetail?.length) {
      fetchWorkspaceDataByInvitation();
    }
  }, [invitationDetail]);

  const fetchWorkspaceDataByInvitation = async () => {
    try {
      dispatch(setLoading(true));
      if (invitationDetail) {
        const userPromises = invitationDetail.map(async (id) => {
          const response = await client.graphql({
            query: getWorkspaceInfo,
            variables: { id: id.workspace_id },
          });
          return response?.data?.getWorkspaceInfo;
        });
        const WSdata = await Promise.all(userPromises);
        const flatArray = WSdata.flat();
        setWorkspaceByInvitation(flatArray);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleInviteNavigate = async () => {
    dispatch(setLoading(true));
    try {
      const variables = {
        filter: {
          email: {
            eq: email,
          },
        },
      };

      const getUserData = await client.graphql({
        query: listUserInfos,
        variables: variables,
      });
      const result = getUserData.data.listUserInfos.items;
      if (!result?.length) {
        navigate("/signup-userinfo", {
          state: {
            detail: invitationDetail,
            email: email,
          },
        });
      } else {
        navigate("/login", {
          state: {
            email: email,
            screen: "invitation",
          },
        });
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error creating todo:", error);
    }
  };

  const truncateName = (name, percentage) => {
    const length = name.length;
    const truncateLength = Math.ceil((percentage / 100) * length);
    return length > truncateLength
      ? name.slice(0, truncateLength) + "..."
      : name;
  };

  const handleEditClick = () => {
    setEditedUrl(workSpaceUrl);
    setOpenEditModal(true);
  };

  const handleSaveUrl = () => {
    // TODO: Add API call to update workspace URL
    // For now, just updating the local state
    navigate(".", { state: { ...state, workSpaceUrl: editedUrl } });
    setOpenEditModal(false);
  };

  const handleDeleteWorkspace = () => {
    // TODO: Add API call to delete workspace
    navigate(".", { state: { ...state, workSpaceUrl: "" } });

    setShowDeleteConfirm(false);
  };
  const handleCreateWorkSpace = async () => {

    navigate("/signup-workspace", {
      state: {
        email: email,
        cartItems: cartItems,
        workSpaceUrl: workSpaceUrl
      },
    })
  }
  return (
    <>
      <div style={cardStyle} className="cardStyle-organisation">
        <div style={{ margin: "0rem 0rem", textAlign: "center" }}>
          <img
            src="/faviconPNG.png"
            height={isMobile ? "50px" : "70px"}
            width={isMobile ? "100%" : "430px"}
          />
        </div>
        {workspaceDetailByInvitation === null && (
          <>
            <div
              style={{
                textAlign: "center",
                color: "#605E61",
                backgroundColor: "#fff",
                display: "inline-block",
                padding: "1rem 1rem",
                borderRadius: "50px",
              }}
            >
              Confirmed as <b>{email}</b> &nbsp;&nbsp;
            </div>

          </>
        )}
        <Grid container spacing={0} style={workSpaceCard}>
          <>
            <Grid item xs={12} sm={12}>
              {workspaceDetailByInvitation &&
                workspaceDetailByInvitation?.length > 0 && (
                  <div style={{ marginTop: isMobile ? "-1rem" : "2.5rem" }}>
                    <h2 style={{ color: "#1D1C1D" }}>Join A Workspace</h2>
                  </div>
                )}
              {workspaceDetailByInvitation &&
                workspaceDetailByInvitation?.length ? (
                workspaceDetailByInvitation.map((itm) => (
                  <Card style={organizationCard}>
                    <Grid container spacing={0}>
                      <Grid item xs={5} md={9.5}>
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                            padding: "0.7rem 2rem",
                          }}
                        >
                          <img
                            src={itm.logo}
                            height="70px"
                            width="70px"
                            style={{ borderRadius: "6px" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h3 style={{ margin: "0px", textAlign: "start" }}>
                                {truncateName(itm.name, 80)}
                              </h3>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0rem",
                              }}
                            >
                              <p
                                style={{
                                  color: "#605E61",
                                  margin: "0.2rem 0rem",
                                }}
                              >{`${itm?.user_id.length} member`}</p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        md={2.5}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Button onClick={handleInviteNavigate}>Join</Button>
                      </Grid>
                    </Grid>
                  </Card>
                ))
              ) : (
                <Grid container spacing={10}>
                  <Grid item xs={12} sm={6}>
                    <div style={{ marginTop: "2rem", textAlign: "start" }}>
                      <h1
                        style={{
                          color: "#1D1C1D",
                          fontSize: isMobile ? "1.4rem" : "3rem",
                        }}
                      >
                        Add A Workspace.
                      </h1>
                      <p
                        style={{
                          fontSize: isMobile ? "0.9rem" : "1.2rem",
                          margin: "0px",
                          lineHeight: isMobile ? "1.4rem" : "1.7rem",
                        }}
                      >
                        Rothbright portal provides your Workspace with a central
                        place for you to manage all of your digital services. To
                        create a new Workspace, click the button below.
                      </p>
                    </div>
                    <div style={{ marginTop: isMobile ? "1rem" : "2rem" }}>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          padding: "0.9rem 0rem",
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                          textTransform: "initial",
                        }}
                        onClick={() =>
                          handleCreateWorkSpace()
                        }
                      >
                        Create a Workspace
                      </Button>
                    </div>
                    <div style={{ textAlign: "start" }}>
                      <p
                        style={{
                          marginTop: isMobile ? "0.9rem" : "1.5rem",
                          fontSize: isMobile ? "0.9rem" : "1rem",
                          lineHeight: isMobile ? "1.4rem" : "1.5rem",
                          color: "#605E61",
                        }}
                      >
                        By continuing, you're agreeing to our Main Services
                        Agreement, User Terms of Service, and Rothbright
                        Supplemental Terms. Additional disclosures are avaliable
                        in our Privacy Policy and Cookie Policy.
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} className="org-bottom-img">
                    <style>
                      {`@media (max-width: 600px) {
                  .login-image {
                    display: none;
                  }
                }`}
                    </style>
                    <img
                      src="/vector.png"
                      style={imageStyle}
                      alt="Login Background"
                      className="organization-img"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        </Grid>
      </div>


      <Dialog open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this workspace?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirm(false)}>Cancel</Button>
          <Button onClick={handleDeleteWorkspace} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Organization;

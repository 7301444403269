import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "../components/appBar";
import Drawer from "../components/drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MobileBottomDrawer from "../components/Common/MobileBottomDrawer";
import MobileAppBar from "../components/Common/MobileAppDrawer";
import Intercom from "@intercom/messenger-js-sdk";
import { generateUserHash } from "./hash";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

function Protected({ children, allowedRoles }) {
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const urlPath = window.location.pathname;
  const routeName = urlPath.substring(1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (userData) {
      const userHashdata = generateUserHash(userData?.sub);

      Intercom({
        // app_id: "edphg4x4",
        app_id: "c0r79ryv",
        user_id: userData?.sub,
        name: userData?.name,
        email: userData?.email,
        user_id: userData?.sub,
        user_hash: userHashdata,
        created_at: Math.floor(Date.now() / 1000),
      });
    }
  }, [userData]);

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  const defaultTheme = createTheme();
  const isAuthorized = allowedRoles?.includes(userData.role);

  if (!isAuthorized) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <AppBar />
          <Box
            sx={{
              backgroundColor: theme.palette.background.default,
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "20px",
            }}
          >
            <Typography variant="h4" gutterBottom>
              Unauthorized Access
            </Typography>
            <Typography variant="body1" gutterBottom>
              You don't have access to this page.
            </Typography>
            <Button variant="contained" color="primary" href="/home">
              Go to Home
            </Button>
          </Box>
        </ThemeProvider>
      </>
    );
  }

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          {routeName === "choose-organization" ||
          routeName === "team-login" ||
          routeName === "new_workspace_name" ||
          routeName === "new_workspace_userinfo" ? (
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              {children}
            </Box>
          ) : (
            <>
              <AppBar />
              <MobileAppBar open={open} setOpen={setOpen} />
              <Drawer />
              <MobileBottomDrawer />
              <Box
                component="main"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: "100vh",
                  overflow: "auto",
                }}
              >
                <Toolbar />
                {children}
              </Box>
            </>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}
export default Protected;

import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Grid, InputAdornment, TextField } from "@mui/material";
import Footer from "../components/Footer";
import EditIcon from "@mui/icons-material/Edit";
import { uploadData } from "aws-amplify/storage";
import { updateUserInfo } from "../graphql/mutations";
import { setLoading, setUserDetails, showToast } from "../redux/userSlice";
import { generateClient } from "aws-amplify/api";
import { useDispatch } from "react-redux";
import { listUserInfos } from "../graphql/queries";
import { updatePassword } from "aws-amplify/auth";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "../Css/UserProfile.css";
import { useMediaQuery } from "@mui/material";

const container = {
  margin: "0rem 4rem",
};
const organizationCard = {
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  minHeight: "70vh",
};
const profileImage = {
  width: "150px",
  height: "150px",
  borderRadius: "50%",
  objectFit: "cover",
  cursor: "pointer",
};
const editIcon = {
  position: "absolute",
  bottom: "30px",
  right: "17px",
  color: "#000",
  transform: "translate(50%, 50%)",
  backgroundColor: "#d7d7d7",
  borderRadius: "50%",
  padding: "4px",
  cursor: "pointer",
  fontSize: "2rem",
};
const profileImageContainer = {
  position: "relative",
  display: "inline-block",
  width: "150px",
  height: "150px",
  borderRadius: "50%",
  backgroundColor: "#bababa",
  cursor: "pointer",
  border: "1px solid #DCE5EC",
};

const UserProfile = () => {
  const client = generateClient();
  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = React.useState(null);
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const bucket = process.env.REACT_APP_BUCKET_NAME;
  const [updatedUserData, setUpdatedUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const fetchUserData = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        user_id: {
          eq: userData.sub,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserInfos,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listUserInfos.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserInfos.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchData = async () => {
    try {
      // dispatch(setLoading(true));

      const res = await fetchUserData(selectedWorkspaceId);
      const result = res?.filter(
        (id) => id.workspace_id === selectedWorkspaceId
      );
      setUserDetail(result);
      setUpdatedUserData({
        ...updatedUserData,
        firstName: result[0].user_name_first,
        lastName: result[0].user_name_second,
        email: result[0].email,
      });
      // dispatch(setLoading(false));
    } catch (error) {
      // dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const fileInput = event.target;

    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  async function handleUpdatePassword() {
    try {
      const oldPassword = updatedUserData.oldPassword;
      const newPassword = updatedUserData.newPassword;
      const updatedPassword = await updatePassword({
        oldPassword,
        newPassword,
      });
      return true;
    } catch (err) {
      dispatch(showToast({ message: err.message, type: "error" }));
      throw err;
    }
  }

  const handleUpdateUser = async () => {
    try {
      setLoading(true);
      if (updatedUserData.newPassword !== updatedUserData.confirmPassword) {
        dispatch(
          showToast({ message: "New passwords must be same", type: "error" })
        );
        return;
      }
      if (updatedUserData.oldPassword && updatedUserData.newPassword) {
        await handleUpdatePassword();
      }
      if (selectedImage) {
        const file = fileInputRef.current.files[0];
        try {
          const fileName = file.name.replace(/\s+/g, "");
          const uploadResult = await uploadData({
            key: `${selectedWorkspaceId}/${userData.sub}/${fileName}`,
            data: file,
            options: {
              accessLevel: "public",
            },
          }).uploadResult;

          const variable = {
            id: userDetail?.[0]?.id,
            user_name_first: updatedUserData.firstName,
            user_name_second: updatedUserData.lastName,
            display_picture: `https://${bucket}/public/${selectedWorkspaceId}/${userData.sub}/${fileName}`,
          };

          const updateUserData = await client.graphql({
            query: updateUserInfo,
            variables: { input: variable },
          });

          const result = updateUserData.data.updateUserInfo;
          dispatch(setUserDetails(result));
        } catch (uploadError) {
          dispatch(
            showToast({
              message: uploadError,
              type: "error",
            })
          );
        }
      } else {
        const variable = {
          id: userDetail?.[0]?.id,
          user_name_first: updatedUserData.firstName,
          user_name_second: updatedUserData.lastName,
        };

        const updateUserData = await client.graphql({
          query: updateUserInfo,
          variables: { input: variable },
        });

        const result = updateUserData.data.updateUserInfo;
      }
      await fetchData();
      setUpdatedUserData({
        ...updatedUserData,
        oldPassword: "",
        newPassword: "",
      });
      dispatch(
        showToast({ message: "Profile updated successfully", type: "success" })
      );
    } catch (error) {
      console.error("Error updating user:", error);
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  return (
    <div
      style={{ ...container, margin: isMobile ? "0px 1rem" : container.margin }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card style={organizationCard}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height: isMobile ? "80vh" : "70vh",
                margin: "2rem 0rem",
              }}
            >
              <div style={{ width: isMobile ? "90%" : "40%" }}>
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <div style={profileImageContainer}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <label htmlFor="fileInput">
                        <img
                          src={
                            selectedImage || userDetail?.[0]?.display_picture
                          }
                          style={profileImage}
                        />
                        <EditIcon
                          style={editIcon}
                          onClick={handleEditIconClick}
                        />
                      </label>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={5} style={{ marginTop: "0.5rem" }}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="First Name"
                          variant="outlined"
                          size="small"
                          value={
                            userDetail?.length > 0
                              ? updatedUserData.firstName
                              : ""
                          }
                          onChange={(e) =>
                            setUpdatedUserData({
                              ...updatedUserData,
                              firstName: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Last Name"
                          variant="outlined"
                          size="small"
                          value={
                            userDetail?.length > 0
                              ? updatedUserData.lastName
                              : ""
                          }
                          onChange={(e) =>
                            setUpdatedUserData({
                              ...updatedUserData,
                              lastName: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          disabled
                          label="Email"
                          variant="outlined"
                          size="small"
                          value={
                            userDetail?.length > 0 ? updatedUserData.email : ""
                          }
                          onChange={(e) =>
                            setUpdatedUserData({
                              ...updatedUserData,
                              email: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Old Password"
                          variant="outlined"
                          type={showOldPassword ? "text" : "password"}
                          size="small"
                          value={updatedUserData.oldPassword}
                          onChange={(e) =>
                            setUpdatedUserData({
                              ...updatedUserData,
                              oldPassword: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleOldPasswordVisibility}
                                  edge="end"
                                >
                                  {showOldPassword ? (
                                    <VisibilityOffIcon
                                      style={{ color: "#777" }}
                                    />
                                  ) : (
                                    <VisibilityIcon style={{ color: "#777" }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="New Password"
                          variant="outlined"
                          size="small"
                          type={showPassword ? "text" : "password"}
                          value={updatedUserData.newPassword}
                          onChange={(e) =>
                            setUpdatedUserData({
                              ...updatedUserData,
                              newPassword: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleTogglePasswordVisibility}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon
                                      style={{ color: "#777" }}
                                    />
                                  ) : (
                                    <VisibilityIcon style={{ color: "#777" }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Confirm Password"
                          variant="outlined"
                          size="small"
                          type={showConfirmPassword ? "text" : "password"}
                          value={updatedUserData.confirmPassword}
                          onChange={(e) =>
                            setUpdatedUserData({
                              ...updatedUserData,
                              confirmPassword: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleConfirmPasswordVisibility}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOffIcon
                                      style={{ color: "#777" }}
                                    />
                                  ) : (
                                    <VisibilityIcon style={{ color: "#777" }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          padding: "0.45rem 0rem",
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                          textTransform: "initial",
                          marginTop: "1rem",
                        }}
                        onClick={handleUpdateUser}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
      {isMobile && <div style={{ height: "10vh" }}></div>}
      {!isMobile && (
        <Grid container spacing={5} className="footer-container">
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default UserProfile;

import {
  Alert,
  Button,
  Card,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";
import countriesList from "../../src/countries.json";
import {
  setLoading,
  setUserInfo,
  selectProductCount,
  setProductCount,
} from "../redux/userSlice.js";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { generateClient } from "aws-amplify/api";
import { listUserInfos } from "../graphql/queries";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EmailIcon from "@material-ui/icons/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import {
  fetchAuthSession,
  fetchUserAttributes,
  signIn,
} from "aws-amplify/auth";
import ShopFooter from "../components/ShopFooter.js";
import CircularProgress from "@mui/material/CircularProgress";
import tagManagerEvents from "./GoogleTagManager.js";
import { useMediaQuery } from "@mui/material";
import ExternalMobileAppBar from "../components/Common/ExternalMobileAppBar.js";
import BillingScreenMobileResponsive from "../components/Common/BillingScreenMobileResponsive.js";
import ClearIcon from "@mui/icons-material/Clear";
import UnAuthenticatedAppBarComponent from "../components/unAuthenticatedAppBar.js";

const appBarContainer = {
  margin: "6rem 4rem",
};

const BillingsScreen = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const client = generateClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const { state } = useLocation();
  const productCount = useSelector(selectProductCount);
  const product = state?.product;
  const cartProductDetail = state?.productDetail;
  const totalPrice = state?.totalPrice;
  const [productDetail, setProductDetail] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailerror, setEmailError] = useState(false);
  const [emailerrorMessage, setEmailErrorMessage] = useState(false);
  const [passworderror, setPasswordError] = useState(false);
  const [passerrorMessage, setPassErrorMessage] = useState(false);
  const [servererror, setServerError] = useState("");
  const [servererrorMessage, setServerErrorMessage] = useState("");
  const [userDetail, setUserDetail] = useState(null);
  const [wocommerceId, setWocommerceId] = useState(0);
  const [checkbox, setCheckbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [userDetailDB, setUserDetailDB] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [cardData, setCardData] = useState({
    cardNumber: "",
    month: "",
    year: "",
    cardCode: "",
  });
  const [customerDetail, setCustomerDetail] = useState({
    customer_id: wocommerceId,
    payment_method: "Authorized.net",
    payment_method_title: "Direct Bank Transfer",
    set_paid: true,
    billing: {
      first_name: "",
      last_name: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
      email: "",
      phone: "",
    },
    shipping: {
      first_name: "",
      last_name: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
    },
    line_items: cartProductDetail?.map((item) => ({
      product_id: item.id,
      variation_id: item.package_id,
      quantity: item.quantity,
    })),
  });
  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    document.title = "Billing";
    tagManagerEvents("billingPage");
    if (userDetailDB?.length > 0) {
      const billingDetails = userDetailDB[0];
      setCustomerDetail((prevCustomerDetail) => ({
        ...prevCustomerDetail,
        billing: {
          ...prevCustomerDetail.billing,
          first_name: billingDetails.first_name || "",
          last_name: billingDetails.last_name || "",
          address_1: billingDetails.address || "",
          city: billingDetails.city || "",
          state: billingDetails.state || "",
          postcode: billingDetails.postal_code || "",
          country: billingDetails.country || "",
          email: billingDetails.email || "",
        },
      }));
      setCardData({
        ...cardData,
        cardNumber: billingDetails.card_number || "",
        month: billingDetails.expiry_month || "",
        year: billingDetails.expiry_year || "",
        cardCode: billingDetails.cvc || "",
      });
    }
  }, [userDetailDB]);

  const [subscriptionDetail, setSubscriptionDetail] = useState({
    customer_id: wocommerceId,
    parent_id: "",
    status: "active",
    billing_period: "month",
    billing_interval: 1,
    start_date: "",
    next_payment_date: "",
    payment_method: "Authorized.net",
    billing: {
      first_name: "",
      last_name: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
      email: "",
      phone: "",
    },
    shipping: {
      first_name: "",
      last_name: "",
      address_1: "",
      address_2: "",
      city: "",
      state: "",
      postcode: "",
      country: "",
    },
    line_items: cartProductDetail?.map((item) => ({
      product_id: item.id,
      variation_id: item.package_id,
      quantity: item.quantity,
    })),
    shipping_lines: [
      {
        method_id: "flat_rate",
        method_title: "Flat Rate",
        total: "",
      },
    ],
  });
  const [isHovered, setIsHovered] = useState(false);
  const [isProps, setIsProps] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const container = {
    margin: "1.5rem 4rem",
  };
  const title = {
    fontSize: "2rem",
    marginTop: "1rem",
    marginBottom: "1rem",
  };
  const organizationCard = {
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    borderRadius: "10px",
    padding: "1.5rem 2rem",
    minHeight: "78vh",
  };
  const headingAlign = {
    display: "flex",
    justifyContent: "space-between",
    margin: "0rem 0rem",
  };
  const rowAlign = {
    display: "flex",
    justifyContent: "space-between",
    margin: "1rem 0rem",
  };
  const textALign = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const signInTextStyle = {
    padding: 0,
    margin: 0,
    textDecoration: "underline",
    cursor: "pointer",
    color: "#000",
    transition: "color 0.3s",
  };

  const signInTextHoverStyle = {
    color: "#1976d2",
  };

  const reorderedCountriesList = [
    { name: "United States" },
    ...countriesList.filter((country) => country.name !== "United States"),
  ];

  useEffect(() => {
    if (product && Object.keys(product).length > 0) {
      setProductDetail(product);
    } else {
      setProductDetail(cartProductDetail);
    }
  }, [product, cartProductDetail]);
  const outputArray = productDetail ? Object.values(productDetail) : [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const variables = {
          filter: {
            id: {
              eq: userData.sub,
            },
          },
        };

        const getUserData = await client.graphql({
          query: listUserInfos,
          variables: variables,
        });
        const result = getUserData.data.listUserInfos.items;
        setUserDetail(result);

        const subscriptionId = result?.[0]?.subscription_id;
        const parsedSubscriptionId = parseInt(subscriptionId);
        if (!isNaN(parsedSubscriptionId)) {
          setWocommerceId(parsedSubscriptionId);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error creating todo:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const localProductData = localStorage.getItem("productCart");
    const productCart = JSON.parse(localProductData);
    dispatch(setProductCount(productCart));
  }, []);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClickOpen = () => {
    setOpenLogin(true);
    tagManagerEvents("alreadyworkspaceButtonClicked");
  };

  const handleClose = () => {
    setOpenLogin(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const fetchData = async (user_id, userAttributes) => {
    try {
      setIsLoading(true);
      const variables = {
        filter: {
          user_id: {
            eq: user_id,
          },
        },
      };

      const getUserData = await client.graphql({
        query: listUserInfos,
        variables: variables,
      });
      const result = getUserData.data.listUserInfos.items[0];
      if (result) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...userAttributes,
            role: "workspace_member",
          })
        );
        tagManagerEvents("externalSignInSuccess");
        setIsLoading(false);
        navigate("/choose-organization", {
          state: {
            fromScreen: "external shop",
            product: outputArray,
          },
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating todo:", error);
    }
  };

  const currentAuthenticatedUser = async () => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const groups = accessToken.payload["cognito:groups"];
      return groups;
    } catch (err) {
      console.log(err);
    }
  };

  async function handleFetchUserAttributes() {
    try {
      setIsLoading(true);
      const userAttributes = await fetchUserAttributes();
      const userGroups = await currentAuthenticatedUser();
      const userObjectString = JSON.stringify(userAttributes);
      if (userAttributes) {
        await fetchData(userAttributes.sub, userAttributes);
      }
      dispatch(setUserInfo({ status: userObjectString }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  const onSignIn = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!email && !password) {
      setPasswordError(true);
      setPassErrorMessage("Password required");
      setEmailError(true);
      setEmailErrorMessage("Email required");
      setIsLoading(false);
      return;
    }
    if (!email) {
      setEmailError(true);
      setEmailErrorMessage("Email required");
      setIsLoading(false);
      return;
    }
    if (!password) {
      setPasswordError(true);
      setPassErrorMessage("Password required");
      setIsLoading(false);
      return;
    }
    try {
      setIsLoading(true);
      const user = await signIn({
        username: email,
        password: password,
      });
      if (user.isSignedIn) {
        handleFetchUserAttributes();
      }
      setIsLoading(false);
      setIsSent(true);
    } catch (error) {
      setIsLoading(false);
      setServerError(true);
      setServerErrorMessage(error.message);
      console.log(error.message);
    }
  };

  const onSignUp = () => {
    navigate("/signup", {
      state: {
        cartItems: outputArray,
      },
    });
  };

  const handleFieldChange = (field, value) => {
    setCustomerDetail((prevCustomerDetail) => ({
      ...prevCustomerDetail,
      billing: {
        ...prevCustomerDetail.billing,
        [field]: value,
      },
    }));

    setSubscriptionDetail((prevSubscriptionDetail) => ({
      ...prevSubscriptionDetail,
      billing: {
        ...prevSubscriptionDetail.billing,
        [field]: value,
      },
    }));
  };

  const getTotalItems = () => {
    return productCount?.reduce((total, item) => total + item.quantity, 0);
  };
  const getTotalAmount = () => {
    let _totalPrice = productCount?.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    return _totalPrice;
  };
  const handleChangeAccordianButton = (panel) => (event, isExpanded) => {
    setExpanded(!expanded);
  };
  const handleCheckboxChange = (event) => {
    setCheckbox(event.target.checked);
    setShowAlert(false);
  };

  return (
    <>
      {isMobile ? (
        <>
          <ExternalMobileAppBar productCount={getTotalItems()} />
          <BillingScreenMobileResponsive
            productDetail={productDetail}
            expanded={expanded}
            productCount={productCount}
            organizationCard={organizationCard}
            signInTextStyle={signInTextStyle}
            isHovered={isHovered}
            signInTextHoverStyle={signInTextHoverStyle}
            customerDetail={customerDetail}
            showAlert={showAlert}
            reorderedCountriesList={reorderedCountriesList}
            cardData={cardData}
            checkbox={checkbox}
            handleChangeAccordianButton={handleChangeAccordianButton}
            getTotalAmount={getTotalAmount}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            handleClickOpen={handleClickOpen}
            handleFieldChange={handleFieldChange}
            setCustomerDetail={setCustomerDetail}
            setSubscriptionDetail={setSubscriptionDetail}
            setCardData={setCardData}
            handleCheckboxChange={handleCheckboxChange}
            // placeOrder={placeOrder}
            loadingButton={loadingButton}
            signedIn={false}
            onSignUp={onSignUp}
          />
        </>
      ) : (
        <div
          style={{
            ...container,
            margin: isProps ? "1rem 0.6rem 0rem 1.5rem" : container.margin,
            width: isProps && "100%",
          }}
        >
          <div style={appBarContainer}>
            <UnAuthenticatedAppBarComponent
              getTotalItems={getTotalItems}
              onHandleCartPressed={props.onHandleCartPressed}
            />
          </div>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div>
                <div>
                  <p style={title}></p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={1.5}>
                <Grid item xs={isProps ? 8 : 8} style={{ fontSize: "0.9rem" }}>
                  <Card style={organizationCard}>
                    <div style={headingAlign}>
                      <Grid container spacing={0}>
                        <Grid item xs={2} style={textALign}></Grid>
                        <Grid item xs={4} style={textALign}>
                          <h4>Product</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Plan</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Quantity</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Price</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Total</h4>
                        </Grid>
                      </Grid>
                    </div>
                    <Divider light />
                    {outputArray?.length && (
                      <>
                        {outputArray.map((item, index) => (
                          <div style={rowAlign} key={index}>
                            <Grid container spacing={0}>
                              <Grid item xs={2} style={textALign}>
                                <div style={{ display: "flex", gap: "0.5rem" }}>
                                  <img
                                    src={item?.image}
                                    height="100px"
                                    width="80px"
                                    style={{
                                      objectFit: "cover",
                                      borderRadius: "3px",
                                      marginLeft: isProps && 15,
                                    }}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={4} style={textALign}>
                                <p>{item?.name}</p>
                              </Grid>
                              <Grid item xs={1.5} style={textALign}>
                                {item?.plan ? (
                                  <p>{item?.plan}</p>
                                ) : (
                                  <p>Basic</p>
                                )}
                              </Grid>
                              <Grid item xs={1.5} style={textALign}>
                                <p>{item?.quantity}</p>
                              </Grid>
                              <Grid item xs={1.5} style={textALign}>
                                <p>
                                  {`$${Number(item.price)
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    .toLocaleString("en-US")}`}
                                </p>
                              </Grid>
                              <Grid item xs={1.5} style={textALign}>
                                <p>
                                  <b>{`$${Number(item.price * item.quantity)
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    .toLocaleString("en-US")}`}</b>
                                </p>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                      </>
                    )}
                    <Divider light />
                    <div style={headingAlign}>
                      <Grid container spacing={0}>
                        <Grid item xs={10.5} style={{ textAlign: "right" }}>
                          <p>Sub-Total:</p>
                        </Grid>
                        <Grid item xs={1.5} style={{ textAlign: "center" }}>
                          <p>
                            <b>{`$${Number(totalPrice)
                              .toFixed(0)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              .toLocaleString("en-US")}`}</b>
                          </p>
                        </Grid>
                      </Grid>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={isProps ? 4 : 4}>
                  <Card style={organizationCard}>
                    <div
                      className="billing-text"
                      style={{
                        background: "lightgrey",
                        display: "flex",
                        alignItems: "center",
                        padding: "20px 0px 20px 20px",
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Already have a WorkSpace? &nbsp;
                      <p
                        style={{
                          ...signInTextStyle,
                          ...(isHovered && signInTextHoverStyle),
                        }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleClickOpen}
                      >
                        Sign in
                      </p>
                    </div>

                    <div>
                      <p style={{ fontSize: "1.3rem", margin: "0.5rem 0rem" }}>
                        Contact Information
                      </p>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                id="outlined-basic"
                                label="First Name"
                                variant="outlined"
                                size="small"
                                value={customerDetail.billing.first_name}
                                onChange={(e) =>
                                  handleFieldChange(
                                    "first_name",
                                    e.target.value
                                  )
                                }
                                style={{ width: "100%", marginBottom: "1rem" }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                size="small"
                                value={customerDetail.billing.last_name}
                                onChange={(e) => {
                                  setCustomerDetail((prevCustomerDetail) => ({
                                    ...prevCustomerDetail,
                                    billing: {
                                      ...prevCustomerDetail.billing,
                                      last_name: e.target.value,
                                    },
                                  }));

                                  setSubscriptionDetail(
                                    (prevSubscriptionDetail) => ({
                                      ...prevSubscriptionDetail,
                                      billing: {
                                        ...prevSubscriptionDetail.billing,
                                        last_name: e.target.value,
                                      },
                                    })
                                  );
                                }}
                                style={{ width: "100%", marginBottom: "1rem" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="outlined-basic"
                              label="Email"
                              variant="outlined"
                              size="small"
                              value={customerDetail.billing.email}
                              onChange={(e) => {
                                setCustomerDetail((prevCustomerDetail) => ({
                                  ...prevCustomerDetail,
                                  billing: {
                                    ...prevCustomerDetail.billing,
                                    email: e.target.value,
                                  },
                                }));

                                setSubscriptionDetail(
                                  (prevSubscriptionDetail) => ({
                                    ...prevSubscriptionDetail,
                                    billing: {
                                      ...prevSubscriptionDetail.billing,
                                      email: e.target.value,
                                    },
                                  })
                                );
                              }}
                              style={{ width: "100%", marginBottom: "1rem" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <p style={{ fontSize: "1.3rem", margin: "0.5rem 0rem" }}>
                        Billing Information
                      </p>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                id="outlined-basic"
                                label="Address"
                                variant="outlined"
                                size="small"
                                value={customerDetail.billing.address_1}
                                onChange={(e) => {
                                  setCustomerDetail((prevCustomerDetail) => ({
                                    ...prevCustomerDetail,
                                    billing: {
                                      ...prevCustomerDetail.billing,
                                      address_1: e.target.value,
                                    },
                                  }));

                                  setSubscriptionDetail(
                                    (prevSubscriptionDetail) => ({
                                      ...prevSubscriptionDetail,
                                      billing: {
                                        ...prevSubscriptionDetail.billing,
                                        address_1: e.target.value,
                                      },
                                    })
                                  );
                                }}
                                multiline
                                rows={2}
                                style={{ width: "100%", marginBottom: "1rem" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                id="outlined-basic"
                                label="City"
                                variant="outlined"
                                size="small"
                                value={customerDetail.billing.city}
                                onChange={(e) => {
                                  setCustomerDetail((prevCustomerDetail) => ({
                                    ...prevCustomerDetail,
                                    billing: {
                                      ...prevCustomerDetail.billing,
                                      city: e.target.value,
                                    },
                                  }));

                                  setSubscriptionDetail(
                                    (prevSubscriptionDetail) => ({
                                      ...prevSubscriptionDetail,
                                      billing: {
                                        ...prevSubscriptionDetail.billing,
                                        city: e.target.value,
                                      },
                                    })
                                  );
                                }}
                                style={{ width: "100%", marginBottom: "1rem" }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                select
                                size="small"
                                margin="normal"
                                fullWidth
                                name="Country"
                                label="Select Country"
                                style={{ margin: "0px" }}
                                value={customerDetail.billing.country}
                                onChange={(e) => {
                                  setCustomerDetail((prevCustomerDetail) => ({
                                    ...prevCustomerDetail,
                                    billing: {
                                      ...prevCustomerDetail.billing,
                                      country: e.target.value,
                                    },
                                  }));

                                  setSubscriptionDetail(
                                    (prevSubscriptionDetail) => ({
                                      ...prevSubscriptionDetail,
                                      billing: {
                                        ...prevSubscriptionDetail.billing,
                                        country: e.target.value,
                                      },
                                    })
                                  );
                                }}
                                SelectProps={{
                                  IconComponent: customerDetail.billing.country
                                    ? () => null
                                    : undefined,
                                  MenuProps: {
                                    PaperProps: {
                                      style: {
                                        maxHeight: "300px",
                                      },
                                    },
                                  },
                                }}
                                InputProps={{
                                  endAdornment: customerDetail.billing
                                    .country && (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="clear selection"
                                        onClick={() =>
                                          setCustomerDetail(
                                            (prevCustomerDetail) => ({
                                              ...prevCustomerDetail,
                                              billing: {
                                                ...prevCustomerDetail.billing,
                                                country: "",
                                              },
                                            })
                                          )
                                        }
                                        edge="end"
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              >
                                {reorderedCountriesList?.map((option) => (
                                  <MenuItem
                                    key={option.name}
                                    value={option.name}
                                  >
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                id="outlined-basic"
                                label="State"
                                variant="outlined"
                                size="small"
                                value={customerDetail.billing.state}
                                onChange={(e) => {
                                  setCustomerDetail((prevCustomerDetail) => ({
                                    ...prevCustomerDetail,
                                    billing: {
                                      ...prevCustomerDetail.billing,
                                      state: e.target.value,
                                    },
                                  }));

                                  setSubscriptionDetail(
                                    (prevSubscriptionDetail) => ({
                                      ...prevSubscriptionDetail,
                                      billing: {
                                        ...prevSubscriptionDetail.billing,
                                        state: e.target.value,
                                      },
                                    })
                                  );
                                }}
                                style={{ width: "100%", marginBottom: "1rem" }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="outlined-basic"
                                label="Postal Code"
                                variant="outlined"
                                size="small"
                                value={customerDetail.billing.postcode}
                                onChange={(e) => {
                                  setCustomerDetail((prevCustomerDetail) => ({
                                    ...prevCustomerDetail,
                                    billing: {
                                      ...prevCustomerDetail.billing,
                                      postcode: e.target.value,
                                    },
                                  }));

                                  setSubscriptionDetail(
                                    (prevSubscriptionDetail) => ({
                                      ...prevSubscriptionDetail,
                                      billing: {
                                        ...prevSubscriptionDetail.billing,
                                        postcode: e.target.value,
                                      },
                                    })
                                  );
                                }}
                                style={{ width: "100%", marginBottom: "1rem" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <p style={{ fontSize: "1.3rem", margin: "0.5rem 0rem" }}>
                        Payment Methods
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <CreditCardIcon style={{ color: "#3B86E9" }} />
                        <p style={{ fontSize: "1rem" }}>Debit or Credit Card</p>
                      </div>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                id="outlined-basic"
                                label="Card Number"
                                variant="outlined"
                                name="cardNumber"
                                size="small"
                                value={cardData.cardNumber}
                                onChange={(event) =>
                                  setCardData({
                                    ...cardData,
                                    cardNumber: event.target.value,
                                  })
                                }
                                style={{ width: "100%", marginBottom: "1rem" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <TextField
                                id="outlined-basic"
                                label="Expiration Month"
                                variant="outlined"
                                name="month"
                                size="small"
                                value={cardData.month}
                                onChange={(event) =>
                                  setCardData({
                                    ...cardData,
                                    month: event.target.value,
                                  })
                                }
                                style={{ width: "100%", marginBottom: "1rem" }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                id="outlined-basic"
                                label="Expiration Year"
                                variant="outlined"
                                name="year"
                                size="small"
                                value={cardData.year}
                                onChange={(event) =>
                                  setCardData({
                                    ...cardData,
                                    year: event.target.value,
                                  })
                                }
                                style={{ width: "100%", marginBottom: "1rem" }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                id="outlined-basic"
                                label="CVC"
                                variant="outlined"
                                name="cardCode"
                                size="small"
                                value={cardData.cardCode}
                                onChange={(event) =>
                                  setCardData({
                                    ...cardData,
                                    cardCode: event.target.value,
                                  })
                                }
                                style={{ width: "100%", marginBottom: "1rem" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              onClick={onSignUp}
                              style={{
                                width: "100%",
                                padding: "0.7rem 0rem",
                                fontSize: "1.1rem",
                                fontWeight: "bold",
                                textTransform: "none",
                                marginTop: "1rem",
                              }}
                              variant="contained"
                            >
                              <span>Sign up</span>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {
            <div>
              <ShopFooter />
            </div>
          }
        </div>
      )}{" "}
      <Dialog open={openLogin} disableBackdropClick>
        <DialogTitle>Sign In</DialogTitle>
        <DialogContent>
          <TextField
            error={emailerror}
            helperText={emailerror ? emailerrorMessage : null}
            id="outlined-basic"
            label="Email"
            variant="standard"
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
              setEmailErrorMessage("");
              setServerError(false);
              setServerErrorMessage("");
            }}
            value={email}
            style={{ width: "100%", marginBottom: "1rem" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EmailIcon style={{ color: "#777", marginRight: "0.5rem" }} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            error={passworderror}
            helperText={passworderror ? passerrorMessage : null}
            id="outlined-basic"
            label="Password"
            variant="standard"
            onChange={(e) => {
              setPassword(e.target.value);
              setPassErrorMessage("");
              setPasswordError(false);
              setServerError(false);
              setServerErrorMessage("");
            }}
            value={password}
            type={showPassword ? "text" : "password"}
            style={{ width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    style={{ marginRight: "0rem" }}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon style={{ color: "#777" }} />
                    ) : (
                      <VisibilityIcon style={{ color: "#777" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        {servererror ? (
          <div style={{ margin: "0rem 1.5rem" }}>
            <Alert severity="error">{servererrorMessage}</Alert>
          </div>
        ) : null}
        <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onSignIn}
            disabled={isLoading || isSent}
            startIcon={
              isLoading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Sign in
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BillingsScreen;

import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Grid, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { signIn } from "aws-amplify/auth";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { setUserInfo, setLoading, showToast } from "../redux/userSlice";
import { fetchUserAttributes, fetchAuthSession } from "aws-amplify/auth";
import { listUserInfos } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import "../Css/Login.css";
const cardStyle = {
  width: "80%",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  margin: "0 auto",
};

const imageStyle = {
  width: "100%",
  height: "600px",
  objectFit: "cover",
};

const buttonStyle = {
  width: "100%",
  padding: "0.4rem 0rem",
  fontSize: "1rem",
  color: "#333",
  fontWeight: "bold",
  textTransform: "initial",
  border: "1px solid #C4C4C4",
};

const lineContianer = {
  position: "relative",
  textAlign: "center",
  marginTop: "3rem",
  marginBottom: "1.5rem",
};

const hr = {
  border: "none",
  height: "1px",
  backgroundColor: "#C4C4C4",
  width: "100%",
};

const lineSpan = {
  position: "relative",
  top: "-20px",
  backgroundColor: "#fff",
  padding: "0px 10px",
  color: "#333",
};

const googleButton = {
  marginTop: "1rem",
  marginBottom: "2rem",
};

const SignInbuttonStyle = {
  width: "100%",
  padding: "0.8rem 0rem",
  fontSize: "1rem",
  color: "#777",
  fontWeight: "bold",
  textTransform: "initial",
  border: "1px solid #C4C4C4",
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const client = generateClient();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailerror, setEmailError] = useState(false);
  const [emailerrorMessage, setEmailErrorMessage] = useState(false);
  const [passworderror, setPasswordError] = useState(false);
  const [passerrorMessage, setPassErrorMessage] = useState(false);
  const [servererror, setServerError] = useState("");
  const [servererrorMessage, setServerErrorMessage] = useState("");
  const user = localStorage.getItem("user");
  const { state } = useLocation();

  const fetchData = async (user_id, userAttributes) => {
    try {
      const variables = {
        filter: {
          user_id: {
            eq: user_id,
          },
        },
        limit: 1000
      };

      const getUserData = await client.graphql({
        query: listUserInfos,
        variables: variables,
      });
      const result = getUserData.data.listUserInfos.items[0];
      if (result) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...userAttributes,
            role: "workspace_member",
          })
        );
        navigate("/choose-organization");
      }
    } catch (error) {
      console.error("Error creating todo:", error);
    }
  };

  const currentAuthenticatedUser = async () => {
    try {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      const groups = accessToken.payload["cognito:groups"];
      return groups;
    } catch (err) {
      console.log(err);
    }
  };

  async function handleFetchUserAttributes() {
    try {
      dispatch(setLoading(true));
      const userAttributes = await fetchUserAttributes();
      console.log("the user Attribute are::", userAttributes)
      const userGroups = await currentAuthenticatedUser();
      const userObjectString = JSON.stringify(userAttributes);
      if (userAttributes) {
        await fetchData(userAttributes?.sub, userAttributes);
      }
      dispatch(setUserInfo({ status: userObjectString }));
      dispatch(setLoading(false));
    } catch (error) {
      console.log(error);
    }
  }

  const onSignIn = async (event) => {
    event.preventDefault();
    if (!email && !password) {
      setPasswordError(true);
      setPassErrorMessage("Password required");
      setEmailError(true);
      setEmailErrorMessage("Email required");
      return;
    }
    if (!email) {
      setEmailError(true);
      setEmailErrorMessage("Email required");
      return;
    }
    if (!password) {
      setPasswordError(true);
      setPassErrorMessage("Password required");
      return;
    }
    localStorage.clear();
    try {
      dispatch(setLoading(true));
      const user = await signIn({
        username: email,
        password: password,
      });
      dispatch(setLoading(false));
      if (user.isSignedIn) {
        handleFetchUserAttributes();
      }
    } catch (error) {
      dispatch(setLoading(false));
      setServerError(true);
      setServerErrorMessage(error.message);
      console.log(error.message);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeEmail = (e) => {
    const trimmedEmail = e.target.value.trim();
    setEmail(trimmedEmail.replace(/\s{2,}/g, " "));
    setEmailError(false);
    setEmailErrorMessage("");
    setServerError(false);
    setServerErrorMessage("");
  };

  return (
    <form
      className="formContainer"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        // gap: "2rem",
      }}
    >
      {/* <div style={{ margin: "2rem" }} className="header-img">
        <img
          src="/faviconPNG.png"
          height="40px"
          width="220px"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      </div> */}
      <Card style={cardStyle} className="card-login-container">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <div style={{ margin: "12% 8%" }}>
              <div
                style={{ margin: "1.5rem 0rem", textAlign: "center" }}
                className="form-img-container"
              >
                <img
                  src="/faviconPNG.png"
                  height="70px"
                  width="430px"
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                  className="form-img"
                />
              </div>
              {/* <div style={googleButton}>
              <Button variant="outlined" style={buttonStyle}>
                <span>
                  <img
                    src="/7611770.png"
                    height="30px"
                    width="30px"
                    alt="Google Icon"
                  />
                </span>
                Continue with Google
              </Button>
            </div> */}
              {/* <div style={lineContianer}>
              <hr style={hr} />
              <span style={lineSpan}>OR</span>
            </div> */}
              <div>
                <TextField
                  error={emailerror}
                  helperText={emailerror ? emailerrorMessage : null}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  onChange={(e) => handleChangeEmail(e)}
                  value={email}
                  style={{ width: "100%", marginBottom: "1rem" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailIcon style={{ color: "#777" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  error={passworderror}
                  helperText={passworderror ? passerrorMessage : null}
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPassErrorMessage("");
                    setPasswordError(false);
                    setServerError(false);
                    setServerErrorMessage("");
                  }}
                  value={password}
                  type={showPassword ? "text" : "password"}
                  style={{ width: "100%", marginBottom: "0.5rem" }}
                  className="password-input"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityIcon style={{ color: "#777" }} />
                          ) : (
                            <VisibilityOffIcon style={{ color: "#777" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "right",
                  marginBottom: "1.5rem",
                }}
                className="forgot-pass-container"
              >
                <span
                  style={{ cursor: "pointer", color: "#777" }}
                  onClick={() => navigate("/reset_password_request")}
                >
                  Forgot password?
                </span>
              </div>
              <div
                className="forgot-pass--mob-container"
                onClick={() => navigate("/reset_password_request")}
              >
                <span
                  style={{ cursor: "pointer", color: "red" }}
                  className="forgot-pass-txt"
                >
                  Forgot password?
                </span>
              </div>
              {servererror ? (
                <div style={{ marginBottom: "0.7rem" }}>
                  <Alert severity="error">{servererrorMessage}</Alert>
                </div>
              ) : null}
              <div>
                <Button
                  variant="outlined"
                  style={SignInbuttonStyle}
                  onClick={(e) => onSignIn(e)}
                  type="submit"
                >
                  Sign in
                </Button>
              </div>

              {state?.screen === "invitation" ? (
                <div
                  style={{
                    color: "#777",
                    marginTop: "2.5rem",
                    textAlign: "center",
                  }}
                >
                  Hey <b>{state.email}</b>! You are already a registered user,
                  please login and join your Workspace
                </div>
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    color: "#777",
                    marginTop: "2.5rem",
                    textAlign: "center",
                  }}
                  className="register-phrase-container"
                  onClick={() => navigate("/signup")}
                >
                  Don't have an account?
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <style>
              {`@media (max-width: 600px) {
              .login-image {
                display: none;
              }
            }`}
            </style>
            <img
              src="/Screenshot from 2024-01-12 16-43-51.png"
              style={imageStyle}
              alt="Login Background"
              className="login-image"
            />
          </Grid>
        </Grid>
      </Card>
    </form>
  );
};

export default Login;

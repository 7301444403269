import React, { useState } from "react";
import {
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "../components/Footer";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { setLoading, showToast } from "../redux/userSlice";
import { generateClient, post } from "aws-amplify/api";
import {
  listTeamInvitations,
  listUserInfos,
  listWorkspaceInfos,
  listWorkspaceTeams,
} from "../graphql/queries";
import {
  createTeamInvitation,
  deleteTeamInvitation,
  deleteUserInfo,
  updateWorkspaceInfo,
  updateWorkspaceTeam,
} from "../graphql/mutations";
import { PulseLoader } from "react-spinners";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import uuid from "react-uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useMediaQuery } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import "../Css/team.css";
import { useNavigate } from "react-router-dom";

const container = {
  margin: "2rem 4rem",
};
const title = {
  fontSize: "2rem",
  margin: "0px",
  marginBottom: "1rem",
};
const organizationCard = {
  padding: "1.5rem 2rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  minHeight: "68vh",
};
const headingAlign = {
  display: "flex",
  justifyContent: "space-between",
  margin: "0.3rem 3rem",
};
const rowAlign = {
  display: "flex",
  justifyContent: "space-between",
  margin: "0.3rem 3rem",
};
const statusStyleActive = {
  color: "#1C984D",
  fontWeight: "bold",
  backgroundColor: "#D8EBDD",
  padding: "0.1rem 0.5rem",
  display: "inline-block",
  borderRadius: "4px",
};
const statusStyleInActive = {
  color: "#fff",
  fontWeight: "bold",
  backgroundColor: "#FFC205",
  padding: "0.1rem 0.5rem",
  display: "inline-block",
  borderRadius: "4px",
};

const nameContainerStyle = {
  display: "inline-block",
  maxWidth: "150px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  verticalAlign: "middle",
};

const Team = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const client = generateClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const [userDetail, setUserDetail] = React.useState(null);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const [cancelReqModalOpen, setCancelReqModal] = React.useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [workspaceDetail, setWorkspaceDetail] = React.useState(null);
  const [error, setError] = useState(false);
  const [RoleError, setRoleError] = useState(false);
  const [regexError, setRegexError] = useState(false);
  const [teamID, setTeamID] = useState("");
  const [selectRole, setSelectRole] = useState("");
  const [listTeamUsers, setListTeamUsers] = useState(null);
  const [idToDelete, setIdToDelete] = useState(null);
  const [cancelReqID, setCancelReqID] = useState(null);
  const [alignment, setAlignment] = React.useState("Active");
  const [invitationDetail, setInvitationDetail] = useState(null);

  React.useEffect(() => {
    fetchData();
    fetchInvitationData();
  }, [alignment]);

  React.useEffect(() => {
    if (userDetail?.length) {
      WorkspaceInfoWithTeams();
      fetchWorkspaceData();
    }
  }, [userDetail]);

  const WorkspaceInfoWithTeams = async () => {
    setLoader(true);
    try {
      const variables = {
        filter: {
          id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const data = await client.graphql({
        query: listWorkspaceInfos,
        variables: variables,
      });
      setTeamID(data.data.listWorkspaceInfos.items?.[0]?.workspaceteamID);
      const userIdis = data.data.listWorkspaceInfos.items?.[0]?.user_id;
      if (userIdis) {
        const userPromises = userIdis.map(async (id) => {
          const variables = {
            filter: {
              user_id: {
                eq: id,
              },
              workspace_id: {
                eq: selectedWorkspaceId,
              },
            },
          };
          const getUserData = await client.graphql({
            query: listUserInfos,
            variables: variables,
          });
          return getUserData?.data?.listUserInfos?.items[0];
        });
        const usersData = await Promise.all(userPromises);
        setListTeamUsers(usersData);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const fetchInvitationData = async () => {
    setLoader(true);
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const invitationData = await client.graphql({
        query: listTeamInvitations,
        variables: variables,
      });
      const result = invitationData.data.listTeamInvitations.items;
      const requiredResult = result?.filter((item) => item.status !== false);
      setInvitationDetail(requiredResult);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const fetchData = async () => {
    try {
      setLoader(true);
      const variables = {
        filter: {
          email: {
            eq: userData.email,
          },
        },
      };

      const getUserData = await client.graphql({
        query: listUserInfos,
        variables: variables,
      });

      const result = getUserData?.data?.listUserInfos?.items;
      setUserDetail(result);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDeleteModal = (id) => {
    setDeleteModalOpen(true);
    setIdToDelete(id);
  };

  const handleCloseDeleteModal = () => {
    setIdToDelete(null);
    setDeleteModalOpen(false);
  };

  const handleClickOpenCancelReqModal = (id) => {
    setCancelReqModal(true);
    setCancelReqID(id);
  };

  const handleCloseCancelReqModal = () => {
    setCancelReqID(null);
    setCancelReqModal(false);
  };

  const fetchWorkspaceData = async () => {
    try {
      setLoader(true);
      const variables = {
        filter: {
          id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getWorkspaceData = await client.graphql({
        query: listWorkspaceInfos,
        variables: variables,
      });
      const result = getWorkspaceData.data.listWorkspaceInfos.items;
      setWorkspaceDetail(result);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleSendInvitation = async () => {
    setLoader(true);
    if (!email && !selectRole) {
      setRoleError(true);
      setError(true);
      setLoader(false);
      return;
    }
    if (!email) {
      setError(true);
      setLoader(false);
      return;
    }

    if (!selectRole) {
      setRoleError(true);
      setLoader(false);
      return;
    }

    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!emailRegex.test(email)) {
      setRegexError(true);
      setLoader(false);
      return;
    }
    let id;
    setIsLoading(true);
    id = uuid();
    const detail = {
      id: id,
      email: email,
      workspace: workspaceDetail?.[0]?.name,
      workspace_id: workspaceDetail?.[0]?.id,
      status: true,
      workspaceteamID: teamID,
      role: selectRole,
    };

    const isEmailFound = listTeamUsers.some((user) => user.email === email);
    const isEmailInvitationFound = invitationDetail.some(
      (user) => user.email === email
    );

    if (isEmailFound) {
      setIsLoading(false);
      setLoader(false);
      dispatch(
        showToast({
          message: "User already exists in your workspace",
          type: "error",
        })
      );
      return;
    } else if (isEmailInvitationFound) {
      setIsLoading(false);
      setLoader(false);
      dispatch(
        showToast({
          message: "You have already requested this on this email",
          type: "error",
        })
      );
      return;
    } else {
      try {
        const result = await client.graphql({
          query: createTeamInvitation,
          variables: { input: detail },
        });

        if (result.data.createTeamInvitation) {
          const payload = {
            recipient: email,
            subject: `${userData?.name} has invited you to the ${workspaceDetail?.[0]?.name} Workspace on Rothbright`,
            mailBody: `Join the ${workspaceDetail?.[0]?.name} Workspace to work on projects with ${userData?.name}. Get started by clicking on https://www.rothbright.app/signup`,
          };
          try {
            const restOperation = post({
              apiName: "emailInvitation",
              path: "/emailInvitation",
              options: {
                body: payload,
              },
            });
            const { body } = await restOperation.response;
          } catch (error) {
            console.log("The error while sending the invitation::", error);
          }
        }
        setIsSent(true);
        setIsLoading(false);
        setEmail("");
        setSelectRole("");
        setLoader(false);
        setOpen(false);
      } catch (error) {
        setIsLoading(false);
        setLoader(false);
        dispatch(showToast({ message: error.message, type: "error" }));
        console.error("Error creating todo:", error);
      }
    }
  };

  const handleOnChange = (e) => {
    setEmail(e.target.value);
    setError(false);
    setRegexError(false);
    setIsSent(false);
  };

  const removeTeamMember = async () => {
    dispatch(setLoading(true));
    setDeleteModalOpen(false);
    try {
      const variables = {
        input: {
          id: idToDelete.id,
        },
      };

      const deletePlans = await client.graphql({
        query: deleteUserInfo,
        variables: variables,
      });
      const result = deletePlans.data.deleteUserInfo.items;

      const listWorkspaceVariables = {
        filter: {
          id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getWSdata = await client.graphql({
        query: listWorkspaceInfos,
        variables: listWorkspaceVariables,
      });

      const userIdisWorkspace =
        getWSdata?.data?.listWorkspaceInfos?.items[0].user_id;
      const filteredUserIdisWorkspace = userIdisWorkspace?.filter(
        (id) => id !== idToDelete.user_id
      );

      const updatedResultWorkspace = await client.graphql({
        query: updateWorkspaceInfo,
        variables: {
          input: {
            id: selectedWorkspaceId,
            user_id: filteredUserIdisWorkspace,
          },
        },
      });

      const listTeamsVariables = {
        filter: {
          workspace_id: {
            contains: selectedWorkspaceId,
          },
        },
      };

      const getUserData = await client.graphql({
        query: listWorkspaceTeams,
        variables: listTeamsVariables,
      });
      const userIdis = getUserData?.data?.listWorkspaceTeams?.items[0].user_id;
      const filteredUserIdis = userIdis?.filter(
        (id) => id !== idToDelete.user_id
      );

      const updatedResult = await client.graphql({
        query: updateWorkspaceTeam,
        variables: {
          input: {
            id: getUserData?.data?.listWorkspaceTeams?.items[0].id,
            user_id: filteredUserIdis,
          },
        },
      });
      WorkspaceInfoWithTeams();
      setIdToDelete(null);
      dispatch(setLoading(false));
      dispatch(
        showToast({ message: "Member removed successfully", type: "success" })
      );
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error saving data to the database:", error);
      dispatch(showToast({ message: error.message, type: "error" }));
    }
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleCancelRequest = async () => {
    setCancelReqModal(false);
    setLoader(true);
    try {
      const variables = {
        input: {
          id: cancelReqID,
        },
      };

      const deleteRequest = await client.graphql({
        query: deleteTeamInvitation,
        variables: variables,
      });
      setCancelReqID(null);
      await fetchInvitationData();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error saving data to the database:", error);
      dispatch(showToast({ message: error.message, type: "error" }));
    }
  };

  return (
    <>
      <div
        style={{ ...container, margin: isMobile ? "1rem 1rem" : "2rem 4rem" }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ marginTop: isMobile && 10 }}>
                <p style={{ ...title, fontSize: isMobile ? "1rem" : "2rem" }}>
                  Team
                </p>
              </div>
              {userData?.role !== "workspace_member" && (
                <div
                  style={{
                    marginBottom: isMobile && 10,
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Active">Active</ToggleButton>
                    <ToggleButton value="Pending">Pending</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              )}
              {userData?.role !== "workspace_member" && (
                <div style={{ marginTop: isMobile && 10 }}>
                  <IconButton
                    style={{ backgroundColor: "#1692E0", padding: "0.5rem" }}
                    onClick={handleClickOpen}
                  >
                    <AddIcon
                      style={{
                        color: "#fff",
                        fontSize: isMobile ? "0.9rem" : "2rem",
                      }}
                    />
                  </IconButton>
                </div>
              )}
            </div>
          </Grid>
        </Grid>

        {isMobile && alignment === "Active" ? (
          <div className="tableInnerWrapper" style={{ background: "white" }}>
            <div className="table-info">
              <table className="d-table">
                <thead>
                  <tr>
                    <th style={{ width: "170px" }}>Title</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </table>
              {listTeamUsers?.length === 0 ? (
                <>
                  {" "}
                  <tbody>
                    <tr className="norecord">
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  </tbody>
                </>
              ) : (
                <>
                  {listTeamUsers?.length &&
                    listTeamUsers.map((item, index) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 8,
                            marginTop: 8,
                          }}
                          className="tableConatiner"
                        >
                          <div>
                            <img
                              src={item?.display_picture}
                              style={{
                                height: "150px",
                                width: "150px",
                                borderRadius: "8%",
                                backgroundColor: "transparent",
                              }}
                              className="table-img"
                            />
                          </div>
                          <div style={{ marginLeft: 10 }}>
                            <div className="td-name">
                              <div className="itemtitle">
                                <div className="td-name">Name:</div>
                                <span
                                  style={nameContainerStyle}
                                  onClick={() =>
                                    navigate("/profile-setting", {
                                      state: {
                                        screen: "team",
                                      },
                                    })
                                  }
                                >{`${item.user_name_first}  ${item.user_name_second}`}</span>
                              </div>
                              <div className="itemtitle">
                                <div className="td-name">Email:</div>
                                <span style={nameContainerStyle}>
                                  {`${item?.email}`}
                                </span>
                              </div>
                              <div className="itemtitle">
                                <div className="td-name">Role:</div>
                                <span style={{ padding: 0 }}>
                                  {item?.role === "workspace_admin"
                                    ? "Workspace Admin"
                                    : item?.role === "workspace_member"
                                    ? "Workspace Member"
                                    : item?.role === "super_admin"
                                    ? "Super Admin"
                                    : "Advisor"}
                                </span>
                              </div>

                              <div
                                className="itemtitle"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <div className="td-name">Created At:</div>
                                  <span>{formatDate(item?.createdAt)}</span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "end",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    disabled={item?.role === "super_admin"}
                                    onClick={() =>
                                      handleClickOpenDeleteModal(item)
                                    }
                                    style={{
                                      ...statusStyleActive,
                                      color:
                                        item?.role === "super_admin"
                                          ? "grey"
                                          : "#E55A54",
                                      backgroundColor: "",
                                      padding: 0,
                                      fontSize: "0.7rem",
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {index < listTeamUsers.length - 1 && (
                          <Divider className="dividerTable" />
                        )}
                      </>
                    ))}
                </>
              )}
            </div>
          </div>
        ) : isMobile && alignment !== "Active" ? (
          <div className="tableInnerWrapper" style={{ background: "white" }}>
            <div className="table-info">
              <table className="d-table">
                <thead>
                  <tr>
                    <th style={{ width: "170px" }}>Invited To</th>
                    <th>Assigned Role</th>
                    <th>Invited At</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </table>
              {invitationDetail?.length === 0 ? (
                <>
                  {" "}
                  <tbody>
                    <tr className="norecord">
                      <td colSpan={7} style={{ textAlign: "center" }}>
                        No Record Found
                      </td>
                    </tr>
                  </tbody>
                </>
              ) : (
                <>
                  {invitationDetail?.length &&
                    invitationDetail.map((item, index) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 8,
                            marginTop: 8,
                          }}
                          className="tableConatiner"
                        >
                          <div>
                            <img
                              key={item?.id}
                              src={
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTet-jk67T6SYdHW04eIMLygHzEeJKobi9zdg&usqp=CAU"
                              }
                              style={{
                                height: "150px",
                                width: "150px",
                                borderRadius: "8%",
                                backgroundColor: "#dddddd",
                              }}
                              className="table-img"
                            />
                          </div>
                          <div style={{ marginLeft: 10 }}>
                            <div className="td-name">
                              <div className="itemtitle">
                                <div className="td-name">Email:</div>
                                <span style={nameContainerStyle}>
                                  {`${item?.email}`}
                                </span>
                              </div>
                              <div className="itemtitle">
                                <div className="td-name">Status:</div>
                                <span
                                  style={{
                                    ...statusStyleActive,
                                    color: "#ffc205",
                                    backgroundColor: "",
                                    padding: 0,
                                  }}
                                >
                                  {item?.status && "Pending"}
                                </span>
                              </div>
                              <div className="itemtitle">
                                <div className="td-name">Role:</div>
                                <span style={{ padding: 0 }}>
                                  {item?.role === "workspace_admin"
                                    ? "Workspace Admin"
                                    : item?.role === "workspace_member"
                                    ? "Workspace Member"
                                    : item?.role === "super_admin"
                                    ? "Super Admin"
                                    : "Advisor"}
                                </span>
                              </div>

                              <div
                                className="itemtitle"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <div className="td-name">Created At:</div>
                                  <span>{formatDate(item?.createdAt)}</span>
                                </div>
                                <div>
                                  <div className="td-name">Action</div>
                                  <span
                                    onClick={() =>
                                      handleClickOpenCancelReqModal(item?.id)
                                    }
                                    style={{
                                      ...statusStyleActive,
                                      color: "red",
                                      backgroundColor: "",
                                      padding: 0,
                                    }}
                                  >
                                    Cancel
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {index < invitationDetail.length - 1 && <Divider />}
                      </>
                    ))}
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            {alignment === "Active" ? (
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Card style={organizationCard}>
                    <div style={headingAlign} className="headAlign-cart">
                      <Grid container spacing={0}>
                        <Grid item xs={5}>
                          <h4>Name</h4>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          <h4>Role</h4>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          <h4>Created</h4>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          <h4>Status</h4>
                        </Grid>
                        {userData?.role !== "workspace_member" && (
                          <Grid item xs={1} style={{ textAlign: "center" }}>
                            <h4>Action</h4>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                    <Divider light />
                    {loader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "50vh",
                        }}
                      >
                        <PulseLoader color="#ACD6EB" />
                      </div>
                    ) : (
                      <>
                        <>
                          {listTeamUsers &&
                            listTeamUsers.map((user, index, array) => (
                              <>
                                <div
                                  style={rowAlign}
                                  className="rowAlign-cart"
                                  key={index}
                                >
                                  <Grid container spacing={0}>
                                    <Grid
                                      item
                                      xs={5}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "0.5rem",
                                        }}
                                      >
                                        <img
                                          key={user?.id}
                                          src={user?.display_picture}
                                          style={{
                                            height: "50px",
                                            width: "50px",
                                            borderRadius: "8%",
                                            backgroundColor: "#dddddd",
                                          }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "start",
                                          }}
                                        >
                                          <div>
                                            <h3
                                              style={{
                                                margin: "0px",
                                                textAlign: "start",
                                              }}
                                              onClick={() =>
                                                navigate("/profile-setting", {
                                                  state: {
                                                    screen: "team",
                                                  },
                                                })
                                              }
                                            >
                                              {`${user?.user_name_first}  ${user?.user_name_second}`}
                                            </h3>
                                          </div>
                                          <div>{user?.email}</div>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p>
                                        {user?.role === "workspace_admin"
                                          ? "Workspace Admin"
                                          : user?.role === "workspace_member"
                                          ? "Workspace Member"
                                          : user?.role === "super_admin"
                                          ? "Super Admin"
                                          : "Advisor"}
                                      </p>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p>{formatDate(user?.createdAt)}</p>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p style={statusStyleActive}>Active</p>
                                    </Grid>
                                    {userData?.role !== "workspace_member" && (
                                      <Grid
                                        item
                                        xs={1}
                                        style={{
                                          textAlign: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <IconButton
                                          color="inherit"
                                          disabled={
                                            user?.role === "super_admin"
                                          }
                                          onClick={() =>
                                            handleClickOpenDeleteModal(user)
                                          }
                                        >
                                          <DeleteIcon
                                            style={{
                                              color:
                                                user?.role === "super_admin"
                                                  ? "grey"
                                                  : "#E55A54",
                                            }}
                                          />
                                        </IconButton>
                                      </Grid>
                                    )}
                                  </Grid>
                                </div>
                                {index !== array?.length - 1 && (
                                  <Divider light />
                                )}
                              </>
                            ))}
                        </>
                      </>
                    )}
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Card style={organizationCard}>
                    <div style={headingAlign} className="headAlign-cart">
                      <Grid container spacing={0}>
                        <Grid item xs={5}>
                          <h4>Invited To</h4>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          <h4>Assigned Role</h4>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          <h4>Invited At</h4>
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "center" }}>
                          <h4>Status</h4>
                        </Grid>
                        {userData?.role !== "workspace_member" && (
                          <Grid item xs={2} style={{ textAlign: "center" }}>
                            <h4>Action</h4>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                    <Divider light />
                    {loader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "50vh",
                        }}
                      >
                        <PulseLoader color="#ACD6EB" />
                      </div>
                    ) : (
                      <>
                        <>
                          {invitationDetail &&
                            invitationDetail.map((user, index, array) => (
                              <>
                                <div style={rowAlign} className="rowAlign-cart">
                                  <Grid container spacing={0}>
                                    <Grid
                                      item
                                      xs={5}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "0.5rem",
                                        }}
                                      >
                                        <img
                                          key={user.id}
                                          src={
                                            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTet-jk67T6SYdHW04eIMLygHzEeJKobi9zdg&usqp=CAU"
                                          }
                                          style={{
                                            height: "50px",
                                            width: "50px",
                                            borderRadius: "8%",
                                            backgroundColor: "#dddddd",
                                          }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "start",
                                          }}
                                        >
                                          <div></div>
                                          <div>{user.email}</div>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p>
                                        {user.role === "workspace_admin"
                                          ? "Workspace Admin"
                                          : user.role === "workspace_member"
                                          ? "Workspace Member"
                                          : user.role === "super_admin"
                                          ? "Super Admin"
                                          : "Advisor"}
                                      </p>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p>{formatDate(user.createdAt)}</p>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p
                                        style={
                                          user.status === "Active"
                                            ? statusStyleActive
                                            : statusStyleInActive
                                        }
                                      >
                                        {user.status && "Pending"}
                                      </p>
                                    </Grid>
                                    {userData?.role !== "workspace_member" && (
                                      <Grid
                                        item
                                        xs={2}
                                        style={{
                                          textAlign: "center",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          style={{
                                            textTransform: "inherit",
                                            padding: "0.1rem 0.3rem",
                                          }}
                                          className="cancel-req-btn"
                                          onClick={() =>
                                            handleClickOpenCancelReqModal(
                                              user.id
                                            )
                                          }
                                        >
                                          Cancel Request
                                        </Button>
                                      </Grid>
                                    )}
                                  </Grid>
                                </div>
                                {index !== array?.length - 1 && (
                                  <Divider light />
                                )}
                              </>
                            ))}
                        </>
                      </>
                    )}
                  </Card>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {!isMobile && (
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        )}
        <Dialog open={open} disableBackdropClick>
          <DialogTitle>Invite a team member</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To invite a team member for this workspace, just enter it's email
              and send invitation
            </DialogContentText>
            <TextField
              error={error || regexError}
              helperText={
                error ? "Email required" : regexError ? "Email not valid" : null
              }
              autoFocus
              required
              margin="dense"
              id="name"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={(e) => handleOnChange(e)}
            />
            <TextField
              error={RoleError}
              helperText={RoleError ? "Role required" : null}
              select
              variant="standard"
              size="small"
              margin="normal"
              fullWidth
              name="selectRole"
              label="Select Role"
              value={selectRole}
              onChange={(e) => {
                setSelectRole(e.target.value);
                setRoleError(false);
                setIsSent(false);
              }}
              SelectProps={{
                IconComponent: selectRole ? () => null : undefined,
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: "300px",
                    },
                  },
                },
              }}
              InputProps={{
                endAdornment: selectRole && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear selection"
                      onClick={() => setSelectRole("")}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="workspace_member">Workspace Member</MenuItem>
              <MenuItem value="workspace_admin">Workspace Admin</MenuItem>
            </TextField>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleClose}
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSendInvitation}
              style={{ textTransform: "inherit" }}
              disabled={isLoading || isSent}
              startIcon={
                isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
              endIcon={isSent ? <CheckIcon /> : null}
            >
              {isSent ? "Invitation Sent" : "Send Invitation"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={deleteModalOpen} disableBackdropClick>
          <DialogTitle>Confirm remove team member</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove team member from your workspace?
              This member will permanantly be removed from your workspace.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleCloseDeleteModal}
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={removeTeamMember}
              style={{ textTransform: "inherit" }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={cancelReqModalOpen} disableBackdropClick>
          <DialogTitle>Confirm cancel request</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel request which you have sent to the
              team member from your workspace?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleCloseCancelReqModal}
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleCancelRequest}
              style={{ textTransform: "inherit" }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Team;

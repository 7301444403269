import React, { useState } from "react";
import {
  Button,
  Card,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import { generateClient } from "aws-amplify/api";
import { listProductCategories } from "../../graphql/queries";
import {
  createProductCategories,
  deleteProductCategories,
  updateProductCategories,
} from "../../graphql/mutations";
import { PulseLoader } from "react-spinners";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import uuid from "react-uuid";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { setLoading, showToast } from "../../redux/userSlice";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";

const container = {
  margin: "0rem 1rem",
};
const organizationCard = {
  padding: "1.5rem 2rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  minHeight: "68vh",
};

const AllCategories = () => {
  const client = generateClient();
  const dispatch = useDispatch();
  const [categories, setCategories] = React.useState(null);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [error, setError] = useState(false);
  const [eidtID, setEditID] = useState("");

  React.useEffect(() => {
    fetchCategoryDetail();
  }, []);

  async function fetchAllProductCategories() {
    let allItems = [];
    let nextToken = null;

    do {
      const getCategoriesData = await client.graphql({
        query: listProductCategories,
        variables: {
          nextToken,
        },
      });

      const response = getCategoriesData.data.listProductCategories.items;
      allItems = [...allItems, ...response];
      nextToken = getCategoriesData.data.listProductCategories.nextToken;
    } while (nextToken);

    return allItems;
  }

  const fetchCategoryDetail = async () => {
    try {
      setLoader(true);
      const response = await fetchAllProductCategories();
      const sortedResult = response?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setCategories(sortedResult);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleAddCategory = async () => {
    if (!name) {
      setError(true);
      dispatch(setLoading(false));
      return;
    }
    setOpen(false);
    let id;
    try {
      id = uuid();
      const detail = {
        id: id,
        name: name,
        status: true,
        is_active: true,
      };
      const result = await client.graphql({
        query: createProductCategories,
        variables: { input: detail },
      });
      await fetchCategoryDetail();
      setName("");
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleRemoveCategory = async (id) => {
    try {
      const variables = {
        input: {
          id: id,
        },
      };

      const deleteCatogory = await client.graphql({
        query: deleteProductCategories,
        variables: variables,
      });
      await fetchCategoryDetail();
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleEditCategory = async (id) => {
    if (!name) {
      setError(true);
      dispatch(setLoading(false));
      return;
    }
    setOpen(false);
    try {
      const detail = {
        id: id,
        name: name,
      };
      const result = await client.graphql({
        query: updateProductCategories,
        variables: { input: detail },
      });
      await fetchCategoryDetail();
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenEdit = (id, name) => {
    setEditID(id);
    setName(name);
    setOpen(true);
  };

  const handleCloseEdit = () => {
    setOpen(false);
    setEditID("");
    setName("");
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: "name",
      headerName: "Name",
      width: 400,
      renderCell: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.row.name}</div>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 200,
      renderCell: (params) => (
        <>
          <IconButton
            color="inherit"
            onClick={() => handleClickOpenEdit(params.row.id, params.row.name)}
          >
            <EditRoundedIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 200,
      renderCell: (params) => (
        <>
          <IconButton
            style={{ color: "#E55A54" }}
            onClick={() => handleRemoveCategory(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const rows =
    categories?.map((item) => ({
      id: item.id,
      name: item.name,
    })) || [];

  return (
    <>
      <div style={container}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card style={organizationCard}>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50vh",
                  }}
                >
                  <PulseLoader color="#ACD6EB" />
                </div>
              ) : (
                <DataGrid
                  rows={rows}
                  columns={columns.filter((column) => column.field !== "id")}
                  getRowId={(row) => row.id}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 7,
                      },
                    },
                  }}
                  pageSizeOptions={[7, 25, 50, 100]}
                  density="comfortable"
                  sx={{
                    border: 0,
                    borderColor: "primary.light",
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                  }}
                />
              )}
            </Card>
          </Grid>
        </Grid>
        <Dialog open={open} disableBackdropClick>
          <DialogTitle>
            {eidtID ? "Edit Category" : "Add a new Category"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can {eidtID ? "edit" : "add"} a new category by filling these
              fields. Super Admin can just add new categories.
            </DialogContentText>
            <TextField
              error={error}
              helperText={error ? "Name required" : null}
              autoFocus
              margin="dense"
              name="name"
              label="Category Name"
              type="text"
              fullWidth
              variant="standard"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setError(false);
              }}
            />
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleCloseEdit}
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={
                eidtID ? () => handleEditCategory(eidtID) : handleAddCategory
              }
              style={{ textTransform: "inherit" }}
            >
              {eidtID ? "Update" : "Add Category"}
            </Button>
          </DialogActions>
        </Dialog>
        <Fab
          sx={{
            position: "absolute",
            bottom: 30,
            right: 30,
            backgroundColor: "#1976d2",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          aria-label={"Add"}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AllCategories;

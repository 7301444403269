import { Container, Typography, Grid, Box, Divider } from "@mui/material";
import Logo from "../Assets/faviconPNG.png";

const ShopFooter = () => {
  return (
    <footer style={{ marginTop: "3rem" }}>
      <Container maxWidth="xl" style={{ marginTop: "3rem" }}>
        <Grid container>
          <Grid item xs={12} sm={6} lg={3} md={3}>
            <Box style={{ maxWidth: 150, maxHeight: 50 }}>
              <img
                src={Logo}
                alt="Logo"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Typography
              variant="body1"
              gutterBottom
              component="a"
              href="mailto:team@rothbright.com"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              team@rothbright.com
            </Typography>
            <Box mt={3}>
              <Typography
                variant="body1"
                gutterBottom
                component="a"
                href="tel:866-536-4908"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                866-536-4908
              </Typography>
              <Box display="flex" alignItems="center" mt={1}></Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={3}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              component="a"
              href="https://rothbright.com/our-solutions/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              Services
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              component="a"
              href="https://rothbright.com/company/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              Company
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              component="a"
              href="https://rothbright.com/portfolio/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              Portfolio
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={3}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="body1"
              gutterBottom
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              Pricing
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              component="a"
              href="https://rothbright.com/blog/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              Blog
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              component="a"
              href="https://rothbright.com/contact/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              Contact
            </Typography>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            xs={12}
            sm={6}
            lg={2}
            ml={1}
            md={2}
            direction={"column"}
          >
            <Typography
              variant="body1"
              gutterBottom
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              © 2023 Rothbright Inc. All Rights Reserved.
            </Typography>

            <Typography
              variant="body1"
              gutterBottom
              component="a"
              href="https://rothbright.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              Privacy Policy
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              component="a"
              href="https://rothbright.com/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              Terms & Conditions
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default ShopFooter;

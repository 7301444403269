import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Footer from "../components/Footer";
import { updateSubscriberCard, updateUserBilling, updateUserInfo } from "../graphql/mutations";
import { setLoading, showToast } from "../redux/userSlice";
import { generateClient } from "aws-amplify/api";
import { useDispatch } from "react-redux";
import { listSubscriberCards, listUserBillings, listUserInfos } from "../graphql/queries";
import countriesList from "../../src/countries.json";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PaymentForm from "./credit_card";
import {
  attachPaymentMethod,
  createPaymentMethod,
  createSetupIntent,
  createStripeCustomer,
  updateCustomerDefaultPaymentMethod,
} from "../utills/stripeApis/stripeApi";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const container = {
  margin: "0rem 4rem",
};
const organizationCard = {
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
};

const reorderedCountriesList = [
  { name: "United States" },
  ...countriesList.filter((country) => country.name !== "United States"),
];

const merchant_name = process.env.REACT_APP_MERCHANT_LOGIN_NAME;
const transaction_key = process.env.REACT_APP_MERCHANT_TRANSACTION_KEY;
const authorize_api = process.env.REACT_APP_AUTHORIZE_API;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const StripeSettings = () => {
  return (
    <Elements stripe={stripePromise}>
      <AddressSettingForm />
    </Elements>
  );
};
const AddressSettingForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const client = generateClient();
  const dispatch = useDispatch();
  const [userBillingDetail, setUserBillingDetail] = React.useState(null);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [userDetail, setUserDetail] = React.useState(null);
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const [subscriberData, setSubscriberData] = useState(null);
  const [open, setOpen] = useState(false);
  const [cardDataAvailable, setCardDataAvailable] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState({
    address: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
    cardNumber: "",
    holderName: "",
    expiry: "",
    expiryYear: "",
    expiryMonth: "",
    cvc: "",
  });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    fetchWorkspaceData();
    fetchData();
  }, []);
  const fetchAllUserBillings = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 10000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserBillings,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listUserBillings.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserBillings.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchWorkspaceData = async () => {
    try {
      const result = await fetchAllUserBillings(selectedWorkspaceId);
      if (result?.[0]) {
        setCardDataAvailable(true)
      }
      setUserBillingDetail(result);
      setUpdatedAddress({
        ...updatedAddress,
        address: result?.[0]?.address,
        city: result?.[0]?.city,
        country: result?.[0]?.country,
        state: result?.[0]?.state || "",
        postalCode: result?.[0]?.postal_code,
        cardNumber: result?.[0]?.card_number,
        holderName: result?.[0]?.holderName,
        expiry: `${result?.[0]?.expiry_month}/${result?.[0]?.expiry_year}`,
        expiryYear: result?.[0]?.expiry_year,
        expiryMonth: result?.[0]?.expiry_month,
        cvc: result?.[0]?.cvc,
      });

      //   dispatch(setLoading(false));
    } catch (error) {
      //   dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };
  const fetchData = async () => {
    try {
      // dispatch(setLoading(true));
      const variables = {
        filter: {
          user_id: {
            eq: userData.sub,
          },
        },
      };

      const getUserData = await client.graphql({
        query: listUserInfos,
        variables: variables,
      });
      const res = getUserData.data.listUserInfos.items;
      const result = res?.filter(
        (id) => id.workspace_id === selectedWorkspaceId
      );
      setUserDetail(result);

      // dispatch(setLoading(false));
    } catch (error) {
      // dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };
  useEffect(() => {
    const fetchSubscriberData = async () => {
      try {
        setLoading(true);
        const variables = {
          filter: {
            workspace_id: {
              eq: selectedWorkspaceId,
            },
          },
        };
        const getSubData = await client.graphql({
          query: listSubscriberCards,
          variables: variables,
        });
        const res = getSubData.data.listSubscriberCards.items;
        setSubscriberData(res);
        setLoading(false);
      } catch (error) {
        console.error("Error creating todo:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriberData();
  }, []);

  const handleUpdatePaymentAddress = async () => {
    dispatch(setLoading(true));
    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.");
      dispatch(showToast({ message: "Stripe has not loaded", type: "error" }));
      dispatch(setLoading(false));
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      console.error("Card element not found. Make sure it's rendered in the component.");
      dispatch(showToast({ message: "Card element not found", type: "error" }));
      dispatch(setLoading(false));
      return;
    }

    if (!updatedAddress.holderName) {
      dispatch(showToast({ message: "Please enter a holder name", type: "error" }));
      dispatch(setLoading(false));
      return;
    }
    function getCountryCode(countryName) {
      const country = countriesList.find(
        (c) => c.name.toLowerCase() === countryName.trim().toLowerCase()
      );
      return country ? country.code : countryName;
    }

    const countryCode = getCountryCode(updatedAddress.country);

    try {
      let paymentMethodId;
      let paymentMethodData;

      // Create Stripe customer if doesn't exist
      if (!subscriberData?.[0]?.stripe_customer_id) {
        try {
          const stripeCustomerId = await createStripeCustomer(userData.email);
          if (stripeCustomerId) {
            const variable = {
              id: subscriberData?.[0]?.id,
              stripe_customer_id: stripeCustomerId,
            };
            const variableUser = {
              id: userDetail?.[0]?.id,
              stripe_customer_id: stripeCustomerId,
            };
            await client.graphql({
              query: updateSubscriberCard,
              variables: { input: variable },
            });
            await client.graphql({
              query: updateUserInfo,
              variables: { input: variableUser },
            });

            // Update subscriberData with new customer ID
            subscriberData[0] = { ...subscriberData[0], stripe_customer_id: stripeCustomerId };
          }
        } catch (error) {
          console.error("Error creating stripe customer:", error);
          return;
        }
      }

      // Create payment method (both for new and existing cases)
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: updatedAddress.holderName,
          address: {
            line1: updatedAddress.address,
            city: updatedAddress.city,
            state: updatedAddress.state,
            postal_code: updatedAddress.postalCode,
            country: countryCode,
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      paymentMethodId = paymentMethod.id;
      paymentMethodData = paymentMethod;

      // Attach payment method to customer
      await attachPaymentMethod(subscriberData[0].stripe_customer_id, paymentMethodId,);

      // Set as default payment method
      await updateCustomerDefaultPaymentMethod(
        subscriberData[0].stripe_customer_id,
        paymentMethodId
      );

      // Update subscriber data in database
      const variable = {
        id: subscriberData?.[0]?.id,
        stripe_payment_profile_id: paymentMethodId,
      };

      await client.graphql({
        query: updateSubscriberCard,
        variables: { input: variable },
      });

      // Update user billing details
      await handleUpdateWorkspace(paymentMethodData);
      localStorage.setItem("selectedGateway", "stripe");

      setOpen(false);

      dispatch(showToast({
        message: "Billing Address and payment method updated successfully",
        type: "success",
      }));
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleUpdateWorkspace = async (paymentMethod) => {
    try {
      dispatch(setLoading(true));
      const variable = {
        id: userBillingDetail?.[0]?.id,
        address: paymentMethod.billing_details.address.line1,
        city: paymentMethod.billing_details.address.city,
        country: paymentMethod.billing_details.address.country,
        state: paymentMethod.billing_details.address.state,
        postal_code: paymentMethod.billing_details.address.postal_code,
        card_number: `**** **** **** ${paymentMethod.card.last4}`,
        holderName: paymentMethod.billing_details.name || updatedAddress?.holderName,
        expiry_month: paymentMethod.card.exp_month.toString().padStart(2, '0'),
        expiry_year: paymentMethod.card.exp_year.toString().slice(-2),
        cvc: '***', // Don't store actual CVC for security reasons
        paymentMethod_Gateway: 'stripe'
      };

      const updateUserBillingData = await client.graphql({
        query: updateUserBilling,
        variables: { input: variable },
      });

      const result = updateUserBillingData.data.updateUserBilling;
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error updating user:", error);
      throw error;
    }
  };

  return (
    <div
      style={{ ...container, margin: isMobile ? "0px 1rem" : container.margin }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card style={organizationCard}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "2rem 0rem",
              }}
            >
              <div style={{ width: isMobile ? "90%" : "50%" }}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div style={{ fontSize: "1.3rem", margin: "0px" }}>
                          {userBillingDetail?.[0]?.paymentMethod_Gateway !== "stripe" && "Stripe"} Billing Information
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Address"
                          variant="outlined"
                          size="small"
                          multiline
                          rows={3}
                          value={updatedAddress.address}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              address: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="City"
                          variant="outlined"
                          size="small"
                          value={updatedAddress.city}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              city: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          select
                          size="small"
                          margin="normal"
                          fullWidth
                          label="Country"
                          style={{ margin: "0px" }}
                          value={updatedAddress.country}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              country: e.target.value,
                            })
                          }
                          SelectProps={{
                            IconComponent: updatedAddress.country
                              ? () => null
                              : undefined,
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: "300px",
                                },
                              },
                            },
                          }}
                          InputProps={{
                            endAdornment: updatedAddress.country && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="clear selection"
                                  onClick={() =>
                                    setUpdatedAddress({
                                      ...updatedAddress,
                                      country: "",
                                    })
                                  }
                                  edge="end"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        >
                          {reorderedCountriesList?.map((option) => (
                            <MenuItem key={option.name} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="State"
                          variant="outlined"
                          size="small"
                          value={updatedAddress.state}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              state: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Postal Code"
                          variant="outlined"
                          size="small"
                          value={updatedAddress.postalCode}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              postalCode: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ fontSize: "1.3rem", margin: "0px" }}>
                          Payment Method
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          value={updatedAddress.holderName}
                          size="small"
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="John Smith"
                          label="Holder Name"
                          required
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              holderName: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      {cardDataAvailable && !open && (
                        <Grid item xs={12}>
                          <TextField
                            value={updatedAddress?.cardNumber}
                            size="small"
                            type="text"
                            name="name"
                            label="Card Number"
                            disabled

                            style={{ width: "100%" }}
                          />
                        </Grid>
                      )}
                      {
                        open && (
                          <Grid item xs={12}>
                            <div
                              style={{
                                border: "1px solid #ccc",
                                padding: "10px",
                                marginBottom: "20px",
                              }}
                            >

                              <CardElement
                                options={{
                                  style: {
                                    base: {
                                      fontSize: "16px",
                                      color: "#424770",
                                      "::placeholder": {
                                        color: cardDataAvailable ? "#4CAF50" : "#aab7c4", // Green to indicate data present
                                        content: cardDataAvailable ? "Card data on file" : "Enter card details",
                                      },
                                    },
                                    invalid: {
                                      color: "#9e2146",
                                    },
                                  },
                                }}
                              />

                            </div>
                          </Grid>
                        )
                      }

                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "0.5rem",
                      }}
                    >
                      {cardDataAvailable && (
                        <Button
                          variant="contained"
                          onClick={() => setOpen(true)}
                          style={{
                            fontSize: "1rem",
                            textTransform: "initial",
                            marginTop: "1rem",
                          }}>
                          Update Card
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        style={{
                          fontSize: "1rem",
                          textTransform: "initial",
                          marginTop: "1rem",
                        }}
                        onClick={handleUpdatePaymentAddress}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>

          </Card>
        </Grid>
      </Grid>
      {isMobile && <div style={{ height: "10vh" }}></div>}
      {!isMobile && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default StripeSettings;
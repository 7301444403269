import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/userSlice";
import { useMediaQuery } from "@mui/material";
import "../Css/Signup-workspace.css";
import { createSamRushProject, createSiteAudit, getSnapshotSiteAudit, launchSiteAudit } from "../utills/SamRushApis/samrushApi";
import axios from "axios";

const ConfirmDomainName = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const cardStyle = {
        margin: isMobile ? "0rem auto" : "0rem auto",
        width: isMobile ? "90%" : "60%",
        textAlign: "center",
        height: !isMobile && "100vh",
        display: !isMobile && "flex",
        justifyContent: !isMobile && "center",
        flexDirection: !isMobile && "column",
        alignItems: !isMobile && "center",
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const email = state?.email;
    const firstName = state?.firstName;
    const lastName = state?.lastName;
    const workSpaceName = state?.workSpace;
    const workSpaceUrl = state?.workSpaceUrl;

    const detail = state?.detail;
    const cartItems = state?.cartItems;
    const [workSpace, setWorkSpace] = useState(workSpaceUrl);
    const [workspaceError, setWorkspaceError] = useState(false);
    const [workspaceErrorMessage, setWorkspaceErrorErrorMessage] = useState("");

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const handleContinue = async (e) => {
        e.preventDefault();
        dispatch(setLoading(true));
        if (!workSpace.trim()) {
            dispatch(setLoading(false));
            setWorkspaceError(true);
            setWorkspaceErrorErrorMessage("Workspace name is required");
            return;
        }


        try {
            const response = await createSamRushProject(workSpaceName, workSpace);
            localStorage.setItem("projectId", response.project_id)
            localStorage.setItem("websiteUrl", workSpace)
            navigate("/signup-verification", {
                state: {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    workSpace: workSpaceName,
                    detail: detail,
                    cartItems: cartItems,
                    workSpaceUrl: workSpace,
                    semrushProjectId: response?.body?.project_id
                },
            });
        } catch (error) {
            console.log(error)
        }
        finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <>
            <form style={cardStyle} className="cardStyle-workspace">
                <div style={{ textAlign: "center" }}>
                    <img
                        src="/faviconPNG.png"
                        height={isMobile ? "50px" : "70px"}
                        width={isMobile ? "100%" : "430px"}
                        className="signup-workspace-img"
                    />
                </div>
                <div style={{ marginTop: isMobile ? "1rem" : "2rem" }}>
                    <h1
                        style={{
                            color: "#1D1C1D",
                            fontSize: isMobile ? "1.4rem" : "3rem",
                            margin: "0rem",
                        }}
                    >
                        Get your website health check.
                    </h1>
                    <p
                        style={{
                            fontSize: isMobile ? "0.9rem" : "1.3rem",
                            color: "#605E61",
                            margin: "1.5rem",
                        }}
                    >
                        Confirm or update your website{" "}
                        <b>domain name.</b>
                    </p>
                </div>
                <div
                    style={{
                        marginTop: isMobile ? "2rem" : "1rem",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <TextField
                        error={workspaceError}
                        helperText={workspaceError ? workspaceErrorMessage : null}
                        id="outlined-basic"
                        label="Domain Name"
                        variant="outlined"
                        onChange={(e) => {
                            setWorkSpace(e.target.value);
                            setWorkspaceError(false);
                            setWorkspaceErrorErrorMessage("");
                        }}
                        value={workSpace}
                        style={{
                            width: isMobile ? "90%" : "50%",
                            marginBottom: "1rem",
                            backgroundColor: "#fff",
                        }}
                    />
                    <Button
                        variant="contained"
                        style={{
                            width: isMobile ? "90%" : "50%",
                            padding: "0.9rem 0rem",
                            fontSize: "1rem",
                            fontWeight: "bold",
                        }}
                        onClick={(e) => handleContinue(e)}
                        type="submit"
                    >
                        Get my website health report
                    </Button>
                </div>
            </form>
        </>
    );
};

export default ConfirmDomainName;

import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip,
    Card,
    useMediaQuery,
    TextField,
    Grid,
    Button,
    IconButton,
    InputAdornment,
    Typography,
    Tooltip,
    Popover,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { generateClient } from "aws-amplify/api";
import { useDispatch } from "react-redux";
import { setLoading, showToast } from "../redux/userSlice";
import { listWorkspaceInfos, listSubscriptions, listUserInfos } from "../graphql/queries";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { Folder } from "@material-ui/icons";
import { ArrowDropDown } from "@mui/icons-material";

const container = {
    margin: "2rem 4rem",
};

const title = {
    fontSize: "2rem",
    margin: "0px",
    marginBottom: "0.5rem",
};

const searchContainer = {
    display: "flex",
    flexDirection: "column",

    marginBottom: "1rem",
};
const filterButtonStyle = {
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #e0e0e0",
    textTransform: "none",
    padding: "4px 12px",
    marginLeft: "10px",
    '&:hover': {
        backgroundColor: "#f5f5f5"
    }
};
const getPlanColor = (planName) => {
    const colors = {
        "General": "#C2F7E0",
        "Plus": "#F4DAFF",
        "Starter": "#C2F7E0",
        "Advanced": "#FED7E3", "Growth": '#D65976',
        "SEMRush": "#9c27b0",
        "Wordpress": "#2196f3",
        "Meta Ads": "#00bcd4",
        "LinkedIn Ads": "#3f51b5",
        "Databox": "#795548"
    };
    return colors[planName] || "#757575";
};
const getPlanColorText = (planName) => {
    const colors = {
        "Plus": "purple",
        "Starter": "#0F7E5E",
        "General": "#2196f3",
        "Advanced": "#D65976",
        "Growth": '#784C1F',
        "SEMRush": "blue",
        "Wordpress": "red",
        "Meta Ads": "yellow",
        "LinkedIn Ads": "purple",
        "Databox": "green"
    };
    return colors[planName] || "#f5f5f5";
};
const filterIconStyle = {
    padding: '8px',
    borderRadius: '4px',
    transition: 'all 0.2s',
    backgroundColor: '#f5f5f5',
    marginLeft: '10px',
    '&:hover': {
        backgroundColor: '#e0e0e0'
    }
};

const datePickerStyle = {
    marginRight: "10px",
    "& .MuiInputBase-root": {
        height: "40px",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#1976d2",
        },
        "&:hover fieldset": {
            borderColor: "#1976d2",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#1976d2",
        },
    },
};

const Wiki = () => {
    const client = generateClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [workspaces, setWorkspaces] = useState([]);
    const [filteredWorkspaces, setFilteredWorkspaces] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [showSubscribed, setShowSubscribed] = useState('all'); // 'all', 'subscribed', 'unsubscribed'
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        fetchWorkspaceData();
    }, []);

    useEffect(() => {
        filterWorkspaces();
    }, [searchTerm, workspaces, showSubscribed]);
    const filterWorkspaces = () => {
        let filtered = workspaces;

        // Filter by search term
        filtered = filtered.filter(workspace =>
            workspace.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            workspace.admin?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            workspace.admin?.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            workspace.subscriptions.some(sub =>
                sub.product_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );

        // Filter by subscription status
        if (showSubscribed === 'subscribed') {
            filtered = filtered.filter(workspace =>
                workspace.subscriptions && workspace.subscriptions.length > 0
            );
        } else if (showSubscribed === 'unsubscribed') {
            filtered = filtered.filter(workspace =>
                !workspace.subscriptions || workspace.subscriptions.length === 0
            );
        }

        setFilteredWorkspaces(filtered);
    };

    const fetchWorkspaceData = async () => {
        dispatch(setLoading(true));
        try {
            const workspaceData = await client.graphql({
                query: listWorkspaceInfos,
            });

            const workspaceItems = workspaceData.data.listWorkspaceInfos.items;

            const workspaceDetails = await Promise.all(
                workspaceItems.map(async (workspace) => {
                    const subscriptionData = await client.graphql({
                        query: listSubscriptions,
                        variables: {
                            filter: {
                                workspace_id: { eq: workspace.id },
                                status: { eq: true },
                            },
                        },
                    });

                    const userInfo = await client.graphql({
                        query: listUserInfos,
                        variables: {
                            filter: {
                                workspace_id: { eq: workspace.id },
                                role: { eq: "workspace_admin" },
                            },
                        },
                    });
                    const subscriptions = subscriptionData.data.listSubscriptions.items;
                    const admin = userInfo.data.listUserInfos.items[0];
                    return {
                        ...workspace,
                        subscriptions,
                        admin,
                    };
                })
            );
            setWorkspaces(workspaceDetails);
            setFilteredWorkspaces(workspaceDetails);
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
            dispatch(showToast({ message: error.message, type: "error" }));
        }
    };

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const handleFilterSelect = (filterType) => {
        setShowSubscribed(filterType);
        handleFilterClose();
    };
    console.log(filteredWorkspaces);
    return (
        <div style={{ ...container, margin: isMobile ? "1rem" : container.margin }}>
            <div style={searchContainer}>
                <p style={title} className="title-heading">
                    Rothbright Workspace Wiki
                </p>
                <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                    <Tooltip title={
                        showSubscribed === 'all' ? 'All Workspaces' :
                            showSubscribed === 'subscribed' ? 'With Subscriptions' :
                                'Without Subscriptions'
                    }>
                        <IconButton
                            size="small"
                            onClick={handleFilterClick}
                            style={{
                                padding: '8px',
                                borderRadius: '4px',
                                backgroundColor: showSubscribed !== 'all' ? '#f5f5f5' : 'transparent',
                                border: '1px solid #e0e0e0',
                                marginLeft: '10px'
                            }}
                        >
                            <Folder style={{
                                color: showSubscribed === 'subscribed' ? '#4caf50' : showSubscribed === 'unsubscribed' ? '#f44336' : '#757575'
                            }} />
                            <Typography variant="body2" color="textSecondary" style={{ marginLeft: '8px' }}>
                                {showSubscribed !== 'all' ? (showSubscribed === 'subscribed' ? 'With Subscriptions' : 'Without Subscriptions') : 'All Workspaces'}
                            </Typography>
                            <ArrowDropDown />
                        </IconButton>
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={handleFilterClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <List sx={{ width: 200, padding: 1 }}>
                                <ListItem
                                    button
                                    onClick={() => handleFilterSelect('all')}
                                    selected={showSubscribed === 'all'}
                                >
                                    <ListItemIcon>
                                        <AllInclusiveIcon color={showSubscribed === 'all' ? 'primary' : 'inherit'} />
                                    </ListItemIcon>
                                    <ListItemText primary="All Workspaces" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => handleFilterSelect('subscribed')}
                                    selected={showSubscribed === 'subscribed'}
                                >
                                    <ListItemIcon>
                                        <CheckCircleIcon color={showSubscribed === 'subscribed' ? 'primary' : 'inherit'} />
                                    </ListItemIcon>
                                    <ListItemText primary="With Subscriptions" />
                                </ListItem>
                                <ListItem
                                    button
                                    onClick={() => handleFilterSelect('unsubscribed')}
                                    selected={showSubscribed === 'unsubscribed'}
                                >
                                    <ListItemIcon>
                                        <BlockIcon color={showSubscribed === 'unsubscribed' ? 'primary' : 'inherit'} />
                                    </ListItemIcon>
                                    <ListItemText primary="Without Subscriptions" />
                                </ListItem>
                            </List>
                        </Popover>
                    </Tooltip>
                    <TextField
                        placeholder="Search workspaces..."
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                    {/* <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => navigate("/add-workspace")}
                        style={{
                            backgroundColor: "#000000de",
                            textTransform: "none",
                        }}
                    >
                        Add Workspace
                    </Button> */}
                </div>
            </div>

            <Card style={{
                margin: "0rem 0rem",
                padding: "1rem 2rem",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 2px 10px",
                minHeight: "68vh",
            }}>
                <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: "bold" }}>Client Name</TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>Plan Selection</TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>Plan Tier</TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>Client Contact Name</TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>Client Contact Email</TableCell>
                                <TableCell>Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredWorkspaces.map((workspace) => (

                                <TableRow key={workspace.id}>
                                    <TableCell>{workspace.name}</TableCell>
                                    <TableCell>
                                        {workspace.subscriptions && workspace.subscriptions.length > 0 ? (
                                            workspace.subscriptions.map((sub) => (
                                                <Chip
                                                    key={sub.id}
                                                    label={sub.product_name || "No Plan"}
                                                    size="small"
                                                    style={{
                                                        margin: "2px",
                                                        backgroundColor: '#757575',
                                                        color: "white"
                                                    }}
                                                />
                                            ))
                                        ) : (
                                            <Chip
                                                label="No Plan Selected"
                                                size="small"
                                                style={{
                                                    margin: "2px",
                                                    color: "#000000de",
                                                    fontStyle: "italic"
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            workspace.subscriptions && workspace.subscriptions.length > 0 ? (
                                                workspace.subscriptions.map((sub) => (

                                                    <Chip
                                                        key={sub.id}
                                                        label={sub.product_plan}
                                                        size="small"
                                                        style={{
                                                            margin: "2px",
                                                            backgroundColor: getPlanColor(sub.product_plan || "Plus"),
                                                            color: getPlanColorText(sub.product_plan || "Plus")
                                                        }}
                                                    />
                                                ))
                                            ) : (
                                                <Chip
                                                    label="No Plan Selected"
                                                    size="small"
                                                    style={{
                                                        margin: "2px",
                                                        color: "#000000de",
                                                        fontStyle: "italic"
                                                    }}
                                                />
                                            )}
                                    </TableCell>
                                    <TableCell>{workspace?.admin ? workspace.admin?.user_name_first + " " + workspace.admin?.user_name_second || "-" : '-'}</TableCell>
                                    <TableCell>{workspace.admin?.email || "-"}</TableCell>
                                    <TableCell>-</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </div>
    );
};

export default Wiki;

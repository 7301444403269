import { IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import countriesList from "../../countries.json";
import ClearIcon from "@mui/icons-material/Clear";

const reorderedCountriesList = [
  { name: "United States" },
  ...countriesList.filter((country) => country.name !== "United States"),
];

const AddressForm = ({
  customerDetail,
  handleFieldChange,
  errors,
  setCustomerDetail,
}) => {
  return (
    <>
      <div>
        <p style={{ fontSize: "1.3rem" }}>Billing Address</p>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  size="normal"
                  value={customerDetail.billing.first_name}
                  onChange={(e) =>
                    handleFieldChange("first_name", e.target.value)
                  }
                  error={errors.billing.first_name}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  size="normal"
                  value={customerDetail.billing.last_name}
                  onChange={(e) =>
                    handleFieldChange("last_name", e.target.value)
                  }
                  error={errors.billing.last_name}
                  //   helperText={errors.billing.last_name ? "Required field" : ""}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                size="normal"
                value={customerDetail.billing.email}
                onChange={(e) => handleFieldChange("email", e.target.value)}
                error={errors.billing.email}
                // helperText={errors.billing.email ? "Required field" : ""}
                style={{ width: "100%", marginBottom: "1rem" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  size="normal"
                  value={customerDetail.billing.address_1}
                  onChange={(e) =>
                    handleFieldChange("address_1", e.target.value)
                  }
                  error={errors.billing.address_1}
                  //   helperText={errors.billing.address_1 ? "Required field" : ""}
                  multiline
                  rows={2}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  size="normal"
                  value={customerDetail.billing.city}
                  onChange={(e) => handleFieldChange("city", e.target.value)}
                  error={errors.billing.city}
                  //   helperText={errors.billing.city ? "Required field" : ""}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  size="normal"
                  margin="normal"
                  fullWidth
                  name="Country"
                  label="Select Country"
                  style={{ margin: "0px" }}
                  value={customerDetail.billing.country}
                  onChange={(e) => handleFieldChange("country", e.target.value)}
                  error={errors.billing.country}
                  SelectProps={{
                    IconComponent: customerDetail.billing.country
                      ? () => null
                      : undefined,
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: "300px",
                        },
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: customerDetail.billing.country && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="clear selection"
                          onClick={() =>
                            setCustomerDetail((prevCustomerDetail) => ({
                              ...prevCustomerDetail,
                              billing: {
                                ...prevCustomerDetail.billing,
                                country: "",
                              },
                            }))
                          }
                          edge="end"
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  {reorderedCountriesList?.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  label="State"
                  variant="outlined"
                  size="normal"
                  value={customerDetail.billing.state}
                  onChange={(e) => handleFieldChange("state", e.target.value)}
                  error={errors.billing.state}
                  //   helperText={errors.billing.state ? "Required field" : ""}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  label="Postal Code"
                  variant="outlined"
                  size="normal"
                  value={customerDetail.billing.postcode}
                  onChange={(e) =>
                    handleFieldChange("postcode", e.target.value)
                  }
                  error={errors.billing.postcode}
                  //   helperText={errors.billing.postcode ? "Required field" : ""}
                  style={{
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AddressForm;

import React, { useState } from "react";
import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@material-ui/icons/Email";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/userSlice";
import { resetPassword } from "aws-amplify/auth";
import { useMediaQuery } from "@mui/material";
import "../Css/RequestPassword.css";
const ResetPassword = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const cardStyle = {
    margin: isMobile ? "4rem auto" : "0 auto",
    width: isMobile ? "90%" : "40%",
    textAlign: "center",
    height: !isMobile && "100vh",
    display: !isMobile && "flex",
    justifyContent: !isMobile && "center",
    flexDirection: !isMobile && "column",
    alignItems: !isMobile && "center",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));
      if (!email.trim()) {
        setEmailError(true);
        setEmailErrorMessage("Email is required");
        dispatch(setLoading(false));
        return;
      }
      if (!isValidEmail(email)) {
        setEmailError(true);
        setEmailErrorMessage("Please type valid email");
        dispatch(setLoading(false));
        return;
      }
      const output = await resetPassword({ username: email });
      handleResetPasswordNextSteps(output);
    } catch (err) {
      console.log(err);
    }
    dispatch(setLoading(false));
    navigate("/reset_password", {
      state: {
        email: email,
      },
    });
  };

  function handleResetPasswordNextSteps(output) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        break;
      case "DONE":
        break;
    }
  }

  return (
    <>
      <form style={cardStyle} className="cardStyle">
        <div
          style={{
            margin: isMobile ? "0rem" : "0rem 0rem",
            textAlign: "center",
          }}
          className="header-img-forget-container"
        >
          <img
            src="/faviconPNG.png"
            height={isMobile ? "50px" : "70px"}
            width={isMobile ? "100%" : "430px"}
            className="header-img-forget"
          />
        </div>
        <div
          style={{ marginTop: isMobile ? "0rem" : "2rem" }}
          className="heading-container"
        >
          <h1
            style={{
              color: "#1D1C1D",
              fontSize: isMobile ? "1.4rem" : "3rem",
              margin: "1rem",
            }}
            className="heading1"
          >
            Password Reset Request
          </h1>
          <p
            style={{
              fontSize: isMobile ? "0.9rem" : "1.5rem",
              color: "#605E61",
              margin: "0px",
            }}
          >
            You will receive a code via email
          </p>
        </div>
        <Grid
          className="input-field-container"
          container
          spacing={0}
          style={{
            marginTop: isMobile ? "1rem" : "4rem",
            marginBottom: "0.3rem",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                error={emailError}
                helperText={emailError ? emailErrorMessage : null}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(false);
                  setEmailErrorMessage("");
                }}
                value={email}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon style={{ color: "#777" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "100%",
              padding: "0.9rem 0rem",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
            onClick={(e) => handleResetPassword(e)}
            type="submit"
          >
            Request Code
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "2rem",
          }}
          className="forgot-pass-container"
        >
          <span
            style={{ cursor: "pointer", color: "#777" }}
            onClick={() => navigate("/login")}
          >
            Back to login?
          </span>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;

import React from "react";
import { Button, Card, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import AddIcon from "@mui/icons-material/Add";
import Footer from "../components/Footer";

const container = {
  margin: "2rem 4rem",
};
const title = {
  fontSize: "2rem",
  margin: "0px",
  marginBottom: "1rem",
};
const organizationCard = {
  padding: "1.5rem 2rem",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 2px 10px",
  minHeight: "68vh",
};
const headingAlign = {
  display: "flex",
  justifyContent: "space-between",
  margin: "0rem 3rem",
};
const contentALign = {
  margin: "13rem auto",
  width: "80%",
  textAlign: "center",
};
const iconStyle = {
  color: "#0D0C0C",
  fontSize: "2.5rem",
};
const cardTitle = {
  fontSize: "1.5rem",
  margin: "0px",
  marginTop: "0.5rem",
};
const cardDesc = {
  lineHeight: "1.8rem",
  color: "#605E61",
};
const buttonStyle = {
  color: "#fff",
  backgroundColor: "#000000de",
  textTransform: "initial",
  padding: "0.3rem 0.8rem",
  fontSize: "1rem",
  marginTop: "1.5rem",
};

const Helpdesk = () => {
  return (
    <>
      <div style={container}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <p style={title}>Helpdesk</p>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card style={organizationCard}>
              <div style={contentALign}>
                <ImportContactsIcon style={iconStyle} />
                <h4 style={cardTitle}>No articles have been created yet</h4>
                <p style={cardDesc}>
                  Articles will shown in this area after they have been added by
                  your service provider.
                </p>
                {/* <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  style={buttonStyle}
                >
                  New
                </Button> */}
              </div>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Helpdesk;

import React, { useRef } from "react";
import Slider from "react-slick";

import {
  IconButton,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Card,
  Divider,
  Box,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import "../../Css/RecentlyViewed.css";
var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const ProductCarousel = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const sliderRef = useRef(null);
  const ResponsiveProductCard = ({ product, index }) => {
    const ratingValue = product.rating_count | 5;

    return (
      <Grid
        item
        key={product.id}
        xs={12}
        md={6}
        lg={4}
        className="carousel-card-container"
      >
        <Card
          className="product-card"
          sx={{ display: "flex", flexDirection: "row", height: "100%" }}
        >
          <CardMedia
            component="img"
            image={product?.image}
            alt={product.name}
            className="product-card-media"
            sx={{ width: "40%" }}
          />
          <CardContent
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="caption" gutterBottom>
              {product.type === "variable subscription" && (
                <p style={{ fontSize: isMobile && "0.8rem" }}>
                  <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                    From:
                  </span>{" "}
                  <span style={{ color: "#2895CF", fontWeight: "bold" }}>
                    {product?.plans?.[0]?.price
                      ? `$${Number(product?.plans?.[0]?.price)
                          .toFixed(0)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          .toLocaleString("en-US")}`
                      : "$"}
                  </span>{" "}
                  / {product?.plans?.[0]?.period}
                </p>
              )}
              {product.type === "simple product" && (
                <p style={{ fontSize: isMobile && "0.8rem" }}>
                  <span style={{ color: "#2895CF", fontWeight: "bold" }}>
                    {`$${Number(product.price)
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      .toLocaleString("en-US")}`}
                  </span>
                </p>
              )}
            </Typography>
            <p
              style={{
                fontSize: "1.2rem",
                minHeight: "40px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                cursor: "pointer",
                margin: 1,
              }}
            >
              {product.name}
            </p>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  return (
    <div style={{ marginTop: 50 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h7" fontWeight="bold">
          Recently viewed products
        </Typography>
        <Box sx={{ display: "flex" }}>
          <IconButton onClick={() => sliderRef.current.slickPrev()}>
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton onClick={() => sliderRef.current.slickNext()}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider style={{ margin: 10 }} />
      <div style={{ width: "100%", padding: 20 }}>
        <Slider ref={sliderRef} {...settings}>
          {props.products?.length > 0 ? (
            props.products.map((product, index) => (
              <ResponsiveProductCard
                key={product.id}
                product={product}
                index={index}
              />
            ))
          ) : (
            <div style={{ marginLeft: 10 }}>
              <span>No products found</span>
            </div>
          )}
        </Slider>
      </div>
    </div>
  );
};

export default ProductCarousel;

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  createMyLinks,
  deleteMyLinks,
  updateMyLinks,
} from "../graphql/mutations";
import uuid from "react-uuid";
import { generateClient } from "aws-amplify/api";
import { setLoading, showToast } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { uploadData } from "aws-amplify/storage";
import { listMyLinks } from "../graphql/queries";
import { useMediaQuery } from "@mui/material";

const container = {
  margin: "2rem 4rem",
};
const title = {
  fontSize: "2rem",
  margin: "0px",
  marginBottom: "1rem",
};
const Cardtitle = {
  fontSize: "1.6rem",
  margin: "0px",
  marginBottom: "0.5rem",
};
const organizationCard = {
  padding: "1.5rem 2rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  minHeight: "68vh",
};
const headingAlign = {
  display: "flex",
  justifyContent: "space-between",
  margin: "0rem 3rem",
};
const rowAlign = {
  display: "flex",
  justifyContent: "space-between",
  margin: "0rem 3rem",
};

const MyLinks = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const client = generateClient();
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const bucket = process.env.REACT_APP_BUCKET_NAME;
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [linkList, setLinkList] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [editID, setEditID] = useState("");
  const [loader, setLoader] = useState(false);
  const [linkDetail, setLinkDetail] = useState({
    name: "",
    url: "",
  });

  useEffect(() => {
    getLinksData();
  }, []);

  const handleOnChange = (event) => {
    setNameError(false);
    setUrlError(false);
    const {
      target: { value, name },
    } = event;
    setLinkDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = async (event) => {
    const fileInput = event.target;

    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleEditIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleEditLinks = (item) => {
    setLinkDetail(item);
    setEditID(item.id);
    setSelectedImage(item.image_url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLinkDetail({});
    setSelectedImage("");
    setEditID("");
  };

  const handleRemoveImage = () => {
    setSelectedImage("");
  };

  const getLinksData = async () => {
    setLoader(true);
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const result = await client.graphql({
        query: listMyLinks,
        variables: variables,
      });
      setLinkList(result.data.listMyLinks.items);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleSaveLink = async () => {
    dispatch(setLoading(true));
    if (!linkDetail.name && !linkDetail.url) {
      setNameError(true);
      setUrlError(true);
      dispatch(setLoading(false));
      return;
    }
    if (!linkDetail.name) {
      setNameError(true);
      dispatch(setLoading(false));
      return;
    }
    if (!linkDetail.url) {
      setUrlError(true);
      dispatch(setLoading(false));
      return;
    }
    setOpen(false);
    let id;
    try {
      let imageUrl = "";
      const file = fileInputRef.current.files[0];
      if (file) {
        const fileName = file.name.replace(/\s+/g, "");
        const uploadResult = await uploadData({
          key: `MyLinks/${fileName}`,
          data: file,
          options: {
            accessLevel: "public",
          },
        }).uploadResult;
        imageUrl = `https://${bucket}/public/MyLinks/${fileName}`;
      }

      id = uuid();
      const detail = {
        id: id,
        workspace_id: selectedWorkspaceId,
        name: linkDetail.name,
        url: linkDetail.url,
        image_url: file
          ? imageUrl
          : "https://www.svgrepo.com/show/508699/landscape-placeholder.svg",
      };
      const result = await client.graphql({
        query: createMyLinks,
        variables: { input: detail },
      });
      getLinksData();
      setLinkDetail({
        name: "",
        url: "",
      });
      setSelectedImage("");
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleSaveLinkEdit = async () => {
    dispatch(setLoading(true));
    if (!linkDetail.name && !linkDetail.url) {
      setNameError(true);
      setUrlError(true);
      dispatch(setLoading(false));
      return;
    }
    if (!linkDetail.name) {
      setNameError(true);
      dispatch(setLoading(false));
      return;
    }
    if (!linkDetail.url) {
      setUrlError(true);
      dispatch(setLoading(false));
      return;
    }
    setOpen(false);
    try {
      let imageUrl = "";
      const file = fileInputRef.current.files[0];
      if (file) {
        const fileName = file.name.replace(/\s+/g, "");
        const uploadResult = await uploadData({
          key: `MyLinks/${fileName}`,
          data: file,
          options: {
            accessLevel: "public",
          },
        }).uploadResult;
        imageUrl = `https://${bucket}/public/MyLinks/${fileName}`;
      }

      const detail = {
        id: editID,
        workspace_id: selectedWorkspaceId,
        name: linkDetail.name,
        url: linkDetail.url,
        image_url: file
          ? imageUrl
          : selectedImage
          ? selectedImage
          : "https://www.svgrepo.com/show/508699/landscape-placeholder.svg",
      };
      const result = await client.graphql({
        query: updateMyLinks,
        variables: { input: detail },
      });

      getLinksData();
      setLinkDetail({
        name: "",
        url: "",
      });
      setSelectedImage("");
      setEditID("");
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleDeleteLink = (id) => {
    setDeleteModal(true);
    setIdToDelete(id);
  };

  const confirmDeleteLink = async () => {
    setDeleteModal(false);
    try {
      const variables = {
        input: {
          id: idToDelete,
        },
      };

      const deleteLink = await client.graphql({
        query: deleteMyLinks,
        variables: variables,
      });
      if (deleteLink) {
        setIdToDelete("");
      }
      dispatch(showToast({ message: "Deleted successfully", type: "success" }));
      getLinksData();
    } catch (error) {
      console.error("Error saving data to the database:", error);
      dispatch(showToast({ message: error.message, type: "error" }));
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
    setIdToDelete("");
  };
  const ResponsiveProductCard = ({
    product,
    index,

    onItemPressed,
  }) => {
    return (
      <Grid item xs={isMobile ? 6 : 12} md={isMobile ? 9 : 3} lg={3}>
        <Card>
          <CardMedia
            component="img"
            image={product?.image_url}
            alt={product.name}
            sx={{ height: "100%", width: "100%" }}
            style={{ cursor: "pointer" }}
          />
          <CardContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: isMobile ? 14 : "1.2rem",
                  display: "-webkit-box",
                  justifyContent: "center",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {product.name}
              </span>

              <IconButton
                color="inherit"
                onClick={() => handleEditLinks(product)}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                color="inherit"
                onClick={() => handleDeleteLink(product.id)}
              >
                <DeleteIcon style={{ color: "#E55A54" }} />
              </IconButton>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  return (
    <div style={{ ...container, margin: isMobile ? "1rem 1rem" : "2rem 4rem" }}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p style={{ ...title, fontSize: isMobile ? "0.9rem" : "2rem" }}>
                My Links
              </p>
            </div>
            {userData?.role !== "workspace_member" && (
              <div>
                <IconButton
                  style={{ backgroundColor: "#1692E0", padding: "0.5rem" }}
                  onClick={handleClickOpen}
                >
                  <AddIcon
                    style={{
                      color: "#fff",
                      fontSize: isMobile ? "0.9rem" : "2rem",
                    }}
                  />
                </IconButton>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {isMobile ? (
        <Grid item xs={12} md={6} lg={10}>
          <Grid container spacing={2} p={isMobile && 1.5}>
            <>
              {linkList?.map((product, index) => (
                <ResponsiveProductCard
                  key={product?.id}
                  product={product}
                  index={index}
                />
              ))}
            </>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card style={organizationCard}>
              {userData?.role === "workspace_member" && (
                <div>
                  <h4
                    style={{
                      ...Cardtitle,
                      fontSize: isMobile ? "0.9rem" : "1.6rem",
                    }}
                  >
                    Links
                  </h4>
                </div>
              )}
              {userData?.role !== "workspace_member" ? (
                <>
                  {linkList?.length > 0 ? (
                    <>
                      <div
                        style={{
                          ...headingAlign,
                          margin: isMobile ? "0rem" : "0rem 3rem",
                        }}
                      >
                        <Grid container spacing={0}>
                          <Grid item xs={3}>
                            <h4 style={{ fontSize: isMobile && "0.9rem" }}>
                              Name
                            </h4>
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: "center" }}>
                            <h4 style={{ fontSize: isMobile && "0.9rem" }}>
                              Edit
                            </h4>
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: "center" }}>
                            <h4 style={{ fontSize: isMobile && "0.9rem" }}>
                              Delete
                            </h4>
                          </Grid>
                        </Grid>
                      </div>
                      <Divider light />
                      {linkList?.map((item, index, array) => (
                        <>
                          <div style={rowAlign} key={index}>
                            <Grid container spacing={0}>
                              <Grid item xs={3}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "1rem",
                                  }}
                                >
                                  <img
                                    src={item.image_url}
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      borderRadius: "6px",
                                    }}
                                  />
                                  <Link
                                    to={item.url}
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                    }}
                                    target="_blank"
                                  >
                                    <h4 style={{ cursor: "pointer" }}>
                                      {item.name}
                                    </h4>
                                  </Link>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  color="inherit"
                                  onClick={() => handleEditLinks(item)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                style={{
                                  textAlign: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  color="inherit"
                                  onClick={() => handleDeleteLink(item.id)}
                                >
                                  <DeleteIcon style={{ color: "#E55A54" }} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </div>
                          {index !== array?.length - 1 && <Divider light />}
                        </>
                      ))}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        minHeight: "60vh",
                      }}
                      className="sub-btn-container"
                    >
                      <span className="shop-text">
                        You haven't added any links.
                      </span>
                      <Button
                        variant="contained"
                        style={{
                          marginTop: "0.5rem",
                          textTransform: "inherit",
                        }}
                        onClick={handleClickOpen}
                        className="sub-button"
                      >
                        Add A Link
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {linkList?.map((item, index) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.8rem",
                        }}
                        key={index}
                      >
                        <img
                          src={item.image_url}
                          height={"50px"}
                          width={"50px"}
                          style={{ borderRadius: "6px" }}
                        />
                        <Link
                          to={item.url}
                          style={{ textDecoration: "none", color: "inherit" }}
                          target="_blank"
                        >
                          <h4 style={{ cursor: "pointer" }}>{item.name}</h4>
                        </Link>
                      </div>
                      <Divider light style={{ margin: "0.1rem 0rem" }} />
                    </>
                  ))}
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      )}
      <Dialog open={open} disableBackdropClick>
        <DialogTitle>Create a new Link</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can create this link by filling theses fields. Workspace member
            can use these created links
          </DialogContentText>
          <TextField
            error={nameError}
            helperText={nameError ? "Name required" : null}
            autoFocus
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={linkDetail.name}
            onChange={handleOnChange}
          />
          <TextField
            error={urlError}
            helperText={urlError ? "URL required" : null}
            autoFocus
            margin="dense"
            name="url"
            label="Link URL"
            type="text"
            fullWidth
            variant="standard"
            value={linkDetail.url}
            onChange={handleOnChange}
          />
          <div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label htmlFor="fileInput">
              <Button
                variant="contained"
                startIcon={<CloudUploadIcon />}
                style={{ marginTop: "1.8rem", textTransform: "inherit" }}
                onClick={handleEditIconClick}
              >
                Upload Icon
              </Button>
            </label>
          </div>
          {selectedImage && (
            <div
              style={{
                marginTop: "1rem",
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={selectedImage}
                alt="Selected Icon"
                style={{
                  maxHeight: "100px",
                  maxWidth: "100px",
                  borderRadius: "5px",
                }}
              />
              <IconButton
                onClick={handleRemoveImage}
                style={{ color: "#E55A54" }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )}
          {!selectedImage && (
            <DialogContentText
              style={{ marginTop: "0.3rem", fontSize: "0.9rem" }}
            >
              Please upload the icon with 64 x 64 dimensions for the better
              quality.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ textTransform: "inherit" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={editID ? handleSaveLinkEdit : handleSaveLink}
            style={{ textTransform: "inherit" }}
          >
            {editID ? "Update" : "Create Link"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteModal} disableBackdropClick>
        <DialogTitle>Confirm delete product</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Link? This Link will
            permanantly be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
          <Button onClick={handleCloseDeleteModal} variant="contained">
            Cancel
          </Button>
          <Button variant="contained" onClick={confirmDeleteLink}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {!isMobile && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default MyLinks;

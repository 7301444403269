import React, { useState } from "react";
import { Alert, Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@material-ui/icons/Email";
import { useDispatch } from "react-redux";
import { setLoading, showToast } from "../redux/userSlice";
import { confirmResetPassword } from "aws-amplify/auth";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery } from "@mui/material";

const ConfirmPasswordReset = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const cardStyle = {
    margin: isMobile ? "4rem auto" : "0 auto",
    width: isMobile ? "90%" : "40%",
    textAlign: "center",
    height: !isMobile && "100vh",
    display: !isMobile && "flex",
    justifyContent: !isMobile && "center",
    flexDirection: !isMobile && "column",
    alignItems: !isMobile && "center",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const email = state?.email;
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [servererror, setServerError] = useState(false);
  const [servererrorMessage, setServerErrorMessage] = useState("");

  const handleConfirmResetPassword = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));
      if (!code) {
        setCodeError(true);
        dispatch(setLoading(false));
        return;
      }
      if (!password) {
        setPasswordError(true);
        dispatch(setLoading(false));
        return;
      }
      if (!confirmPassword) {
        setConfirmPasswordError(true);
        dispatch(setLoading(false));
        return;
      }
      if (password !== confirmPassword) {
        setServerError(true);
        setServerErrorMessage("Both passwords should be the same");
        dispatch(setLoading(false));
        return;
      }
      const result = await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: password,
      });
      dispatch(setLoading(false));
      setPassword("");
      setCode("");
      setConfirmPassword("");
      dispatch(
        showToast({
          message: "New password changed successfully",
          type: "success",
        })
      );
    } catch (err) {
      dispatch(setLoading(false));
      setServerError(true);
      setServerErrorMessage(err.message);
      console.log(err.message);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <form style={cardStyle}>
        <div style={{ margin: "0rem 0rem", textAlign: "center" }}>
          <img
            src="/faviconPNG.png"
            height={isMobile ? "50px" : "70px"}
            width={isMobile ? "100%" : "430px"}
          />
        </div>
        <div>
          <KeyIcon
            style={{ fontSize: isMobile ? "3rem" : "10rem", color: "#605E61" }}
          />
          <p
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              color: "#605E61",
              margin: "0px",
            }}
          >
            Enter the valid code and set your new password
          </p>
        </div>
        <Grid
          container
          spacing={0}
          style={{ marginTop: "2rem", marginBottom: "0.3rem" }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                error={codeError}
                helperText={codeError ? "Required field" : null}
                id="outlined-basic"
                label="Verification Code"
                variant="outlined"
                onChange={(e) => {
                  setCode(e.target.value);
                  setCodeError(false);
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={code}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={passwordError}
                helperText={passwordError ? "Required field" : null}
                id="outlined-basic"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                label="New Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={password}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon style={{ color: "#777" }} />
                        ) : (
                          <VisibilityIcon style={{ color: "#777" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Confirm New Password"
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError(false);
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={confirmPassword}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOffIcon style={{ color: "#777" }} />
                        ) : (
                          <VisibilityIcon style={{ color: "#777" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {servererror ? (
          <div style={{ marginBottom: "0.7rem" }}>
            <Alert severity="error">{servererrorMessage}</Alert>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "100%",
              padding: "0.9rem 0rem",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
            onClick={(e) => handleConfirmResetPassword(e)}
            type="submit"
          >
            Confirm Password
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "2rem",
          }}
          className="forgot-pass-container"
        >
          <span
            style={{ cursor: "pointer", color: "#777" }}
            onClick={() => navigate("/login")}
          >
            Back to login?
          </span>
        </div>
      </form>
    </>
  );
};

export default ConfirmPasswordReset;

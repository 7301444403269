import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "../Css/unauthbar.css";
const topSignIn = {
  backgroundColor: "#232F3E",
  height: "4rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};
const textSignIn = { margin: "0px", color: "#fff", fontSize: "1.3rem" };

export default function UnAuthenticatedDrawer({
  handleDrawerToggle,
  openDrawer,
  categoriesNames,
  handleCategoryChange,
}) {
  const navigate = useNavigate();
  const DrawerList = (
    <Box
      sx={{ width: 270 }}
      role="presentation"
      onClick={handleDrawerToggle(false)}
    >
      <div style={topSignIn} onClick={() => navigate("/login")}>
        <AccountCircleIcon
          style={{ color: "#fff", fontSize: "2.7rem", marginRight: "0.3rem" }}
        />
        <h4 style={textSignIn}>Hello, sign in</h4>
      </div>
      <p
        style={{
          margin: "0px",
          marginTop: "1rem",
          marginLeft: "1rem",
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}
      >
        All Categories
      </p>
      <List>
        {categoriesNames
          ?.sort((a, b) => a.localeCompare(b)) // Sort the categories alphabetically
          .map((name, index) => (
            <ListItem
              key={index}
              disablePadding
              onClick={() => handleCategoryChange(name)}
            >
              <ListItemButton>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer
        open={openDrawer}
        onClose={handleDrawerToggle(false)}
        style={{ zIndex: 1301 }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "../components/Footer";
import { showToast } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import {
  listCoupons,
  listProductCards,
  listProductVariations,
  listSubscriberCards,
  listSubscriptions,
  listUserBillings,
} from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import {
  createSubscriptions,
  deleteSubscriptions,
  updateSubscriptions,
} from "../graphql/mutations";
import uuid from "react-uuid";
import { useMediaQuery } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { DataGrid } from "@mui/x-data-grid";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const Subscription = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const container = {
    margin: isMobile ? "1rem 1rem auto" : "2rem 4rem",
  };
  const title = {
    fontSize: isMobile ? "1rem" : "2rem",
    margin: "0px",
    marginBottom: isMobile ? "0rem" : "1rem",
  };
  const organizationCard = {
    padding: isMobile ? "0rem" : "1.5rem 2rem",
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    borderRadius: "10px",
    minHeight: "68vh",
  };
  const statusStyleActive = {
    color: "#1C984D",
    fontWeight: "bold",
    padding: "0.1rem 0.5rem",
    display: "inline-block",
    borderRadius: "4px",
    margin: "0px",
  };
  const statusStyleInActive = {
    color: "#E35244",
    fontWeight: "bold",
    padding: "0.1rem 0.5rem",
    display: "inline-block",
    borderRadius: "4px",
    margin: "0px",
  };
  const buttonStyle = {
    textTransform: "initial",
    fontSize: "1rem",
    border: "none",
    paddingLeft: 0,
  };
  const counterContainer = {
    display: "flex",
    alignItems: "center",
  };
  const counterButton = {
    color: "#1565C0",
  };
  const nameContainerStyle = {
    display: "inline-block",
    maxWidth: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
  };
  const navigate = useNavigate();
  const client = generateClient();
  const dispatch = useDispatch();
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const [subscriptionsList, setSubscriptionsList] = useState(null);
  const [loader, setLoader] = useState(false);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [open, setOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [idToCancel, setIdToCancel] = useState(null);
  const [openItemModal, setOpenItemModal] = useState(false);
  const [productData, setProductData] = React.useState([]);
  const [purchaseOption, setPurchaseOption] = useState("");
  const [scheduleLaterDate, setScheduleLaterDate] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeError, setPromoCodeError] = React.useState(false);
  const [couponData, setCouponData] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [netPrice, setNetPrice] = React.useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [count, setCount] = useState(1);
  const [itemSelect, setItemSelect] = useState(null);
  const [plansById, setPlansById] = useState(null);
  const [plan, setPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [subscriberData, setSubscriberData] = useState(null);
  const [orderLoading, setOrderLoading] = useState(false);
  const [period, setPeriod] = React.useState("");
  const [every, setEvery] = React.useState("");
  const [idToDelete, setIdToDelete] = React.useState(null);
  const [userDetailDB, setUserDetailDB] = useState(null);


  const merchant_name = process.env.REACT_APP_MERCHANT_LOGIN_NAME;
  const transaction_key = process.env.REACT_APP_MERCHANT_TRANSACTION_KEY;
  const authorize_api = process.env.REACT_APP_AUTHORIZE_API;

  useEffect(() => {
    fetchSubscriptions();
    fetchProductsDetail();
    fetchSubscriberData();
  }, []);

  useEffect(() => {
    if (selectedProduct !== null) {
      fetchPlansById();
    }
  }, [selectedProduct]);


  const userBillingDetail = async () => {
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getUserBilling = await client.graphql({
        query: listUserBillings,
        variables: variables,
      });
      const res = getUserBilling.data.listUserBillings.items;
      setUserDetailDB(res);
    } catch (error) {
      console.error("Error creating todo:", error);
    }
  };

  const fetchSubscriberData = async () => {
    try {
      setLoading(true);
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const getSubData = await client.graphql({
        query: listSubscriberCards,
        variables: variables,
      });
      const res = getSubData.data.listSubscriberCards.items;
      setSubscriberData(res);
      setLoading(false);
    } catch (error) {
      console.error("Error creating todo:", error);
    } finally {
      setLoading(false);
    }
  };

  async function fetchAllProductVariationsIds() {
    let allItems = [];
    let nextToken = null;

    do {
      const variables = {
        filter: {
          product_id: {
            eq: itemSelect?.[0]?.id,
          },
        },
        nextToken: nextToken,
      };
      const getCategories = await client.graphql({
        query: listProductVariations,
        variables: variables,
      });
      const response = getCategories?.data?.listProductVariations?.items || [];
      allItems = [...allItems, ...response];
      nextToken = getCategories?.data?.listProductVariations?.nextToken;
    } while (nextToken);
    return allItems;
  }

  const fetchPlansById = async () => {
    try {
      setLoading(true);
      const response = await fetchAllProductVariationsIds();
      const cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories);
        }
        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags);
        }
        return cleanedItem;
      });
      setPlansById(cleanedResponse);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  const handlePlanChange = (event) => {
    const selectedPlan = event.target.value;
    const selectedPlanObject = plansById.find(
      (option) => option.name === selectedPlan
    );
    if (selectedPlanObject) {
      setPrice(selectedPlanObject.price);
      setTotalPrice(selectedPlanObject.price);
      setPlan(selectedPlan);
      setCount(1);
      setNetPrice(selectedPlanObject.price - totalDiscount);
      setPeriod(selectedPlanObject.period);
      setEvery(selectedPlanObject.every);
    } else {
      setPrice("");
      setPlan("");
      setCount(1);
      setPeriod("");
      setEvery("");
    }
  };

  const fetchProductsDetail = async () => {
    try {
      setLoader(true);
      const getProductData = await client.graphql({
        query: listProductCards,
      });

      const result = getProductData?.data?.listProductCards?.items;
      const sortedResult = result?.sort((a, b) => a.name.localeCompare(b.name));
      setProductData(sortedResult);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };
  const fetchSubscriptionsData = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listSubscriptions,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listSubscriptions.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listSubscriptions.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchSubscriptions = async () => {
    try {
      setLoader(true);
      const res = await fetchSubscriptionsData(selectedWorkspaceId);
      const sortedRes = res?.sort((a, b) => {
        if (a.status === b.status) {
          return 0;
        }
        return a.status ? -1 : 1;
      });

      setSubscriptionsList(sortedRes);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };
  React.useEffect(() => {
    const fetchCouponCode = async () => {
      const getCoupons = await client.graphql({
        query: listCoupons,
      });

      const res = getCoupons.data.listCoupons.items;
      setCouponData(res);
    };
    fetchCouponCode();
  }, []);

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);
    return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
    setPrice(+price + +totalPrice);
    setNetPrice(+price + +totalPrice - +totalDiscount);
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
      setPrice(+price - +totalPrice);
      setNetPrice(+price - +totalDiscount);
      setNetPrice(+price - +totalPrice - +totalDiscount);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseItemModal = () => {
    setOpenItemModal(false);
    setPurchaseOption("");
    setScheduleLaterDate(null);
    setPromoCode("");
    setPromoCodeError(false);
    setSelectedProduct(null);
    setTotalDiscount(0);
    setNetPrice(0);
    setTotalPrice(0);
    setPrice(0);
    setAppliedCoupon("");
    setCount(1);
    setItemSelect(null);
    setPlan("");
    setPeriod("");
    setEvery("");
  };

  const handleDateChange = (newValue) => {
    if (newValue) {
      setScheduleLaterDate(newValue);
    } else {
      setScheduleLaterDate(null);
    }
  };

  const scheduleLaterFormattedDate = scheduleLaterDate
    ? scheduleLaterDate.format("YYYY-MM-DD")
    : "";

  const handleCancelSubscription = (item) => {
    setIdToCancel(item);
    setOpen(true);
  };

  const handleOpenItemModal = () => {
    if (subscriberData?.[0]?.payment_profile_id !== null) {
      setOpenItemModal(true);
    } else {
      dispatch(
        showToast({
          message: "Please add your billing and payment method first",
          type: "error",
        })
      );
    }
  };

  const handleProductChange = (event, newValue) => {
    setSelectedProduct(newValue);
    if (productData?.length > 0) {
      const selectedItem = productData.filter((pro) => pro.name === newValue);
      if (selectedItem?.[0]?.type !== "variable subscription") {
        setPrice(selectedItem?.[0]?.price);
        setTotalPrice(selectedItem?.[0]?.price);
        setNetPrice(selectedItem?.[0]?.price);
        setPlan("");
        setAppliedCoupon("");
        setTotalDiscount(0);
        setPromoCode("");
        setCount(1);
        setPurchaseOption("");
        setItemSelect(selectedItem);
        setPeriod("");
        setEvery("");
        return;
      }
      setItemSelect(selectedItem);
    }
    setPrice(0);
    setTotalPrice(0);
    setNetPrice(0);
    setPlan("");
    setAppliedCoupon("");
    setTotalDiscount(0);
    setPromoCode("");
    setCount(1);
    setPurchaseOption("");
    setPeriod("");
    setEvery("");
  };

  const handleConfrimCancel = async () => {
    try {
      setOpen(false);
      setLoader(true);
      if (idToCancel.subscription_by === "stripe") {
        const stripe = await stripePromise;
        const secretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;

        const response = await fetch(
          `https://api.stripe.com/v1/subscriptions/${idToCancel.subscription_id}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${secretKey}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const result = await response.json();

        if (result.error) {
          throw new Error(result.error.message);
        }

        if (result.status !== "canceled") {
          throw new Error("Failed to cancel the subscription");
        }
      } else {
        // Assume it's Authorize.net if not Stripe
        const cancelData = {
          ARBCancelSubscriptionRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            subscriptionId: idToCancel.subscription_id,
          },
        };

        const response = await axios.post(authorize_api, cancelData);

        if (response.data.messages.resultCode === "Error") {
          throw new Error(response.data.messages.message[0].text);
        }

        if (response.data.messages.resultCode !== "Ok") {
          throw new Error("Failed to cancel the subscription");
        }
      }

      // If we've reached this point, the cancellation was successful
      const variables = {
        input: {
          id: idToCancel.id,
        },
      };

      await client.graphql({
        query: deleteSubscriptions,
        variables: variables,
      });

      await fetchSubscriptions();
      setIdToCancel(null);
      dispatch(
        showToast({
          message: "You have cancelled your subscription successfully",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          message: error.message,
          type: "error",
        })
      );
    } finally {
      setLoader(false);
    }
  };

  function generateInvoiceNumber() {
    const timestamp = Date.now().toString();
    const random = Math.floor(Math.random() * 1000)
      .toString()
      .padStart(3, "0");
    return `${timestamp}-${random}`;
  }

  const generateLineItemsId = () => {
    const newNumbers = Math.floor(100 + Math.random() * 900).toString();
    return newNumbers;
  };

  const adjustDate = () => {
    const newDate = new Date();
    if (period === "day") {
      newDate.setDate(newDate.getDate() + +every);
    } else if (period === "month") {
      newDate.setMonth(newDate.getMonth() + +every);
    }
    return newDate;
  };

  const handlePlaceOrder = async () => {
    const invoiceNumber = generateInvoiceNumber();
    const lineItemId = generateLineItemsId();
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    // Add one month

    const adjustedDate = adjustDate();
    // Format the adjusted date
    const yearAdd = adjustedDate.getFullYear();
    const monthAdd = String(adjustedDate.getMonth() + 1).padStart(2, "0");
    const dayAdd = String(adjustedDate.getDate()).padStart(2, "0");
    const formattedDateAdd = `${yearAdd}-${monthAdd}-${dayAdd}`;

    if (itemSelect?.[0]?.type === "variable subscription") {
      setOrderLoading(true);

      // instant payment

      if (purchaseOption !== "Schedule later") {
        const instantPayment = {
          createTransactionRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            transactionRequest: {
              transactionType: "authCaptureTransaction",
              amount: +netPrice,
              profile: {
                customerProfileId: subscriberData?.[0]?.subscription_id,
                paymentProfile: {
                  paymentProfileId: subscriberData?.[0]?.payment_profile_id,
                },
              },
              order: {
                invoiceNumber: invoiceNumber,
              },
              lineItems: {
                lineItem: {
                  itemId: lineItemId,
                  name: itemSelect?.[0]?.name,
                  description: `${itemSelect?.[0]?.name} is a ${itemSelect?.[0]?.type}`,
                  quantity: count,
                  unitPrice: totalPrice,
                },
              },
              processingOptions: {
                isSubsequentAuth: "true",
              },
              authorizationIndicatorType: {
                authorizationIndicator: "final",
              },
            },
          },
        };
        const instantPaymentResponse = await axios.post(
          authorize_api,
          instantPayment
        );

        if (instantPaymentResponse.data.messages.resultCode === "Ok") {
          if (
            instantPaymentResponse.data.transactionResponse.messages[0].code ===
            "1"
          ) {
            const createSubscription = {
              ARBCreateSubscriptionRequest: {
                merchantAuthentication: {
                  name: merchant_name,
                  transactionKey: transaction_key,
                },
                subscription: {
                  name: itemSelect?.[0]?.name,
                  paymentSchedule: {
                    interval: {
                      length: every,
                      unit: `${period}s`,
                    },
                    startDate: formattedDateAdd,
                    totalOccurrences: "9999",
                  },
                  amount: +netPrice,
                  order: {
                    invoiceNumber: invoiceNumber,
                  },
                  profile: {
                    customerProfileId: subscriberData?.[0]?.subscription_id,
                    customerPaymentProfileId:
                      subscriberData?.[0]?.payment_profile_id,
                  },
                },
              },
            };
            const response = await axios.post(
              authorize_api,
              createSubscription
            );
            if (response.data.messages.resultCode === "Error") {
              setLoading(false);
              setOrderLoading(false);
              dispatch(
                showToast({
                  message: response.data.messages.message[0].text,
                  type: "error",
                })
              );
              return;
            }

            if (response.data.messages.resultCode === "Ok") {
              const sub_id = uuid();
              const variable = {
                id: sub_id,
                product_id: itemSelect?.[0]?.id,
                status: true,
                created_at:
                  purchaseOption === "Schedule later"
                    ? scheduleLaterFormattedDate
                    : formattedDate,
                workspace_id: selectedWorkspaceId,
                subscription_id: response.data.subscriptionId,
                product_image: itemSelect?.[0]?.image,
                product_name: itemSelect?.[0]?.name,
                product_type: itemSelect?.[0]?.type,
                product_period: period,
                product_sku: itemSelect?.[0]?.sku,
                product_plan: plan,
                product_quantity: count,
                product_price: +netPrice,
                invoice_number: invoiceNumber,
              };

              const createSubsCard = await client.graphql({
                query: createSubscriptions,
                variables: { input: variable },
              });

              setOrderLoading(false);
              setOpenItemModal(false);
              setPurchaseOption("");
              setScheduleLaterDate(null);
              setPromoCode("");
              setPromoCodeError(false);
              setSelectedProduct(null);
              setTotalDiscount(0);
              setNetPrice(0);
              setTotalPrice(0);
              setPrice(0);
              setAppliedCoupon("");
              setCount(1);
              setItemSelect(null);
              setPlan("");
              dispatch(
                showToast({
                  message: "Your order is placed successfully",
                  type: "success",
                })
              );
            }
          } else {
            setOrderLoading(false);
            dispatch(
              showToast({
                message:
                  instantPaymentResponse.data.transactionResponse.errors[0]
                    .errorText,
                type: "error",
              })
            );
            return;
          }
        } else {
          setOrderLoading(false);
          dispatch(
            showToast({
              message: instantPaymentResponse.data.messages.message[0].text,
              type: "error",
            })
          );
          return;
        }
      } else {
        const createSubscription = {
          ARBCreateSubscriptionRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            subscription: {
              name: itemSelect?.[0]?.name,
              paymentSchedule: {
                interval: {
                  length: every,
                  unit: `${period}s`,
                },
                startDate:
                  purchaseOption === "Schedule later"
                    ? scheduleLaterFormattedDate
                    : formattedDateAdd,
                totalOccurrences: "9999",
              },
              amount: +netPrice,
              order: {
                invoiceNumber: invoiceNumber,
              },
              profile: {
                customerProfileId: subscriberData?.[0]?.subscription_id,
                customerPaymentProfileId:
                  subscriberData?.[0]?.payment_profile_id,
              },
            },
          },
        };
        const response = await axios.post(authorize_api, createSubscription);
        if (response.data.messages.resultCode === "Error") {
          setLoading(false);
          setOrderLoading(false);
          dispatch(
            showToast({
              message: response.data.messages.message[0].text,
              type: "error",
            })
          );
          return;
        }

        if (response.data.messages.resultCode === "Ok") {
          const sub_id = uuid();
          const variable = {
            id: sub_id,
            product_id: itemSelect?.[0]?.id,
            status: true,
            created_at: scheduleLaterFormattedDate,
            workspace_id: selectedWorkspaceId,
            subscription_id: response.data.subscriptionId,
            product_image: itemSelect?.[0]?.image,
            product_name: itemSelect?.[0]?.name,
            product_type: itemSelect?.[0]?.type,
            product_period: period,
            product_sku: itemSelect?.[0]?.sku,
            product_plan: plan,
            product_quantity: count,
            product_price: +netPrice,
            invoice_number: invoiceNumber,
          };

          const createSubsCard = await client.graphql({
            query: createSubscriptions,
            variables: { input: variable },
          });

          setOrderLoading(false);
          setOpenItemModal(false);
          setPurchaseOption("");
          setScheduleLaterDate(null);
          setPromoCode("");
          setPromoCodeError(false);
          setSelectedProduct(null);
          setTotalDiscount(0);
          setNetPrice(0);
          setTotalPrice(0);
          setPrice(0);
          setAppliedCoupon("");
          setCount(1);
          setItemSelect(null);
          setPlan("");
          dispatch(
            showToast({
              message: "Your order is placed successfully",
              type: "success",
            })
          );
        }
      }
    } else {
      setOrderLoading(true);
      const buyProduct = {
        createTransactionRequest: {
          merchantAuthentication: {
            name: merchant_name,
            transactionKey: transaction_key,
          },
          transactionRequest: {
            transactionType: "authCaptureTransaction",
            amount: +netPrice,
            profile: {
              customerProfileId: subscriberData?.[0]?.subscription_id,
              paymentProfile: {
                paymentProfileId: subscriberData?.[0]?.payment_profile_id,
              },
            },
            order: {
              invoiceNumber: invoiceNumber,
            },
            lineItems: {
              lineItem: {
                itemId: lineItemId,
                name: itemSelect?.[0]?.name,
                description: `${itemSelect?.[0]?.name} is a ${itemSelect?.[0]?.type}`,
                quantity: count,
                unitPrice: totalPrice,
              },
            },
            processingOptions: {
              isSubsequentAuth: "true",
            },
            authorizationIndicatorType: {
              authorizationIndicator: "final",
            },
          },
        },
      };

      const response = await axios.post(authorize_api, buyProduct);

      if (response.data.messages.resultCode === "Ok") {
        if (response.data.transactionResponse.errors[0].errorCode === "1") {
          setOrderLoading(false);
          setOpenItemModal(false);
          setPurchaseOption("");
          setScheduleLaterDate(null);
          setPromoCode("");
          setPromoCodeError(false);
          setSelectedProduct(null);
          setTotalDiscount(0);
          setNetPrice(0);
          setTotalPrice(0);
          setPrice(0);
          setAppliedCoupon("");
          setCount(1);
          setItemSelect(null);
          setPlan("");
          dispatch(
            showToast({
              message: "Your order is placed successfully",
              type: "success",
            })
          );
        } else {
          setLoading(false);
          setOrderLoading(false);
          dispatch(
            showToast({
              message: response.data.transactionResponse.errors[0].errorText,
              type: "error",
            })
          );
          return;
        }
      } else {
        setLoading(false);
        setOrderLoading(false);
        dispatch(
          showToast({
            message: response.data.messages.message[0].text,
            type: "error",
          })
        );
        return;
      }
    }
    fetchSubscriptions();
    setOrderLoading(false);
  };

  const handleProductDiscountDollar = (findPromoCode, selectedItem) => {
    const updatedProductDetail = selectedItem?.map((product) => {
      let productDiscount = 0;
      if (selectedItem.some((p) => p.id === product.id)) {
        productDiscount = findPromoCode?.amount;
      }
      return {
        ...product,
        discount: +productDiscount,
      };
    });
    const totalDiscountOnPrices = updatedProductDetail.reduce(
      (acc, product) => {
        return acc + parseFloat(product.discount || 0);
      },
      0
    );
    setNetPrice(+price - +totalDiscountOnPrices);
    setTotalDiscount(totalDiscountOnPrices);
    setAppliedCoupon(findPromoCode.code);
  };

  const handleProductDiscountPercentage = (findPromoCode, selectedItem) => {
    const totalDiscountPercent = findPromoCode?.percent;

    const updatedProductDetail = selectedItem.map((product) => {
      const discountAmount = (totalDiscountPercent / 100) * price;
      return {
        ...product,
        discount: selectedItem.some((p) => p.id === product.id)
          ? +discountAmount.toFixed(2)
          : 0,
      };
    });
    const totalDiscountOnPrices = updatedProductDetail.reduce(
      (acc, product) => {
        return acc + parseFloat(product.discount || 0);
      },
      0
    );
    setNetPrice(+price - +totalDiscountOnPrices);
    setTotalDiscount(totalDiscountOnPrices);
    setAppliedCoupon(findPromoCode.code);
  };

  const handleFixedProductDiscount = (findPromoCode, selectedItem) => {
    if (findPromoCode?.allowed_email?.length > 0) {
      if (findPromoCode?.product_id?.length > 0) {
        const isMatchedEmail = findPromoCode?.allowed_email?.some(
          (email) => email.toLowerCase() === userData.email.toLowerCase()
        );
        if (isMatchedEmail) {
          if (!findPromoCode?.never_expire) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            const startDate = new Date(findPromoCode?.expire_start_date);
            startDate.setHours(0, 0, 0, 0);

            const endDate = new Date(findPromoCode?.expire_end_date);
            endDate.setHours(0, 0, 0, 0);
            if (startDate <= today && today <= endDate) {
              if (findPromoCode?.amount_type === "Dollar off") {
                handleProductDiscountDollar(findPromoCode, selectedItem);
              } else {
                handleProductDiscountPercentage(findPromoCode, selectedItem);
              }
            } else {
              dispatch(
                showToast({ message: "Promo code expired", type: "error" })
              );
              return;
            }
          } else {
            if (findPromoCode?.amount_type === "Dollar off") {
              handleProductDiscountDollar(findPromoCode, selectedItem);
            } else {
              handleProductDiscountPercentage(findPromoCode, selectedItem);
            }
          }
        } else {
          dispatch(showToast({ message: "Invalid promo code", type: "error" }));
          return;
        }
      } else {
        dispatch(showToast({ message: "Invalid promo code", type: "error" }));
        return;
      }
    } else {
      if (findPromoCode?.product_id?.length > 0) {
        if (!findPromoCode?.never_expire) {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const startDate = new Date(findPromoCode?.expire_start_date);
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date(findPromoCode?.expire_end_date);
          endDate.setHours(0, 0, 0, 0);
          if (startDate <= today && today <= endDate) {
            if (findPromoCode?.amount_type === "Dollar off") {
              handleProductDiscountDollar(findPromoCode, selectedItem);
            } else {
              handleProductDiscountPercentage(findPromoCode, selectedItem);
            }
          } else {
            dispatch(
              showToast({ message: "Promo code expired", type: "error" })
            );
            return;
          }
        } else {
          if (findPromoCode?.amount_type === "Dollar off") {
            handleProductDiscountDollar(findPromoCode, selectedItem);
          } else {
            handleProductDiscountPercentage(findPromoCode, selectedItem);
          }
        }
      } else {
        dispatch(showToast({ message: "Invalid promo code", type: "error" }));
        return;
      }
    }
  };

  const applyToken = () => {
    if (promoCode === "") {
      setPromoCodeError(true);
      return;
    }
    try {
      const findPromoCode = couponData?.find((item) => item.code === promoCode);
      const selectedItem = productData?.filter(
        (pro) => pro.name === selectedProduct
      );
      const matchingProduct = findPromoCode?.product_id?.find(
        (productId) => productId === selectedItem[0].id
      );

      if (findPromoCode && matchingProduct && selectedItem?.length > 0) {
        if (findPromoCode?.type === "Fixed product discount") {
          handleFixedProductDiscount(findPromoCode, selectedItem);
        }
      } else {
        dispatch(showToast({ message: "Invalid promo code", type: "error" }));
        return;
      }
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error", error);
    }
  };

  const today = dayjs();

  const columns = [
    { field: "id", hide: true },
    {
      field: "name",
      headerName: "Name",
      width: 400,
      renderCell: (params) => (
        <div
          style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
        >
          <img
            src={params.row.image}
            alt={params.row.name}
            style={{
              width: 50,
              height: 50,
              borderRadius: "10%",
              marginRight: 8,
            }}
          />
          {params.row.name}
        </div>
      ),
    },
    {
      field: "price",
      headerName: "Subscription Price",
      width: 200,
      renderCell: (params) =>
        `$ ${Number(params.value ?? 0)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .toLocaleString("en-US")}`,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => (
        <p style={params.row.status ? statusStyleActive : statusStyleInActive}>
          {params.row.status ? "Active" : "InActive"}
        </p>
      ),
    },
    {
      field: "action",
      headerName: userData?.role !== "workspace_member" && "Action",
      width: 250,
      renderCell: (params) => (
        <Grid container style={{ height: "100%" }}>
          {userData?.role !== "workspace_member" && (
            <>
              {params.row.status && params.row.subscription_id !== "" ? (
                <Button
                  disabled={!params.row.status}
                  variant="outlined"
                  style={buttonStyle}
                  onClick={() => handleCancelSubscription(params.row)}
                >
                  Cancel Subscription
                </Button>
              ) : !params.row.status ? (
                <Button disabled variant="outlined" style={buttonStyle}>
                  Cancelled
                </Button>
              ) : params.row.status && params.row.subscription_id === "" ? (
                <p style={{ margin: "0px", fontSize: "1rem", color: "#333" }}>
                  Subscribed
                </p>
              ) : null}
            </>
          )}
        </Grid>
      ),
    },
    {
      field: "delete",
      headerName: userData?.role !== "workspace_member" && "Delete",
      width: 200,
      renderCell: (params) => (
        <>
          {userData?.role !== "workspace_member" && (
            <>
              {params.row.status && params.row.subscription_id === "" ? (
                <IconButton
                  onClick={() => handleOpenDeleteModal(params.row.id)}
                >
                  <DeleteIcon style={{ color: "#E55A54" }} />
                </IconButton>
              ) : null}
            </>
          )}
        </>
      ),
    },
  ];

  const rows =
    subscriptionsList?.map((item) => ({
      id: item.id,
      name: item.product_name,
      image: item.product_image,
      startDate: item.created_at,
      status: item.status,
      subscription_id: item.subscription_id,
      price: item.product_price,
      subscription_by: item.subscription_by,
    })) || [];

  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setIdToDelete(id);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    setOpenDeleteModal(false);
    setLoader(true);
    try {
      const variables = {
        input: {
          id: idToDelete,
        },
      };

      const deleteSubsciptionRow = await client.graphql({
        query: deleteSubscriptions,
        variables: variables,
      });
      await fetchSubscriptions();
      setIdToDelete(null);
      setLoader(false);
      dispatch(
        showToast({
          message: "Plan is deleted successfully",
          type: "success",
        })
      );
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error", error);
    }
  };
  return (
    <>
      <div style={container}>
        {isMobile && (
          <div>
            <div>
              <p style={title}>My Plan</p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Link onClick={() => navigate("/orders")}>Orders</Link>
              {userData?.role !== "workspace_member" && (
                <>
                  <Link onClick={() => navigate("/payment_address_settings")}>
                    Payment Method
                  </Link>
                  <Link onClick={handleOpenItemModal}>Add Item</Link>
                </>
              )}
            </div>
          </div>
        )}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "start",
                marginBottom: isMobile && 10,
              }}
            >
              {!isMobile && (
                <div>
                  <p style={title}>My Plan</p>
                </div>
              )}
              {isMobile ? (
                <></>
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    marginTop: "0.45rem",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/orders")}
                    style={{ textTransform: "inherit" }}
                  >
                    Orders
                  </Button>
                  {userData?.role !== "workspace_member" && (
                    <Button
                      variant="outlined"
                      onClick={() => navigate("/payment_address_settings")}
                      style={{ textTransform: "inherit" }}
                    >
                      Payment Method
                    </Button>
                  )}
                  {userData?.role !== "workspace_member" && (
                    <Button
                      variant="outlined"
                      onClick={handleOpenItemModal}
                      style={{ textTransform: "inherit" }}
                    >
                      Add Item
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        {isMobile ? (
          <div className="tableInnerWrapper" style={{ background: "white" }}>
            <div className="table-info">
              <table className="d-table">
                <thead>
                  <tr>
                    <th style={{ width: "170px" }}>Title</th>
                    <th>Name</th>
                    <th>Created At</th>
                    <th>Status</th>
                    {userData?.role !== "workspace_member" && <th>Action</th>}
                  </tr>
                </thead>
                {subscriptionsList?.length === 0 ? (
                  <>
                    {" "}
                    <tbody>
                      <tr className="norecord">
                        <td colSpan={7} style={{ textAlign: "center" }}>
                          No Record Found
                        </td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  <>
                    <tbody>
                      {subscriptionsList?.length > 0 &&
                        subscriptionsList?.map((item, index, array) => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                marginBottom: 8,
                                marginTop: 8,
                              }}
                            >
                              <div>
                                <img
                                  src={item?.product_image}
                                  style={{
                                    height: "150px",
                                    width: "150px",
                                    borderRadius: "8%",
                                    backgroundColor: "transparent",
                                  }}
                                />
                              </div>
                              <div style={{ marginLeft: 10 }}>
                                <div className="td-name">
                                  <div className="itemtitle">
                                    <div className="td-name">Name:</div>
                                    <span style={nameContainerStyle}>
                                      {" "}
                                      {`${item?.product_name}`}
                                    </span>
                                  </div>
                                  <div className="itemtitle">
                                    <div className="td-name">Status:</div>
                                    <span
                                      style={
                                        item.status === true
                                          ? statusStyleActive
                                          : statusStyleInActive
                                      }
                                    >
                                      {item.product_type ===
                                        "variable subscription" &&
                                        item.status === true
                                        ? "Active"
                                        : item.product_type ===
                                          "variable subscription" &&
                                          item.status === false
                                          ? "InActive"
                                          : "Purchased"}
                                    </span>
                                  </div>
                                  <div className="itemtitle">
                                    <div className="td-name">Role:</div>
                                    <span style={{ padding: 0 }}>
                                      {item?.role === "workspace_admin"
                                        ? "Workspace Admin"
                                        : item?.role === "workspace_member"
                                          ? "Workspace Member"
                                          : item?.role === "super_admin"
                                            ? "Super Admin"
                                            : "Advisor"}
                                    </span>
                                  </div>

                                  <div
                                    className="itemtitle"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <div className="td-name">Created At:</div>
                                      <span>{formatDate(item?.createdAt)}</span>
                                    </div>
                                    <div style={{ marginLeft: 30 }}>
                                      {userData?.role !==
                                        "workspace_member" && (
                                          <>
                                            <div className="td-name">Action:</div>
                                            {item?.status ? (
                                              <span
                                                style={{
                                                  ...statusStyleInActive,
                                                  backgroundColor: "#F0F0F0",
                                                  color: "red",
                                                  padding: "0rem 0rem",
                                                }}
                                                onClick={() =>
                                                  handleCancelSubscription(
                                                    item?.row
                                                  )
                                                }
                                              >
                                                Cancel
                                              </span>
                                            ) : !item?.status ? (
                                              <span
                                                style={{
                                                  ...statusStyleInActive,
                                                  backgroundColor: "#F0F0F0",
                                                  color: "black",
                                                }}
                                              >
                                                Cancelled
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {index < subscriptionsList.length - 1 && (
                              <Divider sx={{ width: "100%" }} />
                            )}
                          </>
                        ))}
                    </tbody>
                  </>
                )}
              </table>
            </div>
          </div>
        ) : (
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Card style={organizationCard}>
                {loader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: "50vh",
                    }}
                  >
                    <PulseLoader color="#ACD6EB" />
                  </div>
                ) : (
                  <>
                    {subscriptionsList && subscriptionsList.length > 0 ? (
                      <DataGrid
                        rows={rows}
                        columns={columns.filter(
                          (column) => column.field !== "id"
                        )}
                        getRowId={(row) => row.id}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 7,
                            },
                          },
                        }}
                        pageSizeOptions={[7, 25, 50, 100]}
                        density="comfortable"
                        sx={{
                          border: 0,
                          borderColor: "primary.light",
                          "& .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                          },
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          justifyContent: "center",
                          minHeight: "50vh",
                        }}
                        className="sub-btn-container"
                      >
                        <span className="shop-text">
                          You haven't started your plan.
                        </span>
                        <Button
                          variant="contained"
                          style={{
                            marginTop: "0.5rem",
                            textTransform: "inherit",
                          }}
                          onClick={() => navigate("/shop")}
                          className="sub-button"
                        >
                          Build Your Plan
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        )}
        <Dialog open={openDeleteModal} disableBackdropClick>
          <DialogTitle>Delete Plan</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this plan? This plan will be
              removed permanantly.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleCloseDeleteModal}
              style={{ textTransform: "inherit" }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmDelete}
              style={{ textTransform: "inherit" }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open} disableBackdropClick>
          <DialogTitle>Cancel Subscription</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel this subscription? This
              subscription will be cancelled permanantly.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleClose}
              style={{ textTransform: "inherit" }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfrimCancel}
              style={{ textTransform: "inherit" }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openItemModal}
          disableBackdropClick
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Add Product</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container spacing={0} style={{ marginTop: "1rem" }}>
                <Grid item xs={9}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={productData?.map((product) => product.name)}
                    onChange={handleProductChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Search for a product..." />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div style={counterContainer}>
                    <IconButton
                      aria-label="delete"
                      size={isMobile ? "small" : "large"}
                      onClick={handleDecrement}
                    >
                      <RemoveCircleOutlinedIcon
                        fontSize={isMobile ? "small" : "large"}
                        style={counterButton}
                      />
                    </IconButton>
                    <p>
                      <b>{count}</b>
                    </p>
                    <IconButton
                      aria-label="delete"
                      size={isMobile ? "small" : "large"}
                      onClick={handleIncrement}
                    >
                      <AddCircleOutlinedIcon
                        fontSize={isMobile ? "small" : "large"}
                        style={counterButton}
                      />
                    </IconButton>
                  </div>
                </Grid>
                {selectedProduct !== null &&
                  itemSelect?.[0]?.type === "variable subscription" && (
                    <Grid item xs={9} style={{ marginTop: "1rem" }}>
                      <TextField
                        select
                        fullWidth
                        name="plan"
                        label="Choose plan"
                        value={plan}
                        onChange={handlePlanChange}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          },
                        }}
                      >
                        {loading ? (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress size={20} />
                          </Box>
                        ) : (
                          plansById?.map((option) => (
                            <MenuItem key={option.name} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))
                        )}
                      </TextField>
                    </Grid>
                  )}
                {itemSelect?.[0]?.type === "variable subscription" && (
                  <Grid item xs={9} style={{ marginTop: "1rem" }}>
                    <TextField
                      // error={amountTypeError}
                      select
                      fullWidth
                      label="Purchase Option"
                      value={purchaseOption}
                      onChange={(e) => {
                        setPurchaseOption(e.target.value);
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: "300px",
                            },
                          },
                        },
                      }}
                    >
                      {["Instant buy", "Schedule later"].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {purchaseOption === "Schedule later" && (
                  <Grid item xs={9} style={{ marginTop: "1rem" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Select Date"
                        fullWidth
                        value={scheduleLaterDate}
                        onChange={handleDateChange}
                        minDate={today}
                        slotProps={{
                          field: {
                            clearable: true,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}
                <Grid item xs={9} style={{ marginTop: "1rem" }}>
                  <TextField
                    error={promoCodeError}
                    id="outlined-basic"
                    label="Promo code"
                    variant="outlined"
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                      setTotalDiscount(0);
                      setPromoCodeError(false);
                      setAppliedCoupon("");
                    }}
                    value={promoCode}
                    style={{ width: "100%", marginBottom: "1rem" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={
                      itemSelect?.[0]?.type === "variable subscription" &&
                      plan === ""
                    }
                    size="small"
                    variant="contained"
                    style={{
                      fontSize: "0.9rem",
                      textTransform: "initial",
                    }}
                    onClick={applyToken}
                  >
                    Apply Coupon
                  </Button>
                </Grid>
                <Grid
                  container
                  spacing={0}
                  style={{
                    marginTop: "2rem",
                    backgroundColor: "#f4f4f4",
                    padding: "2rem",
                    borderRadius: "1rem",
                  }}
                >
                  {appliedCoupon !== "" && (
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        flexDirection: "column",
                      }}
                    >
                      <h4 style={{ margin: "0px" }}>Coupon</h4>
                      <Chip
                        style={{
                          borderRadius: "5px",
                          marginTop: "0.5rem",
                          backgroundColor: "#D8EBDD",
                        }}
                        label={appliedCoupon}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={appliedCoupon !== "" ? 6 : 12}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          gap: "2rem",
                        }}
                      >
                        <h4
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          Item Total:
                        </h4>
                        <p
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          {`$${Number(price ?? 0)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .toLocaleString("en-US")}`}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          gap: "2rem",
                          marginTop: "1rem",
                        }}
                      >
                        <h4
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          Discount:
                        </h4>
                        <p
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          {`$${Number(totalDiscount ?? 0)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .toLocaleString("en-US")}`}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          gap: "2rem",
                          marginTop: "1rem",
                        }}
                      >
                        <h4
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          Grand Total:
                        </h4>
                        <p
                          style={{
                            margin: "0px",
                            width: "150px",
                            textAlign: "right",
                          }}
                        >
                          {`$${Number(netPrice ?? 0)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .toLocaleString("en-US")}`}
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleCloseItemModal}
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Close
            </Button>
            <LoadingButton
              onClick={handlePlaceOrder}
              endIcon={<ChevronRightRoundedIcon />}
              loading={orderLoading}
              loadingPosition="end"
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Place order
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {!isMobile && (
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default Subscription;

import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const CircularGauge = ({ value }) => {
  const data = [
    { name: "filled", value: value },
    { name: "empty", value: 8 },
    { name: "indicator", value: 2 },
  ];

  const COLORS = ["#29B6F6", "#EEEEEE", "#f44336"];

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "280px",
        padding: "20px",
      }}
    >
      <PieChart width={300} height={180}>
        {/* Center text - Positioned before the Pie to appear behind it */}
        <text
          x={150}
          y={130}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fontSize: "48px",
            fontWeight: "bold",
            fill: "#333",
          }}
        >
          {value}%
        </text>
        <text
          x={150}
          y={160}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fontSize: "16px",
            fill: "#f44336",
          }}
        >
          -3
        </text>

        <Pie
          data={data}
          cx={150}
          cy={150}
          startAngle={180}
          endAngle={0}
          innerRadius={100}
          outerRadius={120}
          dataKey="value"
          strokeWidth={0}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} strokeWidth={0} />
          ))}
        </Pie>
      </PieChart>

      {/* Legend - Now in column layout */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "0 20px",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: "#29B6F6",
                marginRight: "8px",
              }}
            />
            <span>Your site</span>
          </div>
          <span>{value}%</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "0",
                height: "0",
                borderLeft: "6px solid transparent",
                borderRight: "6px solid transparent",
                borderTop: "8px solid #f44336",
                marginRight: "8px",
              }}
            />
            <span>Top-10% websites</span>
          </div>
          <span>92%</span>
        </div>
      </div>
    </div>
  );
};

export default CircularGauge;
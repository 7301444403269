import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  TextField,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { Divider } from "@mui/material";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import AddIcon from "@mui/icons-material/Add";
import { generateClient } from "aws-amplify/api";
import Footer from "../components/Footer";
import { listSubscriptions } from "../graphql/queries";
import { useDispatch } from "react-redux";
import { showToast } from "../redux/userSlice";
import { PulseLoader } from "react-spinners";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

const container = {
  margin: "2rem 4rem",
};
const title = {
  fontSize: "2rem",
  margin: "0px",
  marginBottom: "1rem",
};
const organizationCard = {
  padding: "1.5rem 2rem",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 2px 10px",
  minHeight: "68vh",
  position: "relative",
};

const Template = () => {
  const client = generateClient();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [subscriptionsList, setSubscriptionsList] = useState(null);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editValue, setEditValue] = useState("");

  useEffect(() => {
    fetchSubscriptions();
  }, []);
  const fetchSubscriptions = async () => {
    try {
      setLoader(true);
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getSubscriptionData = await client.graphql({
        query: listSubscriptions,
        variables: variables,
      });
      const res = getSubscriptionData.data.listSubscriptions.items;
      //   const sortedRes = res?.sort((a, b) => {
      //     if (a.status === b.status) {
      //       return 0;
      //     }
      //     return a.status ? -1 : 1;
      //   });
      const sortedRes = res?.filter((item) => item.status !== false);

      setSubscriptionsList(sortedRes);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleAddClick = () => {
    setIsAdding(true);
    setEditIndex(null);
    setEditValue("");
  };

  const handleCloseField = () => {
    setIsAdding(false);
  };

  const handleSaveNewItem = () => {
    if (newItem.trim() !== "") {
      setItems([...items, newItem]);
      setNewItem("");
      setIsAdding(false);
    }
  };

  const handleDelete = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleEditClick = (index) => {
    setIsAdding(false);
    setEditIndex(index);
    setEditValue(items[index]);
  };

  const handleCloseEditField = () => {
    setEditIndex(null);
    setEditValue("");
  };

  const handleSaveEdit = (index) => {
    const updatedItems = [...items];
    updatedItems[index] = editValue;
    setItems(updatedItems);
    setEditIndex(null);
    setEditValue("");
  };

  return (
    <>
      <div style={container}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <p style={title}>Template</p>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card style={organizationCard}>
              <Grid container spacing={0}>
                <Grid item xs={2.8}>
                  {loader ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "50vh",
                      }}
                    >
                      <PulseLoader color="#ACD6EB" />
                    </div>
                  ) : (
                    <>
                      {subscriptionsList?.map((item, index, array) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                            }}
                            key={index}
                          >
                            <img
                              src={item.product_image}
                              alt={item.product_name}
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: "10%",
                                marginRight: 8,
                              }}
                            />
                            {item.product_name}
                          </div>
                          {index !== array?.length - 1 && (
                            <Divider light style={{ margin: "0.5rem 0rem" }} />
                          )}
                        </>
                      ))}
                    </>
                  )}
                </Grid>
                <Grid item xs={0.2} style={{ minHeight: "60vh" }}>
                  <Divider orientation="vertical" variant="middle" light />
                </Grid>
                <Grid item xs={9}>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "center", marginBottom: "0.5rem" }}
                    >
                      <p style={{ ...title, fontSize: "1.5rem" }}>
                        Custom Development
                      </p>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        // error={lastError}
                        id="outlined-basic"
                        placeholder="Product Description"
                        variant="outlined"
                        // onChange={(e) => {
                        //   setLastName(e.target.value);
                        //   setLastError(false);
                        //   setServerError(false);
                        //   setServerErrorMessage("");
                        // }}
                        // value={lastName}
                        multiline
                        rows={8}
                        style={{
                          width: "100%",
                          marginBottom: "1rem",
                          backgroundColor: "#fff",
                        }}
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="end">
                        //       <PersonIcon style={{ color: "#777" }} />
                        //     </InputAdornment>
                        //   ),
                        // }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "2rem",
                        }}
                      >
                        <Typography style={{ fontSize: "1.5rem" }}>
                          List
                        </Typography>
                        <IconButton
                          style={{
                            backgroundColor: "#1692E0",
                            padding: "0.1rem",
                          }}
                          onClick={handleAddClick}
                        >
                          <AddIcon
                            style={{
                              color: "#fff",
                              fontSize: "2rem",
                            }}
                          />
                        </IconButton>
                      </div>

                      {isAdding && (
                        <div
                          style={{
                            marginTop: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            size="small"
                            style={{ width: "100%" }}
                            value={newItem}
                            onChange={(e) => setNewItem(e.target.value)}
                            placeholder="Enter list item"
                          />
                          <Button
                            onClick={handleCloseField}
                            variant="outlined"
                            style={{ textTransform: "inherit" }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={handleSaveNewItem}
                            variant="contained"
                            style={{ textTransform: "inherit" }}
                          >
                            Save
                          </Button>
                        </div>
                      )}

                      <List>
                        {items.map((item, index) => (
                          <ListItem
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {editIndex === index ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  gap: "0.5rem",
                                  width: "100%",
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  style={{ width: "100%" }}
                                  value={editValue}
                                  onChange={(e) => setEditValue(e.target.value)}
                                  placeholder="Enter list item"
                                />
                                <Button
                                  onClick={handleCloseEditField}
                                  variant="outlined"
                                  style={{ textTransform: "inherit" }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => handleSaveEdit(index)}
                                  variant="contained"
                                  style={{ textTransform: "inherit" }}
                                >
                                  Save
                                </Button>
                              </div>
                            ) : (
                              <>
                                {/* //     <div
                            //       style={{
                            //         display: "flex",
                            //         justifyContent: "center",
                            //         alignItems: "center",
                            //         gap: "10rem",
                            //       }}
                            //     > */}
                                <ul>
                                  <li>{item}</li>
                                </ul>
                                <div>
                                  <IconButton
                                    onClick={() => handleEditClick(index)}
                                  >
                                    <EditIcon style={{ color: "#1976D2" }} />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => handleDelete(index)}
                                  >
                                    <DeleteIcon style={{ color: "#E55A54" }} />
                                  </IconButton>
                                </div>
                                {/* // </div> */}
                              </>
                            )}
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Button
                style={{
                  position: "absolute",
                  bottom: 20,
                  right: 48,
                  textTransform: "inherit",
                }}
                variant="contained"
              >
                Save Template
              </Button>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Template;

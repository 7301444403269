import React, { useEffect, useState } from "react";
import { Alert, Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch } from "react-redux";
import { setLoading, showToast } from "../redux/userSlice";
import {
  createSubscriberCard,
  createUserBilling,
  createUserInfo,
  createWorkspaceInfo,
  createWorkspaceTeam,
  updateWorkspaceInfo,
  updateWorkspaceTeam,
} from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import {
  listUserInfos,
  listWorkspaceInfos,
  listWorkspaceTeams,
} from "../graphql/queries";
import uuid from "react-uuid";
import axios from "axios";

const cardStyle = {
  margin: "10rem auto",
  width: "40%",
  textAlign: "center",
};
const desktendLoginUrl = process.env.REACT_APP_DESKTEND_LOGIN_ENDPOINT
const desktendFeedsUrl = process.env.REACT_APP_DESKTEND_FEED_ENDPOINT;
const desktendEmail = process.env.REACT_APP_DESKTEND_USER_EMAIL
const desktendPassword = process.env.REACT_APP_DESKTEND_USER_PASSWORD
const desktendDesk = process.env.REACT_APP_DESKTEND_DESK

const AddWorkspaceUserInfo = () => {
  const dispatch = useDispatch();
  const client = generateClient();
  const { state } = useLocation();
  const workspaceName = state?.workSpace;
  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastError, setLastError] = useState(false);
  const [firstError, setFirstError] = useState(false);
  const [servererror, setServerError] = useState(false);
  const [servererrorMessage, setServerErrorMessage] = useState("");

  const merchant_name = process.env.REACT_APP_MERCHANT_LOGIN_NAME;
  const transaction_key = process.env.REACT_APP_MERCHANT_TRANSACTION_KEY;
  const authorize_api = process.env.REACT_APP_AUTHORIZE_API;

  const getProfileId = async () => {
    const email = userData?.email;
    const getCustomer = {
      getCustomerProfileRequest: {
        merchantAuthentication: {
          name: merchant_name,
          transactionKey: transaction_key,
        },
        email,
      },
    };
    const fetchCustomerData = await axios.post(authorize_api, getCustomer);
    const id = fetchCustomerData?.data?.profile?.customerProfileId;
    return id;
  };

  // const createPaymentProfile = async () => {createPaymentProfile
  //   const email = userData?.email;
  //   const getCustomer = {
  //     getCustomerProfileRequest: {
  //       merchantAuthentication: {
  //         name: merchant_name,
  //         transactionKey: transaction_key,
  //       },
  //       email,
  //     },
  //   };
  //   const fetchCustomerData = await axios.post(authorize_api, getCustomer);
  //   const id = fetchCustomerData?.data?.profile?.customerProfileId;
  //   return id;
  // };

  // const res = getProfileId();

  const postUserBilling = async (userBillingId, workspace_id) => {
    dispatch(setLoading(true));
    try {
      const variable = {
        id: userBillingId,
        workspace_id: workspace_id,
        card_number: "",
        expiry_month: "",
        expiry_year: "",
        cvc: "",
        first_name: firstName,
        last_name: lastName,
        email: userData?.email,
        city: "",
        country: "",
        postal_code: "",
        state: "",
      };
      const createBilling = await client.graphql({
        query: createUserBilling,
        variables: { input: variable },
      });
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error updating workspace_ids:", error);
    }
  };

  const postWorkspaceTeamInfo = async (
    user_id,
    workspaceTeamId,
    workspace_id
  ) => {
    dispatch(setLoading(true));
    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      const workspaceTeamDetail = {
        id: workspaceTeamId,
        workspace_id: [workspace_id],
        user_id: [user_id],
        joined_at: formattedDate,
        status: true,
        workspaceinfoID: workspace_id,
      };

      const result = await client.graphql({
        query: createWorkspaceTeam,
        variables: { input: workspaceTeamDetail },
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
    }
  };

  const postWorkspaceInfo = async (workspace_id, workspaceTeamId, user_id, feedId) => {
    dispatch(setLoading(true));
    try {
      // getting date
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      //getting url
      const workSpaceLowercase = workspaceName.toLowerCase();
      const workSpaceUrl = workSpaceLowercase.replace(/\s+/g, "-");
      const WorkSpaceUrl = `${workSpaceUrl}.rothbright.app`;

      const workspaceDetail = {
        id: workspace_id,
        name: workspaceName,
        url: WorkSpaceUrl,
        logo: "https://shop.raceya.fit/wp-content/uploads/2020/11/logo-placeholder.jpg",
        created_at: formattedDate,
        workspaceteamID: workspaceTeamId,
        user_id: [user_id],
        feed_id: feedId
      };

      const result = await client.graphql({
        query: createWorkspaceInfo,
        variables: { input: workspaceDetail },
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const postSubscriberID = async (subscriberId, workspace_id, CustomerId) => {
    try {
      dispatch(setLoading(true));
      const variable = {
        id: subscriberId,
        workspace_id: workspace_id,
        email: userData?.email,
        subscription_id: CustomerId,
      };

      const createSubscriber = await client.graphql({
        query: createSubscriberCard,
        variables: { input: variable },
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      return;
    }
  };

  const postUserData = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    if (!firstName && !lastName) {
      setFirstError(true);
      setLastError(true);
      dispatch(setLoading(false));
      return;
    }
    if (!firstName) {
      setFirstError(true);
      dispatch(setLoading(false));
      return;
    }
    if (!lastName) {
      setLastError(true);
      dispatch(setLoading(false));
      return;
    }
    try {
      let userId = uuid();
      let workspaceTeamId = uuid();
      let userBillingId = uuid();
      let workspace_id = uuid();
      let subscriberId = uuid();
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      const CustomerId = await getProfileId();

      if (CustomerId === undefined) {
        dispatch(setLoading(false));
        dispatch(
          showToast({
            message: "Authorize.net: Customer profile not found",
            type: "error",
          })
        );
        return;
      } else {

        const newUserDetail = {
          id: userId,
          user_id: userData?.sub,
          user_name_first: firstName,
          user_name_second: lastName,
          display_picture:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTet-jk67T6SYdHW04eIMLygHzEeJKobi9zdg&usqp=CAU",
          email: userData?.email,
          role: "workspace_admin",
          joined_at: formattedDate,
          workspace: workspaceName,
          workspaceteamID: workspaceTeamId,
          workspace_id: workspace_id,
          subscription_id: CustomerId,
        };
        const result = await client.graphql({
          query: createUserInfo,
          variables: { input: newUserDetail },
        });

        if (result) {
          let createFeedResponse;
          const loginResponse = await axios.post(desktendLoginUrl, {
            username: desktendEmail,
            password: desktendPassword // You'll need to determine how to securely handle the password
          });
          console.log("The login response of desktent api is:::", loginResponse)
          if (loginResponse.data.accessToken) {
            // Create empty feed in Desktend
            createFeedResponse = await axios.post(desktendFeedsUrl, {
              name: workspaceName,
              mode: "Regular"
              // Add any other required fields for creating a feed
            }, {
              headers: {
                'Authorization': `Bearer ${loginResponse.data.accessToken}`,
                'Desk': desktendDesk
              }
            });

            if (createFeedResponse.data) {
              console.log('Empty feed created successfully in Desktend');
            }
          }
          await postUserBilling(userBillingId, workspace_id);
          await postWorkspaceTeamInfo(
            userData?.sub,
            workspaceTeamId,
            workspace_id
          );
          await postWorkspaceInfo(workspace_id, workspaceTeamId, userData?.sub, createFeedResponse.data.id);
          await postSubscriberID(subscriberId, workspace_id, CustomerId);

          localStorage.setItem(
            "user",
            JSON.stringify({
              ...userData,
              role: "workspace_admin",
            })
          );
          localStorage.setItem("selectedWorkspaceId", workspace_id);
          navigate("/home");
        }
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      setServerError(true);
      setServerErrorMessage(error.message);
    }
  };

  return (
    <>
      <form style={cardStyle}>
        <div style={{ margin: "1rem 0rem", textAlign: "center" }}>
          <img src="/faviconPNG.png" height="70px" width="430px" />
        </div>
        <div style={{ marginTop: "2rem" }}>
          <h1 style={{ color: "#1D1C1D", fontSize: "4rem", margin: "0rem" }}>
            Create owner
          </h1>
          <p style={{ fontSize: "1.5rem", color: "#605E61", margin: "0px" }}>
            Set up your name to join the Team and manage your projects
          </p>
        </div>
        <Grid container spacing={0} style={{ marginTop: "4rem" }}>
          <Grid item xs={12} style={{ display: "flex", gap: "1rem" }}>
            <Grid item xs={6}>
              <TextField
                error={firstError}
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstError(false);
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={firstName}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon style={{ color: "#777" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                error={lastError}
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                onChange={(e) => {
                  setLastName(e.target.value);
                  setLastError(false);
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={lastName}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon style={{ color: "#777" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {servererror ? (
          <div style={{ marginBottom: "0.7rem" }}>
            <Alert severity="error">{servererrorMessage}</Alert>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "100%",
              padding: "0.9rem 0rem",
              fontSize: "1rem",
              fontWeight: "bold",
              marginTop: "0.5rem",
            }}
            onClick={(e) => postUserData(e)}
            type="submit"
          >
            Continued
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddWorkspaceUserInfo;

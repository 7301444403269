import React from "react";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Box,
  Typography,
  Divider,
  CardMedia,
  Rating,
  Paper,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
const ProductDetailMobileResposnive = (props) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <>
      <Grid2
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ margin: "1rem 1rem 0px 1rem" }}
      >
        <Card style={{ marginBottom: 10 }}>
          <CardMedia
            component="img"
            image={props?.productById?.[0]?.image}
            alt={props?.productById?.[0]?.name}
            sx={{
              height: "100%",
              width: "100%",
              boxShadow: "rgba(20, 46, 110, 0.1) ",
            }}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontSize: 14, fontWeight: "bold", padding: 0, margin: 0 }}
            >
              {props?.productById?.[0]?.name}
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontSize: 14, fontWeight: "bold", padding: 0, margin: 0 }}
            >
              From{" "}
              <span style={{ color: "#2895CF", fontWeight: "bold" }}>
                {`$${
                  Number.isNaN(Number(props?.price)) ||
                  props?.price === undefined
                    ? ""
                    : Number(props?.price)
                        .toFixed(0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        .toLocaleString("en-US")
                }`}
              </span>{" "}
              / {props?.period}
            </Typography>
          </CardContent>
          <Grid2 container columns={16} m={1} alignItems={"center"}>
            <Grid2 xs={8}>
              <Typography>Plan Size</Typography>
            </Grid2>
            <Grid2 xs={8}>
              <TextField
                select
                size="small"
                margin="normal"
                fullWidth
                name="plan"
                label="Choose an option"
                error={props?.error}
                helperText={props?.error ? "Required field" : ""}
                style={{ margin: "0px", width: "95%", marginLeft: 10 }}
                value={props.plan}
                onChange={props.handlePlanChange}
              >
                <MenuItem value="" key="">
                  Clear
                </MenuItem>
                {props?.plansById?.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid2>
          </Grid2>
          <Grid2
            container
            columns={16}
            m={1}
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid2 xs={8}>
              <Typography>Quantity</Typography>
            </Grid2>
            <Grid2
              xs={8}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <IconButton
                aria-label="delete"
                size="small"
                onClick={props.handleDecrement}
              >
                <RemoveCircleOutlinedIcon
                  fontSize="medium"
                  style={props.counterButton}
                />
              </IconButton>
              <p>
                <b>{props?.count}</b>
              </p>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={props.handleIncrement}
              >
                <AddCircleOutlinedIcon
                  fontSize="medium"
                  style={props?.counterButton}
                />
              </IconButton>
            </Grid2>
          </Grid2>
          <Grid item xs={12} spacing={0} m={1}>
            <Grid item xs={12}>
              <div style={{ display: "flex", gap: "1rem" }}>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    padding: "0.3rem 0rem",
                    fontSize: 14,
                    fontWeight: "bold",
                    textTransform: "initial",
                    backgroundColor: "#222",
                  }}
                  onClick={props.handleBillingData}
                >
                  Buy Now
                </Button>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    padding: "0.3rem 0rem",
                    fontSize: 14,
                    fontWeight: "bold",
                    textTransform: "initial",
                  }}
                  onClick={props.setProductToLocal}
                >
                  Add To Cart
                </Button>
              </div>
            </Grid>
            <Typography
              variant="subtitle1"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                textAlign: "center",
                color: "#1976d2",
                marginTop: 20,
                fontSize: 14,
              }}
              onClick={props.handleMeetAdvisorClick}
            >
              Meet with a Rothbright advisor to talk about the plan best for you
            </Typography>
          </Grid>
          <Accordion style={{ margin: 0, padding: 0 }}>
            <AccordionSummary
              style={{ margin: 10, padding: 0 }}
              expandIcon={<ExpandMoreIcon />}
            >
              Description
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0, margin: 0 }}>
              <div
                style={{
                  lineHeight: "1.8rem",
                  color: "#605E61",
                  fontSize: 14,
                  margin: "0px 10px 0px 10px",
                  // padding: 2,
                  textAlign: "left",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    props?.updateDescription.length !== 0
                      ? props?.updateDescription
                      : props?.parentDescription,
                }}
              ></div>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Grid2>
    </>
  );
};

export default ProductDetailMobileResposnive;

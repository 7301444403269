/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDesktendUserInfo = /* GraphQL */ `
  mutation CreateDesktendUserInfo(
    $input: CreateDesktendUserInfoInput!
    $condition: ModelDesktendUserInfoConditionInput
  ) {
    createDesktendUserInfo(input: $input, condition: $condition) {
      id
      first_name
      last_name
      desk_title
      desk_id
      user_id
      role
      username
      status
      feed_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDesktendUserInfo = /* GraphQL */ `
  mutation UpdateDesktendUserInfo(
    $input: UpdateDesktendUserInfoInput!
    $condition: ModelDesktendUserInfoConditionInput
  ) {
    updateDesktendUserInfo(input: $input, condition: $condition) {
      id
      first_name
      last_name
      desk_title
      desk_id
      user_id
      role
      username
      status
      feed_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDesktendUserInfo = /* GraphQL */ `
  mutation DeleteDesktendUserInfo(
    $input: DeleteDesktendUserInfoInput!
    $condition: ModelDesktendUserInfoConditionInput
  ) {
    deleteDesktendUserInfo(input: $input, condition: $condition) {
      id
      first_name
      last_name
      desk_title
      desk_id
      user_id
      role
      username
      status
      feed_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      code
      description
      type
      amount
      percent
      never_expire
      expire_start_date
      product_id
      allowed_email
      expire_end_date
      created_at
      amount_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon(
    $input: UpdateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    updateCoupon(input: $input, condition: $condition) {
      id
      code
      description
      type
      amount
      percent
      never_expire
      expire_start_date
      product_id
      allowed_email
      expire_end_date
      created_at
      amount_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      code
      description
      type
      amount
      percent
      never_expire
      expire_start_date
      product_id
      allowed_email
      expire_end_date
      created_at
      amount_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrders = /* GraphQL */ `
  mutation CreateOrders(
    $input: CreateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    createOrders(input: $input, condition: $condition) {
      id
      workspace_id
      product_id
      product_plan
      product_quantity
      product_price
      status
      created_at
      subscription_id
      stripe_customer_id
      product_image
      product_name
      product_type
      product_period
      product_sku
      invoice_number
      refund_amount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrders = /* GraphQL */ `
  mutation UpdateOrders(
    $input: UpdateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    updateOrders(input: $input, condition: $condition) {
      id
      workspace_id
      product_id
      product_plan
      product_quantity
      product_price
      status
      created_at
      subscription_id
      stripe_customer_id
      product_image
      product_name
      product_type
      product_period
      product_sku
      invoice_number
      refund_amount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrders = /* GraphQL */ `
  mutation DeleteOrders(
    $input: DeleteOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    deleteOrders(input: $input, condition: $condition) {
      id
      workspace_id
      product_id
      product_plan
      product_quantity
      product_price
      status
      created_at
      subscription_id
      stripe_customer_id
      product_image
      product_name
      product_type
      product_period
      product_sku
      invoice_number
      refund_amount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCartItems = /* GraphQL */ `
  mutation CreateCartItems(
    $input: CreateCartItemsInput!
    $condition: ModelCartItemsConditionInput
  ) {
    createCartItems(input: $input, condition: $condition) {
      id
      product_id
      image
      name
      period
      price
      type
      user_id
      workspace_id
      plan
      quantity
      sku
      every
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCartItems = /* GraphQL */ `
  mutation UpdateCartItems(
    $input: UpdateCartItemsInput!
    $condition: ModelCartItemsConditionInput
  ) {
    updateCartItems(input: $input, condition: $condition) {
      id
      product_id
      image
      name
      period
      price
      type
      user_id
      workspace_id
      plan
      quantity
      sku
      every
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCartItems = /* GraphQL */ `
  mutation DeleteCartItems(
    $input: DeleteCartItemsInput!
    $condition: ModelCartItemsConditionInput
  ) {
    deleteCartItems(input: $input, condition: $condition) {
      id
      product_id
      image
      name
      period
      price
      type
      user_id
      workspace_id
      plan
      quantity
      sku
      every
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductTags = /* GraphQL */ `
  mutation CreateProductTags(
    $input: CreateProductTagsInput!
    $condition: ModelProductTagsConditionInput
  ) {
    createProductTags(input: $input, condition: $condition) {
      id
      name
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductTags = /* GraphQL */ `
  mutation UpdateProductTags(
    $input: UpdateProductTagsInput!
    $condition: ModelProductTagsConditionInput
  ) {
    updateProductTags(input: $input, condition: $condition) {
      id
      name
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductTags = /* GraphQL */ `
  mutation DeleteProductTags(
    $input: DeleteProductTagsInput!
    $condition: ModelProductTagsConditionInput
  ) {
    deleteProductTags(input: $input, condition: $condition) {
      id
      name
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductCategories = /* GraphQL */ `
  mutation CreateProductCategories(
    $input: CreateProductCategoriesInput!
    $condition: ModelProductCategoriesConditionInput
  ) {
    createProductCategories(input: $input, condition: $condition) {
      id
      name
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductCategories = /* GraphQL */ `
  mutation UpdateProductCategories(
    $input: UpdateProductCategoriesInput!
    $condition: ModelProductCategoriesConditionInput
  ) {
    updateProductCategories(input: $input, condition: $condition) {
      id
      name
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductCategories = /* GraphQL */ `
  mutation DeleteProductCategories(
    $input: DeleteProductCategoriesInput!
    $condition: ModelProductCategoriesConditionInput
  ) {
    deleteProductCategories(input: $input, condition: $condition) {
      id
      name
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductVariations = /* GraphQL */ `
  mutation CreateProductVariations(
    $input: CreateProductVariationsInput!
    $condition: ModelProductVariationsConditionInput
  ) {
    createProductVariations(input: $input, condition: $condition) {
      id
      name
      price
      description
      product_id
      period
      signup_fee
      is_active
      is_deleted
      sku
      every
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductVariations = /* GraphQL */ `
  mutation UpdateProductVariations(
    $input: UpdateProductVariationsInput!
    $condition: ModelProductVariationsConditionInput
  ) {
    updateProductVariations(input: $input, condition: $condition) {
      id
      name
      price
      description
      product_id
      period
      signup_fee
      is_active
      is_deleted
      sku
      every
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductVariations = /* GraphQL */ `
  mutation DeleteProductVariations(
    $input: DeleteProductVariationsInput!
    $condition: ModelProductVariationsConditionInput
  ) {
    deleteProductVariations(input: $input, condition: $condition) {
      id
      name
      price
      description
      product_id
      period
      signup_fee
      is_active
      is_deleted
      sku
      every
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscriberCard = /* GraphQL */ `
  mutation CreateSubscriberCard(
    $input: CreateSubscriberCardInput!
    $condition: ModelSubscriberCardConditionInput
  ) {
    createSubscriberCard(input: $input, condition: $condition) {
      id
      workspace_id
      email
      subscription_id
      stripe_customer_id
      payment_profile_id
      stripe_payment_profile_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubscriberCard = /* GraphQL */ `
  mutation UpdateSubscriberCard(
    $input: UpdateSubscriberCardInput!
    $condition: ModelSubscriberCardConditionInput
  ) {
    updateSubscriberCard(input: $input, condition: $condition) {
      id
      workspace_id
      email
      subscription_id
      stripe_customer_id
      payment_profile_id
      stripe_payment_profile_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubscriberCard = /* GraphQL */ `
  mutation DeleteSubscriberCard(
    $input: DeleteSubscriberCardInput!
    $condition: ModelSubscriberCardConditionInput
  ) {
    deleteSubscriberCard(input: $input, condition: $condition) {
      id
      workspace_id
      email
      subscription_id
      stripe_customer_id
      payment_profile_id
      stripe_payment_profile_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserBilling = /* GraphQL */ `
  mutation CreateUserBilling(
    $input: CreateUserBillingInput!
    $condition: ModelUserBillingConditionInput
  ) {
    createUserBilling(input: $input, condition: $condition) {
      id
      workspace_id
      address
      card_number
      expiry_month
      expiry_year
      cvc
      first_name
      last_name
      email
      city
      country
      postal_code
      state
      holderName
      paymentMethod_Gateway
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserBilling = /* GraphQL */ `
  mutation UpdateUserBilling(
    $input: UpdateUserBillingInput!
    $condition: ModelUserBillingConditionInput
  ) {
    updateUserBilling(input: $input, condition: $condition) {
      id
      workspace_id
      address
      card_number
      expiry_month
      expiry_year
      cvc
      first_name
      last_name
      email
      city
      country
      postal_code
      state
      holderName
      paymentMethod_Gateway
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserBilling = /* GraphQL */ `
  mutation DeleteUserBilling(
    $input: DeleteUserBillingInput!
    $condition: ModelUserBillingConditionInput
  ) {
    deleteUserBilling(input: $input, condition: $condition) {
      id
      workspace_id
      address
      card_number
      expiry_month
      expiry_year
      cvc
      first_name
      last_name
      email
      city
      country
      postal_code
      state
      holderName
      paymentMethod_Gateway
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMyLinks = /* GraphQL */ `
  mutation CreateMyLinks(
    $input: CreateMyLinksInput!
    $condition: ModelMyLinksConditionInput
  ) {
    createMyLinks(input: $input, condition: $condition) {
      id
      workspace_id
      name
      url
      image_url
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMyLinks = /* GraphQL */ `
  mutation UpdateMyLinks(
    $input: UpdateMyLinksInput!
    $condition: ModelMyLinksConditionInput
  ) {
    updateMyLinks(input: $input, condition: $condition) {
      id
      workspace_id
      name
      url
      image_url
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMyLinks = /* GraphQL */ `
  mutation DeleteMyLinks(
    $input: DeleteMyLinksInput!
    $condition: ModelMyLinksConditionInput
  ) {
    deleteMyLinks(input: $input, condition: $condition) {
      id
      workspace_id
      name
      url
      image_url
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTeamInvitation = /* GraphQL */ `
  mutation CreateTeamInvitation(
    $input: CreateTeamInvitationInput!
    $condition: ModelTeamInvitationConditionInput
  ) {
    createTeamInvitation(input: $input, condition: $condition) {
      id
      email
      workspace
      workspace_id
      status
      workspaceteamID
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeamInvitation = /* GraphQL */ `
  mutation UpdateTeamInvitation(
    $input: UpdateTeamInvitationInput!
    $condition: ModelTeamInvitationConditionInput
  ) {
    updateTeamInvitation(input: $input, condition: $condition) {
      id
      email
      workspace
      workspace_id
      status
      workspaceteamID
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeamInvitation = /* GraphQL */ `
  mutation DeleteTeamInvitation(
    $input: DeleteTeamInvitationInput!
    $condition: ModelTeamInvitationConditionInput
  ) {
    deleteTeamInvitation(input: $input, condition: $condition) {
      id
      email
      workspace
      workspace_id
      status
      workspaceteamID
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscriptions = /* GraphQL */ `
  mutation CreateSubscriptions(
    $input: CreateSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    createSubscriptions(input: $input, condition: $condition) {
      id
      product_id
      status
      UserInfo {
        id
        user_id
        user_name_first
        user_name_second
        email
        display_picture
        workspace
        workspaceteamID
        workspace_id
        phone_number
        subscription_id
        stripe_customer_id
        role
        joined_at
        createdAt
        updatedAt
        __typename
      }
      ProductCard {
        id
        name
        description
        image
        categories
        tags
        type
        period
        is_active
        is_deleted
        sku
        price
        slug
        stripe_product_id
        createdAt
        updatedAt
        __typename
      }
      created_at
      workspace_id
      renewal_date
      subscription_id
      stripe_customer_id
      product_image
      product_name
      product_type
      product_period
      product_quantity
      product_plan
      product_price
      product_sku
      invoice_number
      subscription_by
      createdAt
      updatedAt
      subscriptionsUserInfoId
      subscriptionsProductCardId
      __typename
    }
  }
`;
export const updateSubscriptions = /* GraphQL */ `
  mutation UpdateSubscriptions(
    $input: UpdateSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    updateSubscriptions(input: $input, condition: $condition) {
      id
      product_id
      status
      UserInfo {
        id
        user_id
        user_name_first
        user_name_second
        email
        display_picture
        workspace
        workspaceteamID
        workspace_id
        phone_number
        subscription_id
        stripe_customer_id
        role
        joined_at
        createdAt
        updatedAt
        __typename
      }
      ProductCard {
        id
        name
        description
        image
        categories
        tags
        type
        period
        is_active
        is_deleted
        sku
        price
        slug
        stripe_product_id
        createdAt
        updatedAt
        __typename
      }
      created_at
      workspace_id
      renewal_date
      subscription_id
      stripe_customer_id
      product_image
      product_name
      product_type
      product_period
      product_quantity
      product_plan
      product_price
      product_sku
      invoice_number
      subscription_by
      createdAt
      updatedAt
      subscriptionsUserInfoId
      subscriptionsProductCardId
      __typename
    }
  }
`;
export const deleteSubscriptions = /* GraphQL */ `
  mutation DeleteSubscriptions(
    $input: DeleteSubscriptionsInput!
    $condition: ModelSubscriptionsConditionInput
  ) {
    deleteSubscriptions(input: $input, condition: $condition) {
      id
      product_id
      status
      UserInfo {
        id
        user_id
        user_name_first
        user_name_second
        email
        display_picture
        workspace
        workspaceteamID
        workspace_id
        phone_number
        subscription_id
        stripe_customer_id
        role
        joined_at
        createdAt
        updatedAt
        __typename
      }
      ProductCard {
        id
        name
        description
        image
        categories
        tags
        type
        period
        is_active
        is_deleted
        sku
        price
        slug
        stripe_product_id
        createdAt
        updatedAt
        __typename
      }
      created_at
      workspace_id
      renewal_date
      subscription_id
      stripe_customer_id
      product_image
      product_name
      product_type
      product_period
      product_quantity
      product_plan
      product_price
      product_sku
      invoice_number
      subscription_by
      createdAt
      updatedAt
      subscriptionsUserInfoId
      subscriptionsProductCardId
      __typename
    }
  }
`;
export const createWorkspaceTeam = /* GraphQL */ `
  mutation CreateWorkspaceTeam(
    $input: CreateWorkspaceTeamInput!
    $condition: ModelWorkspaceTeamConditionInput
  ) {
    createWorkspaceTeam(input: $input, condition: $condition) {
      id
      workspace_id
      user_id
      joined_at
      status
      workspaceinfoID
      UserInfos {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWorkspaceTeam = /* GraphQL */ `
  mutation UpdateWorkspaceTeam(
    $input: UpdateWorkspaceTeamInput!
    $condition: ModelWorkspaceTeamConditionInput
  ) {
    updateWorkspaceTeam(input: $input, condition: $condition) {
      id
      workspace_id
      user_id
      joined_at
      status
      workspaceinfoID
      UserInfos {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWorkspaceTeam = /* GraphQL */ `
  mutation DeleteWorkspaceTeam(
    $input: DeleteWorkspaceTeamInput!
    $condition: ModelWorkspaceTeamConditionInput
  ) {
    deleteWorkspaceTeam(input: $input, condition: $condition) {
      id
      workspace_id
      user_id
      joined_at
      status
      workspaceinfoID
      UserInfos {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      user_id
      user_name_first
      user_name_second
      email
      display_picture
      workspace
      workspaceteamID
      workspace_id
      phone_number
      subscription_id
      stripe_customer_id
      WorkspaceInfos {
        nextToken
        __typename
      }
      role
      joined_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      user_id
      user_name_first
      user_name_second
      email
      display_picture
      workspace
      workspaceteamID
      workspace_id
      phone_number
      subscription_id
      stripe_customer_id
      WorkspaceInfos {
        nextToken
        __typename
      }
      role
      joined_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      user_id
      user_name_first
      user_name_second
      email
      display_picture
      workspace
      workspaceteamID
      workspace_id
      phone_number
      subscription_id
      stripe_customer_id
      WorkspaceInfos {
        nextToken
        __typename
      }
      role
      joined_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWorkspaceInfo = /* GraphQL */ `
  mutation CreateWorkspaceInfo(
    $input: CreateWorkspaceInfoInput!
    $condition: ModelWorkspaceInfoConditionInput
  ) {
    createWorkspaceInfo(input: $input, condition: $condition) {
      id
      name
      url
      logo
      created_at
      WorkspaceTeams {
        nextToken
        __typename
      }
      userinfoID
      workspaceteamID
      user_id
      feed_id
      semrushProjectId
      samrushDomainName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWorkspaceInfo = /* GraphQL */ `
  mutation UpdateWorkspaceInfo(
    $input: UpdateWorkspaceInfoInput!
    $condition: ModelWorkspaceInfoConditionInput
  ) {
    updateWorkspaceInfo(input: $input, condition: $condition) {
      id
      name
      url
      logo
      created_at
      WorkspaceTeams {
        nextToken
        __typename
      }
      userinfoID
      workspaceteamID
      user_id
      feed_id
      semrushProjectId
      samrushDomainName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWorkspaceInfo = /* GraphQL */ `
  mutation DeleteWorkspaceInfo(
    $input: DeleteWorkspaceInfoInput!
    $condition: ModelWorkspaceInfoConditionInput
  ) {
    deleteWorkspaceInfo(input: $input, condition: $condition) {
      id
      name
      url
      logo
      created_at
      WorkspaceTeams {
        nextToken
        __typename
      }
      userinfoID
      workspaceteamID
      user_id
      feed_id
      semrushProjectId
      samrushDomainName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductCard = /* GraphQL */ `
  mutation CreateProductCard(
    $input: CreateProductCardInput!
    $condition: ModelProductCardConditionInput
  ) {
    createProductCard(input: $input, condition: $condition) {
      id
      name
      description
      image
      categories
      tags
      type
      period
      is_active
      is_deleted
      sku
      price
      slug
      stripe_product_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductCard = /* GraphQL */ `
  mutation UpdateProductCard(
    $input: UpdateProductCardInput!
    $condition: ModelProductCardConditionInput
  ) {
    updateProductCard(input: $input, condition: $condition) {
      id
      name
      description
      image
      categories
      tags
      type
      period
      is_active
      is_deleted
      sku
      price
      slug
      stripe_product_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductCard = /* GraphQL */ `
  mutation DeleteProductCard(
    $input: DeleteProductCardInput!
    $condition: ModelProductCardConditionInput
  ) {
    deleteProductCard(input: $input, condition: $condition) {
      id
      name
      description
      image
      categories
      tags
      type
      period
      is_active
      is_deleted
      sku
      price
      slug
      stripe_product_id
      createdAt
      updatedAt
      __typename
    }
  }
`;

import { Grid, TextField } from "@mui/material";
import React from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import InputMask from "react-input-mask";

export default class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cvc: "",
      expiry: "",
      focus: "",
      name: "",
      number: "",
    };
  }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  };

  render() {
    return (
      <div id="PaymentForm">
        <Cards
          cvc={this.props.updatedAddress.cvc}
          expiry={this.props.updatedAddress.expiry}
          focused={this.state.focus}
          name={this.props.updatedAddress.holderName}
          number={this.props.updatedAddress.cardNumber}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0rem",
              }}
            >
              <Grid item xs={8}>
                <TextField
                  type="tel"
                  size="small"
                  name="number"
                  className="form-control"
                  label="Card Number"
                  placeholder="xxxx xxxx xxxx xxxx"
                  inputProps={{ maxLength: 22 }}
                  required
                  onChange={(e) =>
                    this.props.setUpdatedAddress({
                      ...this.props.updatedAddress,
                      cardNumber: e.target.value,
                    })
                  }
                  onFocus={this.handleInputFocus}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="John Smith"
                  label="Holder Name"
                  required
                  onChange={(e) =>
                    this.props.setUpdatedAddress({
                      ...this.props.updatedAddress,
                      holderName: e.target.value,
                    })
                  }
                  onFocus={this.handleInputFocus}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "0rem",
                }}
              >
                <Grid item xs={4}>
                  <InputMask
                    mask="99/99"
                    maskChar=""
                    onChange={(e) => {
                      this.props.setUpdatedAddress({
                        ...this.props.updatedAddress,
                        expiry: e.target.value,
                      });
                      console.log(e.target.value);
                    }}
                  >
                    {() => (
                      <TextField
                        size="small"
                        type="text"
                        name="expiry"
                        className="form-control"
                        placeholder="MM/YY"
                        label="Expiry"
                        required
                        // onFocus={this.handleInputFocus}
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    type="tel"
                    name="cvc"
                    className="form-control"
                    placeholder="xxx"
                    label="CVC"
                    inputProps={{ maxLength: 4 }}
                    required
                    onChange={(e) =>
                      this.props.setUpdatedAddress({
                        ...this.props.updatedAddress,
                        cvc: e.target.value,
                      })
                    }
                    onFocus={this.handleInputFocus}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

// import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import { PulseLoader } from "react-spinners";
// import { selectLoading, setLoading } from "../redux/userSlice";
// import { Box, LinearProgress } from "@mui/material";
// import LoadingBar from "react-top-loading-bar";

// const Loader = () => {
//   const dispatch = useDispatch();
//   const loading = useSelector(selectLoading);
//   const [progress, setProgress] = useState(0);

//   useEffect(() => {
//     if (loading) {
//       // setProgress(0); // Reset progress when loading starts
//       const interval = setInterval(() => {
//         setProgress((prevProgress) => {
//           if (prevProgress >= 100) {
//             clearInterval(interval);
//             return 100;
//           }
//           return prevProgress + 10; // Increment progress by 10%
//         });
//       }, 500); // Adjust interval as needed
//     } else {
//       setProgress(0); // Set progress to 100% when loading closes
//     }
//   }, [loading]);

//   return (
//     <>
//
//     </>
//   );
// };

// export default Loader;
import React from "react";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { selectLoading } from "../redux/userSlice";
import { BounceLoader } from "react-spinners";

const Loader = () => {
  const loading = useSelector(selectLoading);

  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          {/* <Oval
            height={60}
            width={60}
            color="#a48d31"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#a48d31"
            strokeWidth={3}
            strokeWidthSecondary={3}
          /> */}
          <BounceLoader color="#2DBCE3" size={50} />
        </div>
      )}
    </>
  );
};

export default Loader;

import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmSignUp } from "aws-amplify/auth";
import { autoSignIn } from "aws-amplify/auth";
import { useDispatch } from "react-redux";
import { setLoading, showToast, setUserInfo } from "../redux/userSlice";
import uuid from "react-uuid";
import { fetchUserAttributes, resendSignUpCode } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import {
  createUserInfo,
  createWorkspaceInfo,
  createWorkspaceTeam,
  createSubscriptions,
  updateWorkspaceInfo,
  updateWorkspaceTeam,
  updateTeamInvitation,
  createUserBilling,
  createSubscriberCard,
  createCartItems,
} from "../graphql/mutations";
import { listWorkspaceInfos, listWorkspaceTeams } from "../graphql/queries";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import { createStripeCustomer } from "../utills/stripeApis/stripeApi";
import { runGTMetrixTest } from "../components/gt-matrix";

//

const merchant_name = process.env.REACT_APP_MERCHANT_LOGIN_NAME;
const transaction_key = process.env.REACT_APP_MERCHANT_TRANSACTION_KEY;
const authorize_api = process.env.REACT_APP_AUTHORIZE_API;
const apiUsername = process.env.REACT_APP_GT_MATRIX_Username;
const apiKey = process.env.REACT_APP_GT_MATRIX_KEY;
const desktendLoginUrl = process.env.REACT_APP_DESKTEND_LOGIN_ENDPOINT
const desktendFeedsUrl = process.env.REACT_APP_DESKTEND_FEED_ENDPOINT;
const desktendEmail = process.env.REACT_APP_DESKTEND_USER_EMAIL
const desktendPassword = process.env.REACT_APP_DESKTEND_USER_PASSWORD
const desktendDesk = process.env.REACT_APP_DESKTEND_DESK
const SignUpVerification = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const cardStyle = {
    margin: isMobile ? "1rem auto" : "0rem auto",
    width: isMobile ? "90%" : "65%",
    textAlign: "center",
    height: !isMobile && "100vh",
    display: !isMobile && "flex",
    justifyContent: !isMobile && "center",
    flexDirection: !isMobile && "column",
    alignItems: !isMobile && "center",
  };
  const dispatch = useDispatch();
  const client = generateClient();
  const navigate = useNavigate();
  const { state } = useLocation();
  const email = state?.email;
  const firstName = state?.firstName;
  const lastName = state?.lastName;
  const workSpace = state?.workSpace;
  const samrushDomainName = state?.workSpaceUrl;

  const detail = state?.detail;
  const semrushProjectId = state?.semrushProjectId;
  const cartItems = state?.cartItems;
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [combinedCode, setCombinedCode] = useState("");
  const [userData, setUserData] = useState(null);
  const [workspaceInfo, setWorkspaceInfo] = useState(null);
  const [workspaceTeamInfo, setWorkspaceTeamInfo] = useState(null);

  const handleCodeChange = (index, value) => {
    const sanitizedValue = value.slice(0, 1);
    const newCodes = [...code];
    newCodes[index] = sanitizedValue;
    if (sanitizedValue === "" && index > 0) {
      document.getElementById(`code${index - 1}`).focus();
    } else if (index < 5 && sanitizedValue !== "") {
      document.getElementById(`code${index + 1}`).focus();
    }

    setCode(newCodes);
  };

  useEffect(() => {
    const newCombinedCode = code.filter(Boolean).join("");
    setCombinedCode(newCombinedCode);
  }, [code]);

  const resendVerificationCode = async () => {
    try {
      const { username } = await resendSignUpCode({
        username: email,
      });
    } catch (error) {
      throw error;
    }
  };

  async function handleSignUpConfirmation({ username, confirmationCode }) {
    const e = arguments[0];
    e.preventDefault();
    const createNewCustomer = {
      createCustomerProfileRequest: {
        merchantAuthentication: {
          name: merchant_name,
          transactionKey: transaction_key,
        },
        profile: {
          email: email,
        },
      },
    };
    let userId;
    let user_id;
    let workspaceId;
    let workspaceTeamId;
    let userBillingDetailId;
    let subscriberId;
    try {
      dispatch(setLoading(true));
      if (combinedCode.length < 6) {
        dispatch(setLoading(false));
        dispatch(
          showToast({
            message: "Please enter a valid 6-digit code",
            type: "error",
          })
        );
        return;
      }

      const createAuthorizeCustomer = async () => {
        const response = await axios.post(authorize_api, createNewCustomer);
        if (
          response?.data?.messages?.resultCode === "Error" &&
          response?.data?.messages?.message?.[0]?.code === "E00039"
        ) {
          const getCustomer = {
            getCustomerProfileRequest: {
              merchantAuthentication: {
                name: merchant_name,
                transactionKey: transaction_key,
              },
              email,
            },
          };
          const fetchCustomerData = await axios.post(
            authorize_api,
            getCustomer
          );
          const id = fetchCustomerData?.data?.profile?.customerProfileId;
          return id;
        } else {
          const id = response.data.customerProfileId;
          return id;
        }
      };

      const newCustomerId = await createAuthorizeCustomer();
      const stripeCustomerId = await createStripeCustomer(email);
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: email,
        confirmationCode: combinedCode,
      });

      if (isSignUpComplete) {
        async function handleFetchUserAttributes() {
          try {
            dispatch(setLoading(true));
            const userAttributes = await fetchUserAttributes();
            const userObjectString = JSON.stringify(userAttributes);
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...userAttributes,
                role:
                  detail?.length > 0 ? detail?.[0]?.role : "workspace_admin",
              })
            );
            dispatch(setUserInfo({ status: userObjectString }));

            workspaceId = uuid();
            user_id = uuid();
            userId = userAttributes?.sub;
            workspaceTeamId = uuid();
            userBillingDetailId = uuid();
            subscriberId = uuid();
            // Desktend API integration



            // Login to Desktend
            let createFeedResponse;
            if (!detail?.length > 0) {
              const loginResponse = await axios.post(desktendLoginUrl, {
                username: desktendEmail,
                password: desktendPassword // You'll need to determine how to securely handle the password
              });
              console.log("The login response of desktent api is:::", loginResponse)
              if (loginResponse.data.accessToken) {
                // Create empty feed in Desktend
                createFeedResponse = await axios.post(desktendFeedsUrl, {
                  name: workSpace,
                  mode: "Regular"
                  // Add any other required fields for creating a feed
                }, {
                  headers: {
                    'Authorization': `Bearer ${loginResponse.data.accessToken}`,
                    'Desk': desktendDesk
                  }
                });

                if (createFeedResponse.data) {
                  console.log('Empty feed created successfully in Desktend');
                }
              }
            }

            await postCartItems(userId, workspaceId);
            await postSubscriberID(
              workspaceId,
              subscriberId,
              newCustomerId,
              stripeCustomerId
            );
            await postWorkspaceInfo(
              workspaceId,
              workspaceTeamId,
              userId,
              user_id,
              createFeedResponse?.data?.id,
              semrushProjectId,
              samrushDomainName
            );
            await postUserData(
              userId,
              workspaceId,
              workspaceTeamId,
              user_id,
              newCustomerId,
              stripeCustomerId
            );
            // await createFolder(userId, workspaceId);
            await userBillingDetail(workspaceId, userBillingDetailId);
            await postWorkspaceTeamInfo(
              workspaceTeamId,
              workspaceId,
              userId,
              user_id
            );
            dispatch(
              showToast({
                message: "User has been created successfully",
                type: "success",
              })
            );
            dispatch(setLoading(false));


          } catch (error) {
            dispatch(setLoading(false));
            dispatch(showToast({ message: error.message, type: "error" }));
          }
        }

        async function handleAutoSignIn() {
          try {
            dispatch(setLoading(true));
            const signInOutput = await autoSignIn();
            if (signInOutput.isSignedIn) {
              await handleFetchUserAttributes();
            }
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(showToast({ message: error.message, type: "error" }));
          }
        }

        await handleAutoSignIn();
        // await runGTMetrixTest(workSpaceUrl, apiUsername, apiKey);
        if (cartItems?.length > 0) {
          navigate("/cart");
        } else {
          navigate("/home");
        }
      }

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
    }
  }

  const postCartItems = async (userId, workspaceId) => {
    try {
      dispatch(setLoading(true));
      if (cartItems?.length > 0) {
        for (const productItem of cartItems) {
          let cartId = uuid();
          const detail = {
            id: cartId,
            product_id: productItem.id,
            user_id: userId,
            workspace_id: workspaceId,
            image: productItem.image,
            name: productItem.name,
            period: productItem.period,
            price: productItem.price,
            type: productItem.type,
            plan: productItem.plan,
            quantity: productItem.quantity,
            sku: productItem.sku,
          };
          const saveItemInDB = await client.graphql({
            query: createCartItems,
            variables: { input: detail },
          });
        }
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error updating user:", error);
      return;
    }
  };

  const postSubscriberID = async (
    workspaceId,
    subscriberId,
    newCustomerId,
    stripeCustomerId
  ) => {
    try {
      dispatch(setLoading(true));
      if (detail === undefined) {
        const variable = {
          id: subscriberId,
          workspace_id: workspaceId,
          email: email,
          subscription_id: newCustomerId,
          stripe_customer_id: stripeCustomerId,
        };

        const createSubscriber = await client.graphql({
          query: createSubscriberCard,
          variables: { input: variable },
        });
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error updating user:", error);
      return;
    }
  };

  const userBillingDetail = async (workspaceId, userBillingDetailId) => {
    try {
      dispatch(setLoading(true));
      const variable = {
        id: userBillingDetailId,
        workspace_id:
          detail?.length > 0 ? detail?.[0]?.workspace_id : workspaceId,
        address: "",
        card_number: "",
        expiry_month: "",
        expiry_year: "",
        cvc: "",
        first_name: firstName,
        last_name: lastName,
        email: email,
        city: "",
        country: "",
        postal_code: "",
        state: "",
      };

      const updateUserBillingData = await client.graphql({
        query: createUserBilling,
        variables: { input: variable },
      });

      const result = updateUserBillingData.data.createUserBilling;
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error updating user:", error);
      return;
    }
  };

  const removeInvitation = async () => {
    dispatch(setLoading(true));
    const updatedResult = await client.graphql({
      query: updateTeamInvitation,
      variables: {
        input: {
          id: detail?.[0]?.id,
          status: false,
        },
      },
    });
    dispatch(setLoading(false));
  };

  const postUserData = async (
    userId,
    workspaceId,
    workspaceTeamId,
    user_id,
    newCustomerId,
    stripeCustomerId
  ) => {
    dispatch(setLoading(true));
    // getting date
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const userDetail = {
      id: user_id,
      user_id: userId,
      user_name_first: firstName,
      user_name_second: lastName,
      display_picture:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTet-jk67T6SYdHW04eIMLygHzEeJKobi9zdg&usqp=CAU",
      email: email,
      joined_at: formattedDate,
      role: detail?.length > 0 ? detail?.[0]?.role : "workspace_admin",
      workspace: detail?.length > 0 ? detail?.[0]?.workspace : workSpace,
      workspaceteamID:
        detail?.length > 0 ? detail?.[0]?.workspaceteamID : workspaceTeamId,
      workspace_id:
        detail?.length > 0 ? detail?.[0]?.workspace_id : workspaceId,
      subscription_id: newCustomerId,
      stripe_customer_id: stripeCustomerId,
    };

    try {
      const result = await client.graphql({
        query: createUserInfo,
        variables: { input: userDetail },
      });
      setUserData(result.data.createUserInfo);

      if (detail?.length > 0) {
        const variables = {
          filter: {
            id: {
              eq: result.data.createUserInfo.workspace_id,
            },
          },
        };

        const getUserData = await client.graphql({
          query: listWorkspaceInfos,
          variables: variables,
        });

        const userIdis =
          getUserData?.data?.listWorkspaceInfos?.items[0].user_id;
        const updatedUserIds = [...userIdis, userId];
        const updatedResult = await client.graphql({
          query: updateWorkspaceInfo,
          variables: {
            input: {
              id: result.data.createUserInfo.workspace_id,
              user_id: updatedUserIds,
              workspaceteamID:
                detail?.length > 0
                  ? detail?.[0]?.workspaceteamID
                  : workspaceTeamId,
            },
          },
        });
        localStorage.setItem(
          "selectedWorkspaceId",
          detail?.length > 0 ? detail?.[0]?.workspace_id : workspaceId
        );
      }

      if (detail?.length > 0) {
        const variables = {
          filter: {
            id: {
              eq: result.data.createUserInfo.workspaceteamID,
            },
          },
        };

        const getUserData = await client.graphql({
          query: listWorkspaceTeams,
          variables: variables,
        });

        const userIdis =
          getUserData?.data?.listWorkspaceTeams?.items[0].user_id;

        const updatedUserIds = [...userIdis, userId];
        const updatedResult = await client.graphql({
          query: updateWorkspaceTeam,
          variables: {
            input: {
              id: result.data.createUserInfo.workspaceteamID,
              user_id: updatedUserIds,
            },
          },
        });

        if (updatedResult) {
          removeInvitation();
        }
      }

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const postWorkspaceInfo = async (
    workspaceId,
    workspaceTeamId,
    userId,
    user_id,
    feedId,
    semrushProjectId,
    samrushDomainName
  ) => {
    dispatch(setLoading(true));
    try {
      if (!detail?.length) {
        // getting date
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        //getting url
        const workSpaceLowercase =
          detail?.length > 0
            ? detail?.[0]?.workspace.toLowerCase()
            : workSpace.toLowerCase();
        const workSpaceUrl = workSpaceLowercase.replace(/\s+/g, "-");
        const WorkSpaceUrl = `${workSpaceUrl}.rothbright.app`;
        const workspaceDetail = {
          id: detail?.length > 0 ? detail?.[0]?.workspace_id : workspaceId,
          name: detail?.length > 0 ? detail?.[0]?.workspace : workSpace,
          url: WorkSpaceUrl,
          logo: "https://shop.raceya.fit/wp-content/uploads/2020/11/logo-placeholder.jpg",
          created_at: formattedDate,
          workspaceteamID: workspaceTeamId,
          user_id: [userId],
          feed_id: feedId,
          semrushProjectId: semrushProjectId,
          samrushDomainName: samrushDomainName
        };

        const result = await client.graphql({
          query: createWorkspaceInfo,
          variables: { input: workspaceDetail },
        });

        setWorkspaceInfo(result.data.createWorkspaceInfo);
        localStorage.setItem(
          "selectedWorkspaceId",
          detail?.length > 0 ? detail?.[0]?.workspace_id : workspaceId
        );
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const postWorkspaceTeamInfo = async (
    workspaceTeamId,
    workspaceId,
    userId,
    user_id
  ) => {
    dispatch(setLoading(true));

    try {
      if (!detail?.length) {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        const workspaceTeamDetail = {
          id:
            detail?.length > 0 ? detail?.[0]?.workspaceteamID : workspaceTeamId,
          workspace_id:
            detail?.length > 0 ? [detail?.[0]?.workspace_id] : [workspaceId],
          user_id: [userId],
          joined_at: formattedDate,
          status: true,
          workspaceinfoID:
            detail?.length > 0 ? detail?.[0]?.workspace_id : workspaceId,
        };

        const result = await client.graphql({
          query: createWorkspaceTeam,
          variables: { input: workspaceTeamDetail },
        });

        setWorkspaceTeamInfo(result.data.createWorkspaceTeam);
      }

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    const newCodes = [...code];
    let pasteIndex = index;
    for (let i = 0; i < pastedText.length && pasteIndex < 6; i++) {
      newCodes[pasteIndex++] = pastedText[i];
    }
    setCode(newCodes);
    if (pasteIndex < 6) {
      document.getElementById(`code${pasteIndex}`).focus();
    }
  };

  return (
    <>
      <form style={cardStyle}>
        <div style={{ textAlign: "center" }}>
          <img
            src="/faviconPNG.png"
            height={isMobile ? "50px" : "70px"}
            width={isMobile ? "100%" : "430px"}
          />
        </div>
        <div style={{ marginTop: !isMobile && "0rem" }}>
          <h1
            style={{
              color: "#1D1C1D",
              fontSize: isMobile ? "1.4rem" : "3rem",
              margin: isMobile ? "0.4rem" : "1.5rem",
            }}
          >
            Check your email for a code
          </h1>
          <p
            style={{
              fontSize: isMobile ? "1rem" : "1.3rem",
              color: "#605E61",
              margin: "0px",
            }}
          >
            We've sent a 6-digit code to <b>{email}</b>. The code will expire
            shortly, so please enter it soon.
          </p>
        </div>
        <div
          style={{
            marginTop: isMobile ? "1rem" : "2rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "0rem",
          }}
        >
          <div style={{ display: isMobile && "flex" }}>
            {code.map((_, index) => (
              <TextField
                key={index}
                id={`code${index}`}
                variant="outlined"
                value={code[index]}
                onChange={(e) => handleCodeChange(index, e.target.value)}
                onPaste={(e) => handlePaste(e, index)}
                style={{
                  width: isMobile ? "15%" : "5%",
                  marginRight: "0.5rem",
                  marginBottom: "1rem",
                }}
              />
            ))}
          </div>

          <Button
            variant="contained"
            style={{
              width: "35%",
              padding: "0.9rem 0rem",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
            onClick={handleSignUpConfirmation}
            type="submit"
          >
            Continue
          </Button>
          <p
            onClick={resendVerificationCode}
            style={{
              cursor: "pointer",
              // margin: "0px",
              marginBottom: isMobile ? "1rem" : "0rem",
              color: "#605E61",
              textAlign: "center",
            }}
          >
            Resend code?
          </p>
          {/* {!isMobile && (
            <div
              style={{
                cursor: "pointer",
                color: "#777",
                marginTop: "2.5rem",
                textAlign: "center",
              }}
            >
              Privacy & Terms &nbsp;&nbsp;&nbsp; Contact Us &nbsp;&nbsp; &nbsp;
              <span role="img" aria-label="world">
                🌐
              </span>{" "}
              Change Region
            </div>
          )} */}
        </div>
      </form>
    </>
  );
};

export default SignUpVerification;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "emailInvitation",
            "endpoint": "https://a92g458huf.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "sendEmail",
            "endpoint": "https://eiggd612aa.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vvxaa2h2rnhxfpdlq7dpmsbhpa.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4p4shmh5ufd4pn6vdltofvcize",
    "aws_cognito_identity_pool_id": "us-east-1:0195b385-a53b-46e6-8e7d-ba8dcb2190eb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QLmjoHQvQ",
    "aws_user_pools_web_client_id": "3lu99aoacnpsfgagsgc8r14oif",
    "oauth": {
        "domain": "ochsci5n2t1t-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://rothbright.com/my-account/",
        "redirectSignOut": "https://rothbright.com/my-account/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME",
        "FAMILY_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "rothbright-portal-assets",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

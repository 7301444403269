import * as React from "react";
import { Grid, Card, IconButton } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import Footer from "../components/Footer";
import { InputAdornment } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

const Root = styled("div")(({ theme }) => ({
  textAlign: "center",
  width: "100%",
  "& > :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
  },
}));

const messageInputStyle = {
  position: "absolute",
  bottom: 15,
  width: "-webkit-fill-available",
};

const inputTextBoxStyle = {
  width: "calc(100% - 32px)",
  backgroundColor: "#f4f4f4",
};

const uploadIconStyle = {
  color: "#777",
  marginRight: "8px",
};

const container = {
  margin: "2rem 4rem",
};
const title = {
  fontSize: "2rem",
  margin: "0px",
  marginBottom: "1rem",
};
const organizationCard = {
  padding: "1.5rem 2rem",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 2px 10px",
  minHeight: "68vh",
  position: "relative",
};

const Chat = () => {
  return (
    <>
      <div style={container}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <p style={title}>Messages</p>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card style={organizationCard}>
              <div>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <img
                        src="https://i2-prod.mirror.co.uk/incoming/article31870315.ece/ALTERNATES/s615b/0_Dubai-Invitational-Day-One.jpg"
                        height="50px"
                        width="50px"
                        style={{ objectFit: "cover", borderRadius: "5px" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          alignItems: "start",
                        }}
                      >
                        <div>
                          <h3 style={{ margin: "0px", textAlign: "start" }}>
                            Daniel Patton
                          </h3>
                          <p style={{ margin: "0px" }}>
                            Hello James, <br /> Welcome to the rothbright portal{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Root>
                      <Divider>5:11 PM</Divider>
                    </Root>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <img
                        src="images (1).jpeg"
                        height="50px"
                        width="50px"
                        style={{ objectFit: "cover", borderRadius: "5px" }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          alignItems: "start",
                        }}
                      >
                        <div>
                          <h3 style={{ margin: "0px", textAlign: "start" }}>
                            James Gomez
                          </h3>
                          <p style={{ margin: "0px" }}>
                            Hi Daniel, great to meet you here
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div style={messageInputStyle}>
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FileUploadOutlinedIcon style={uploadIconStyle} />
                        <Divider
                          sx={{ height: 28, m: 0.5 }}
                          orientation="vertical"
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          style={{
                            backgroundColor: "#000",
                            padding: "0.5rem",
                            borderRadius: "5px",
                          }}
                        >
                          <SendIcon
                            style={{ color: "#fff", fontSize: "1.5rem" }}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={inputTextBoxStyle}
                  placeholder="Type your message"
                />
              </div>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Chat;

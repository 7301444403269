import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import Footer from "../components/Footer";
import { useDispatch } from "react-redux";
import { setLoading, showToast } from "../redux/userSlice";
import { generateClient, post } from "aws-amplify/api";
import {
  listProductCards,
  listProductCategories,
  listProductTags,
  listProductVariations,
} from "../graphql/queries";
import {
  createProductCard,
  createProductVariations,
  deleteProductVariations,
  updateProductCard,
  updateProductVariations,
} from "../graphql/mutations";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import uuid from "react-uuid";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { uploadData } from "aws-amplify/storage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import ClearIcon from "@mui/icons-material/Clear";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const container = {
  margin: "2rem 4rem",
};
const title = {
  fontSize: "2rem",
  margin: "0px",
  marginBottom: "1rem",
};
const organizationCard = {
  padding: "1.5rem 2rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  minHeight: "68vh",
};

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddProduct = () => {
  const client = generateClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const productIdFromState = state?.productIdFromState;
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [imageDB, setDBImage] = useState("");
  const [allCategories, setAllCategories] = useState(null);
  const [allTags, setAllTags] = useState(null);
  const [sizeFields, setShowSizeFields] = useState(false);
  const [rows, setRows] = useState([]);
  const [plansFetched, setPlansFetched] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [editSizeIndex, setEditSizeIndex] = React.useState(null);
  const [idWiseProductData, setIdWiseProductData] = React.useState(null);
  const [deletedPlansIdis, setDeletedPlansIdis] = React.useState([]);
  const [AddPlansIdWise, setAddPlansIdWise] = React.useState([]);
  const [editorContent, setEditorContent] = useState("");
  const bucket = process.env.REACT_APP_BUCKET_NAME;
  const [alertError, setAlertError] = useState(false);
  const [productDescription, setProductDescription] = useState("");
  const [productData, setProductData] = useState({
    name: "",
    price: "",
    period: "",
    sku: "",
    type: "",
    categories: [],
    tags: [],
  });
  const [planSizeData, setPlanSizeData] = useState({
    name: "",
    price: "",
    sku: "",
    signup_fee: "",
    period: "",
    every: "",
    description: "",
  });
  const [editPlanSizeData, setEditPlanSizeData] = useState({
    name: "",
    price: "",
    sku: "",
    signup_fee: "",
    period: "",
    every: "",
    description: "",
  });

  React.useEffect(() => {
    if (id) {
      fetchProductData();
      fetchAllPlansIdWise();
    }
    fetchCategoryDetail();
    fetchTagDetails();
  }, []);

  React.useEffect(() => {
    if (id && idWiseProductData?.length > 0) {
      setDBImage(productData.image);
      setSelectedImage(productData.image);
    }
  }, [idWiseProductData]);

  const fetchProductData = async () => {
    dispatch(setLoading(true));
    try {
      const variables = {
        filter: {
          id: {
            eq: productIdFromState,
          },
        },
      };
      const getProductData = await client.graphql({
        query: listProductCards,
        variables: variables,
      });
      setIdWiseProductData(getProductData?.data?.listProductCards?.items);
      const res = getProductData?.data?.listProductCards?.items[0];
      const cleanedItem = { ...res };
      if (cleanedItem.categories) {
        cleanedItem.categories = JSON.parse(cleanedItem.categories);
      }
      if (cleanedItem.tags) {
        cleanedItem.tags = JSON.parse(cleanedItem.tags);
      }
      setProductData(cleanedItem);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  async function fetchAllProductVariationsIds() {
    let allItems = [];
    let nextToken = null;

    do {
      const variables = {
        filter: {
          product_id: {
            eq: productIdFromState,
          },
        },
        nextToken: nextToken,
      };
      const getCategories = await client.graphql({
        query: listProductVariations,
        variables: variables,
      });
      const response = getCategories?.data?.listProductVariations?.items || [];
      allItems = [...allItems, ...response];
      nextToken = getCategories?.data?.listProductVariations?.nextToken;
    } while (nextToken);
    return allItems;
  }

  const fetchAllPlansIdWise = async () => {
    dispatch(setLoading(true));
    try {
      const fetchAllVariations = await fetchAllProductVariationsIds();
      setRows(fetchAllVariations);
      setPlansFetched(fetchAllVariations);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const fetchCategoryDetail = async () => {
    dispatch(setLoading(true));
    try {
      const getCatoegories = await client.graphql({
        query: listProductCategories,
      });

      const res = getCatoegories?.data?.listProductCategories?.items;
      const result = res?.filter((item) => item.status === true);
      setAllCategories(result);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const fetchTagDetails = async () => {
    dispatch(setLoading(true));
    try {
      const getTags = await client.graphql({
        query: listProductTags,
      });

      const res = getTags?.data?.listProductTags?.items;
      const result = res?.filter((item) => item.status === true);
      setAllTags(result);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  };

  const handleAddProduct = async () => {
    if (productData.type !== "variable subscription" && rows.length > 0) {
      setAlertError(true);
      return;
    }
    let product_id = uuid();
    const stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;

    if (id) {
      try {
        dispatch(setLoading(true));
        let imageUrl = productData.image;
        const file = fileInputRef.current.files[0];
        if (file) {
          const fileName = file.name.replace(/\s+/g, "");
          const uploadResult = await uploadData({
            key: `product_images/${fileName}`,
            data: file,
            options: {
              accessLevel: "public",
            },
          }).uploadResult;
          imageUrl = `https://${bucket}/public/product_images/${fileName}`;
        }

        const slug = slugify(productData.name);

        // Update product in Stripe
        const stripeProductUpdateData = {
          name: productData.name,
          description: editorContent,
          metadata: {
            sku: productData.sku,
          },
        };

        if (imageUrl) {
          stripeProductUpdateData.images = [imageUrl];
        }

        await axios.post(
          `https://api.stripe.com/v1/products/${productData.stripe_product_id}`,
          stripeProductUpdateData,
          {
            headers: {
              Authorization: `Bearer ${stripeSecretKey}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        // Update or create price in Stripe
        if (productData.type === "simple product") {
          // Fetch existing prices for the product
          const existingPrices = await axios.get(
            `https://api.stripe.com/v1/prices?product=${productData.stripe_product_id}&active=true`,
            {
              headers: {
                Authorization: `Bearer ${stripeSecretKey}`,
              },
            }
          );

          if (existingPrices.data.data.length > 0) {
            // If there's an existing price, update it by creating a new one and making the old one inactive
            const oldPrice = existingPrices.data.data[0];
            await axios.post(
              "https://api.stripe.com/v1/prices",
              {
                product: productData.stripe_product_id,
                unit_amount: Math.round(productData.price * 100),
                currency: "usd",
                active: true,
              },
              {
                headers: {
                  Authorization: `Bearer ${stripeSecretKey}`,
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );

            // Deactivate the old price
            await axios.post(
              `https://api.stripe.com/v1/prices/${oldPrice.id}`,
              { active: false },
              {
                headers: {
                  Authorization: `Bearer ${stripeSecretKey}`,
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );
          } else {
            // If no existing price, create a new one
            await axios.post(
              "https://api.stripe.com/v1/prices",
              {
                product: productData.stripe_product_id,
                unit_amount: Math.round(productData.price * 100),
                currency: "usd",
              },
              {
                headers: {
                  Authorization: `Bearer ${stripeSecretKey}`,
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );
          }
        } else if (productData.type === "variable subscription") {
          // Create or update prices for each variation
          for (const row of rows) {
            // Validate the period and every values
            if (!row.period || !row.every) {
              console.error(`Invalid period or every value for row:`, row);
              dispatch(
                showToast({
                  message: `Invalid subscription details for ${row.name}. Please check the period and frequency.`,
                  type: "error",
                })
              );
              dispatch(setLoading(false));
              return; // Stop execution if there's an invalid row
            }

            // Ensure period is one of the allowed values
            const allowedIntervals = ["day", "week", "month", "year"];
            const interval = row.period.toLowerCase();
            if (!allowedIntervals.includes(interval)) {
              console.error(`Invalid interval value: ${interval}`);
              dispatch(
                showToast({
                  message: `Invalid subscription period for ${row.name}. Allowed values are: day, week, month, year.`,
                  type: "error",
                })
              );
              dispatch(setLoading(false));
              return; // Stop execution if there's an invalid interval
            }

            try {
              await axios.post(
                "https://api.stripe.com/v1/prices",
                {
                  product: productData.stripe_product_id,
                  unit_amount: Math.round(row.price * 100),
                  currency: "usd",
                  recurring: {
                    interval: interval,
                    interval_count: parseInt(row.every) || 1,
                  },
                },
                {
                  headers: {
                    Authorization: `Bearer ${stripeSecretKey}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              );
            } catch (error) {
              console.error(
                `Error creating price for ${row.name}:`,
                error.response?.data || error.message
              );
              dispatch(
                showToast({
                  message: `Failed to create price for ${row.name}. Please check the details and try again.`,
                  type: "error",
                })
              );
              dispatch(setLoading(false));
              return; // Stop execution if there's an error creating a price
            }
          }
        }

        // Update product in database
        const detail = {
          id: productIdFromState,
          name: productData.name,
          description: editorContent,
          sku: productData.sku,
          price:
            productData.type === "simple product" ? productData.price : "0",
          image: imageUrl,
          categories: JSON.stringify(productData.categories),
          tags: JSON.stringify(productData.tags),
          period: productData.period,
          type: productData.type,
          slug: slug,
          stripe_product_id: productData.stripe_product_id,
        };
        const result = await client.graphql({
          query: updateProductCard,
          variables: { input: detail },
        });

        if (deletedPlansIdis.length > 0) {
          try {
            const promises = deletedPlansIdis.map(async (id) => {
              const variables = {
                input: {
                  id: id,
                },
              };

              const deletePlans = await client.graphql({
                query: deleteProductVariations,
                variables: variables,
              });
              return deletePlans;
            });
            const results = await Promise.all(promises);
          } catch (error) {
            console.error("Error saving data to the database:", error);
          }
        }

        if (AddPlansIdWise.length > 0) {
          try {
            const promises = AddPlansIdWise.map(async (row) => {
              let PlanSizeID = uuid();
              const variable = {
                id: PlanSizeID,
                name: row.name,
                sku: row.sku,
                description: row.description,
                price: row.price,
                product_id: productIdFromState,
                period: row.period,
                signup_fee: row.signup_fee,
                every: row.every,
                is_active: true,
                is_deleted: false,
              };

              const createVariations = await client.graphql({
                query: createProductVariations,
                variables: { input: variable },
              });
              return createVariations;
            });
            const results = await Promise.all(promises);
          } catch (error) {
            console.error("Error saving data to the database:", error);
          }
        }

        if (rows.length > 0) {
          try {
            const promises = rows.map(async (row) => {
              const variable = {
                id: row.id,
                name: row.name,
                description: row.description,
                sku: row.sku,
                price: row.price,
                product_id: productIdFromState,
                period: row.period,
                signup_fee: row.signup_fee,
                every: row.every,
              };

              const updateVariation = await client.graphql({
                query: updateProductVariations,
                variables: { input: variable },
              });
              return updateVariation;
            });
            const results = await Promise.all(promises);
          } catch (error) {
            console.error("Error saving data to the database:", error);
          }
        }
        dispatch(setLoading(false));
        dispatch(
          showToast({
            message: "Product updated successfully in database and Stripe",
            type: "success",
          })
        );
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(showToast({ message: error.message, type: "error" }));
        console.error("Error updating product:", error);
      }
    } else {
      try {
        dispatch(setLoading(true));
        let imageUrl = "";
        const file = fileInputRef.current.files[0];
        if (file) {
          const fileName = file.name.replace(/\s+/g, "");
          const uploadResult = await uploadData({
            key: `product_images/${fileName}`,
            data: file,
            options: {
              accessLevel: "public",
            },
          }).uploadResult;
          imageUrl = `https://${bucket}/public/product_images/${fileName}`;
        }

        const slug = slugify(productData.name);

        // Create product in Stripe
        const stripeProductData = {
          name: productData.name,
          description: editorContent,
          metadata: {
            sku: productData.sku,
          },
        };

        if (imageUrl) {
          stripeProductData.images = [imageUrl];
        }

        const stripeProductResponse = await axios.post(
          "https://api.stripe.com/v1/products",
          stripeProductData,
          {
            headers: {
              Authorization: `Bearer ${stripeSecretKey}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const stripe_product_id = stripeProductResponse.data.id;

        // Create price in Stripe
        if (productData.type === "simple product") {
          await axios.post(
            "https://api.stripe.com/v1/prices",
            {
              product: stripe_product_id,
              unit_amount: Math.round(productData.price * 100),
              currency: "usd",
            },
            {
              headers: {
                Authorization: `Bearer ${stripeSecretKey}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
        } else if (productData.type === "variable subscription") {
          // Create prices for each variation
          for (const row of rows) {
            await axios.post(
              "https://api.stripe.com/v1/prices",
              {
                product: stripe_product_id,
                unit_amount: Math.round(row.price * 100),
                currency: "usd",
                recurring: {
                  interval: row.period,
                  interval_count: parseInt(row.every),
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${stripeSecretKey}`,
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );
          }
        }

        // Create product in database
        const detail = {
          id: product_id,
          name: productData.name,
          description: editorContent,
          price: productData.type === "simple product" ? productData.price : "0",
          sku: productData.sku,
          image: imageUrl,
          categories: JSON.stringify(productData.categories),
          tags: JSON.stringify(productData.tags),
          period: productData.period,
          type: productData.type,
          is_active: true,
          is_deleted: false,
          slug: slug,
          stripe_product_id: stripe_product_id,
        };
        const result = await client.graphql({
          query: createProductCard,
          variables: { input: detail },
        });

        if (rows.length > 0) {
          try {
            for (const row of rows) {
              let PlanSizeID = uuid();
              const variable = {
                id: PlanSizeID,
                name: row.name,
                description: row.description,
                sku: row.sku,
                price: row.price,
                product_id: product_id,
                period: row.period,
                signup_fee: row.signup_fee,
                every: row.every,
                is_active: true,
                is_deleted: false,
              };
              const createVariations = await client.graphql({
                query: createProductVariations,
                variables: { input: variable },
              });
            }
          } catch (error) {
            console.error("Error saving plan data to the database:", error);
          }
        }
        dispatch(setLoading(false));
        dispatch(
          showToast({
            message: "New product added successfully to database and Stripe",
            type: "success",
          })
        );
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(showToast({ message: error.message, type: "error" }));
        console.error("Error creating product:", error);
      }
    }
    // navigate("/store_management");
  };

  const handleOnChange = (event) => {
    setAlertError(false);
    const { name, value } = event.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOnChangePlazSize = (event) => {
    const { name, value } = event.target;
    setPlanSizeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditOnChangePlazSize = (event) => {
    const { name, value } = event.target;
    setEditPlanSizeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (event) => {
    const fileInput = event.target;

    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleEditIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  const handleAddPlanSize = () => {
    if (id) {
      setAddPlansIdWise([...AddPlansIdWise, planSizeData]);
    }
    setRows([...rows, planSizeData]);
    setPlanSizeData({
      name: "",
      price: "",
      sku: "",
      signup_fee: "",
      period: "",
      description: "",
    });
  };
  console.log(rows);

  const handleRemoveRow = (index) => {
    if (id) {
      if (rows[index].id) {
        setDeletedPlansIdis((prevIds) => [...prevIds, rows[index].id]);
      }
    }
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows.splice(index, 1);
      return newRows;
    });
  };

  const handleEditVariation = (index) => {
    const selectedRow = rows[index];
    setEditPlanSizeData(selectedRow);
    setEditSizeIndex(selectedRow);
    setOpen(true);
  };

  const handleUpdateRow = () => {
    const rowIndex = rows.findIndex((row) => row.name === editSizeIndex.name);
    if (rowIndex !== -1) {
      const updatedRows = [...rows];
      updatedRows[rowIndex] = editPlanSizeData;
      setRows(updatedRows);
      if (id && AddPlansIdWise.length > 0) {
        const updatedAddPlansIdWise = AddPlansIdWise.map((row) => {
          if (row.name === editSizeIndex.name) {
            return editPlanSizeData;
          }
          return row;
        });
        setAddPlansIdWise(updatedAddPlansIdWise);
      }
      setOpen(false);
    }
  };

  const handleChangeCategoryCheckbox = (event, id) => {
    setAlertError(false);
    const { checked } = event.target;
    if (checked) {
      setProductData((prevData) => ({
        ...prevData,
        categories: [...prevData?.categories, id],
      }));
    } else {
      setProductData((prevData) => ({
        ...prevData,
        categories: prevData?.categories?.filter(
          (categoryId) => categoryId !== id
        ),
      }));
    }
  };

  const handleChangeTagsCheckbox = (event, id) => {
    setAlertError(false);
    const { checked } = event.target;
    if (checked) {
      setProductData((prevData) => ({
        ...prevData,
        tags: [...prevData?.tags, id],
      }));
    } else {
      setProductData((prevData) => ({
        ...prevData,
        tags: prevData?.tags?.filter((tagId) => tagId !== id),
      }));
    }
  };

  // useEffect(() => {
  //   if (id && productData?.description !== null) {
  //     setEditorContent(productData?.description);
  //   } else {
  //     setEditorContent("");
  //   }
  // }, [id, productData]);

  useEffect(() => {
    if (id && productData?.description !== null) {
      setProductDescription(productData?.description);
    } else {
      setProductDescription("");
    }
  }, [id, productData?.description]);

  useEffect(() => {
    setEditorContent(productDescription);
  }, [productDescription]);

  return (
    <div style={container}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p style={title}>{id ? `Edit Product` : "Add Product"}</p>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card style={organizationCard}>
            <Grid container spacing={5}>
              <Grid item xs={8}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="type"
                      label="Select Product Type"
                      value={productData.type}
                      onChange={handleOnChange}
                      SelectProps={{
                        IconComponent: productData.type
                          ? () => null
                          : undefined,
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: "300px",
                            },
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: productData.type && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear selection"
                              onClick={() =>
                                setProductData({
                                  ...productData,
                                  type: "",
                                })
                              }
                              edge="end"
                            >
                              <ClearIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem value="simple product">Simple Product</MenuItem>
                      <MenuItem value="variable subscription">
                        Variable Subscription
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      //   error={nameError}
                      //   helperText={nameError ? "Name required" : null}
                      //   autoFocus
                      name="name"
                      label="Product Name"
                      size="small"
                      fullWidth
                      variant="outlined"
                      value={productData.name}
                      onChange={handleOnChange}
                    />
                  </Grid>
                  {productData?.type === "simple product" && (
                    <>
                      <Grid item xs={6}>
                        <TextField
                          //   error={nameError}
                          //   helperText={nameError ? "Name required" : null}
                          //   autoFocus
                          name="price"
                          label="Product Price"
                          type="number"
                          size="small"
                          fullWidth
                          variant="outlined"
                          value={productData.price}
                          onChange={handleOnChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          //   error={nameError}
                          //   helperText={nameError ? "Name required" : null}
                          //   autoFocus
                          name="sku"
                          label="SKU"
                          size="small"
                          fullWidth
                          variant="outlined"
                          value={productData.sku}
                          onChange={handleOnChange}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <ReactQuill
                      theme="snow"
                      style={{ height: "500px" }}
                      value={editorContent}
                      onChange={setEditorContent}
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <TextField
                      select
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="period"
                      label="Renewal Period"
                      value={productData.period}
                      onChange={handleOnChange}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: "300px",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="" key="">
                        Clear
                      </MenuItem>
                      <MenuItem value="day">Day</MenuItem>
                      <MenuItem value="month">Month</MenuItem>
                      <MenuItem value="year">Year</MenuItem>
                    </TextField>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => setShowSizeFields(true)}
                      disabled={productData?.type !== "variable subscription"}
                      style={{ marginTop: "2.5rem", textTransform: "inherit" }}
                    >
                      Add Plan
                    </Button>
                  </Grid>
                  {sizeFields &&
                    productData?.type === "variable subscription" && (
                      <>
                        <Grid item xs={4}>
                          <TextField
                            //   error={nameError}
                            //   helperText={nameError ? "Name required" : null}
                            //   autoFocus
                            name="name"
                            label="Plan Name"
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={planSizeData.name}
                            onChange={handleOnChangePlazSize}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            //   error={nameError}
                            //   helperText={nameError ? "Name required" : null}
                            //   autoFocus
                            name="price"
                            label="Price"
                            type="number"
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={planSizeData.price}
                            onChange={handleOnChangePlazSize}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            //   error={nameError}
                            //   helperText={nameError ? "Name required" : null}
                            //   autoFocus
                            name="sku"
                            label="SKU"
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={planSizeData.sku}
                            onChange={handleOnChangePlazSize}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            //   error={nameError}
                            //   helperText={nameError ? "Name required" : null}
                            //   autoFocus
                            name="signup_fee"
                            label="Sign-up Fee"
                            type="number"
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={planSizeData.signup_fee}
                            onChange={handleOnChangePlazSize}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            select
                            variant="outlined"
                            size="small"
                            fullWidth
                            name="period"
                            label="Renewable Time"
                            value={planSizeData.period}
                            onChange={handleOnChangePlazSize}
                            SelectProps={{
                              IconComponent: planSizeData.period
                                ? () => null
                                : undefined,
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: "300px",
                                  },
                                },
                              },
                            }}
                            InputProps={{
                              endAdornment: planSizeData.period && (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="clear selection"
                                    onClick={() =>
                                      setPlanSizeData({
                                        ...planSizeData,
                                        period: "",
                                      })
                                    }
                                    edge="end"
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          >
                            <MenuItem value="day">Day</MenuItem>
                            <MenuItem value="month">Month</MenuItem>
                            {/* <MenuItem value="year">Year</MenuItem> */}
                          </TextField>
                        </Grid>
                        {planSizeData?.period === "day" ? (
                          <Grid item xs={4}>
                            <TextField
                              select
                              variant="outlined"
                              size="small"
                              fullWidth
                              name="every"
                              label="Every"
                              value={planSizeData.every}
                              onChange={handleOnChangePlazSize}
                              SelectProps={{
                                IconComponent: planSizeData.every
                                  ? () => null
                                  : undefined,
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: "300px",
                                    },
                                  },
                                },
                              }}
                              InputProps={{
                                endAdornment: planSizeData.every && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="clear selection"
                                      onClick={() =>
                                        setPlanSizeData({
                                          ...planSizeData,
                                          every: "",
                                        })
                                      }
                                      edge="end"
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            >
                              <MenuItem value="7">7 (days)</MenuItem>
                              <MenuItem value="14">14 (days)</MenuItem>
                              <MenuItem value="21">21 (days)</MenuItem>
                            </TextField>
                          </Grid>
                        ) : planSizeData?.period === "month" ? (
                          <Grid item xs={4}>
                            <TextField
                              select
                              variant="outlined"
                              size="small"
                              fullWidth
                              name="every"
                              label="Every"
                              value={planSizeData.every}
                              onChange={handleOnChangePlazSize}
                              SelectProps={{
                                IconComponent: planSizeData.every
                                  ? () => null
                                  : undefined,
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: "300px",
                                    },
                                  },
                                },
                              }}
                              InputProps={{
                                endAdornment: planSizeData.every && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="clear selection"
                                      onClick={() =>
                                        setPlanSizeData({
                                          ...planSizeData,
                                          every: "",
                                        })
                                      }
                                      edge="end"
                                    >
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            >
                              <MenuItem value="1">Every</MenuItem>
                              <MenuItem value="2">2nd</MenuItem>
                              <MenuItem value="3">3rd</MenuItem>
                              <MenuItem value="4">4th</MenuItem>
                              <MenuItem value="5">5th</MenuItem>
                              <MenuItem value="6">6th</MenuItem>
                            </TextField>
                          </Grid>
                        ) : null}
                        <Grid item xs={12}>
                          <TextField
                            //   error={nameError}
                            //   helperText={nameError ? "Name required" : null}
                            //   autoFocus
                            name="description"
                            label="Description"
                            type="number"
                            size="small"
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={4}
                            value={planSizeData.description}
                            onChange={handleOnChangePlazSize}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <Button
                            variant="contained"
                            onClick={handleAddPlanSize}
                          >
                            Add
                          </Button>
                        </Grid>
                      </>
                    )}
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    {rows?.length > 0 && (
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Price
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              SKU
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Renewable Time
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Edit
                            </TableCell>
                            <TableCell
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Delete
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ textAlign: "center" }}
                              >
                                {row.name}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ textAlign: "center" }}
                              >
                                {`$${Number(row.price ?? 0)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  .toLocaleString("en-US")}`}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ textAlign: "center" }}
                              >
                                {row.sku}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ textAlign: "center" }}
                              >
                                {row.period}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ textAlign: "center" }}
                              >
                                <IconButton
                                  style={{ color: "#1976D2" }}
                                  onClick={() => handleEditVariation(index)}
                                >
                                  <EditRoundedIcon
                                    style={{ fontSize: "1.3rem" }}
                                  />
                                </IconButton>
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ textAlign: "center" }}
                              >
                                <IconButton
                                  style={{ color: "#E55A54" }}
                                  onClick={() => handleRemoveRow(index)}
                                >
                                  <DeleteIcon style={{ fontSize: "1.3rem" }} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <div>
                      <label htmlFor="fileInput">
                        <Button
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          onClick={handleEditIconClick}
                          style={{ textTransform: "inherit" }}
                        >
                          {id ? "Edit Image" : "Upload Image"}
                        </Button>
                      </label>
                      <div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </div>
                      {selectedImage && (
                        <div
                          style={{
                            marginTop: "1rem",
                            width: "fit-content",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={selectedImage ? selectedImage : imageDB}
                            alt="Selected Icon"
                            style={{
                              height: "150px",
                              width: "150px",
                              borderRadius: "5px",
                            }}
                          />
                          <IconButton
                            onClick={handleRemoveImage}
                            style={{ color: "#E55A54" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      )}
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                          style={{ backgroundColor: "#F3F3F3" }}
                        >
                          <Typography>Product Categories</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ maxHeight: "350px", overflowY: "scroll" }}
                        >
                          {allCategories?.length > 0
                            ? allCategories.map((item) => (
                                <FormGroup key={item.id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={item.name}
                                        checked={productData?.categories?.includes(
                                          item.id
                                        )}
                                        onChange={(event) =>
                                          handleChangeCategoryCheckbox(
                                            event,
                                            item.id
                                          )
                                        }
                                      />
                                    }
                                    label={item.name}
                                  />
                                </FormGroup>
                              ))
                            : null}
                        </AccordionDetails>
                      </Accordion>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel2-content"
                          id="panel2-header"
                          style={{ backgroundColor: "#F3F3F3" }}
                        >
                          <Typography>Product Tags</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{ maxHeight: "350px", overflowY: "scroll" }}
                        >
                          {allTags?.length > 0
                            ? allTags.map((item) => (
                                <FormGroup key={item.id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={item.name}
                                        checked={productData?.tags?.includes(
                                          item.id
                                        )}
                                        onChange={(event) =>
                                          handleChangeTagsCheckbox(
                                            event,
                                            item.id
                                          )
                                        }
                                      />
                                    }
                                    label={item.name}
                                  />
                                </FormGroup>
                              ))
                            : null}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "end" }}
              >
                {alertError && (
                  <Alert severity="error">
                    You cannot add plans when you do not have variable product.
                    Please remove plans or make a product as variable.
                  </Alert>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  paddingTop: "0.5rem",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleAddProduct}
                  style={{ textTransform: "inherit" }}
                >
                  {id ? "Update" : "Add Product"}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={open} disableBackdropClick>
        <DialogTitle>Edit Plan Size</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can edit this variations from these modal. Super Admin can just
            edit these variations.
          </DialogContentText>
          <Grid item xs={6}>
            <TextField
              //   error={nameError}
              //   helperText={nameError ? "Name required" : null}
              //   autoFocus
              name="name"
              margin="normal"
              label="Plan Name"
              size="small"
              fullWidth
              variant="outlined"
              value={editPlanSizeData.name}
              onChange={handleEditOnChangePlazSize}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              //   error={nameError}
              //   helperText={nameError ? "Name required" : null}
              //   autoFocus
              name="signup_fee"
              margin="normal"
              label="Sign-up Fee"
              type="number"
              size="small"
              fullWidth
              variant="outlined"
              value={editPlanSizeData.signup_fee}
              onChange={handleEditOnChangePlazSize}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              //   error={nameError}
              //   helperText={nameError ? "Name required" : null}
              //   autoFocus
              name="sku"
              margin="normal"
              label="SKU"
              size="small"
              fullWidth
              variant="outlined"
              value={editPlanSizeData.sku}
              onChange={handleEditOnChangePlazSize}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              //   error={nameError}
              //   helperText={nameError ? "Name required" : null}
              //   autoFocus
              name="price"
              margin="normal"
              label="Price"
              type="number"
              size="small"
              fullWidth
              variant="outlined"
              value={editPlanSizeData.price}
              onChange={handleEditOnChangePlazSize}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              variant="outlined"
              margin="normal"
              size="small"
              fullWidth
              name="period"
              label="Renewable Time"
              value={editPlanSizeData.period}
              onChange={handleEditOnChangePlazSize}
              SelectProps={{
                IconComponent: editPlanSizeData.period ? () => null : undefined,
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: "300px",
                    },
                  },
                },
              }}
              InputProps={{
                endAdornment: editPlanSizeData.period && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear selection"
                      onClick={() =>
                        setEditPlanSizeData({
                          ...editPlanSizeData,
                          period: "",
                        })
                      }
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="day">Day</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              {/* <MenuItem value="year">Year</MenuItem> */}
            </TextField>
          </Grid>
          {editPlanSizeData?.period === "day" ? (
            <Grid item xs={6}>
              <TextField
                select
                variant="outlined"
                margin="normal"
                size="small"
                fullWidth
                name="every"
                label="Every"
                value={editPlanSizeData.every}
                onChange={handleEditOnChangePlazSize}
                SelectProps={{
                  IconComponent: editPlanSizeData.every
                    ? () => null
                    : undefined,
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "300px",
                      },
                    },
                  },
                }}
                InputProps={{
                  endAdornment: editPlanSizeData.every && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear selection"
                        onClick={() =>
                          setEditPlanSizeData({
                            ...editPlanSizeData,
                            every: "",
                          })
                        }
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="7">7 (days)</MenuItem>
                <MenuItem value="14">14 (days)</MenuItem>
                <MenuItem value="21">21 (days)</MenuItem>
              </TextField>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <TextField
                select
                variant="outlined"
                margin="normal"
                size="small"
                fullWidth
                name="every"
                label="Every"
                value={editPlanSizeData.every}
                onChange={handleEditOnChangePlazSize}
                SelectProps={{
                  IconComponent: editPlanSizeData.every
                    ? () => null
                    : undefined,
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "300px",
                      },
                    },
                  },
                }}
                InputProps={{
                  endAdornment: editPlanSizeData.every && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear selection"
                        onClick={() =>
                          setEditPlanSizeData({
                            ...editPlanSizeData,
                            every: "",
                          })
                        }
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="1">Every</MenuItem>
                <MenuItem value="2">2nd</MenuItem>
                <MenuItem value="3">3rd</MenuItem>
                <MenuItem value="4">4th</MenuItem>
                <MenuItem value="5">5th</MenuItem>
                <MenuItem value="6">6th</MenuItem>
              </TextField>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              //   error={nameError}
              //   helperText={nameError ? "Name required" : null}
              //   autoFocus
              name="description"
              label="Description"
              margin="normal"
              type="number"
              size="small"
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={editPlanSizeData.description}
              onChange={handleEditOnChangePlazSize}
            />
          </Grid>
        </DialogContent>
        <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            style={{ textTransform: "inherit" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleUpdateRow}
            style={{ textTransform: "inherit" }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
};

export default AddProduct;

import React, { useState } from "react";
import { Button, Card, Grid, IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import { generateClient } from "aws-amplify/api";
import { listProductCards, listProductVariations } from "../../graphql/queries";
import { deleteProductCard } from "../../graphql/mutations";
import { PulseLoader } from "react-spinners";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { showToast } from "../../redux/userSlice";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import DeleteIcon from "@mui/icons-material/Delete";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";

const container = {
  margin: "0rem 1rem",
};
const organizationCard = {
  padding: "1.5rem 2rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  minHeight: "68vh",
};
const statusStyleActive = {
  color: "#1C984D",
  fontWeight: "bold",
  padding: "0.1rem 0.5rem",
  display: "inline-block",
  borderRadius: "4px",
  margin: "0px",
};
const statusStyleInActive = {
  color: "#E35244",
  fontWeight: "bold",
  padding: "0.1rem 0.5rem",
  display: "inline-block",
  borderRadius: "4px",
  margin: "0px",
};

const AllProducts = () => {
  const client = generateClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [productData, setProductData] = React.useState(null);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [productDetail, setProductDetail] = useState(null);

  React.useEffect(() => {
    fetchProductsDetail();
  }, []);

  React.useEffect(() => {
    if (productData?.length > 0) {
      fetchPlansById();
    }
  }, [productData]);

  const fetchProductsDetail = async () => {
    try {
      setLoader(true);
      const getProductData = await client.graphql({
        query: listProductCards,
      });

      const result = getProductData?.data?.listProductCards?.items;
      const sortedResult = result?.sort((a, b) => a.name.localeCompare(b.name));
      setProductData(sortedResult);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleDeleteProduct = (id) => {
    setOpen(true);
    setIdToDelete(id);
  };

  async function fetchAllProductVariations() {
    let allItems = [];
    let nextToken = null;

    do {
      const getProductsData = await client.graphql({
        query: listProductVariations,
        variables: {
          nextToken,
        },
      });

      const response = getProductsData.data.listProductVariations.items;
      allItems = [...allItems, ...response];
      nextToken = getProductsData.data.listProductVariations.nextToken;
    } while (nextToken);

    return allItems;
  }

  const fetchPlansById = async () => {
    try {
      setLoader(true);
      const response = await fetchAllProductVariations();
      const cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories);
        }
        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags);
        }
        return cleanedItem;
      });

      const updatedProductData = productData?.map((product) => {
        const matchedPlans = cleanedResponse.filter(
          (plan) => plan.product_id === product.id
        );
        const lowestPrice = Math.min(
          ...matchedPlans.map((plan) => Number(plan.price))
        );
        return {
          ...product,
          plans: matchedPlans.filter(
            (plan) => Number(plan.price) === lowestPrice
          ),
        };
      });
      setProductDetail(updatedProductData);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  const confirmDeleteProduct = async () => {
    setOpen(false);
    try {
      const variables = {
        input: {
          id: idToDelete,
        },
      };

      const deletePlans = await client.graphql({
        query: deleteProductCard,
        variables: variables,
      });
      if (deletePlans) {
        setIdToDelete("");
      }
      dispatch(showToast({ message: "Deleted successfully", type: "success" }));
      fetchProductsDetail();
    } catch (error) {
      console.error("Error saving data to the database:", error);
      dispatch(showToast({ message: error.message, type: "error" }));
    }
  };

  const handleClose = () => {
    setOpen(false);
    setIdToDelete("");
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: "name",
      headerName: "Name",
      width: 400,
      renderCell: (params) => (
        <div
          style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}
        >
          <img
            src={params.row.image}
            alt={params.row.name}
            style={{
              width: 50,
              height: 50,
              borderRadius: "10%",
              marginRight: 8,
            }}
          />
          {params.row.name}
        </div>
      ),
    },
    {
      field: "price",
      headerName: "Price",
      width: 250,
      renderCell: (params) => (
        <>
          {params.row.type === "variable subscription" ? (
            <p style={{ margin: "0px" }}>
              <span
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
              >
                From&nbsp;
              </span>
              <span
                style={{
                  color: "#2895CF",
                  fontWeight: "bold",
                }}
              >
                {`$${Number(params.row?.plans?.[0]?.price)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  .toLocaleString("en-US")}`}
              </span>
              /{params.row?.plans?.[0]?.period}
            </p>
          ) : params.row.type === "simple product" ? (
            <p style={{ margin: "0px" }}>
              <span
                style={{
                  color: "#2895CF",
                  fontWeight: "bold",
                }}
              >
                {`$${Number(params.value)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  .toLocaleString("en-US")}`}
              </span>
            </p>
          ) : null}
        </>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => (
        <p style={params.row.status ? statusStyleActive : statusStyleInActive}>
          {params.row.status ? "Active" : "InActive"}
        </p>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="inherit"
            onClick={() =>
              navigate(
                `/add_product/${slugify(params.row.name, {
                  lower: true,
                })}`,
                {
                  state: {
                    productIdFromState: params.row.id,
                  },
                }
              )
            }
          >
            <EditRoundedIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="inherit"
            onClick={() => handleDeleteProduct(params.row.id)}
          >
            <DeleteIcon style={{ color: "#E55A54" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const rows =
    productDetail?.map((item) => ({
      id: item.id,
      name: item.name,
      image: item.image,
      type: item.type,
      status: item.is_active,
      price: item.price,
      plans: item.plans,
    })) || [];

  return (
    <>
      <div style={container}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card style={organizationCard}>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50vh",
                  }}
                >
                  <PulseLoader color="#ACD6EB" />
                </div>
              ) : (
                <DataGrid
                  rows={rows}
                  columns={columns.filter((column) => column.field !== "id")}
                  getRowId={(row) => row.id}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 7,
                      },
                    },
                  }}
                  pageSizeOptions={[7, 25, 50, 100]}
                  density="comfortable"
                  sx={{
                    border: 0,
                    borderColor: "primary.light",
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                  }}
                />
              )}
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
        <Dialog open={open} disableBackdropClick>
          <DialogTitle>Confirm delete product</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this product? This product will
              permanantly be removed from your shop.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button variant="contained" onClick={confirmDeleteProduct}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Fab
          sx={{
            position: "absolute",
            bottom: 30,
            right: 30,
            backgroundColor: "#1976d2",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          aria-label={"Add"}
          onClick={() => navigate("/add_product")}
        >
          <AddIcon />
        </Fab>
      </div>
    </>
  );
};

export default AllProducts;

import React, { useState } from "react";
import { Alert, Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { signUp } from "aws-amplify/auth";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/userSlice";
import { useMediaQuery } from "@mui/material";
import "../Css/Signup-userinfo.css";
const SignUpUserInfo = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const cardStyle = {
    margin: isMobile ? "1rem auto" : "0rem auto",
    width: isMobile ? "90%" : "45%",
    textAlign: "center",
    height: !isMobile && "100vh",
    display: !isMobile && "flex",
    justifyContent: !isMobile && "center",
    flexDirection: !isMobile && "column",
    alignItems: !isMobile && "center",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const email = state?.email;
  const workSpace = state?.workSpace;
  const workSpaceUrl = state?.workSpaceUrl;
  const detail = state?.detail;
  const cartItems = state?.cartItems;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [lastError, setLastError] = useState(false);
  const [firstError, setFirstError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [lastErrorMsg, setLastErrorMsg] = useState("");
  const [firstErrorMsg, setFirstErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [showPasswordErrorMsg, setShowPasswordErrorMsg] = useState("");
  const [servererror, setServerError] = useState(false);
  const [servererrorMessage, setServerErrorMessage] = useState("");

  async function handleSignUp(e) {
    e.preventDefault();
    dispatch(setLoading(true));
    try {
      if (!firstName && !lastName && !password) {
        setFirstError(true);
        setFirstErrorMsg("First name required");
        setLastError(true);
        setLastErrorMsg("Last name required");
        setPasswordError(true);
        setPasswordErrorMsg("Password required");
        dispatch(setLoading(false));
        return;
      }
      if (!firstName) {
        setFirstError(true);
        setFirstErrorMsg("First name required");
        dispatch(setLoading(false));
        return;
      }
      if (!lastName) {
        setLastError(true);
        setLastErrorMsg("Last name required");
        dispatch(setLoading(false));
        return;
      }
      if (!password) {
        setPasswordError(true);
        setPasswordErrorMsg("Password required");
        dispatch(setLoading(false));
        return;
      }
      if (password !== confirmPassword) {
        setServerError(true);
        setServerErrorMessage("Both passwords should be the same");
        dispatch(setLoading(false));
        return;
      }

      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            email: email,
            name: firstName,
            family_name: lastName,
          },
          autoSignIn: true,
        },
      });
      dispatch(setLoading(false));
      navigate("/confirm-domain", {
        state: {
          email: email,
          firstName: firstName,
          lastName: lastName,
          workSpace: workSpace,
          detail: detail,
          cartItems: cartItems,
          workSpaceUrl: workSpaceUrl
        },
      });
    } catch (error) {
      dispatch(setLoading(false));
      setServerError(true);
      setServerErrorMessage(error.message);
    }
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <form style={cardStyle} className="cardStyle1">
        <div style={{ textAlign: "center" }}>
          <img
            src="/faviconPNG.png"
            height={isMobile ? "50px" : "70px"}
            width={isMobile ? "100%" : "430px"}
          />
        </div>
        <div style={{ marginTop: isMobile ? "0.5rem" : "0rem" }}>
          <h1
            style={{
              color: "#1D1C1D",
              fontSize: isMobile ? "1.4rem" : "3rem",
              margin: isMobile ? "0.5rem" : "0rem",
              marginTop: !isMobile && "1.5rem",
            }}
            className="signup-h1"
          >
            Create Workspace Account
          </h1>
          <p
            style={{
              fontSize: isMobile ? "0.9rem" : "1.4rem",
              color: "#605E61",
              margin: "1rem",
            }}
          >
            Create Workspace account to manage your projects
          </p>
        </div>
        <Grid
          container
          spacing={0}
          style={{ marginTop: isMobile ? "2rem" : "2rem" }}
        >
          <Grid item xs={12} style={{ display: "flex", gap: "1rem" }}>
            <Grid item xs={6}>
              <TextField
                error={firstError}
                helperText={firstError ? firstErrorMsg : null}
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstError(false);
                  setFirstErrorMsg("");
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={firstName}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon style={{ color: "#777" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                error={lastError}
                helperText={lastError ? lastErrorMsg : null}
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                onChange={(e) => {
                  setLastName(e.target.value);
                  setLastError(false);
                  setLastErrorMsg("");
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={lastName}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon style={{ color: "#777" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                error={passwordError}
                helperText={passwordError ? passwordErrorMsg : null}
                id="outlined-basic"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                label="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                  setPasswordErrorMsg("");
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={password}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon style={{ color: "#777" }} />
                        ) : (
                          <VisibilityIcon style={{ color: "#777" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setShowPasswordError(false);
                  setShowPasswordErrorMsg("");
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={confirmPassword}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOffIcon style={{ color: "#777" }} />
                        ) : (
                          <VisibilityIcon style={{ color: "#777" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {servererror ? (
          <div style={{ marginBottom: "0.7rem" }}>
            <Alert severity="error">{servererrorMessage}</Alert>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "100%",
              padding: "0.9rem 0rem",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
            onClick={(e) => handleSignUp(e)}
            type="submit"
          >
            Continue
          </Button>
          <div
            style={{
              color: "#777",
              marginTop: isMobile ? "2rem" : "2rem",
              textAlign: "center",
            }}
          >
            By creating an account you agree to our{" "}
            <Link
              to={"https://rothbright.com/terms-and-conditions/"}
              target="__blank"
            >
              <span
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "#1976D2",
                }}
              >
                Terms of Services
              </span>{" "}
            </Link>
            and{" "}
            <Link
              to={"https://rothbright.com/privacy-policy/"}
              target="__blank"
            >
              <span
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "#1976D2",
                }}
              >
                Privacy Policy
              </span>
            </Link>
          </div>
        </div>
      </form>
    </>
  );
};

export default SignUpUserInfo;

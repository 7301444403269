import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  TextField,
  MenuItem,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import { useParams, useLocation } from "react-router";
import {
  selectLoading,
  setLoading,
  setProductCount,
  showToast,
} from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../Assets/faviconPNG.png";
import { selectProductCount } from "../redux/userSlice";
import { LocalMall } from "@mui/icons-material";
import ShopFooter from "../components/ShopFooter";
import { useJune } from "./JuneSdk";
import { listProductCards, listProductVariations } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import TagManager from "react-gtm-module";
import tagManagerEvents from "./GoogleTagManager";
import { useMediaQuery } from "@mui/material";
import uuid from "react-uuid";
import ExternalMobileAppBar from "../components/Common/ExternalMobileAppBar";
import ProductDetailMobileResposnive from "../components/Common/ProductDetailMobileResposnive";
import { createCartItems } from "../graphql/mutations";
import ClearIcon from "@mui/icons-material/Clear";
import UnAuthenticatedAppBarComponent from "../components/unAuthenticatedAppBar";

const SingleProduct = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const client = generateClient();
  const analytics = useJune("xcngUCruinFOUefl");

  const appBarContainer = {
    margin: "6rem 4rem",
  };

  const container = {
    margin: "1.5rem 4rem",
  };
  const organizationCard = {
    margin: "0rem 0rem",
    padding: "3rem 4rem",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 2px 10px",
    minHeight: "68vh",
  };
  const productDesc = {
    fontSize: "1.1rem",
    fontWeight: "bold",
  };
  const productName = {
    color: "#2895CF",
    margin: "0px",
  };
  const counterContainer = {
    display: "flex",
    alignItems: "center",
  };
  const counterButton = {
    color: "#1565C0",
  };
  const title = {
    fontSize: "2rem",
    marginTop: "1rem",
    marginBottom: "1rem",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const [count, setCount] = useState(1);
  const [productById, setProductById] = useState(null);
  const [plansById, setPlansById] = useState(null);
  const [plan, setPlan] = useState("");
  const [error, setError] = useState(false);
  const [price, setPrice] = useState("");
  const productCount = useSelector(selectProductCount);
  const [updateDescription, setUpdateDescription] = useState("");
  const [parentDescription, setParentDescription] = useState("");
  const [period, setPeriod] = useState("");
  const [every, setEvery] = useState("");
  const params = useParams();
  const [lowestPlan, setLowestPlan] = useState(null);
  const productId = params.productDetails;

  useEffect(() => {
    tagManagerEvents("product_detail_page_external");
    if (productId) {
      fetchProductById(productId);
    }
    document.title = "Plan";
  }, [productId]);

  useEffect(() => {
    setPrice(productById?.[0]?.price);
    setPeriod(productById?.[0]?.period);
    setParentDescription(productById?.[0]?.description);
  }, [productById]);

  useEffect(() => {
    const storedCartItems =
      JSON.parse(localStorage.getItem("productCart")) || [];
    const totalItems = storedCartItems.reduce(
      (total, item) => total + item.quantity,
      0
    );
    const totalCount = storedCartItems.reduce(
      (total, item) => total + item.quantity * item.price,
      0
    );
    dispatch(setProductCount(storedCartItems));
  }, []);

  const fetchProductById = async (id) => {
    try {
      dispatch(setLoading(true));
      const variables = {
        filter: {
          slug: {
            eq: id,
          },
        },
      };
      const getProductsData = await client.graphql({
        query: listProductCards,
        variables: variables,
      });
      const response = getProductsData.data.listProductCards.items;
      const cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories);
        }
        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags);
        }
        return cleanedItem;
      });
      TagManager.dataLayer({
        dataLayer: {
          event: "productView",
          productId: cleanedResponse[0]?.id,
          productName: cleanedResponse[0]?.name,
        },
      });
      setPrice(cleanedResponse[0]?.price);
      setProductById(cleanedResponse);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    if (productById?.length > 0) {
      fetchPlansById();
    }
  }, [productById]);

  async function fetchAllProductVariationsIds() {
    let allItems = [];
    let nextToken = null;

    do {
      const variables = {
        filter: {
          product_id: {
            eq: productById?.[0]?.id,
          },
        },
        nextToken: nextToken,
      };
      const getCategories = await client.graphql({
        query: listProductVariations,
        variables: variables,
      });
      const response = getCategories?.data?.listProductVariations?.items || [];
      allItems = [...allItems, ...response];
      nextToken = getCategories?.data?.listProductVariations?.nextToken;
    } while (nextToken);
    return allItems;
  }

  const fetchPlansById = async () => {
    try {
      setLoading(true);
      const response = await fetchAllProductVariationsIds();
      const cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories);
        }
        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags);
        }
        return cleanedItem;
      });
      setPlansById(cleanedResponse);
      if (cleanedResponse) {
        const prices = cleanedResponse?.map((plan) => Number(plan.price));
        const lowestPrice = Math.min(...prices);
        const lowestPricePlans = cleanedResponse?.filter(
          (plan) => Number(plan.price) === lowestPrice
        );
        setLowestPlan(lowestPricePlans);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  const handleBillingData = () => {
    if (!plan && productById?.[0]?.type === "variable subscription") {
      dispatch(
        showToast({ message: "Please select any plan size", type: "error" })
      );
      return;
    }
    const productArray = [];
    productById.forEach((productItem) => {
      const productObject = {
        ...productItem,
        quantity: count,
        price:
          productById?.[0]?.type === "simple product"
            ? productById?.[0]?.price
            : price,
        plan: plan,
        period: period,
        every: every,
      };
      productArray.push(productObject);
    });
    if (props.isShopItem) {
      let _totalPrice = price * count;
      props.onBuyNowPressed(productArray, _totalPrice);
    } else {
      tagManagerEvents("buyNowPressed");
      navigate("/billings", {
        state: {
          product: productArray,
          totalPrice: price * count,
        },
      });
    }
  };

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const setProductToLocal = () => {
    if (!plan && productById?.[0]?.type === "variable subscription") {
      dispatch(
        showToast({ message: "Please select any plan size", type: "error" })
      );
      return;
    }

    let existingData = localStorage.getItem("productCart");
    let productCart = existingData ? JSON.parse(existingData) : [];

    const existingProductIndex = productCart.findIndex(
      (item) => item.id === productById.id && item.plan === plan
    );

    if (existingProductIndex !== -1) {
      const updatedProduct = {
        ...productCart[existingProductIndex],
        quantity: productCart[existingProductIndex].quantity + count,
      };
      productCart[existingProductIndex] = updatedProduct;
      tagManagerEvents("addToCartPressed");
    } else {
      productCart.push({
        ...productById[0],
        quantity: count,
        price:
          productById?.[0]?.type === "simple product"
            ? productById?.[0]?.price
            : price,
        plan: plan,
        period: period,
        every: every,
      });
      tagManagerEvents("addToCartPressed");
    }
    localStorage.setItem("productCart", JSON.stringify(productCart));
    dispatch(setProductCount(productCart));
  };

  const handlePlanChange = (event) => {
    const selectedPlan = event.target.value;
    const selectedPlanObject = plansById.find(
      (option) => option.name === selectedPlan
    );
    if (selectedPlanObject) {
      setPrice(selectedPlanObject.price);
      setPlan(selectedPlan);
      setUpdateDescription(selectedPlanObject.description);
      setPeriod(selectedPlanObject.period);
      setEvery(selectedPlanObject.every);
    } else {
      setPrice(productById?.[0]?.price);
      setPeriod(productById?.[0]?.period);
      setUpdateDescription(productById?.[0]?.description);
      setPlan("");
      setEvery("");
    }
  };

  const getTotalItems = () => {
    return productCount?.reduce((total, item) => total + item.quantity, 0);
  };

  const handleMeetAdvisorClick = () => {
    if (analytics) {
      analytics.track("Meet with a Rothbright advisor clicked", {
        button_name: "Meet with a Rothbright advisor clicked",
      });
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <ExternalMobileAppBar productCount={getTotalItems()} />
          <ProductDetailMobileResposnive
            productById={productById}
            plansById={plansById}
            price={price !== "0" ? price : lowestPlan?.[0]?.price}
            period={period}
            error={error}
            plan={plan}
            handlePlanChange={handlePlanChange}
            handleDecrement={handleDecrement}
            counterButton={counterButton}
            count={count}
            handleIncrement={handleIncrement}
            handleBillingData={handleBillingData}
            setProductToLocal={setProductToLocal}
            handleMeetAdvisorClick={handleMeetAdvisorClick}
            updateDescription={updateDescription}
            parentDescription={parentDescription}
          />
        </>
      ) : (
        <div style={container}>
          <>
            <div style={appBarContainer}>
              <UnAuthenticatedAppBarComponent
                getTotalItems={getTotalItems}
                onHandleCartPressed={props.onHandleCartPressed}
              />
            </div>
          </>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div>
                <div>
                  <p style={title}></p>
                </div>
              </div>
            </Grid>
          </Grid>
          {loading ? (
            <Stack spacing={4}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <Skeleton
                  variant="rounded"
                  width={"50%"}
                  height={600}
                  animation="wave"
                />
                <Skeleton
                  variant="rounded"
                  width={"50%"}
                  height={600}
                  animation="wave"
                />
              </div>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem" }}
                height={50}
                animation="wave"
              />
            </Stack>
          ) : (
            <>
              <Card style={organizationCard}>
                <Grid container spacing={7}>
                  <Grid item xs={5}>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={productById?.[0]?.image}
                        width="90%"
                        // height="500px"
                        style={{
                          objectFit: "cover",
                          borderRadius: "15px",
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={7} style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div>
                        <h1 style={productName}>{productById?.[0]?.name}</h1>
                        {/* <p style={productDesc}>
                          <span
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "bold",
                            }}
                          >
                            From:
                          </span>{" "}
                          <span
                            style={{ color: "#2895CF", fontWeight: "bold" }}
                          >
                            {`$${Number(price)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              .toLocaleString("en-US")}`}
                          </span>{" "}
                          / {period}
                        </p> */}
                        {productById?.[0]?.type === "variable subscription" ? (
                          <p
                            style={{
                              ...productDesc,
                              fontSize: isMobile && "0.8rem",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "0.8rem",
                                fontWeight: "bold",
                              }}
                            >
                              From:
                            </span>{" "}
                            <span
                              style={{
                                color: "#2895CF",
                                fontWeight: "bold",
                              }}
                            >
                              {`$${
                                Number.isNaN(Number(lowestPlan?.[0]?.price)) ||
                                lowestPlan?.[0]?.price === undefined
                                  ? ""
                                  : Number(lowestPlan?.[0]?.price)
                                      .toFixed(0)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      .toLocaleString("en-US")
                              }`}
                            </span>{" "}
                            / {lowestPlan?.[0]?.period}
                          </p>
                        ) : productById?.[0]?.type === "simple product" ? (
                          <p
                            style={{
                              ...productDesc,
                              fontSize: isMobile && "0.8rem",
                            }}
                          >
                            <span
                              style={{
                                color: "#2895CF",
                                fontWeight: "bold",
                              }}
                            >
                              {`$${Number(productById?.[0]?.price)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                .toLocaleString("en-US")}`}
                            </span>
                          </p>
                        ) : null}
                      </div>
                      <div
                        style={{
                          lineHeight: "1.8rem",
                          color: "#605E61",
                          fontSize: "1.2rem",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: productById?.[0]?.description,
                        }}
                      ></div>
                      <div>
                        <Grid item xs={12} spacing={0}>
                          <Grid
                            container
                            spacing={0}
                            style={{
                              margin: "1rem 0rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {productById?.[0]?.type ===
                            "variable subscription" ? (
                              <>
                                <Grid item xs={2}>
                                  <h4 style={{ margin: "0px" }}>Plan Size</h4>
                                </Grid>
                                <Grid item xs={7}>
                                  <TextField
                                    select
                                    margin="normal"
                                    fullWidth
                                    name="plan"
                                    label="Choose an option"
                                    error={error}
                                    helperText={error ? "Required field" : ""}
                                    style={{ margin: "0px" }}
                                    value={plan}
                                    onChange={handlePlanChange}
                                    InputProps={{
                                      endAdornment: plan && (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="clear selection"
                                            onClick={() => setPlan("")}
                                            edge="end"
                                          >
                                            <ClearIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    SelectProps={{
                                      IconComponent: plan
                                        ? () => null
                                        : undefined,
                                    }}
                                  >
                                    {plansById?.map((option) => (
                                      <MenuItem
                                        key={option.name}
                                        value={option.name}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item xs={1}></Grid>
                              </>
                            ) : null}

                            <Grid item xs={2}>
                              <div style={counterContainer}>
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  onClick={handleDecrement}
                                >
                                  <RemoveCircleOutlinedIcon
                                    fontSize="large"
                                    style={counterButton}
                                  />
                                </IconButton>
                                <p>
                                  <b>{count}</b>
                                </p>
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  onClick={handleIncrement}
                                >
                                  <AddCircleOutlinedIcon
                                    fontSize="large"
                                    style={counterButton}
                                  />
                                </IconButton>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        {plan && (
                          <Grid item xs={12} spacing={0}>
                            <Grid
                              container
                              spacing={0}
                              style={{
                                margin: "2rem 0rem",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Grid item xs={12}>
                                <p
                                  style={{
                                    lineHeight: "1.8rem",
                                    color: "#605E61",
                                    fontSize: "1.2rem",
                                    margin: "0px",
                                  }}
                                >
                                  {updateDescription}
                                </p>
                              </Grid>
                              <Grid item xs={12}>
                                <p
                                  style={{
                                    ...productDesc,
                                    fontSize: isMobile && "0.8rem",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#2895CF",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`$${Number(price)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      .toLocaleString("en-US")}`}
                                  </span>{" "}
                                  / {period}
                                </p>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        <Grid item xs={12} spacing={0}>
                          <Grid item xs={12}>
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                                marginTop: "2rem",
                              }}
                            >
                              <Button
                                variant="contained"
                                style={{
                                  width: "100%",
                                  padding: "0.7rem 0rem",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                  textTransform: "initial",
                                  backgroundColor: "#222",
                                }}
                                onClick={handleBillingData}
                              >
                                Buy Now
                              </Button>
                              <Button
                                variant="contained"
                                style={{
                                  width: "100%",
                                  padding: "0.7rem 0rem",
                                  fontSize: "1.1rem",
                                  fontWeight: "bold",
                                  textTransform: "initial",
                                }}
                                onClick={setProductToLocal}
                              >
                                Add To Cart
                              </Button>
                            </div>
                          </Grid>
                          <Link
                            to={"https://calendly.com/michael-yawa/30min"}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                            target="_blank"
                          >
                            <Typography
                              variant="subtitle1"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                textAlign: "center",
                                color: "#1976d2",
                                marginTop: 20,
                              }}
                              // onClick={handleMeetAdvisorClick}
                            >
                              Meet with a Rothbright advisor to talk about the
                              plan best for you
                            </Typography>
                          </Link>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Card>
              {
                <div>
                  <ShopFooter />
                </div>
              }
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SingleProduct;

import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import Footer from "../components/Footer";
import EditIcon from "@mui/icons-material/Edit";
import { uploadData } from "aws-amplify/storage";
import {
  updateUserInfo,
  updateWorkspaceInfo,
  updateWorkspaceTeam,
} from "../graphql/mutations";
import { setLoading, setWorkspaceDetails, showToast } from "../redux/userSlice";
import { generateClient } from "aws-amplify/api";
import { useDispatch } from "react-redux";
import {
  listUserInfos,
  listWorkspaceInfos,
  listWorkspaceTeams,
} from "../graphql/queries";
import { useMediaQuery } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";

const container = {
  margin: "0rem 4rem",
};
const organizationCard = {
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  minHeight: "70vh",
};
const AssignRole = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const client = generateClient();
  const [userDetail, setUserDetail] = useState([]);
  const [user, setUser] = useState("");
  const [role, setRole] = useState("");
  const [workspace, setWorkspace] = useState("");
  const [loading, setLoading] = useState(false);
  const [workspaceList, setWorkspaceList] = useState([]);
  const [userError, setUserError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [workspaceError, setWorkspaceError] = useState(false);

  useEffect(() => {
    if (user !== "") {
      fetchWorkspaces();
    }
  }, [user]);

  async function fetchFromAllWorkspaces() {
    let allItems = [];
    let nextToken = null;

    do {
      const getWorkspace = await client.graphql({
        query: listWorkspaceInfos,
        variables: {
          nextToken: nextToken,
        },
      });
      const response = getWorkspace?.data?.listWorkspaceInfos?.items || [];
      allItems = [...allItems, ...response];
      nextToken = getWorkspace?.data?.listWorkspaceInfos?.nextToken;
    } while (nextToken);
    return allItems;
  }

  const fetchWorkspaces = async () => {
    try {
      setLoading(true);
      const allWorkspaces = await fetchFromAllWorkspaces();
      const filteredWorkspaces = allWorkspaces.filter((workspace) =>
        workspace.user_id.includes(user)
      );
      setWorkspaceList(filteredWorkspaces);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching workspaces:", error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // setLoading(true);
        const getUsers = await client.graphql({
          query: listUserInfos,
        });
        const res = getUsers.data.listUserInfos.items;
        const filteredUsers = res?.filter(
          (user) => user.role !== "super_admin"
        );

        const uniqueUsers = [];
        const userIds = new Set();
        for (const user of filteredUsers) {
          if (!userIds.has(user.user_id)) {
            userIds.add(user.user_id);
            uniqueUsers.push(user);
          }
        }

        setUserDetail(uniqueUsers);
        // setLoading(false);
      } catch (error) {
        console.error("Error creating todo:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleAssignRole = async () => {
    if (user === "" && role === "" && workspace === "") {
      setUserError(true);
      setRoleError(true);
      setWorkspaceError(true);
      return;
    } else if (user === "") {
      setUserError(true);
      return;
    } else if (role === "") {
      setRoleError(true);
      return;
    } else if (workspace === "") {
      setWorkspaceError(true);
      return;
    }

    try {
      const Uservariable = {
        filter: {
          user_id: {
            eq: user,
          },
          workspace_id: {
            eq: workspace,
          },
        },
      };

      const getUserData = await client.graphql({
        query: listUserInfos,
        variables: Uservariable,
      });
      const result = getUserData?.data?.listUserInfos?.items;
      if (result?.length > 0) {
        const variable = {
          id: result?.[0]?.id,
          role: role,
        };
        const updateUserData = await client.graphql({
          query: updateUserInfo,
          variables: { input: variable },
        });
      }
      dispatch(
        showToast({ message: "Role Updated Successfully", type: "success" })
      );
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error(error);
    }
  };

  return (
    <div
      style={{ ...container, margin: isMobile ? "0px 1rem" : container.margin }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card style={organizationCard}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: isMobile ? "60vh" : "70vh",
              }}
            >
              <div style={{ width: isMobile ? "90%" : "40%" }}>
                <Grid container spacing={5} style={{ marginTop: "0.5rem" }}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div style={{ fontSize: "1.3rem", margin: "0px" }}>
                          Assign Role
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          select
                          margin="normal"
                          error={userError}
                          size="small"
                          fullWidth
                          label="Select User"
                          style={{ margin: "0px" }}
                          value={user}
                          onChange={(e) => {
                            setUser(e.target.value);
                            setUserError(false);
                          }}
                          SelectProps={{
                            IconComponent: user ? () => null : undefined,
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: "300px",
                                },
                              },
                            },
                          }}
                          InputProps={{
                            endAdornment: user && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="clear selection"
                                  onClick={() => {
                                    setUser("");
                                    setRoleError(false);
                                  }}
                                  edge="end"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        >
                          {userDetail?.map((option) => (
                            <MenuItem
                              key={option.user_id}
                              value={option.user_id}
                            >
                              {` ${option.email}`}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          select
                          size="small"
                          error={roleError}
                          margin="normal"
                          fullWidth
                          label="Role"
                          style={{ margin: "0px" }}
                          value={role}
                          onChange={(e) => {
                            setRole(e.target.value);
                            setRoleError(false);
                          }}
                          SelectProps={{
                            IconComponent: role ? () => null : undefined,
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: "300px",
                                },
                              },
                            },
                          }}
                          InputProps={{
                            endAdornment: role && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="clear selection"
                                  onClick={() => setRole("")}
                                  edge="end"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        >
                          {[
                            "super_admin",
                            "workspace_admin",
                            "advisor",
                            "workspace_member",
                          ]?.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          select
                          fullWidth
                          disabled={user === ""}
                          size="small"
                          error={workspaceError}
                          label="Workspace"
                          value={workspace}
                          onChange={(e) => {
                            setWorkspace(e.target.value);
                            setWorkspaceError(false);
                          }}
                          SelectProps={{
                            IconComponent: workspace ? () => null : undefined,
                            renderValue: (selected) => {
                              const selectedOption = workspaceList.find(
                                (option) => option.id === selected
                              );
                              return selectedOption ? selectedOption.name : "";
                            },
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 300,
                                },
                              },
                            },
                          }}
                          InputProps={{
                            endAdornment: workspace && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="clear selection"
                                  onClick={() => setWorkspace("")}
                                  edge="end"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        >
                          {loading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <CircularProgress size={20} />
                            </Box>
                          ) : (
                            workspaceList?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                <ListItemIcon>
                                  <img
                                    src={option.logo}
                                    alt={option.name}
                                    style={{
                                      width: 30,
                                      height: 30,
                                      borderRadius: "5px",
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={option.name} />
                              </MenuItem>
                            ))
                          )}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          padding: "0.45rem 0rem",
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                          textTransform: "initial",
                          marginTop: "1rem",
                        }}
                        onClick={handleAssignRole}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid container spacing={5} className="footer-container">
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AssignRole;

import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Button,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import Footer from "../components/Footer";
import { useParams } from "react-router";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import {
  selectLoading,
  setLoading,
  setProductCount,
  showToast,
} from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectProductCount } from "../redux/userSlice";
import { useJune } from "./JuneSdk";
import { listProductCards, listProductVariations } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { createCartItems, createSubscriptions } from "../graphql/mutations";
import uuid from "react-uuid";
import { useMediaQuery } from "@mui/material";
import ExternalMobileAppBar from "../components/Common/ExternalMobileAppBar";
import ProductDetailMobileResposnive from "../components/Common/ProductDetailMobileResposnive";
import ClearIcon from "@mui/icons-material/Clear";

const container = {
  margin: "3rem 3rem",
};
const organizationCard = {
  margin: "1rem 0rem",
  padding: "3rem 4rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  minHeight: "70vh",
};
const productDesc = {
  fontSize: "1.1rem",
  fontWeight: "bold",
};
const productName = {
  color: "#2895CF",
  margin: "0px",
};
const counterContainer = {
  display: "flex",
  alignItems: "center",
};
const counterButton = {
  color: "#1565C0",
};

const SingleProduct = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const client = generateClient();
  const analytics = useJune("xcngUCruinFOUefl");
  const pathname = window.location.pathname;
  const routeName = pathname.substring(0, pathname.indexOf("/", 1));
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const [count, setCount] = useState(1);
  const [productById, setProductById] = useState(null);
  const [plansById, setPlansById] = useState(null);
  const [plan, setPlan] = useState("");
  const [error, setError] = useState(false);
  const [price, setPrice] = useState("");
  const [period, setPeriod] = useState("");
  const [every, setEvery] = useState("");
  const [selectedSKU, setSelectedSKU] = useState("");
  const productCount = useSelector(selectProductCount);
  const [updateDescription, setUpdateDescription] = useState("");
  const [lowestPlan, setLowestPlan] = useState(null);

  useEffect(() => {
    if (params.id) {
      fetchProductById(params.id);
    } else if (props.isShopItem) {
      fetchProductById(props.productId);
      fetchPlansById();
    }
  }, [params.id]);

  useEffect(() => {
    if (productById?.length > 0) {
      fetchPlansById();
    }
  }, [productById]);

  const fetchProductById = async (id) => {
    try {
      dispatch(setLoading(true));
      const variables = {
        filter: {
          slug: {
            eq: id,
          },
        },
      };
      const getProductsData = await client.graphql({
        query: listProductCards,
        variables: variables,
      });
      const response = getProductsData.data.listProductCards.items;
      const cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories);
        }
        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags);
        }
        return cleanedItem;
      });
      setProductById(cleanedResponse);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  async function fetchAllProductVariationsIds() {
    let allItems = [];
    let nextToken = null;

    do {
      const variables = {
        filter: {
          product_id: {
            eq: productById?.[0]?.id,
          },
        },
        nextToken: nextToken,
      };
      const getCategories = await client.graphql({
        query: listProductVariations,
        variables: variables,
      });
      const response = getCategories?.data?.listProductVariations?.items || [];
      allItems = [...allItems, ...response];
      nextToken = getCategories?.data?.listProductVariations?.nextToken;
    } while (nextToken);
    return allItems;
  }

  const fetchPlansById = async () => {
    try {
      setLoading(true);
      const response = await fetchAllProductVariationsIds();
      const cleanedResponse = response.map((item) => {
        const cleanedItem = { ...item };
        if (cleanedItem.categories) {
          cleanedItem.categories = JSON.parse(cleanedItem.categories);
        }
        if (cleanedItem.tags) {
          cleanedItem.tags = JSON.parse(cleanedItem.tags);
        }
        return cleanedItem;
      });
      setPlansById(cleanedResponse);
      if (cleanedResponse) {
        const prices = cleanedResponse?.map((plan) => Number(plan.price));
        const lowestPrice = Math.min(...prices);
        const lowestPricePlans = cleanedResponse?.filter(
          (plan) => Number(plan.price) === lowestPrice
        );
        setLowestPlan(lowestPricePlans);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  const handleBillingData = () => {
    if (!plan && productById?.[0]?.type === "variable subscription") {
      dispatch(
        showToast({ message: "Please select any plan size", type: "error" })
      );
      return;
    }
    const productArray = [];
    productById.forEach((productItem) => {
      const productObject = {
        ...productItem,
        quantity: count,
        price:
          productById?.[0]?.type === "simple product"
            ? productById?.[0]?.price
            : price,
        plan: plan,
        period: period,
        every: every,
      };
      productArray.push(productObject);
    });
    if (props.isShopItem) {
      let _totalPrice = price * count;
      props.onBuyNowPressed(productArray, _totalPrice);
    } else {
      navigate("/billing", {
        state: {
          product: productArray,
        },
      });
    }
  };

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const setProductToLocal = async () => {
    if (!plan && productById?.[0]?.type === "variable subscription") {
      dispatch(
        showToast({ message: "Please select any plan size", type: "error" })
      );
      return;
    }
    let id = uuid();
    for (const productItem of productById) {
      const detail = {
        id: id,
        product_id: productItem.id,
        user_id: userData.sub,
        workspace_id: selectedWorkspaceId,
        image: productItem.image,
        name: productItem.name,
        period: period,
        price:
          productById?.[0]?.type === "simple product"
            ? productById?.[0]?.price
            : price,
        type: productItem.type,
        plan: plan,
        quantity: count,
        sku: productItem.sku,
        every: every,
      };
      const saveItemInDB = await client.graphql({
        query: createCartItems,
        variables: { input: detail },
      });
      dispatch(setProductCount(saveItemInDB.data.createCartItems));
    }
  };

  const handlePlanChange = (event) => {
    const selectedPlan = event.target.value;
    const selectedPlanObject = plansById.find(
      (option) => option.name === selectedPlan
    );
    if (selectedPlanObject) {
      setPrice(selectedPlanObject.price);
      setPlan(selectedPlan);
      setUpdateDescription(selectedPlanObject.description);
      setPeriod(selectedPlanObject.period);
      setEvery(selectedPlanObject.every);
      setSelectedSKU(selectedPlanObject.sku);
    } else {
      setPrice(productById?.[0]?.price);
      setPeriod(productById?.[0]?.period);
      setUpdateDescription(productById?.[0]?.description);
      setPlan("");
      setEvery("");
      setSelectedSKU("");
    }
  };

  const handleMeetAdvisorClick = () => {
    if (analytics) {
      analytics.track("Meet with a Rothbright advisor clicked", {
        button_name: "Meet with a Rothbright advisor clicked",
      });
    }
  };

  const handleAddItemToPlan = async () => {
    setLoading(true);
    try {
      if (!plan && productById?.[0]?.type === "variable subscription") {
        dispatch(
          showToast({ message: "Please select any plan size", type: "error" })
        );
        return;
      }
      const sub_id = uuid();
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const variable = {
        id: sub_id,
        product_id: productById?.[0]?.id,
        status: true,
        created_at: formattedDate,
        workspace_id: selectedWorkspaceId,
        subscription_id: "",
        product_image: productById?.[0]?.image,
        product_name: productById?.[0]?.name,
        product_type: productById?.[0]?.type,
        product_period: period,
        product_sku: selectedSKU,
        product_plan: plan,
        product_quantity: count,
        product_price: +count * +price,
        invoice_number: "",
      };

      const createSubsCard = await client.graphql({
        query: createSubscriptions,
        variables: { input: variable },
      });
      setLoading(false);
      dispatch(
        showToast({
          message: "Item added to plan successfully",
          type: "success",
        })
      );
    } catch (error) {
      setLoading(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error fetching orders:", error);
    }
  };

  return (
    <>
      {isMobile ? (
        <div style={{ marginBottom: 70 }}>
          <ProductDetailMobileResposnive
            productById={productById}
            plansById={plansById}
            price={price}
            period={period}
            error={error}
            plan={plan}
            handlePlanChange={handlePlanChange}
            handleDecrement={handleDecrement}
            counterButton={counterButton}
            count={count}
            handleIncrement={handleIncrement}
            handleBillingData={handleBillingData}
            setProductToLocal={setProductToLocal}
            handleMeetAdvisorClick={handleMeetAdvisorClick}
            updateDescription={updateDescription}
          />
        </div>
      ) : (
        <>
          <div style={container}>
            {loading ? (
              <Stack spacing={4}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "2rem",
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    width={"50%"}
                    height={600}
                    animation="wave"
                  />
                  <Skeleton
                    variant="rounded"
                    width={"50%"}
                    height={600}
                    animation="wave"
                  />
                </div>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  height={50}
                  animation="wave"
                />
              </Stack>
            ) : (
              <>
                <Card style={organizationCard}>
                  <Grid container spacing={7}>
                    <Grid item xs={5}>
                      <div style={{ textAlign: "center" }}>
                        <img
                          src={productById?.[0]?.image}
                          width="90%"
                          // height="500px"
                          style={{
                            objectFit: "cover",
                            borderRadius: "15px",
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={7} style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>
                          <h1 style={productName}>{productById?.[0]?.name}</h1>
                          <p style={productDesc}>
                            {productById?.[0]?.type ===
                            "variable subscription" ? (
                              <p
                                style={{
                                  ...productDesc,
                                  fontSize: isMobile && "0.8rem",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "0.8rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  From:
                                </span>{" "}
                                <span
                                  style={{
                                    color: "#2895CF",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`$${Number(lowestPlan?.[0]?.price)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    .toLocaleString("en-US")}`}
                                </span>{" "}
                                / {lowestPlan?.[0]?.period}
                              </p>
                            ) : productById?.[0]?.type === "simple product" ? (
                              <p
                                style={{
                                  ...productDesc,
                                  fontSize: isMobile && "0.8rem",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#2895CF",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`$${Number(productById?.[0]?.price)
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    .toLocaleString("en-US")}`}
                                </span>
                              </p>
                            ) : null}
                          </p>
                        </div>
                        <div
                          style={{
                            lineHeight: "1.8rem",
                            color: "#605E61",
                            fontSize: "1.2rem",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: productById?.[0]?.description,
                          }}
                        ></div>
                        <div>
                          <Grid item xs={12} spacing={0}>
                            <Grid
                              container
                              spacing={0}
                              style={{
                                margin: "1rem 0rem",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {productById?.[0]?.type ===
                              "variable subscription" ? (
                                <>
                                  <Grid item xs={2}>
                                    <h4 style={{ margin: "0px" }}>Plan Size</h4>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <TextField
                                      select
                                      margin="normal"
                                      fullWidth
                                      name="plan"
                                      label="Choose an option"
                                      error={error}
                                      helperText={error ? "Required field" : ""}
                                      style={{ margin: "0px" }}
                                      value={plan}
                                      onChange={handlePlanChange}
                                      InputProps={{
                                        endAdornment: plan && (
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="clear selection"
                                              onClick={() => setPlan("")}
                                              edge="end"
                                            >
                                              <ClearIcon />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                      SelectProps={{
                                        IconComponent: plan
                                          ? () => null
                                          : undefined,
                                      }}
                                    >
                                      {plansById?.map((option) => (
                                        <MenuItem
                                          key={option.name}
                                          value={option.name}
                                        >
                                          {option.name}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={1}></Grid>
                                </>
                              ) : null}

                              <Grid item xs={2}>
                                <div style={counterContainer}>
                                  <IconButton
                                    aria-label="delete"
                                    size="large"
                                    onClick={handleDecrement}
                                  >
                                    <RemoveCircleOutlinedIcon
                                      fontSize="large"
                                      style={counterButton}
                                    />
                                  </IconButton>
                                  <p>
                                    <b>{count}</b>
                                  </p>
                                  <IconButton
                                    aria-label="delete"
                                    size="large"
                                    onClick={handleIncrement}
                                  >
                                    <AddCircleOutlinedIcon
                                      fontSize="large"
                                      style={counterButton}
                                    />
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                          {plan && (
                            <Grid item xs={12} spacing={0}>
                              <Grid
                                container
                                spacing={0}
                                style={{
                                  margin: "2rem 0rem",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Grid item xs={12}>
                                  <p
                                    style={{
                                      lineHeight: "1.8rem",
                                      color: "#605E61",
                                      fontSize: "1.2rem",
                                      margin: "0px",
                                    }}
                                  >
                                    {updateDescription}
                                  </p>
                                </Grid>
                                <Grid item xs={12}>
                                  <p
                                    style={{
                                      ...productDesc,
                                      fontSize: isMobile && "0.8rem",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#2895CF",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {`$${Number(price)
                                        .toFixed(2)
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        .toLocaleString("en-US")}`}
                                    </span>{" "}
                                    / {period}
                                  </p>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item xs={12} spacing={0}>
                            {userData?.role !== "workspace_member" && (
                              <Grid item xs={12}>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "1rem",
                                    marginTop: "2rem",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    style={{
                                      width: "100%",
                                      padding: "0.7rem 0rem",
                                      fontSize: "1.1rem",
                                      fontWeight: "bold",
                                      textTransform: "initial",
                                    }}
                                    onClick={handleBillingData}
                                    color="success"
                                  >
                                    Buy Now
                                  </Button>
                                  <Button
                                    variant="contained"
                                    style={{
                                      width: "100%",
                                      padding: "0.7rem 0rem",
                                      fontSize: "1.1rem",
                                      fontWeight: "bold",
                                      textTransform: "initial",
                                    }}
                                    onClick={setProductToLocal}
                                  >
                                    Add To Cart
                                  </Button>
                                </div>
                              </Grid>
                            )}
                            <Button
                              variant="contained"
                              style={{
                                width: "100%",
                                padding: "0.7rem 0rem",
                                fontSize: "1.1rem",
                                fontWeight: "bold",
                                textTransform: "initial",
                                marginTop: "1rem",
                                backgroundColor: "#222",
                              }}
                              onClick={handleAddItemToPlan}
                            >
                              Add Item To Plan
                            </Button>
                            <Link
                              to={"https://calendly.com/d/cpng-nc4-t7n/rothbright-advisor-calendar"}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                              target="_blank"
                            >
                              <Typography
                                variant="subtitle1"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  textAlign: "center",
                                  color: "#1976d2",
                                  marginTop: 20,
                                }}
                                // onClick={handleMeetAdvisorClick}
                              >
                                Meet with a Rothbright advisor to talk about the
                                plan best for you
                              </Typography>
                            </Link>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
                {!props.isShopItem && routeName !== "/product" && (
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <Footer />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SingleProduct;

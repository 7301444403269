import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Badge, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import Logo from "../Assets/whiteLogo.png";
import SearchIcon from "@mui/icons-material/Search";
import UnAuthenticatedDrawer from "./unAuthenticatedDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import "../Css/unauthbar.css";
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "initial !important",
    marginRight: "0.4rem !important",
    backgroundColor: "#fff !important",
    color: "#00003F !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#1976d2 !important",
    },
  },
}));

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "2.7rem",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const style = {
  position: "absolute",
  top: "17%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function UnAuthenticatedAppBarComponent({
  open,
  totalItems,
  searchQuery,
  handleSearchChange,
  categoriesNames,
  handleCategoryChange,
  onHandleCartPressed,
  getTotalItems,
}) {
  const [openModal, setOpenModal] = React.useState(false);
  const classes = useStyles();
  const handleCloseModal = () => setOpenModal(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  const [isHovered, setIsHovered] = React.useState(null);

  const handleDrawerToggle = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const handleClick = () => {
    if (lastSegment === "") {
      onHandleCartPressed(false);
    } else if (lastSegment !== "" && getTotalItems() > 0) {
      navigate("/cart-item");
    }
  };

  const count = () => {
    if (lastSegment === "") {
      return totalItems !== 0 ? totalItems : undefined;
    } else {
      return getTotalItems() > 0 ? getTotalItems() : undefined;
    }
  };

  const handleNavigate = () => {
    if (lastSegment === "") {
      navigate("/login");
    } else {
      navigate("/");
    }
  };

  const handleMouseEnter = (name) => {
    setIsHovered(name);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  const signInTextHoverStyle = {
    color: "#1976d2",
  };

  return (
    <>
      {!isMobile && (
        <>
          {lastSegment === "" && (
            <UnAuthenticatedDrawer
              handleDrawerToggle={handleDrawerToggle}
              openDrawer={openDrawer}
              categoriesNames={categoriesNames}
              handleCategoryChange={handleCategoryChange}
            />
          )}
          <AppBar
            position="absolute"
            open={!open}
            style={{
              background: "#000",
              height: lastSegment === "" ? "7rem" : "4.5rem",
              width: "100%",
              zIndex: 1300,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: "4rem",
                marginLeft: "4rem",
                height: "100%",
              }}
              className="app-bar-container"
            >
              <div
                style={{ cursor: "pointer" }}
                className="img-container"
                onClick={handleNavigate}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  style={{
                    width: "200px",
                    height: "25px",
                  }}
                />
              </div>
              {lastSegment === "" && (
                <div style={{ width: "50%" }} className="searchbar">
                  <CustomTextField
                    fullWidth
                    placeholder="Search product"
                    variant="outlined"
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{ backgroundColor: "#fff", borderRadius: "5px" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon style={{ fontSize: "2rem" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "1.5rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton color="inherit" onClick={handleClick}>
                    <Badge
                      color="error"
                      badgeContent={count()}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <ShoppingCartIcon
                        style={{ color: "#fff", fontSize: "2rem" }}
                      />
                    </Badge>
                  </IconButton>
                  <div>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => navigate("/login")}
                    >
                      Sign In
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.button}
                      onClick={() => navigate("/signup")}
                    >
                      New Workspace
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {lastSegment === "" && (
              <div className="bottomBar">
                <div className="iconAlign" onClick={handleDrawerToggle(true)}>
                  <MenuIcon style={{ fontSize: "1.5rem", color: "#fff" }} />
                  <p className="listItems">All Categories</p>
                </div>
                <div className="scrollableContainer">
                  {categoriesNames?.slice(0, 9).map((name) => (
                    <p
                      className="listItems"
                      style={isHovered === name ? signInTextHoverStyle : {}}
                      key={name}
                      onClick={() => handleCategoryChange(name)}
                      onMouseEnter={() => handleMouseEnter(name)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {name}
                    </p>
                  ))}
                </div>
              </div>
            )}
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" component="h2">
                  Are you sure you want to logout?
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <Button
                    onClick={handleCloseModal}
                    variant="contained"
                    sx={{ m: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    className="delButton"
                  >
                    Logout
                  </Button>
                </Typography>
              </Box>
            </Modal>
          </AppBar>
        </>
      )}
    </>
  );
}

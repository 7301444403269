import React, { useState, useEffect } from 'react';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch, Typography, Tooltip } from '@mui/material';
import { generateClient } from 'aws-amplify/api';
import { listUserInfos, listSubscriberCards, listUserBillings } from '../graphql/queries';
import { updateSubscriberCard, updateUserBilling } from '../graphql/mutations';
import { useDispatch } from 'react-redux';
import { setLoading, showToast } from '../redux/userSlice';
import { toast } from 'react-toastify';

const UserGatewayPreference = () => {
  const [users, setUsers] = useState([]);
  const client = generateClient();
  const dispatch = useDispatch();
  const [subscriberData, setSubscriberData] = useState(null);
  const [userBillingDetail, setUserBillingDetail] = React.useState(null);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");


  useEffect(() => {
    fetchUsers();
    fetchUserBillingDetail();
    fetchSubscriberData();
  }, []);
  const fetchAllUserBillings = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserBillings,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listUserBillings.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserBillings.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchAllUserSubscriber = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listSubscriberCards,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listSubscriberCards.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listSubscriberCards.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchUserBillingDetail = async () => {
    try {
      const result = await fetchAllUserBillings(selectedWorkspaceId);

      console.log(result, "result")
      setUserBillingDetail(result);

      //   dispatch(setLoading(false));
    } catch (error) {
      //   dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };
  const fetchUsers = async () => {
    try {
      dispatch(setLoading(true));

      const userInfoResult = await client.graphql({
        query: listUserInfos,
        variables: {
          limit: 1000 // Adjust this value based on your needs
        }
      });

      let subscriberCards = [];
      try {
        const subscriberCardResult = await client.graphql({
          query: listSubscriberCards,
          variables: {
            limit: 1000 // Adjust this value based on your needs
          }
        });
        subscriberCards = subscriberCardResult.data.listSubscriberCards.items.filter(card =>
          card && card.workspace_id && card.createdAt && card.updatedAt
        );
      } catch (subscriberCardError) {
        console.error('Error fetching subscriber cards:', subscriberCardError);
        if (subscriberCardError.errors) {
          subscriberCardError.errors.forEach(e => console.error('GraphQL error:', e.message));
        }
        dispatch(setLoading(false));
        // Continue with empty subscriberCards array
      }

      const userInfos = userInfoResult.data.listUserInfos.items;


      const combinedUsers = userInfos.map(user => {
        const subscriberCard = subscriberCards.find(card => card.workspace_id === user.workspace_id);
        return {
          ...user,
          stripeInitialized: !!subscriberCard,
          preferStripe: !!subscriberCard?.paymentMethod_Gateway,
          paymentProfileId: subscriberCard?.payment_profile_id || null,
          stripePaymentProfileId: subscriberCard?.stripe_payment_profile_id || null,
          subscriberCardId: subscriberCard?.id || null,
        };
      });

      setUsers(combinedUsers);
      dispatch(setLoading(false));
    } catch (error) {
      console.error('Error fetching users:', error);
      if (error.errors) {
        error.errors.forEach(e => console.error('GraphQL error:', e.message));
      }
    }
  };
  const fetchSubscriberData = async () => {
    try {

      const res = await fetchAllUserSubscriber(selectedWorkspaceId);
      setSubscriberData(res);
      setLoading(false);
    } catch (error) {
      console.error("Error creating todo:", error);
    }
  };

  const handleGatewayChange = async (userId, newPreferStripe) => {
    try {
      const userToUpdate = users.find(user => user.id === userId);
      console.log('userToUpdate', userToUpdate);
      if (!userToUpdate) {
        throw new Error('User not found');
      }

      // Prepare the update input for SubscriberCard
      let updateSubscriberCardInput = {
        id: subscriberData?.[0]?.id,
        stripe_customer_id: newPreferStripe ? userToUpdate.stripe_customer_id : null,
        payment_profile_id: newPreferStripe ? null : userToUpdate.paymentProfileId,
        stripe_payment_profile_id: newPreferStripe ? userToUpdate.stripePaymentProfileId : null,
      };

      // Prepare the update input for UserBilling
      let updateUserBillingInput = {
        id: userBillingDetail?.[0]?.id,
        paymentMethod_Gateway: newPreferStripe ? 'stripe' : 'authorized',
        workspace_id: userToUpdate.workspace_id
      };

      console.log('updateUserBillingInput', updateUserBillingInput);
      console.log('updateSubscriberCardInput', updateSubscriberCardInput);

      // If switching to Stripe and it's properly configured
      if (newPreferStripe && isStripeConfigured(userToUpdate)) {
        // Additional Stripe-specific logic can be added here if needed
      } else if (!newPreferStripe) {
        // Additional Authorized.net-specific logic can be added here if needed
      }

      // Update UserBilling
      const updateUserBillingData = await client.graphql({
        query: updateUserBilling,
        variables: { input: updateUserBillingInput },
      });

      if (updateUserBillingData.errors) {
        throw new Error(updateUserBillingData.errors[0].message);
      }

      // Update SubscriberCard
      if (userToUpdate.stripeInitialized) {
        const updateSubscriberCardData = await client.graphql({
          query: updateSubscriberCard,
          variables: { input: updateSubscriberCardInput }
        });

        if (updateSubscriberCardData.errors) {
          throw new Error(updateSubscriberCardData.errors[0].message);
        }
      }

      // Update local state
      setUsers(users.map(user =>
        user.id === userId ? { ...user, preferStripe: newPreferStripe } : user
      ));

      // Show success message
      toast.success(`Successfully switched payment gateway to ${newPreferStripe ? 'Stripe' : 'Authorized.net'}`);

    } catch (error) {
      console.error('Error updating gateway preference:', error);
      toast.error(`Failed to switch payment gateway: ${error.message}`);
    }
  };

  const isStripeConfigured = (user) => {
    console.log('user', user);
    return user.stripeInitialized && (user.stripe_customer_id || user.stripe_payment_profile_id);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Typography variant="h6" gutterBottom component="div" m={2} >
          User Gateway Preferences
        </Typography>
        <Table sx={{ minWidth: 650 }} aria-label="user gateway preference table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Workspace</TableCell>
              <TableCell>Stripe Status</TableCell>
              <TableCell>Preferred Gateway</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{`${user.user_name_first} ${user.user_name_second || ''}`}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.workspace}</TableCell>
                <TableCell>
                  {console.log(user.stripeInitialized, "user.stripeInitialized")}
                  {console.log(isStripeConfigured(user), "isStripeConfigured(user)")}
                  {console.log(user.preferStripe, "user.preferStripe")}
                  {console.log(user, "user")}
                  {user.stripeInitialized
                    ? (isStripeConfigured(user)
                      ? <Typography color="green">Configured</Typography>
                      : <Tooltip title="Please update your card information">
                        <Typography color="error">Not Configured</Typography>
                      </Tooltip>)
                    : 'Not Initialized'}
                </TableCell>
                <TableCell>
                  <Tooltip title={!isStripeConfigured(user) ? "Stripe not fully configured" : ""}>
                    <span>
                      <Switch
                        checked={isStripeConfigured(user)}
                        onChange={(e) => handleGatewayChange(user.id, e.target.checked)}
                        disabled={!isStripeConfigured(user)}
                        inputProps={{ 'aria-label': 'gateway preference' }}
                      />
                      {isStripeConfigured(user) ? 'Stripe' : 'Authorized.net'}
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserGatewayPreference;

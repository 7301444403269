import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SpeedIcon from "@mui/icons-material/Speed";
import GroupIcon from "@mui/icons-material/Group";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import WidgetsIcon from "@mui/icons-material/Widgets";
import "../../Css/MobileBottomDrawer.css";
import { useMediaQuery } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 999,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  bottomNav: {
    fontSize: "8px",
  },
  bottomNavAction: {
    padding: "0",
    width: "20%",
    "& .MuiBottomNavigationAction-label": {
      fontSize: "8px",
    },
    "& .MuiBottomNavigationAction-icon": {
      fontSize: "10px",
      width: "10px",
      height: "10px",
    },
  },
}));
export default function MobileBottomDrawer() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(0);

  const tabs = [
    { label: "Dashboard", value: "/home", icon: <SpeedIcon /> },
    { label: "Team", value: "/team", icon: <GroupIcon /> },
    {
      label: "Plans",
      value: "/subscription",
      icon: <WidgetsIcon />,
    },
    { label: "Shop", value: "/shop", icon: <ShoppingCartIcon /> },
    // { label: "Settings", value: "/profile-setting", icon: <SettingsIcon /> },
  ];

  const classes = useStyles();
  const location = useLocation();

  return (
    <>
      {isMobile && (
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          style={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            zIndex: 999,
            display: "none",
          }}
          className={classes.bottomNav}
          id="mobileBottomNav"
        >
          {tabs.map((tab, index) => (
            <BottomNavigationAction
              key={index}
              label={tab.label}
              value={tab.value}
              icon={tab.icon}
              component={Link}
              to={tab.value}
              className={classes.bottomNavAction}
            />
          ))}
        </BottomNavigation>
      )}
    </>
  );
}

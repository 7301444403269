import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { setLoading, showToast } from "../redux/userSlice";
import "../Css/Signup.css";
import { useMediaQuery } from "@mui/material";
const SignUp = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const cardStyle = {
    margin: isMobile ? "0rem auto" : "0rem auto",
    width: isMobile ? "90%" : "40%",
    textAlign: "center",
    height: !isMobile && "100vh",
    display: !isMobile && "flex",
    justifyContent: !isMobile && "center",
    flexDirection: !isMobile && "column",
    alignItems: !isMobile && "center",
  };

  const lineContianer = {
    position: "relative",
    textAlign: "center",
    marginTop: "2rem",
    width: "40%",
  };

  const hr = {
    border: "none",
    height: "1px",
    backgroundColor: "#C4C4C4",
    width: "100%",
  };

  const lineSpan = {
    position: "relative",
    top: "-23px",
    backgroundColor: "#F8F9FB",
    padding: "0px 12px",
    color: "#605E61",
    fontSize: "1.5rem",
  };

  const buttonStyle = {
    width: "100%",
    padding: "0.4rem 0rem",
    fontSize: "1rem",
    color: "#333",
    fontWeight: "bold",
    textTransform: "initial",
    border: "1px solid #C4C4C4",
    backgroundColor: "#fff",
  };

  const googleButton = {
    width: "40%",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const cartItems = state?.cartItems;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleContinue = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    if (!email.trim()) {
      setEmailError(true);
      setEmailErrorMessage("Email is required");
      dispatch(setLoading(false));
      return;
    }
    if (!isValidEmail(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please type valid email");
      dispatch(setLoading(false));
      return;
    }
    const domain = email.split('@')[1]; // 'rothbright.com'

    const workSpaceUrl = `www.${domain}`;

    dispatch(setLoading(false));
    if (cartItems) {
      navigate("/add-organization", {
        state: {
          email: email,
          cartItems: cartItems,
        },
      });
    } else {
      navigate("/add-organization", {
        state: {
          email: email,
          workSpaceUrl: workSpaceUrl,
        },
      });
    }
  };

  return (
    <>
      {/* <div style={{ margin: "2rem" }} className="header-image-signup-container">
        <img
          src="/faviconPNG.png"
          height={isMobile ? "50px" : "40px"}
          width={isMobile ? "100%" : "220px"}
          onClick={() => navigate("/login")}
          style={{ cursor: "pointer" }}
          className="header-image-signup"
        />
      </div> */}
      <form style={cardStyle} className="cardStyle-signup">
        <div style={{ marginTop: isMobile ? "1rem" : "0rem" }}>
          <h1
            style={{
              color: "#1D1C1D",
              fontSize: isMobile ? "1.5rem" : "3.5rem",
              margin: isMobile ? "0.5rem" : "1.5rem",
            }}
          >
            First, enter your email
          </h1>
          <p
            style={{
              fontSize: isMobile ? "0.9rem" : "1.5rem",
              color: "#605E61",
              margin: "0px",
            }}
          >
            We suggest using the <b>email address you use at work.</b>
          </p>
        </div>
        <div
          style={{
            marginTop: isMobile ? "1rem" : "2.5rem",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <TextField
            error={emailError}
            helperText={emailError ? emailErrorMessage : null}
            id="outlined-basic"
            label="Email"
            variant="outlined"
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
              setEmailErrorMessage("");
            }}
            value={email}
            style={{
              width: "100%",
              marginBottom: "1rem",
              backgroundColor: "#fff",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EmailIcon style={{ color: "#777" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            style={{
              width: isMobile ? "100%" : "40%",
              padding: "0.9rem 0rem",
              fontSize: "1rem",
              fontWeight: "bold",
              width: "100%",
            }}
            onClick={(e) => handleContinue(e)}
            type="submit"
          >
            Continue
          </Button>
          {/* <div style={lineContianer}>
          <hr style={hr} />
          <span style={lineSpan}>OR</span>
        </div>
        <div style={googleButton}>
          <Button variant="outlined" style={buttonStyle}>
            <span>
              <img
                src="/7611770.png"
                height="30px"
                width="30px"
                alt="Google Icon"
              />
            </span>
            Continue with Google
          </Button>
        </div> */}
          <div
            style={{
              cursor: "pointer",
              color: "#777",
              marginTop: isMobile ? "1rem" : "2.5rem",
              textAlign: "center",
            }}
            onClick={() => navigate("/login")}
          >
            Already using Rothbright?
          </div>
          {/* <div
          style={{
            cursor: "pointer",
            color: "#777",
            textAlign: "center",
            fontSize: "1.2rem",
            color: "#1976D2",
          }}
          //   onClick={() => navigate("/login")}
        >
          Sign in to an existing organization.
        </div> */}
          {!isMobile && (
            <div
              style={{
                cursor: "pointer",
                color: "#777",
                marginTop: "2.5rem",
                textAlign: "center",
              }}
            >
              <Link
                to={"https://rothbright.com/privacy-policy/"}
                target="__blank"
              >
                <span>Privacy Policy&nbsp;&nbsp;&nbsp;</span>
              </Link>{" "}
              <Link
                to={"https://rothbright.com/terms-and-conditions/"}
                target="__blank"
              >
                <span>Terms of Services &nbsp;&nbsp;&nbsp;</span>
              </Link>{" "}
              {/* <span role="img" aria-label="world">
                🌐
              </span>{" "}
              Change Region */}
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default SignUp;

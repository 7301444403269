import * as React from "react";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function ReviewOrder({
  customerDetail,
  cardData,
  buyNowProduct,
  totalPrice,
  productDetail,
}) {
  const maskDetails = (detail) => {
    const visibleDigits = 4;
    return (
      "*".repeat(detail.length - visibleDigits) + detail.slice(-visibleDigits)
    );
  };

  const payments = [
    { name: "Card holder:", detail: cardData?.holderName },
    { name: "Card number:", detail: maskDetails(cardData?.cardNumber) },
    {
      name: "Expiry date:",
      detail: `${cardData?.month}/${cardData?.year.slice(-2)}`,
    },
  ];

  return (
    <Stack spacing={2}>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1">
            {`$ ${Number(
              buyNowProduct?.length > 0
                ? buyNowProduct?.[0]?.price * buyNowProduct?.[0]?.quantity
                : totalPrice
            )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              .toLocaleString("en-US")}`}
          </Typography>
        </ListItem>
        {productDetail?.length > 0 &&
        productDetail?.some((item) => item.discount !== undefined) ? (
          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Discount" secondary="Offer" />
            <Typography variant="body2">
              {`$ ${Number(
                productDetail?.reduce((acc, item) => acc + item.discount, 0)
              )
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .toLocaleString("en-US")}`}
            </Typography>
          </ListItem>
        ) : (
          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Discount" secondary="Offer" />
            <Typography variant="body2">$ 0.00</Typography>
          </ListItem>
        )}
        {productDetail?.length > 0 &&
        productDetail.some((item) => item.discount !== undefined) ? (
          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Grand Total" />
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {`$ ${Number(
                totalPrice -
                  productDetail?.reduce((acc, item) => acc + item.discount, 0)
              )
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .toLocaleString("en-US")}`}
            </Typography>
          </ListItem>
        ) : buyNowProduct?.length > 0 ? (
          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Grand Total" />
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {`$ ${Number(
                buyNowProduct?.length > 0
                  ? buyNowProduct?.[0]?.price * buyNowProduct?.[0]?.quantity
                  : totalPrice
              )
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .toLocaleString("en-US")}`}
            </Typography>
          </ListItem>
        ) : (
          <ListItem sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Grand Total" />
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {`$ ${Number(totalPrice)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .toLocaleString("en-US")}`}
            </Typography>
          </ListItem>
        )}
      </List>
      <Divider />
      <Stack
        direction="column"
        divider={<Divider flexItem />}
        spacing={2}
        sx={{ my: 2 }}
      >
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Shipment details
          </Typography>
          <Typography gutterBottom>
            {customerDetail?.billing?.first_name}{" "}
            {customerDetail?.billing?.last_name}
          </Typography>
          <Typography color="text.secondary" gutterBottom>
            {customerDetail.billing.address_1}, {customerDetail.billing.city},{" "}
            {customerDetail.billing.country}
          </Typography>
          <Typography color="text.secondary" gutterBottom>
            {customerDetail.billing.state}
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2" gutterBottom>
            Payment details
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Stack
                  direction="row"
                  spacing={1}
                  useFlexGap
                  sx={{ width: "100%", mb: 1 }}
                >
                  <Typography variant="body1" color="text.secondary">
                    {payment.name}
                  </Typography>
                  <Typography variant="body2">{payment.detail}</Typography>
                </Stack>
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </Stack>
    </Stack>
  );
}

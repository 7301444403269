import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  Avatar,
  useMediaQuery,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
  Modal,
  CardContent,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import Footer from "../components/Footer";
import SpeedIcon from "@mui/icons-material/Speed";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LanguageIcon from "@mui/icons-material/Language";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import { AreaChart, Area, Line, YAxis, ResponsiveContainer } from "recharts";
import CircularGauge from "../components/CircularGauge";
import { Link, useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {
  createSamRushProject,
  getSamRushProject,
} from "../utills/SamRushApis/samrushApi";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Advisor from "../Assets/1721063651853.jpg";
import { generateClient } from "aws-amplify/api";
import {
  getWorkspaceInfo,
  listProductCards,
  listProductVariations,
  listSubscriptions,
} from "../graphql/queries";
import { LoadingScreen } from "../components/LoadingSiteAudit";
import RefreshIcon from "@mui/icons-material/Refresh";
import { updateWorkspaceInfo } from "../graphql/mutations";
import { useDispatch } from "react-redux";
import { showToast } from "../redux/userSlice";

const organizationCard = {
  margin: "0.7rem 0rem",
  padding: "1rem 1.5rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  minHeight: "300px",
  borderRadius: "10px",
};
const cardTitle = {
  fontSize: "1.5rem",
  margin: "0px",
  marginTop: "0rem",
};
const buttonStyle = {
  border: "none",
  color: "#000000de",
  textTransform: "initial",
  fontSize: "1rem",
  backgroundColor: "#F0F0F0",
};
const avsiorContainer = {
  display: "flex",
  justifyContent: "space-between",
};
const productImage = {
  width: "70%",
  objectFit: "contain",
  marginTop: 10,
};
const productName = {
  color: "#2895CF",
  margin: "0px",
  fontSize: "1.3rem",
};
const productContent = {
  margin: "1rem 1rem",
};
const productDesc = {
  fontSize: "1.1rem",
  fontWeight: "bold",
};
const productCard = {
  border: "1px solid #DCE5EC",
  borderRadius: "0px",
  cursor: "pointer",
  width: "60%",
};

const Performance = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);
  const [openModal, setOpenModal] = useState(false); // State to control modal visibility
  const [openModalForNewAudit, setOpenModalForNewAudit] = useState(false); // State to control modal visibility
  const [hasSubscription, setHasSubscription] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const navigate = useNavigate();
  const client = generateClient();
  const SemrushProjectName = localStorage.getItem("workSpaceName");
  const [workSpaceName, setWorkSpaceName] = useState(
    SemrushProjectName ? SemrushProjectName : "Rothbright"
  );
  const websiteUrl = localStorage.getItem("websiteUrl");
  const [workSpace, setWorkSpace] = useState("");

  useEffect(() => {
    const fetchWorkspaceInfo = async () => {
      try {
        if (!selectedWorkspaceId) return;

        const response = await client.graphql({
          query: getWorkspaceInfo,
          variables: {
            id: selectedWorkspaceId,
          },
        });

        const workspaceData = response.data.getWorkspaceInfo;
        setWorkSpace(workspaceData?.samrushDomainName);
        setWorkSpaceName(workspaceData?.name);
        if (workspaceData?.semrushProjectId) {
          setProjectId(workspaceData?.semrushProjectId);
          // Optionally update other states if needed
          // setWebsiteUrl(workspaceData.websiteUrl);
        } else {
          setIsLoading(false);
          return;
        }
      } catch (error) {
        console.error("Error fetching workspace info:", error);
      }
    };

    fetchWorkspaceInfo();
  }, [selectedWorkspaceId, projectId]); // Run when selectedWorkspaceId changes

  // Combine the two useEffects into one
  useEffect(() => {
    if (!projectId) return; // Don't run if no projectId

    let intervalId;

    const initFetch = async () => {
      // Initial fetch
      const isDataReady = await fetchData();

      if (!isDataReady) {
        // Only set up polling if data isn't ready
        intervalId = setInterval(async () => {
          const isDataReady = await fetchData();

          if (isDataReady || isDataReady == undefined) {
            // If we have complete data, clear the interval
            clearInterval(intervalId);
          }
        }, 10000); // 10 seconds
      }
    };

    initFetch();

    // Cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [projectId]); // Only depend on projectId

  // Remove the other useEffect that was watching projectData
  useEffect(() => {
    if (projectData) {
      setIsLoading(false);
      fetchPlansById();
    }
  }, [projectData]);

  //Get Semrush Project Id

  const fetchData = async () => {
    try {
      const _response = await getSamRushProject(projectId);
      let response = _response?.body;
      console.log("check status response ::", response);
      if (response?.data === "processing") {
        setIsLoading(true);
        return false; // Return false to indicate data is not ready
      }
      if (response?.message == 'Failed to fetch audit report') {
        setIsLoading(false);
        return;
      }
      if (response && response?.data?.id) {
        setProjectData(response?.data);

        setIsLoading(false);
        const hasExistingSubscription = await fetchSubscriptions();
        if (!hasExistingSubscription[0]) {
          handleOpenModal();
        }
        return true; // Return true to indicate data is ready
      }
    } catch (error) {
      console.error("Error fetching project data:", error);
      return false; // Return false on error to continue polling
    }
  };
  async function fetchProductVariationById(id) {
    let allItems = [];
    let nextToken = null;

    do {
      const getProductsData = await client.graphql({
        query: listProductCards,
        variables: {
          nextToken,
        },
      });
      const response = getProductsData.data.listProductCards.items;
      const item = response.find((item) => item.id === id);
      if (item) {
        return item; // Return the item immediately if found
      }
      allItems = [...allItems, ...response];
      nextToken = getProductsData.data.listProductVariations.nextToken;
    } while (nextToken);

    return null;
  }

  const fetchPlansById = async (products) => {
    try {
      const Semrush = "e981fc8a-af15-bef4-c816-c15a09cd08a6";
      const response = await fetchProductVariationById(Semrush);
      if (!response) {
        console.error("No product variation found with the given ID.");
        return;
      }
      const cleanedItem = { ...response };
      if (cleanedItem.categories) {
        cleanedItem.categories = JSON.parse(cleanedItem.categories);
      }
      if (cleanedItem.tags) {
        cleanedItem.tags = JSON.parse(cleanedItem.tags);
      }
      setProduct(cleanedItem);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  const fetchSubscriptionsData = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
        product_id: { eq: "e981fc8a-af15-bef4-c816-c15a09cd08a6" },
      },
      limit: 1000,
    };

    do {
      const getAddressData = await client.graphql({
        query: listSubscriptions,
        variables: {
          ...variables,
          nextToken: nextToken,
        },
      });

      const items = getAddressData.data.listSubscriptions.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listSubscriptions.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchSubscriptions = async () => {
    try {
      const res = await fetchSubscriptionsData(selectedWorkspaceId);

      setHasSubscription(res);
      return res;
    } catch (error) {
      console.error("Error creating todo:", error);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true); // Function to open modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Function to close modal
  };

  const handleClick = async () => {
    try {
      // Create SEMrush project
      const response = await createSamRushProject(workSpaceName, workSpace);
      console.warn("first api response ::", response);
      if (response?.body?.statusCode == 400) {
        dispatch(
          showToast({
            message: "Can't create project with this domain",
            type: "error",
          })
        );
        return;
      }
      // Update workspace info in database
      const updateResponse = await client.graphql({
        query: updateWorkspaceInfo,
        variables: {
          input: {
            id: selectedWorkspaceId,
            semrushProjectId: response?.body?.project_id,
            samrushDomainName: workSpace
          },
        },
      });

      if (updateResponse.data.updateWorkspaceInfo) {
        // Optionally update local state
        setProjectId(response?.body?.project_id);
        // setWorkSpace(response.project_id)

        // Refresh data without full page reload
        await fetchData(); // Your existing data fetch function
      }
    } catch (error) {
      console.error("Error updating workspace:", error);
      // Add proper error handling here
    }
  };

  // Updated data with API values
  const errorData = [
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
    { value: projectData?.errors || 0 },
  ];

  const warningData = [
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
    { value: projectData?.warnings || 0 },
  ];

  const noticeData = [
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
    { value: projectData?.notices || 0 },
  ];

  // Updated issues data from API
  const issuesData =
    projectData?.current_snapshot?.topIssues
      ?.map((issueId) => {
        const error = projectData?.current_snapshot?.errors.find(
          (e) => e.id === issueId
        );
        const warning = projectData?.current_snapshot?.warnings.find(
          (w) => w.id === issueId
        );

        if (error) {
          return {
            type: `Issue ID: ${issueId}`,
            count: error.count,
            severity: "errors",
          };
        }
        if (warning) {
          return {
            type: `Issue ID: ${issueId}`,
            count: warning.count,
            severity: "warnings",
          };
        }
        return null;
      })
      .filter(Boolean) || [];
  return (
    <Box sx={{ padding: 3, backgroundColor: "#f5f5f5", minHeight: "100vh" }}>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            width: "660px", // Increased width to accommodate two cards
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <img src="/faviconPNG.png" alt="Rothbright" height="30" />
            <CancelOutlinedIcon
              onClick={handleCloseModal}
              sx={{ cursor: "pointer", color: "#999999", fontSize: "32px" }}
            />
          </Box>
          <Divider sx={{ width: "100%", my: 2 }} />

          <Box sx={{ display: "flex", gap: 3 }}>
            {" "}
            {/* Container for the two cards */}
            {/* SEMrush Card */}
            <Card
              sx={{ flex: 1, border: "1px solid #DCE5EC", borderRadius: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={product?.image}
                      alt="SEMrush"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                    <Typography
                      variant="button"
                      sx={{
                        position: "absolute",
                        bottom: "50px", // Position from bottom
                        left: "50%",
                        transform: "translateX(-50%)", // Center horizontally
                        color: "#666666",
                        fontSize: "12px",
                        fontWeight: 700,
                        letterSpacing: "0.5px",
                        cursor: "pointer",
                        padding: "4px 8px",
                        textDecoration: "underline",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => navigate(`/shop/${product?.slug}`)}
                    >
                      VIEW DETAILS
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
            {/* Advisor Card */}
            <Card sx={{ flex: 1, p: 2, border: "none", boxShadow: "none" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Avatar src={Advisor} sx={{ width: 100, height: 100 }} />
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Marcus Frye
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    marcus@rothbright.com
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#00A651",
                    "&:hover": { backgroundColor: "#008C44" },
                    textTransform: "none",
                    py: 1.5,
                    mt: 2,
                  }}
                  onClick={() =>
                    window.open(
                      "https://calendly.com/d/cpng-nc4-t7n/rothbright-advisor-calendar",
                      "_blank"
                    )
                  }
                >
                  Connect with Advisor
                </Button>
              </Box>
            </Card>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openModalForNewAudit}
        onClose={() => setOpenModalForNewAudit(false)}
      >
        <Box
          sx={{
            width: "60%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2, // Optional: rounded corners
          }}
        >
          <h3 style={{ marginBottom: "1rem" }}>Enter Domain Name</h3>
          <TextField
            id="outlined-basic"
            label="Domain"
            variant="outlined"
            onChange={(e) => setWorkSpace(e.target.value)}
            value={workSpace}
            fullWidth
            sx={{
              backgroundColor: "#fff",
              marginBottom: "1rem",
            }}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={() => handleClick()} // Replace with your button logic
              sx={{ whiteSpace: "nowrap" }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>

      {projectId == null && (
        <h3>
          Your Rothbright Health Check has not been configured. Please enter your
          domain.
        </h3>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 3,
          padding: "20px",
        }}
      >
        {/* Title and Date Section */}
        <Box sx={{ flexShrink: 0 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              color: "#2c3e50",
              marginBottom: "4px",
            }}
          >
            Site Audit
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#94a3b8",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <AccessTimeIcon sx={{ fontSize: 16 }} />
            Checked on:{" "}
            {projectData?.current_snapshot?.finish_date
              ? new Date(
                projectData?.current_snapshot?.finish_date
              ).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
              : "Not checked yet"}
          </Typography>
        </Box>

        {/* URL TextField - Wrapped in Box to control width */}

        <Box sx={{ width: "20%", ml: "3%" }}>
          {" "}
          {/* Fixed width container */}
          <TextField
            value={workSpace}
            onChange={(e) => setWorkSpace(e.target.value)}
            variant="outlined"
            fullWidth
            label="Domain"
            sx={{
              borderRadius: 5,
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#f8fafc",
                "&:hover": {
                  backgroundColor: "#f1f5f9",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LanguageIcon sx={{ color: "#64748b" }} />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Regenerate Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClick()}
          startIcon={<RefreshIcon />}
          sx={{
            textTransform: "none",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#1e7ab0",
            },
            minWidth: "160px",
            height: "40px",
            "&.Mui-disabled": {
              backgroundColor: "#e0e0e0",
              color: "#9e9e9e",
            },
          }}
        >
          {isLoading || projectData?.status === "processing"
            ? "Processing..."
            : "Regenerate Report"}
        </Button>
        <Button
          sx={{ marginLeft: "auto" }}
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
        >
          {" "}
          {/* Updated onClick */}
          Have Questions?
        </Button>
      </Box>

      <Grid container spacing={3}>
        {isLoading && (
          <Grid item xs={12}>
            <LoadingScreen
              websiteUrl={workSpace}
              open={isLoading}
              onClose={() => setIsLoading(false)}
            />
          </Grid>
        )}

        {/* Site Health Gauge */}
        <Grid item xs={12} md={3} lg={3}>
          {isLoading ? null : (
            <Paper
              elevation={1}
              sx={{
                p: 2,
                height: "100%",
                backgroundColor: "white",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="h6">Site Health</Typography>
                <InfoIcon sx={{ color: "grey.500" }} />
              </Box>
              <CircularGauge
                value={projectData?.current_snapshot?.quality?.value || 0}
              />
            </Paper>
          )}
        </Grid>

        {/* Issues Table */}
        <Grid item xs={12} md={6} lg={9}>
          {isLoading ? null : (
            <Paper elevation={1} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Top Issues
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type of issues</TableCell>
                    <TableCell>Number of issues</TableCell>
                    <TableCell>About the issue</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {issuesData.map((issue, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {issue.type}
                        <span
                          style={{
                            marginLeft: "8px",
                            color:
                              issue.severity === "errors" ? "red" : "orange",
                            fontSize: "12px",
                          }}
                        >
                          {issue.severity}
                        </span>
                      </TableCell>
                      <TableCell>{issue.count}</TableCell>
                      <TableCell>
                        <Button size="small">Why and how to fix it</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Grid>

        {/* Statistics Cards */}
        {[1, 2, 3].map((item) => (
          <Grid item xs={12} md={4} key={item}>
            {isLoading ? (
              <Paper
                elevation={0}
                sx={{ p: 3, backgroundColor: "white", borderRadius: 2 }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Skeleton
                    variant="circular"
                    width={24}
                    height={24}
                    sx={{ mr: 1 }}
                  />
                  <Skeleton variant="text" width={80} height={32} />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Skeleton variant="text" width={120} height={48} />
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html:
                        item === 1
                          ? "We're analyzing errors..."
                          : item === 2
                            ? "Checking for warnings..."
                            : "Scanning for notices...",
                    }}
                    sx={{ color: "grey.500", fontSize: "0.875rem" }}
                  />
                </Box>
                <Skeleton variant="rectangular" height={80} />
              </Paper>
            ) : (
              <Paper
                elevation={0}
                sx={{ p: 3, backgroundColor: "white", borderRadius: 2 }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  {item === 1 ? (
                    <ErrorIcon sx={{ color: "#FF4444", mr: 1 }} />
                  ) : item === 2 ? (
                    <WarningIcon sx={{ color: "#FFA726", mr: 1 }} />
                  ) : (
                    <InfoIcon sx={{ color: "#2196F3", mr: 1 }} />
                  )}
                  <Typography variant="h6">
                    {item === 1
                      ? "Errors"
                      : item === 2
                        ? "Warnings"
                        : "Notices"}
                  </Typography>
                </Box>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {item === 1
                    ? projectData?.errors
                    : item === 2
                      ? projectData?.warnings
                      : projectData?.notices}
                  <span style={{ color: "#4CAF50", fontSize: "1rem" }}>
                    {item === 1
                      ? ` ${projectData?.errors_delta || 0}`
                      : item === 2
                        ? ` ${projectData?.warnings_delta || 0}`
                        : ` ${projectData?.notices_delta || 0}`}
                  </span>
                </Typography>
                <Box sx={{ position: "relative", height: 80 }}>
                  <Typography
                    variant="caption"
                    sx={{ position: "absolute", left: 0, top: 0 }}
                  >
                    {item === 1 ? "15" : item === 2 ? "395" : "221"}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ position: "absolute", left: 0, bottom: 0 }}
                  >
                    0
                  </Typography>
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      data={
                        item === 1
                          ? errorData
                          : item === 2
                            ? warningData
                            : noticeData
                      }
                      margin={{ top: 5, right: 5, bottom: 5, left: 15 }}
                    >
                      <YAxis
                        hide
                        domain={[0, item === 1 ? 15 : item === 2 ? 395 : 221]}
                      />
                      <Area
                        type="monotone"
                        dataKey="value"
                        fill={
                          item === 1
                            ? "#FF444420"
                            : item === 2
                              ? "#FFA72620"
                              : "#2196F320"
                        }
                        stroke={
                          item === 1
                            ? "#FF4444"
                            : item === 2
                              ? "#FFA726"
                              : "#2196F3"
                        }
                        strokeWidth={1}
                        dot={{
                          r: 2,
                          fill:
                            item === 1
                              ? "#FF4444"
                              : item === 2
                                ? "#FFA726"
                                : "#2196F3",
                          strokeWidth: 0,
                        }}
                        activeDot={{ r: 2 }}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>
              </Paper>
            )}
          </Grid>
        ))}
      </Grid>

      {/* Footer */}
      {!isMobile && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Performance;
import React, { useState } from "react";
import { Link } from "react-router-dom";

const footerText = {
  color: "#605E61",
  fontSize: "1.3rem",
};
const footerAlign = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1.5rem",
};
const signInTextStyle = {
  cursor: "pointer",
  color: "#605E61",
  transition: "color 0.3s",
};

const signInTextHoverStyle = {
  color: "#1976d2",
};

const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isServiceHovered, setIsServiceHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnterService = () => {
    setIsServiceHovered(true);
  };

  const handleMouseLeaveService = () => {
    setIsServiceHovered(false);
  };

  return (
    <>
      <div style={footerAlign}>
        <p style={footerText}>
          <Link to={"https://rothbright.com/privacy-policy/"} target="__blank">
            <span
              style={{
                ...signInTextStyle,
                ...(isHovered && signInTextHoverStyle),
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Privacy
            </span>
          </Link>{" "}
          &nbsp;&nbsp;&nbsp;{" "}
          <Link
            to={"https://rothbright.com/terms-and-conditions/"}
            target="__blank"
          >
            <span
              style={{
                ...signInTextStyle,
                ...(isServiceHovered && signInTextHoverStyle),
              }}
              onMouseEnter={handleMouseEnterService}
              onMouseLeave={handleMouseLeaveService}
            >
              Terms of Service
            </span>
          </Link>
        </p>
        <p style={footerText}>
          Copyright &copy; Rothbright Inc. All Rights Reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { generateClient } from "aws-amplify/api";
import { listCoupons, listProductCards } from "../../graphql/queries";
import {
  createCoupon,
  deleteCoupon,
  updateCoupon,
} from "../../graphql/mutations";
import { PulseLoader } from "react-spinners";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import uuid from "react-uuid";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { showToast } from "../../redux/userSlice";
import Footer from "../Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { DataGrid } from "@mui/x-data-grid";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const container = {
  margin: "0rem 1rem",
};
const organizationCard = {
  padding: "1.5rem 2rem",
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  minHeight: "68vh",
};

const AllCoupon = () => {
  const client = generateClient();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [eidtID, setEditID] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [productData, setProductData] = React.useState(null);
  const [couponData, setCouponData] = React.useState(null);
  const [idToDelete, setIdToDelete] = React.useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [productsError, setproductsError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [amountTypeError, setAmountTypeError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [percentError, setPercentError] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [couponForm, setCouponForm] = useState({
    code: "",
    type: "",
    description: "",
    amount: "",
    percent: "",
    never_expire: false,
    amount_type: "",
    expire_start_date: "",
    expire_end_date: "",
    product_id: [],
    allowed_email: [],
  });

  useEffect(() => {
    const productNames = couponForm?.product_id?.map(
      (productId) =>
        productData?.find((product) => product.id === productId).name
    );
    setSelectedProducts(productNames);
  }, [couponForm.product_id, productData]);

  const handleChange = (fieldName, value) => {
    if (fieldName === "product_id") {
      const selectedProducts = value.map((option) => option.id);
      setproductsError(false);
      setCouponForm({ ...couponForm, [fieldName]: selectedProducts });
    } else {
      setCouponForm({ ...couponForm, [fieldName]: value });
    }
  };

  const handleAddEmail = () => {
    if (newEmail?.trim() !== "") {
      setCouponForm({
        ...couponForm,
        allowed_email: [...(couponForm?.allowed_email || []), newEmail.trim()],
      });
      setNewEmail("");
    }
  };

  const handleDeleteEmail = (index) => {
    const updatedEmails = [...couponForm.allowed_email];
    updatedEmails.splice(index, 1);
    setCouponForm({ ...couponForm, allowed_email: updatedEmails });
  };

  function generateCouponCode() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let couponCode = "";
    for (let i = 0; i < 8; i++) {
      couponCode += characters.charAt(
        Math.floor(Math.random() * characters?.length)
      );
    }
    setCouponForm({
      ...couponForm,
      code: couponCode,
    });
    setCodeError(false);
  }

  const fetchProductsDetail = async () => {
    try {
      setLoader(true);
      const getProductData = await client.graphql({
        query: listProductCards,
      });

      const result = getProductData?.data?.listProductCards?.items;
      setProductData(result);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const fetchCouponDetails = async () => {
    try {
      setLoader(true);
      const getCoupons = await client.graphql({
        query: listCoupons,
      });

      const result = getCoupons?.data?.listCoupons?.items;
      setCouponData(result);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  React.useEffect(() => {
    fetchCouponDetails();
    fetchProductsDetail();
  }, []);

  const handleAddCoupon = async () => {
    if (couponData?.some((cod) => cod.code === couponForm.code)) {
      setOpen(false);
      setCouponForm({
        code: "",
        type: "",
        description: "",
        amount: "",
        percent: "",
        never_expire: false,
        expire_start_date: "",
        expire_end_date: "",
        product_id: [],
        allowed_email: [],
      });
      dispatch(
        showToast({ message: "Coupen code already exists", type: "error" })
      );
      return;
    }
    if (couponForm.code === "") {
      setCodeError(true);
      return;
    }
    if (couponForm.type === "") {
      setTypeError(true);
      return;
    }
    if (couponForm?.amount_type === "") {
      setAmountTypeError(true);
      return;
    }
    if (couponForm.amount_type === "Dollar off" && couponForm.amount === "") {
      setAmountError(true);
      return;
    }
    if (
      couponForm.amount_type === "Percentage off" &&
      couponForm.percent === ""
    ) {
      setPercentError(true);
      return;
    }
    if (!couponForm.never_expire && couponForm.expire_start_date === "") {
      setStartDateError(true);
      return;
    }
    if (!couponForm.never_expire && couponForm.expire_end_date === "") {
      setEndDateError(true);
      return;
    }
    if (
      couponForm.type === "Fixed product discount" &&
      couponForm?.product_id?.length === 0
    ) {
      setproductsError(true);
      return;
    }
    setOpen(false);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    let id;
    try {
      id = uuid();
      const detail = {
        id: id,
        code: couponForm.code,
        description: couponForm.description,
        type: couponForm.type,
        amount_type: couponForm.amount_type,
        amount: couponForm.amount,
        percent: couponForm.percent.replace(/\D/g, ""),
        never_expire: couponForm.never_expire,
        expire_start_date: couponForm.expire_start_date,
        expire_end_date: couponForm.expire_end_date,
        product_id: couponForm.product_id,
        allowed_email: couponForm.allowed_email,
        created_at: formattedDate,
      };
      const result = await client.graphql({
        query: createCoupon,
        variables: { input: detail },
      });
      await fetchCouponDetails();
      setCouponForm({
        code: "",
        type: "",
        description: "",
        amount: "",
        percent: "",
        never_expire: false,
        expire_start_date: "",
        expire_end_date: "",
        product_id: [],
        allowed_email: [],
      });
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleEditCoupon = async () => {
    if (couponForm.code === "") {
      setCodeError(true);
      return;
    }
    if (!couponForm.never_expire && couponForm.expire_start_date === "") {
      setStartDateError(true);
      return;
    }
    if (!couponForm.never_expire && couponForm.expire_end_date === "") {
      setEndDateError(true);
      return;
    }
    if (
      couponForm.type === "Fixed product discount" &&
      couponForm?.product_id?.length === 0
    ) {
      setproductsError(true);
      return;
    }
    setOpen(false);
    try {
      const detail = {
        id: eidtID,
        code: couponForm.code,
        description: couponForm.description,
        type: couponForm.type,
        amount_type: couponForm.amount_type,
        amount: couponForm.amount,
        percent: couponForm.percent.replace(/\D/g, ""),
        never_expire: couponForm.never_expire,
        expire_start_date: couponForm.expire_start_date,
        expire_end_date: couponForm.expire_end_date,
        product_id: couponForm.product_id,
        allowed_email: couponForm.allowed_email,
      };
      const result = await client.graphql({
        query: updateCoupon,
        variables: { input: detail },
      });
      await fetchCouponDetails();
      setCouponForm({
        code: "",
        type: "",
        description: "",
        amount: "",
        percent: "",
        never_expire: false,
        expire_start_date: "",
        expire_end_date: "",
        product_id: [],
        allowed_email: [],
      });
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleRemoveCoupon = async (id) => {
    setOpenDeleteModal(false);
    try {
      const variables = {
        input: {
          id: idToDelete,
        },
      };
      const result = await client.graphql({
        query: deleteCoupon,
        variables: variables,
      });
      dispatch(showToast({ message: "Deleted successfully", type: "success" }));
      await fetchCouponDetails();
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    setCouponForm({
      code: "",
      type: "",
      description: "",
      amount: "",
      percent: "",
      never_expire: false,
      expire_start_date: "",
      expire_end_date: "",
      product_id: [],
      allowed_email: [],
    });
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setIdToDelete("");
  };

  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setIdToDelete(id);
  };

  const handleClickOpenEdit = (item) => {
    setCouponForm(item);
    setEditID(item.id);
    const productNames = item?.product_id?.map(
      (productId) =>
        productData?.find((product) => product.id === productId)?.name
    );
    setSelectedProducts(productNames || []);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCouponForm({
      code: "",
      type: "",
      description: "",
      amount: "",
      percent: "",
      never_expire: false,
      expire_start_date: "",
      expire_end_date: "",
      product_id: [],
      allowed_email: [],
    });
    setSelectedProducts([]);
    setEditID("");
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: "code",
      headerName: "Code",
      width: 250,
      renderCell: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.row.code}</div>
      ),
    },
    {
      field: "type",
      headerName: "Coupon Type",
      width: 200,
      renderCell: (params) => <div>{params.row.type}</div>,
    },
    {
      field: "amount",
      headerName: "Amount/Percentage",
      width: 200,
      renderCell: (params) => (
        <>{params.row.amount ? params.row.amount : `${params.row.percent}%`}</>
      ),
    },
    {
      field: "expire_start_date",
      headerName: "Expiry start date",
      width: 150,
      renderCell: (params) => <div>{params.row.expire_start_date || "-"}</div>,
    },
    {
      field: "expire_end_date",
      headerName: "Expiry end date",
      width: 150,
      renderCell: (params) => <div>{params.row.expire_end_date || "-"}</div>,
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="inherit"
            onClick={() => handleClickOpenEdit(params.row)}
          >
            <EditRoundedIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 0,
      renderCell: (params) => (
        <>
          <IconButton
            style={{ color: "#E55A54" }}
            onClick={() => handleOpenDeleteModal(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const rows =
    couponData?.map((item) => ({
      id: item.id,
      allowed_email: item.allowed_email,
      amount: item.amount,
      amount_type: item.amount_type,
      code: item.code,
      description: item.description,
      expire_end_date: item.expire_end_date,
      expire_start_date: item.expire_start_date,
      never_expire: item.never_expire,
      percent: item.percent,
      product_id: item.product_id,
      type: item.type,
    })) || [];

  return (
    <>
      <div style={container}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card style={organizationCard}>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50vh",
                  }}
                >
                  <PulseLoader color="#ACD6EB" />
                </div>
              ) : (
                <DataGrid
                  rows={rows}
                  columns={columns.filter((column) => column.field !== "id")}
                  getRowId={(row) => row.id}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 7,
                      },
                    },
                  }}
                  pageSizeOptions={[7, 25, 50, 100]}
                  density="comfortable"
                  sx={{
                    border: 0,
                    borderColor: "primary.light",
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                  }}
                />
              )}
            </Card>
          </Grid>
        </Grid>
        <Dialog open={open} disableBackdropClick fullWidth maxWidth="md">
          <DialogTitle>{eidtID ? "Edit Coupon" : "Add new coupon"}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: "1rem" }}>
              You can {eidtID ? "edit" : "add"} a new coupon by filling these
              fields
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextField
                  error={codeError}
                  autoFocus
                  size="small"
                  name="code"
                  label="Coupon code"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={couponForm.code}
                  onChange={(e) => {
                    handleChange("code", e.target.value);
                    setCodeError(false);
                  }}
                />
              </Grid>
              <Grid item xs={3} style={{ textAlign: "center" }}>
                <Button
                  onClick={generateCouponCode}
                  variant="contained"
                  style={{ textTransform: "inherit" }}
                >
                  Generate Code
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={typeError}
                  select
                  size="small"
                  name="type"
                  fullWidth
                  label="Discount type"
                  value={couponForm.type}
                  onChange={(e) => {
                    setCouponForm({
                      ...couponForm,
                      type: e.target.value,
                      product_id: [],
                    });
                    setTypeError(false);
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: "300px",
                        },
                      },
                    },
                  }}
                >
                  {["Fixed cart discount", "Fixed product discount"].map(
                    (option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  //   error={error}
                  //   helperText={error ? "Name required" : null}
                  autoFocus
                  size="small"
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  value={couponForm.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={amountTypeError}
                  select
                  size="small"
                  name="amount_type"
                  fullWidth
                  label="Amount type"
                  value={couponForm.amount_type}
                  onChange={(e) => {
                    setCouponForm({
                      ...couponForm,
                      amount_type: e.target.value,
                      amount: "",
                      percent: "",
                    });
                    setAmountTypeError(false);
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: "300px",
                        },
                      },
                    },
                  }}
                >
                  {["Dollar off", "Percentage off"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {couponForm?.amount_type === "Percentage off" ? (
                <Grid item xs={12}>
                  <TextField
                    error={percentError}
                    autoFocus
                    size="small"
                    name="percent"
                    label="Coupon percentage (%)"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={couponForm.percent}
                    onChange={(e) => {
                      handleChange("percent", e.target.value);
                      setPercentError(false);
                    }}
                  />
                </Grid>
              ) : couponForm?.amount_type === "Dollar off" ? (
                <Grid item xs={12}>
                  <TextField
                    error={amountError}
                    autoFocus
                    size="small"
                    name="amount"
                    label="Coupon amount"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={couponForm.amount}
                    onChange={(e) => {
                      handleChange("amount", e.target.value);
                      setAmountError(false);
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ display: "inline-block" }}
                        checked={couponForm.never_expire}
                        onChange={(e) => {
                          setCouponForm({
                            ...couponForm,
                            never_expire: e.target.checked,
                            expire_start_date: "",
                            expire_end_date: "",
                          });
                          setStartDateError(false);
                          setEndDateError(false);
                        }}
                      />
                    }
                    label="Never expire"
                  />
                </FormGroup>
              </Grid>
              {!couponForm?.never_expire && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      error={startDateError}
                      size="small"
                      margin="normal"
                      fullWidth
                      name="startDate"
                      type="date"
                      style={{ margin: "0px" }}
                      value={couponForm.expire_start_date}
                      onChange={(e) => {
                        setCouponForm({
                          ...couponForm,
                          expire_start_date: e.target.value,
                        });
                        setStartDateError(false);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Effective Start Date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={endDateError}
                      size="small"
                      margin="normal"
                      fullWidth
                      name="expire_end_date"
                      type="date"
                      style={{ margin: "0px" }}
                      value={couponForm.expire_end_date}
                      onChange={(e) => {
                        setCouponForm({
                          ...couponForm,
                          expire_end_date: e.target.value,
                        });
                        setEndDateError(false);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Expiry End Date"
                    />
                  </Grid>
                </>
              )}
              {couponForm?.type === "Fixed product discount" && (
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={productData}
                    fullWidth
                    margin="normal"
                    name="product_id"
                    size="small"
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    onChange={(e, selectedOptions) =>
                      handleChange("product_id", selectedOptions)
                    }
                    value={selectedProducts?.map((name, index) => ({
                      id: couponForm?.product_id[index],
                      name,
                    }))}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        error={productsError}
                        {...params}
                        label="Select Product"
                        placeholder="Search for a product"
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  // error={error}
                  // helperText={error ? "Name required" : null}
                  autoFocus
                  size="small"
                  name="allowed_email"
                  label="Allowed emails"
                  type="text"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="outlined"
                          style={{ border: "none", textTransform: "inherit" }}
                          onClick={handleAddEmail}
                        >
                          Add
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  {couponForm?.allowed_email?.map((email, index) => (
                    <Chip
                      key={index}
                      label={email}
                      onDelete={() => handleDeleteEmail(index)}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={eidtID ? handleClose : handleClickClose}
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={eidtID ? handleEditCoupon : handleAddCoupon}
              style={{ textTransform: "inherit" }}
            >
              {eidtID ? "Update" : "Add Coupon"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDeleteModal} disableBackdropClick>
          <DialogTitle>Confirm Delete Coupon</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this coupon? This coupon will
              permanantly be removed.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleCloseDeleteModal}
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleRemoveCoupon}
              style={{ textTransform: "inherit" }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Fab
          sx={{
            position: "absolute",
            bottom: 30,
            right: 30,
            backgroundColor: "#1976d2",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
          aria-label={"Add"}
          onClick={handleClickOpen}
        >
          <AddIcon />
        </Fab>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AllCoupon;

import axios from "axios";
const api_key = "6920435c2e4da4e4301f9c4e957826ac";
const api_base_url =
    "https://tb25ps1gcl.execute-api.us-east-1.amazonaws.com/staging";

export const createSamRushProject = async (workSpace, workSpaceUrl) => {
    const url = `${api_base_url}/create_and_audit_project`;
    const payload = {
        project_name: workSpace,
        url: workSpaceUrl,
        path: "create_and_audit_project",
    };
    const headers = {
        "Content-Type": "application/json",
    };

    try {
        console.log("Sending payload:", payload);
        const response = await axios.post(url, JSON.stringify(payload), {
            headers,
        });
        return response.data;
    } catch (error) {
        console.error("Error creating SEMrush project:", error.response || error);
        throw error;
    }
};

export const getSamRushProject = async (projectId) => {
    const url = `${api_base_url}/create_and_audit_project`;
    const payload = {
        project_id: projectId,
        path: "fetch_site_audit_report",
    };
    const headers = {
        "Content-Type": "application/json",
    };

    try {
        const response = await axios.post(url, payload, { headers });

        return response.data;
    } catch (error) {
        return error;
    }
};
export const createSiteAudit = async (projectId, workSpaceUrl) => {
    const payload = {
        domain: workSpaceUrl,
        scheduleDay: 1,
        notify: false,
        pageLimit: 100,
        userAgentType: 2,
        crawlSubdomains: true,
        respectCrawlDelay: false,
    };

    const url = `https://api.semrush.com/management/v1/projects/${projectId}/siteaudit/enable?key=${api_key}`;

    try {
        const response = await axios.post(url, payload, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error:", error);
    }
};

export const launchSiteAudit = async (projectId) => {
    const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";
    const apiUrl = `https://api.semrush.com/reports/v1/projects/${projectId}/siteaudit/launch?key=${api_key}`;
    const urlWithProxy = CORS_PROXY + apiUrl;
    const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    };
    try {
        const response = await axios.post(apiUrl, {}, { headers });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const getSnapshotSiteAudit = async (projectId) => {
    const url = `https://api.semrush.com/reports/v1/projects/${projectId}/siteaudit/snapshot?key=${api_key}`;
    const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    };
    try {
        const response = await axios.get(url, { headers });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
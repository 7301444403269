import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/faviconPNG.png";
import { LocalMall } from "@mui/icons-material";
import { selectProductCount, setProductCount } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import ShopFooter from "../components/ShopFooter";
import tagManagerEvents from "./GoogleTagManager";
import { useMediaQuery } from "@mui/material";
import ExternalMobileAppBar from "../components/Common/ExternalMobileAppBar";
import CartMobileResponsive from "../components/Common/CartMobileResponsive";
import UnAuthenticatedAppBarComponent from "../components/unAuthenticatedAppBar";
import "../Css/cart-item.css";
const CartScreen = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const productCount = useSelector(selectProductCount);

  const appBarContainer = {
    margin: "6rem 4rem",
  };
  const container = {
    margin: "1.5rem 4rem",
  };
  const title = {
    fontSize: "2rem",
    marginTop: "1rem",
    marginBottom: "1rem",
  };
  const organizationCard = {
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    borderRadius: "10px",
    padding: "1.5rem 2rem",
    minHeight: "60vh",
    maxHeight: "60vh",
    overflowY: "auto",
    scrollbarWidth: "thin",
    position: "relative",
  };
  const headingAlign = {
    display: "flex",
    justifyContent: "space-between",
    margin: "0rem 3rem",
  };
  const rowAlign = {
    display: "flex",
    justifyContent: "space-between",
    margin: props.isCartPressed ? "1rem 0rem" : "1rem 3rem",
  };
  const textALign = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const counterContainer = {
    display: "flex",
    alignItems: "center",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productDetail, setProductDetail] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [expanded, setExpanded] = React.useState(true);

  React.useEffect(() => {
    document.title = "Cart Item";
    tagManagerEvents("cartItemPage");
    const localProductData = localStorage.getItem("productCart");
    const productCart = JSON.parse(localProductData);
    setProductDetail(productCart);
    calculateTotalPrice(productCart);
    dispatch(setProductCount(productCart));
  }, []);

  const calculateTotalPrice = (cartItems) => {
    const total = cartItems?.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    setTotalPrice(total);
  };

  const handleIncrement = (index) => {
    const updatedProductDetail = [...productDetail];
    const updatedProduct = { ...updatedProductDetail[index] };
    updatedProduct.quantity += 1;
    updatedProductDetail[index] = updatedProduct;
    setProductDetail(updatedProductDetail);
    updateLocalStorage(updatedProductDetail);
    dispatch(setProductCount(updatedProductDetail));
    calculateTotalPrice(updatedProductDetail);
  };

  const handleDecrement = (index) => {
    if (productDetail[index].quantity > 1) {
      const updatedProductDetail = [...productDetail];
      const updatedProduct = { ...updatedProductDetail[index] };
      updatedProduct.quantity -= 1;
      updatedProductDetail[index] = updatedProduct;
      setProductDetail(updatedProductDetail);
      updateLocalStorage(updatedProductDetail);
      dispatch(setProductCount(updatedProductDetail));
      calculateTotalPrice(updatedProductDetail);
    }
  };

  const updateLocalStorage = (updatedProductDetail) => {
    localStorage.setItem("productCart", JSON.stringify(updatedProductDetail));
    if (props.updateCartItems) {
      props.updateCartItems(updatedProductDetail);
    }
  };

  const handleDelete = (index) => {
    try {
      const updatedProductDetail = productDetail.filter((_, i) => i !== index);
      setProductDetail(updatedProductDetail);
      updateLocalStorage(updatedProductDetail);
      dispatch(setProductCount(updatedProductDetail));
      calculateTotalPrice(updatedProductDetail);
    } catch (error) {
      console.error("Error in handleDelete:", error);
    }
  };

  const handleBillingData = () => {
    if (!productDetail) {
      return;
    } else {
      navigate("/billings", {
        state: {
          productDetail: productDetail,
          totalPrice: totalPrice,
        },
      });
    }
    tagManagerEvents("checkoutButtonPressed");
  };

  const handleChangeAccordianButton = (panel) => (event, isExpanded) => {
    setExpanded(!expanded);
  };
  const getTotalItems = () => {
    return productCount?.reduce((total, item) => total + item.quantity, 0);
  };
  const getTotalAmount = () => {
    let _totalPrice = productCount?.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    return _totalPrice;
  };
  return (
    <>
      {isMobile ? (
        <>
          <ExternalMobileAppBar productCount={getTotalItems()} />
          <CartMobileResponsive
            expanded={expanded}
            productDetail={productDetail}
            handleBillingData={handleBillingData}
            handleChangeAccordianButton={handleChangeAccordianButton}
            productCount={productCount}
            getTotalAmount={getTotalAmount}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            totalPrice={totalPrice}
            showPromoCode={false}
            handleDelete={handleDelete}
          />
        </>
      ) : (
        <div style={container}>
          <div style={appBarContainer}>
            <UnAuthenticatedAppBarComponent
              getTotalItems={getTotalItems}
              onHandleCartPressed={props.onHandleCartPressed}
            />
          </div>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div>
                <div>
                  <p style={title}></p>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Grid container spacing={1.5} style={{ fontSize: "0.9rem" }}>
                <Grid item xs={9}>
                  <Card style={organizationCard}>
                    <div style={headingAlign} className="headAlign-cart">
                      <Grid container spacing={0}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={3} style={textALign}>
                          <h4>Product</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Plan</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Quantity</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Price</h4>
                        </Grid>
                        <Grid item xs={1.5} style={textALign}>
                          <h4>Total</h4>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </div>
                    <Divider light />
                    {productDetail?.length
                      ? productDetail?.map((item, index) => (
                          <>
                            <div style={rowAlign} className="rowAlign-cart">
                              <Grid container spacing={0}>
                                <Grid item xs={2} style={textALign}>
                                  <div
                                    style={{ display: "flex", gap: "0.5rem" }}
                                  >
                                    <img
                                      src={item?.image}
                                      height="100px"
                                      width="80px"
                                      style={{
                                        objectFit: "cover",
                                        borderRadius: "3px",
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={3} style={textALign}>
                                  <p>{item?.name}</p>
                                </Grid>
                                <Grid item xs={1.5} style={textALign}>
                                  {item?.plan ? (
                                    <p>{item?.plan}</p>
                                  ) : (
                                    <p>Basic</p>
                                  )}
                                </Grid>
                                <Grid item xs={1.5} style={textALign}>
                                  <div style={counterContainer}>
                                    <IconButton
                                      aria-label="delete"
                                      size="large"
                                      onClick={() => handleDecrement(index)}
                                    >
                                      <RemoveCircleOutlinedIcon fontSize="small" />
                                    </IconButton>
                                    <p>
                                      <b>{item.quantity}</b>
                                    </p>
                                    <IconButton
                                      aria-label="delete"
                                      size="large"
                                      onClick={() => handleIncrement(index)}
                                    >
                                      <AddCircleOutlinedIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                </Grid>
                                <Grid item xs={1.5} style={textALign}>
                                  <p>{`$${Number(item.price)
                                    .toFixed(0)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    .toLocaleString("en-US")}`}</p>
                                </Grid>
                                <Grid item xs={1.5} style={textALign}>
                                  <p>
                                    <b>{`$${Number(item.price * item.quantity)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      .toLocaleString("en-US")}`}</b>
                                  </p>
                                </Grid>
                                <Grid item xs={1} style={textALign}>
                                  <IconButton
                                    onClick={() => handleDelete(index)}
                                  >
                                    <CancelIcon style={{ cursor: "pointer" }} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </div>
                            <Divider light />
                          </>
                        ))
                      : null}
                  </Card>
                </Grid>
                <Grid item xs={3}>
                  <Card style={organizationCard}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          ...title,
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Order Summary
                      </p>
                    </div>
                    <Divider light style={{ marginBottom: "2rem" }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Items</p>
                      <p>
                        <b>{productDetail?.length}</b>
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>Total Price</p>
                      <p>
                        <b>
                          {`$${Number(totalPrice)
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            .toLocaleString("en-US")}`}
                        </b>
                      </p>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        style={{
                          width: "90%",
                          padding: "0.5rem 1rem",
                          fontSize: "1rem",
                          textTransform: "initial",
                          marginTop: "3rem",
                          position: "absolute",
                          bottom: 20,
                          right: 27,
                        }}
                        className="checkout-btn"
                        onClick={handleBillingData}
                      >
                        Checkout
                      </Button>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {
            <div>
              <ShopFooter />
            </div>
          }
        </div>
      )}
    </>
  );
};

export default CartScreen;

import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch } from "react-redux";
import { setLoading, showToast } from "../redux/userSlice";
import {
  createUserBilling,
  createUserInfo,
  updateTeamInvitation,
  updateUserInfo,
  updateWorkspaceInfo,
  updateWorkspaceTeam,
} from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import {
  getUserInfo,
  listUserInfos,
  listWorkspaceInfos,
  listWorkspaceTeams,
} from "../graphql/queries";
import uuid from "react-uuid";

const container = {
  margin: "10rem auto",
  width: "40%",
  textAlign: "center",
};

const TeamUserLogin = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const client = generateClient();
  const { state } = useLocation();
  const WsID = state?.WsID;
  const invitationDetail = state?.invitationDetail;
  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastError, setLastError] = useState(false);
  const [firstError, setFirstError] = useState(false);
  const [lastErrorMsg, setLastErrorMsg] = useState("");
  const [firstErrorMsg, setFirstErrorMsg] = useState("");
  const [servererror, setServerError] = useState(false);
  const [servererrorMessage, setServerErrorMessage] = useState("");

  const removeInvitation = async () => {
    const updatedResult = await client.graphql({
      query: updateTeamInvitation,
      variables: {
        input: {
          id: invitationDetail?.[0]?.id,
          status: false,
        },
      },
    });
  };

  const postUserData = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    if (!firstName && !lastName) {
      setFirstError(true);
      setFirstErrorMsg("First name required");
      setLastError(true);
      setLastErrorMsg("Last name required");
      dispatch(setLoading(false));
      return;
    }
    if (!firstName) {
      setFirstError(true);
      setFirstErrorMsg("First name required");
      dispatch(setLoading(false));
      return;
    }
    if (!lastName) {
      setLastError(true);
      setLastErrorMsg("Last name required");
      dispatch(setLoading(false));
      return;
    }
    try {
      let userId = uuid();
      let userBillingId = uuid();
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      const variables = {
        filter: {
          id: {
            eq: WsID,
          },
        },
      };

      const getWorkspaceData = await client.graphql({
        query: listWorkspaceInfos,
        variables: variables,
      });

      const userWorspaceID =
        getWorkspaceData?.data?.listWorkspaceInfos?.items[0].user_id;

      const updatedUserIdis = [...userWorspaceID, userData.sub];
      const userResult = await client.graphql({
        query: updateWorkspaceInfo,
        variables: {
          input: {
            id: WsID,
            user_id: updatedUserIdis,
          },
        },
      });

      if (userResult) {
        const workspaceName =
          getWorkspaceData?.data?.listWorkspaceInfos?.items[0].name;
        const variables = {
          filter: {
            user_id: {
              eq: userData.sub,
            },
          },
        };

        const getUserData = await client.graphql({
          query: listUserInfos,
          variables: variables,
        });

        const userDetail = getUserData?.data?.listUserInfos?.items[0];
        const newUserDetail = {
          id: userId,
          user_id: userDetail.user_id,
          user_name_first: firstName,
          user_name_second: lastName,
          display_picture: userDetail.display_picture,
          email: userDetail.email,
          role: invitationDetail?.[0]?.role,
          joined_at: formattedDate,
          workspace: workspaceName,
          workspaceteamID: userDetail.workspaceteamID,
          workspace_id: selectedWorkspaceId,
          subscription_id: userDetail.subscription_id,
        };

        const result = await client.graphql({
          query: createUserInfo,
          variables: { input: newUserDetail },
        });

        // const variable = {
        //   id: userBillingId,
        //   workspace_id: selectedWorkspaceId,
        //   card_number: "",
        //   expiry_month: "",
        //   expiry_year: "",
        //   cvc: "",
        //   first_name: firstName,
        //   last_name: lastName,
        //   email: userDetail.email,
        //   city: "",
        //   country: "",
        //   postal_code: "",
        //   state: "",
        // };

        // const updateUserBillingData = await client.graphql({
        //   query: createUserBilling,
        //   variables: { input: variable },
        // });

        if (result) {
          const teamid = userResult?.data?.updateWorkspaceInfo?.workspaceteamID;
          const variables = {
            filter: {
              id: {
                eq: teamid,
              },
            },
          };

          const getTeamData = await client.graphql({
            query: listWorkspaceTeams,
            variables: variables,
          });

          const userTeamID =
            getTeamData?.data?.listWorkspaceTeams?.items[0].user_id;
          const updatedUserIdis = [...userTeamID, userData.sub];
          const userTeamResult = await client.graphql({
            query: updateWorkspaceTeam,
            variables: {
              input: {
                id: teamid,
                user_id: updatedUserIdis,
              },
            },
          });
          if (userTeamResult) {
            removeInvitation();
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...userData,
                role: invitationDetail?.[0]?.role,
              })
            );
            navigate("/home");
          }
        }
      }

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      setServerError(true);
      setServerErrorMessage(error.message);
      console.error("Error updating workspace_ids:", error);
    }
  };

  return (
    <>
      <form
        style={{
          ...container,
          margin: isMobile ? "0px 1rem" : container.margin,
          width: isMobile ? "95%" : container.width,
        }}
      >
        <div style={{ margin: "1rem 0rem", textAlign: "center" }}>
          <img
            src="/faviconPNG.png"
            height={isMobile ? "60px" : "70px"}
            width={isMobile ? "350px" : "430px"}
          />
        </div>
        <div style={{ marginTop: "2rem" }}>
          <h1
            style={{
              color: "#1D1C1D",
              fontSize: isMobile ? "3rem" : "4rem",
              margin: "0rem",
            }}
          >
            Create owner
          </h1>
          <p
            style={{
              fontSize: isMobile ? "1.2rem" : "1.5rem",
              color: "#605E61",
              margin: "0px",
            }}
          >
            Set up your name to join the Team and manage your projects
          </p>
        </div>
        <Grid container spacing={0} style={{ marginTop: "4rem" }}>
          <Grid item xs={12} style={{ display: "flex", gap: "1rem" }}>
            <Grid item xs={6}>
              <TextField
                error={firstError}
                helperText={firstError ? firstErrorMsg : null}
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstError(false);
                  setFirstErrorMsg("");
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={firstName}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon style={{ color: "#777" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                error={lastError}
                helperText={lastError ? lastErrorMsg : null}
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                onChange={(e) => {
                  setLastName(e.target.value);
                  setLastError(false);
                  setLastErrorMsg("");
                  setServerError(false);
                  setServerErrorMessage("");
                }}
                value={lastName}
                style={{
                  width: "100%",
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon style={{ color: "#777" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {servererror ? (
          <div style={{ marginBottom: "0.7rem" }}>
            <Alert severity="error">{servererrorMessage}</Alert>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "100%",
              padding: "0.9rem 0rem",
              fontSize: "1rem",
              fontWeight: "bold",
              marginTop: "0.5rem",
            }}
            onClick={(e) => postUserData(e)}
            type="submit"
          >
            Continue
          </Button>
          {/* <div
            style={{
              color: "#777",
              marginTop: "4rem",
              textAlign: "center",
            }}
          >
            By creating an account you agree to our{" "}
            <span
              style={{
                cursor: "pointer",
                textAlign: "center",
                color: "#1976D2",
              }}
            >
              Terms of Services
            </span>{" "}
            and{" "}
            <span
              style={{
                cursor: "pointer",
                textAlign: "center",
                color: "#1976D2",
              }}
            >
              Privacy Policy
            </span>
          </div> */}
        </div>
      </form>
    </>
  );
};

export default TeamUserLogin;

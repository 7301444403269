import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Menu, MenuItem, useMediaQuery, Badge, Avatar } from "@mui/material";
import { signOut } from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import {
  getWorkspaceInfo,
  listCartItems,
  listUserInfos,
  listWorkspaceInfos,
} from "../../graphql/queries";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  selectWorkspaceDetails,
  setLoading,
  setWorkspaceDetails,
  showToast,
  selectProductCount,
  setProductCount,
} from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import { alpha } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SettingsIcon from "@mui/icons-material/Settings";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Logo from "../../Assets/faviconPNG.png";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    minWidth: 250,
    color: "#fff",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    backgroundColor: "#232f3e",
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MobileAppBar(props) {
  const { _window } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const client = generateClient();
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [userDetail, setUserDetail] = React.useState(null);
  const [workspaceDetail, setWorkspaceDetail] = React.useState(null);
  const [cartQuantity, setCartQuantity] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [allWorkspaces, setAllWorkspaces] = React.useState(null);
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const productCountCart = useSelector(selectProductCount);
  const workspaceInfo = useSelector(selectWorkspaceDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openDropdown = Boolean(anchorEl);
  const [displayPicture, setDisplayPicture] = React.useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const localProductData = localStorage.getItem("productCart");
    const productCart = JSON.parse(localProductData);
    let sum = 0;
    if (productCart) {
      sum = productCart.reduce((acc, item) => acc + item.quantity, 0);
    }
    setCartQuantity(sum);
    dispatch(setProductCount(productCart));
  }, [dispatch, localStorage.getItem("productCart")]);

  React.useEffect(() => {
    const fetchCartItems = async () => {
      const variables = {
        filter: {
          user_id: {
            eq: userData.sub,
          },
        },
      };

      const getCartItems = await client.graphql({
        query: listCartItems,
        variables: variables,
      });
      const res = getCartItems.data.listCartItems.items;
      const result = res?.filter(
        (id) => id.workspace_id === selectedWorkspaceId
      );
      let sum = 0;
      if (result) {
        sum = result.reduce((acc, item) => acc + +item.quantity, 0);
      }
      setCartQuantity(sum);
    };
    fetchCartItems();
  }, [dispatch, productCountCart]);

  React.useEffect(() => {
    fetchData();
    fetchWorkspaceData();
  }, []);

  const fetchData = async () => {
    try {
      dispatch(setLoading(true));
      const variables = {
        filter: {
          user_id: {
            eq: userData?.sub,
          },
        },
      };

      const getUserData = await client.graphql({
        query: listUserInfos,
        variables: variables,
      });

      const result = getUserData?.data?.listUserInfos?.items;
      const single = result?.filter(
        (user) => user.workspace_id === selectedWorkspaceId
      );
      setDisplayPicture(single);
      setUserDetail(result);
      if (result) {
        if (userData?.role === "super_admin") {
          const getAllWorkspaces = await client.graphql({
            query: listWorkspaceInfos,
          });
          const wsResult = getAllWorkspaces?.data?.listWorkspaceInfos?.items;
          const filtered = wsResult?.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setAllWorkspaces(filtered);
        } else {
          const workspaceIds = result?.map((id) => id.workspace_id);
          if (workspaceIds) {
            const userPromises = workspaceIds.map(async (wsInfo) => {
              const response = await client.graphql({
                query: getWorkspaceInfo,
                variables: { id: wsInfo },
              });
              return response?.data?.getWorkspaceInfo;
            });
            const WSdata = await Promise.all(userPromises);
            const flatArray = WSdata.flat();
            setAllWorkspaces(flatArray);
          }
        }
        dispatch(setLoading(false));
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const fetchWorkspaceData = async () => {
    try {
      dispatch(setLoading(true));
      const variables = {
        filter: {
          id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getWorkspaceData = await client.graphql({
        query: listWorkspaceInfos,
        variables: variables,
      });
      const result = getWorkspaceData.data.listWorkspaceInfos.items;
      setWorkspaceDetail(result);
      dispatch(setWorkspaceDetails(result));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  async function handleSignOut() {
    try {
      await signOut();
      localStorage.clear();
      navigate("/login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  async function switchWorkspace(id) {
    localStorage.setItem("selectedWorkspaceId", id);
    if (userData?.role !== "super_admin") {
      const single = userDetail?.filter((user) => user.workspace_id === id);
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...userData,
          role: single?.[0]?.role,
        })
      );
    }
    navigate("/home");
    window.location.reload();
  }

  const handleItemClick = (index, text) => {
    setSelectedIndex(index);
    if (text === "Profile") {
      navigate("/profile-setting");
    } else if (text === "Cart") {
      navigate("/cart");
    } else if (text === "Settings") {
      navigate("/profile-setting");
    } else if (text === "My Links") {
      navigate("/links");
    } else if (text === "Logout") {
      handleSignOut();
    }
    handleDrawerToggle();
  };
  const container =
    _window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          style={{
            height: "50px",
            width: "190px",
            padding: 10,
          }}
        />
      </div>
      <List
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 10,
          height: "10%",
          marginLeft: 0,
        }}
      >
        {["My Links", "Cart", "Settings", "Profile", "Logout"].map(
          (text, index) => (
            <ListItem
              key={text}
              disablePadding
              button
              onClick={() => handleItemClick(index, text)}
            >
              <ListItemButton
                sx={{ borderRadius: "8px" }}
                selected={selectedIndex === index}
              >
                <ListItemIcon>
                  {index === 0 ? (
                    <OpenInNewIcon style={{ fontSize: "2rem" }} />
                  ) : index === 1 ? (
                    <Badge
                      color="error"
                      badgeContent={cartQuantity}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <ShoppingCartIcon style={{ fontSize: "2rem" }} />
                    </Badge>
                  ) : index === 2 ? (
                    <SettingsIcon style={{ fontSize: "2rem" }} />
                  ) : index === 3 ? (
                    <Avatar
                      src={displayPicture?.[0]?.display_picture}
                      sx={{ width: "40px", height: "40px" }}
                    />
                  ) : (
                    <LogoutIcon style={{ fontSize: "2rem" }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </>
  );
  return (
    <div>
      <CssBaseline />
      {isMobile && (
        <AppBar position="fixed" open={open} sx={{ backgroundColor: "black" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              // onClick={handleDrawerOpen}
              onClick={handleDrawerToggle}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={workspaceDetail?.[0]?.logo}
                    alt="Logo"
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "10px",
                      backgroundColor: "#dddddd",
                      border: "2px solid #fff",
                      objectFit: "cover",
                    }}
                    id="demo-customized-button"
                    aria-controls={
                      openDropdown ? "demo-customized-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openDropdown ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={(event) => handleClick(event)}
                  />
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={openDropdown}
                    onClose={handleClose}
                    style={{ height: "60%" }}
                  >
                    {allWorkspaces?.length > 0 &&
                      allWorkspaces?.map((item) => (
                        <>
                          <MenuItem
                            onClick={() => switchWorkspace(item.id)}
                            disableRipple
                            key={item.id}
                            style={{ width: "22rem" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={item.logo}
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "10px",
                                    marginRight: "0.7rem",
                                    border: "2px solid #fff",
                                    objectFit: "cover",
                                  }}
                                />
                                <span
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "16rem",
                                  }}
                                >
                                  {item.name}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {selectedWorkspaceId === item.id ? (
                                  <DoneIcon
                                    style={{
                                      margin: "0 auto",
                                      color: "#fff",
                                      fontSize: "1.3rem",
                                    }}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </MenuItem>
                        </>
                      ))}
                  </StyledMenu>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    fontSize: "1rem",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        margin: "0px",
                        textAlign: "start",
                        fontSize: "1.2rem",
                      }}
                    >
                      {workspaceInfo?.[0]?.name}
                    </h3>{" "}
                  </div>
                  <div style={{ fontSize: "0.9rem" }}>
                    {workspaceInfo?.[0]?.url}
                  </div>
                </div>
              </div>
              <div>
                <IconButton color="inherit" onClick={() => navigate("/cart")}>
                  <Badge
                    color="error"
                    badgeContent={cartQuantity}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <ShoppingCartIcon
                      style={{ color: "#fff", fontSize: "2rem" }}
                    />
                  </Badge>
                </IconButton>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      )}

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
}

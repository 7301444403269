import React from "react";
import { Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

function NonProtected({ children }) {
  const token = localStorage.getItem("user");

  if (token) {
    return <Navigate to="/choose-organization" replace />;
  }
  const defaultTheme = createTheme();

  return (
    <>
      <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
    </>
  );
}
export default NonProtected;

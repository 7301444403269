import TagManager from "react-gtm-module";
const tagManagerEvents = (eventName, product_name_variable) => {
  // console.warn(
  //   "this is event name",
  //   eventName,
  //   "variable ",
  //   product_name_variable
  // );
  const tagManagerArgs = {
    dataLayer: {
      event: eventName,
      product_name_variable: product_name_variable,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};
const landingTagMangerEvent = (eventName, utm_source) => {};
export default tagManagerEvents;

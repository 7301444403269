import React, { useEffect, useState } from "react";
import { Button, Card, Grid, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "../components/Footer";
import { showToast } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import { listSubscriberCards } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { PulseLoader } from "react-spinners";
import { useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import TransactionDetailModal from "../components/transaction_detail_modal";

const Order = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const container = {
    margin: isMobile ? "1rem 1rem" : "2rem 4rem",
  };
  const title = {
    fontSize: isMobile ? "1rem" : "2rem",
    margin: "0px",
    marginBottom: "1rem",
  };
  const organizationCard = {
    padding: isMobile ? "0rem" : "1.5rem 2rem",
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    borderRadius: "10px",
    minHeight: "68vh",
  };
  const statusStyleActive = {
    color: "#1C984D",
    fontWeight: "bold",
    padding: "0.1rem 0.5rem",
    display: "inline-block",
    borderRadius: "4px",
    margin: "0px",
  };
  const statusStyleInActive = {
    color: "#E35244",
    fontWeight: "bold",
    padding: "0.1rem 0.5rem",
    display: "inline-block",
    borderRadius: "4px",
    margin: "0px",
  };
  const nameContainerStyle = {
    display: "inline-block",
    maxWidth: "180px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
  };
  const buttonStyle = {
    textTransform: "initial",
    fontSize: "1rem",
    border: "none",
    paddingLeft: 0,
  };
  const client = generateClient();
  const dispatch = useDispatch();
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const [orderList, setOrderList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [openRefundModal, setOpenRefundModal] = React.useState(false);
  const [openTransDetailModal, setOpenTransDetailModal] = React.useState(false);
  const [idToRefund, setIdToRefund] = useState(null);
  const [refundAmount, setRefundAmount] = React.useState("");
  const [refundDesc, setRefundDesc] = React.useState("");
  const [refundAmountError, setRefundAmountError] = React.useState(false);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [subscriberData, setSubscriberData] = useState(null);
  const [transDetail, setTransDetail] = useState(null);

  const merchant_name = process.env.REACT_APP_MERCHANT_LOGIN_NAME;
  const transaction_key = process.env.REACT_APP_MERCHANT_TRANSACTION_KEY;
  const authorize_api = process.env.REACT_APP_AUTHORIZE_API;

  useEffect(() => {
    fetchSubscriberData();
    fetchTransDetail();
  }, []);

  useEffect(() => {
    if (subscriberData?.length > 0) {
      fetchOrders();
    }
  }, [subscriberData]);

  const fetchSubscriberData = async () => {
    try {
      const variables = {
        filter: {
          workspace_id: {
            eq: selectedWorkspaceId,
          },
        },
      };
      const getSubData = await client.graphql({
        query: listSubscriberCards,
        variables: variables,
      });
      const res = getSubData.data.listSubscriberCards.items;
      setSubscriberData(res);
    } catch (error) {
      console.error("Error creating todo:", error);
    }
  };

  const fetchOrders = async () => {
    try {
      setLoader(true);
      const getRes = {
        getTransactionListForCustomerRequest: {
          merchantAuthentication: {
            name: merchant_name,
            transactionKey: transaction_key,
          },
          customerProfileId: subscriberData?.[0]?.subscription_id,
          customerPaymentProfileId: subscriberData?.[0]?.payment_profile_id,
          sorting: {
            orderBy: "submitTimeUTC",
            orderDescending: true,
          },
          paging: {
            limit: "100",
            offset: "1",
          },
        },
      };

      const response = await axios.post(authorize_api, getRes);
      setOrderList(response.data.transactions);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const fetchTransDetail = async (transId) => {
    try {
      const getRes = {
        getTransactionDetailsRequest: {
          merchantAuthentication: {
            name: merchant_name,
            transactionKey: transaction_key,
          },
          transId: transId,
        },
      };

      const response = await axios.post(authorize_api, getRes);
      setTransDetail(response?.data?.transaction);
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleCloseTransDetailModal = () => {
    setOpenTransDetailModal(false);
    setTransDetail(null);
  };

  const handleOpenTransDetailModal = async (transId) => {
    await fetchTransDetail(transId);
    setOpenTransDetailModal(true);
  };

  const handleCloseRefundModal = () => {
    setOpenRefundModal(false);
    setRefundAmount("");
    setIdToRefund(null);
  };

  const handleRefundAmount = (item) => {
    setIdToRefund(item);
    setOpenRefundModal(true);
  };

  const handleConfirmRefund = async () => {
    if (refundAmount === "" || refundAmount === "0") {
      setRefundAmountError(true);
      return;
    }
    try {
      setOpenRefundModal(false);
      const refundVariable = {
        createTransactionRequest: {
          merchantAuthentication: {
            name: merchant_name,
            transactionKey: transaction_key,
          },
          transactionRequest: {
            transactionType: "refundTransaction",
            amount: `${refundAmount}.00`,
            currencyCode: "USD",
            payment: {
              creditCard: {
                cardNumber: idToRefund?.accountNumber.slice(-4),
                expirationDate: "XXXX",
              },
            },
            refTransId: idToRefund?.id,
            order: {
              invoiceNumber: idToRefund?.invoice || "",
              description: refundDesc,
            },
          },
        },
      };
      const fetchCustomerData = await axios.post(authorize_api, refundVariable);
      setIdToRefund(null);
      setRefundAmount("");
      setRefundDesc("");
      dispatch(
        showToast({
          message: "Your refund operation is successfully completed",
          type: "success",
        })
      );
      await fetchOrders();
    } catch (error) {
      dispatch(
        showToast({
          message: error.message,
          type: "error",
        })
      );
    }
  };

  const formatUtcDate = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const year = utcDate.getFullYear();
    const month = utcDate.getMonth() + 1;
    const day = utcDate.getDate();
    const hours = utcDate.getHours().toString().padStart(2, "0");
    const minutes = utcDate.getMinutes().toString().padStart(2, "0");

    return `${day}-${month
      .toString()
      .padStart(2, "0")}-${year} ${hours}:${minutes}`;
  };

  const formatString = (str) => {
    const formattedString = str
      ?.replace(/([a-z])([A-Z])/g, "$1 $2")
      ?.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      ?.split(" ")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");

    return formattedString;
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: "transId",
      headerName: "Trans ID",
      width: 150,
      renderCell: (params) => (
        <p
          style={{
            margin: "0px",
            textDecoration: "underline",
            color: "#1976d2",
            cursor: "pointer",
          }}
          onClick={() => handleOpenTransDetailModal(params.value)}
        >
          {params.value}
        </p>
      ),
    },
    {
      field: "invoice",
      headerName: "Invoice Number",
      width: 200,
      renderCell: (params) => params.value,
    },
    {
      field: "status",
      headerName: "Trans Status",
      width: 250,
      renderCell: (params) => (
        <p
          style={
            params.value === "settledSuccessfully"
              ? statusStyleActive
              : params.value === "refundSettledSuccessfully"
              ? statusStyleActive
              : statusStyleInActive
          }
        >
          {formatString(params.value)}
        </p>
      ),
    },
    {
      field: "submitDate",
      headerName: "Submit Date",
      width: 150,
      renderCell: (params) => formatUtcDate(params.value),
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 150,
      renderCell: (params) => `${params.row.lastName}, ${params.row.firstName}`,
    },
    {
      field: "card",
      headerName: "Card",
      width: 100,
      renderCell: (params) => (
        <p style={{ margin: "0px" }}>{formatString(params.value)}</p>
      ),
    },
    {
      field: "accountNumber",
      headerName: "Payment Method",
      width: 150,
      renderCell: (params) => params.value,
    },
    {
      field: "price",
      headerName: "Settlement Amount",
      width: 150,
      renderCell: (params) =>
        `$ ${Number(params.value ?? 0)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .toLocaleString("en-US")}`,
    },
    {
      field: "refund",
      headerName: userData?.role === "super_admin" && "Refund Transaction",
      width: 200,
      renderCell: (params) => (
        <Grid container style={{ height: "100%" }}>
          {userData?.role === "super_admin" && (
            <>
              <Button
                disabled={!params.row.status}
                variant="outlined"
                style={buttonStyle}
                onClick={() => handleRefundAmount(params.row)}
              >
                Refund
              </Button>
            </>
          )}
        </Grid>
      ),
    },
  ];

  const rows =
    orderList?.map((item) => ({
      id: item.transId,
      transId: item.transId,
      invoice: item.invoiceNumber || "-",
      status: item.transactionStatus,
      submitDate: item.submitTimeUTC,
      firstName: item.firstName,
      lastName: item.lastName,
      card: item.accountType,
      accountNumber: item.accountNumber,
      price: item.settleAmount,
    })) || [];

  return (
    <>
      <div style={container}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div>
              <p style={title}>Orders</p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Card style={organizationCard}>
              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50vh",
                  }}
                >
                  <PulseLoader color="#ACD6EB" />
                </div>
              ) : (
                <>
                  {isMobile ? (
                    <div
                      className="tableInnerWrapper"
                      style={{ background: "white" }}
                    >
                      <div className="table-info">
                        <table className="d-table">
                          <thead>
                            <tr>
                              <th style={{ width: "170px" }}>Title</th>
                              <th>Name</th>
                              <th>Created At</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {orderList?.length === 0 ? (
                            <>
                              {" "}
                              <tbody>
                                <tr className="norecord">
                                  <td
                                    colSpan={7}
                                    style={{ textAlign: "center" }}
                                  >
                                    No Record Found
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          ) : (
                            <>
                              <tbody>
                                {orderList?.length > 0 &&
                                  orderList?.map((item, index, array) => (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: 8,
                                          marginTop: 8,
                                        }}
                                      >
                                        <div>
                                          <div className="td-name">
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Name:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  {`${item.lastName}, ${item.firstName}`}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Invoice Number:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  {`${
                                                    item.invoiceNumber || "-"
                                                  }`}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Transaction Status:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color:
                                                      item.status ===
                                                      "settledSuccessfully"
                                                        ? "#1c984d"
                                                        : "#E35244",
                                                  }}
                                                >
                                                  {formatString(
                                                    item.transactionStatus
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Submit Date:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  {`${formatUtcDate(
                                                    item.submitTimeUTC
                                                  )}`}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="td-name">
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Card:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  {`${item.accountType || "-"}`}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Payment Method:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  {`${item.accountNumber}`}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="itemtitle">
                                              <div className="td-name">
                                                Settlement Amount:{" "}
                                                <span
                                                  style={{
                                                    ...nameContainerStyle,
                                                    color: "#359cd2",
                                                  }}
                                                >
                                                  ${` ${item.settleAmount}`}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {index < orderList.length - 1 && (
                                        <Divider />
                                      )}
                                    </>
                                  ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  ) : (
                    <>
                      {orderList && orderList.length > 0 && (
                        <DataGrid
                          rows={rows}
                          columns={columns.filter(
                            (column) => column.field !== "id"
                          )}
                          getRowId={(row) => row.id}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 7,
                              },
                            },
                          }}
                          pageSizeOptions={[7, 25, 50, 100]}
                          density="comfortable"
                          sx={{
                            border: 0,
                            borderColor: "primary.light",
                            "& .MuiDataGrid-cell:hover": {
                              color: "primary.main",
                            },
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>
        {/* Refund Modal */}
        <Dialog
          open={openRefundModal}
          disableBackdropClick
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Refund Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div>
                <TextField
                  error={refundAmountError}
                  size="small"
                  type="number"
                  fullWidth
                  style={{ marginTop: "1rem", width: "100%" }}
                  label="Refund Amount"
                  inputProps={{ min: 0 }}
                  value={refundAmount}
                  onChange={(e) => {
                    setRefundAmount(e.target.value);
                    setRefundAmountError(false);
                  }}
                />
                <TextField
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  style={{ marginTop: "1rem", width: "100%" }}
                  label="Reason (Optional)"
                  value={refundDesc}
                  onChange={(e) => {
                    setRefundDesc(e.target.value);
                  }}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
            <Button
              onClick={handleCloseRefundModal}
              variant="contained"
              style={{ textTransform: "inherit" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirmRefund}
              style={{ textTransform: "inherit" }}
            >{`Refund ${`$${Number(refundAmount)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              .toLocaleString("en-US")}`}`}</Button>
          </DialogActions>
        </Dialog>
        <TransactionDetailModal
          handleCloseTransDetailModal={handleCloseTransDetailModal}
          openTransDetailModal={openTransDetailModal}
          transDetail={transDetail}
          formatString={formatString}
          formatUtcDate={formatUtcDate}
        />
        {!isMobile && (
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default Order;

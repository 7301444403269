import * as React from 'react';
export const SemRushChartPopUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={64}
        height={56}
        fill="none"
        {...props}
    >
        <path
            fill="#999"
            d="M15.375 35.5c-.75.75-2.125.75-2.875 0s-.75-2.125 0-2.875l14-14c.75-.75 2.125-.75 2.875 0L40 29.25l16.5-16.5c.75-.75 2.125-.75 2.875 0s.75 2 0 2.875l-18 17.875c-.75.75-2.125.75-2.875 0L28 22.875 15.375 35.5ZM62 52c1 0 2 1 2 2 0 1.125-1 2-2 2H10C4.375 56 0 51.625 0 46V2c0-1 .875-2 2-2 1 0 2 1 2 2v44c0 3.375 2.625 6 6 6h52Z"
        />
    </svg>
)

export const SemRushChartPopUp2 = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={65}
        height={64}
        fill="none"
        {...props}
    >
        <path
            fill="#999"
            d="M64.875 32c0 4.5-2.375 8.625-6.125 10.75 1.125 4.25-.125 8.75-3.25 11.875C52.25 58 47.75 59 43.625 57.875 41.5 61.625 37.375 64 32.875 64c-4.375 0-8.5-2.375-10.625-6.125-4 1.125-8.625.125-12-3.25C7.125 51.5 6 47 7.125 42.75 3.375 40.625.875 36.5.875 32c0-4.375 2.5-8.5 6.25-10.625-1.125-4.25.125-8.75 3.125-12 3.125-3.125 7.75-4.25 12-3.125C24.375 2.5 28.5 0 32.875 0c4.5 0 8.625 2.5 10.75 6.25 4.25-1.125 8.75 0 11.875 3.125 3.125 3.25 4.375 7.75 3.25 12C62.5 23.5 64.875 27.625 64.875 32ZM53.5 40.625c1.25-.5 7.5-2 7.5-8.625 0-6.375-5.875-7.875-7.5-8.5.625-1.125 3.875-6.625-.75-11.25-4.5-4.5-9.75-1.5-11.25-.75C41 10.25 39.5 4 33 4c-6.375 0-8 5.875-8.625 7.5-1.125-.625-6.625-3.875-11.25.75-4.5 4.5-1.5 9.75-.75 11.25C11.125 24 5 25.5 5 32c0 6.375 5.875 8 7.375 8.625-.625 1.125-3.875 6.625.75 11.25 3.625 3.625 7.25 2.625 11.25.75.5 1.25 2 7.375 8.625 7.375 6.375 0 7.875-5.875 8.5-7.375 4 1.875 7.625 2.875 11.25-.75 4.5-4.5 1.5-9.75.75-11.25Zm-12-20c.75-.75 2.125-.75 2.875 0s.75 2.125 0 2.875l-20 20C24 43.875 23.5 44 23 44c-.625 0-1.125-.125-1.5-.5-.75-.75-.75-2.125 0-2.875l20-20ZM25 27c-1.75 0-3-1.25-3-3 0-1.625 1.25-3 3-3 1.625 0 3 1.375 3 3 0 1.75-1.375 3-3 3Zm16 10c1.625 0 3 1.375 3 3 0 1.75-1.375 3-3 3-1.75 0-3-1.25-3-3 0-1.625 1.25-3 3-3Z"
        />
    </svg>
)

export const SemRushUsersIconPopUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={80}
        height={64}
        fill="none"
        {...props}
    >
        <path
            fill="#999"
            d="M39.875 40c-7.125 0-13-5.75-12.875-13 0-7.125 5.75-13 12.875-13s13 5.875 13 13c0 7.25-5.75 13-13 13Zm0-22c-4.875 0-9 4.125-9 9 0 5 4.125 9 9 9 5 0 9-4 9-9 0-4.875-4-9-9-9Zm6.25 26C56 44 64 51.5 64 60.75c0 1.875-1.625 3.25-3.625 3.25H19.5c-2 0-3.5-1.375-3.5-3.25C16 51.5 23.875 44 33.75 44h12.375ZM20 60h39.875c-.375-6.625-6.375-12-13.75-12h-12.5c-7.25 0-13.25 5.375-13.625 12Zm44-40c-5.625 0-10-4.375-10-10 0-5.5 4.375-10 10-10 5.5 0 10 4.5 10 10 0 5.625-4.5 10-10 10Zm0-16c-3.375 0-6 2.75-6 6 0 3.375 2.625 6 6 6 3.25 0 6-2.625 6-6 0-3.25-2.75-6-6-6ZM16 20c-5.625 0-10-4.375-10-10C6 4.5 10.375 0 16 0c5.5 0 10 4.5 10 10 0 5.625-4.5 10-10 10Zm0-16c-3.375 0-6 2.75-6 6 0 3.375 2.625 6 6 6 3.25 0 6-2.625 6-6 0-3.25-2.75-6-6-6Zm54.125 20C75.5 24 80 28.75 80 34.375V36c0 1.125-1 2-2 2-1.125 0-2-.875-2-2v-1.625c0-3.5-2.75-6.375-5.875-6.375H62c-.875 0-1.625.25-2.375.625-1 .5-2.25 0-2.75-1-.375-1 0-2.125 1-2.625 1.25-.625 2.625-1 4.125-1h8.125ZM20.25 28.625C19.5 28.25 18.75 28 18 28H9.75C6.625 28 4 30.875 4 34.375V36c0 1.125-1 2-2 2-1.125 0-2-.875-2-2v-1.625C0 28.75 4.375 24 9.75 24H18c1.375 0 2.75.375 4 1 1 .5 1.375 1.625 1 2.625-.5 1-1.75 1.5-2.75 1Z"
        />
    </svg>
)

export const SemRushLinkIconPopUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={64}
        height={64}
        fill="none"
        {...props}
    >
        <path
            fill="#999"
            d="M25.875 12c6.25 0 11.25 5.125 11.25 11.25 0 4.375-4.75 10.875-8.75 15.625-.625.75-1.75 1.125-2.5 1.125-.875 0-1.75-.375-2.375-1.125-4-4.625-8.75-11.25-8.75-15.625 0-6.125 5-11.25 11.125-11.25ZM26 35.625c4.375-5.375 7.125-10.125 7.125-12.375 0-4-3.25-7.25-7.125-7.25-4 0-7.25 3.25-7.25 7.25 0 2.25 2.75 7 7.25 12.375ZM26 20c1.625 0 3 1.375 3 3 0 1.75-1.375 3-3 3-1.75 0-3-1.25-3-3 0-1.625 1.25-3 3-3Zm37.375 40.625c.75.75.75 2.125 0 2.875-.375.375-.875.5-1.375.5-.625 0-1.125-.125-1.5-.5L42.875 45.75C38.25 49.75 32.375 52 26 52 11.625 52 0 40.375 0 26 0 11.75 11.625 0 26 0c14.25 0 26 11.75 26 26 0 6.5-2.375 12.375-6.375 17l17.75 17.625ZM26 48c12.125 0 22-9.75 22-22 0-12.125-9.875-22-22-22C13.75 4 4 13.875 4 26s9.75 22 22 22Z"
        />
    </svg>
)

export const SemRushSEOAuditIconPopUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={72}
        height={64}
        fill="none"
        {...props}
    >
        <path
            fill="#999"
            d="M64 0c4.375 0 8 3.625 8 8v36c0 4.5-3.625 8-8 8H45.25l2.375 8H54c1 0 2 1 2 2 0 1.125-1 2-2 2H18c-1.125 0-2-.875-2-2 0-1 .875-2 2-2h6.25l2.375-8H8c-4.5 0-8-3.5-8-8V8c0-4.375 3.5-8 8-8h56ZM43.5 60 41 52H30.875l-2.5 8H43.5ZM68 44V8c0-2.125-1.875-4-4-4H8C5.75 4 4 5.875 4 8v36c0 2.25 1.75 4 4 4h56c2.125 0 4-1.75 4-4Z"
        />
        <path
            fill="#999"
            d="M35.25 31.25a.74.74 0 0 1-.75.75c-.375 0-.75-.328-.75-.75v-7.5c0-.375.375-.75.75-.75.422 0 .75.375.75.75v7.5Zm-4.5 0A.74.74 0 0 1 30 32c-.375 0-.75-.328-.75-.75v-10.5c0-.375.375-.75.75-.75.422 0 .75.375.75.75v10.5ZM45 14.75c0-.375.375-.75.75-.75.422 0 .75.375.75.75v19.5c0 2.11-1.64 3.75-3.75 3.75h-16.5c-.375 0-.75-.328-.75-.75 0-.375.375-.75.75-.75h16.5c1.266 0 2.25-.984 2.25-2.25v-19.5Zm-5.25 16.5A.74.74 0 0 1 39 32c-.375 0-.75-.328-.75-.75v-13.5c0-.375.375-.75.75-.75.422 0 .75.375.75.75v13.5Z"
        />
    </svg>
)

export const SemRushDollarIconPopUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={64}
        height={64}
        fill="none"
        {...props}
    >
        <path
            fill="#999"
            d="M32.5 30.125c4.375 1.25 10.5 3 9.25 9.625-.5 3.5-3.5 5.75-7.75 6.25v4c0 1.125-1 2-2 2-1.125 0-2-.875-2-2v-4.125h-.375c-1.875-.375-3.875-1-5.5-1.625L23.25 44c-1-.375-1.625-1.5-1.25-2.625.375-1 1.625-1.625 2.625-1.25l.75.375c1.625.5 3.375 1.125 4.875 1.375 3.125.5 7.125.125 7.625-2.875.5-2.625-1.125-3.5-6.5-5l-1-.375c-3.375-.875-9.375-2.75-8.25-9.25.5-3.5 3.5-5.75 7.875-6.25V14c0-1 .875-2 2-2 1 0 2 1 2 2v4.25h.25c1.25.25 2.625.625 4.375 1.25 1 .25 1.625 1.5 1.25 2.5s-1.5 1.625-2.625 1.25c-1.5-.5-2.625-.875-3.625-1-3.125-.5-7.125 0-7.625 2.875-.375 2.375.75 3.375 5.5 4.75l1 .25ZM32 0c17.625 0 32 14.375 32 32 0 17.75-14.375 32-32 32C14.25 64 0 49.75 0 32 0 14.375 14.25 0 32 0Zm0 60c15.375 0 28-12.5 28-28C60 16.625 47.375 4 32 4 16.5 4 4 16.625 4 32c0 15.5 12.5 28 28 28Z"
        />
    </svg>
)

export const SemRushOptimizeIconPopUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={56}
        height={56}
        fill="none"
        {...props}
    >
        <path
            fill="#999"
            d="M10 36c5.5 0 10 4.5 10 10 0 5.625-4.5 10-10 10-5.625 0-10-4.375-10-10 0-5.5 4.375-10 10-10Zm0 16c3.25 0 6-2.625 6-6 0-3.25-2.75-6-6-6-3.375 0-6 2.75-6 6 0 3.375 2.625 6 6 6ZM2 0c29.75 0 54 24.25 54 54 0 1.125-1 2-2 2-1.125 0-2-.875-2-2C52 26.5 29.5 4 2 4 .875 4 0 3.125 0 2c0-1 .875-2 2-2Zm0 18c19.75 0 36 16.25 36 36 0 1.125-1 2-2 2-1.125 0-2-.875-2-2 0-17.625-14.375-32-32-32-1.125 0-2-.875-2-2 0-1 .875-2 2-2Z"
        />
    </svg>
)

export const SemRushInfinityIconPopUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={80}
        height={40}
        fill="none"
        {...props}
    >
        <path
            fill="#999"
            d="M61 0c10.5 0 19 9 19 20 0 11.125-8.5 20-19 20-10.25 0-17.75-10.25-21-15.75C36.625 29.75 29.125 40 18.875 40 8.375 40 0 31.125 0 20 0 9 8.375 0 18.875 0 29.125 0 36.625 10.375 40 15.875 43.25 10.375 50.75 0 61 0ZM18.875 36c9.75 0 17-12.5 18.875-16-1.875-3.375-9.125-16-18.875-16C10.625 4 4 11.25 4 20c0 8.875 6.625 16 14.875 16ZM61 36c8.25 0 15-7.125 15-16 0-8.75-6.75-16-15-16-9.75 0-17 12.625-18.75 16 1.75 3.5 9 16 18.75 16Z"
        />
    </svg>
)

export const SemRushStartsIconPopUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={65}
        height={64}
        fill="none"
        {...props}
    >
        <path
            fill="#999"
            d="M46.5 33.5c2.375.25 3.25 3.125 1.625 4.875l-9.5 9.25 2.25 13.125c.125.75-.125 1.625-.75 2.25-.5.625-1.25 1-2.125 1-.5 0-1-.125-1.375-.25L25 57.5l-11.75 6.25c-.5.25-.875.25-1.375.25-.875 0-1.625-.375-2.125-1s-.75-1.5-.625-2.25l2.125-13.125-9.375-9.25C.125 36.75 1 33.75 3.375 33.5l13.125-2 5.875-11.875c.5-1 1.5-1.625 2.5-1.625C26 18 27 18.625 27.5 19.625L33.375 31.5l13.125 2ZM34.25 46.25l9.375-9.125L30.75 35.25 25 23.5l-5.875 11.75L6.25 37.125l9.25 9.125-2.125 12.875L24.875 53 36.5 59.125 34.25 46.25ZM33 14c-1.125 0-2-.875-2-2 0-1 .875-2 2-1.875L41 10V2c0-1 .875-2 2-2 1 0 2 1 2 2v8l8 .125c1 0 2 .875 2 1.875 0 1.125-1 2-2 2h-8v8c0 1.125-1 2-2 2-1.125 0-2-.875-2-2v-8h-8Zm30 12c1 0 2 1 2 2.125 0 1-1 1.875-2 1.875h-4v4c0 1.125-1 2.125-2 2.125-1.125 0-2-1-2-2.125v-4h-4c-1.125 0-2-.875-2-1.875C49 27 49.875 26 51 26h4v-4c0-1 1-1.875 2-1.875S59 21 59 22v4h4Z"
        />
    </svg>
)

export const SemRushFileIconPopUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={64}
        fill="none"
        {...props}
    >
        <path
            fill="#999"
            d="M45.625 15.75c1.5 1.5 2.375 3.5 2.375 5.625V56c0 4.5-3.625 8-8 8H8c-4.5 0-8-3.5-8-8V8c0-4.375 3.5-8 8-8h18.625c2.125 0 4.125.875 5.625 2.375L45.625 15.75ZM28 4.375V18c0 1.125.875 2 2 2h13.625c-.125-.5-.375-1-.875-1.5L29.5 5.25c-.5-.5-1-.75-1.5-.875ZM44 56V24H30c-3.375 0-6-2.625-6-6V4H8C5.75 4 4 5.875 4 8v48c0 2.25 1.75 4 4 4h32c2.125 0 4-1.75 4-4ZM10 38c0-6.5 5.375-12 12-12 6.5 0 12 5.5 12 12 0 2.625-.875 5-2.25 7l7.625 7.625c.75.75.75 2.125 0 2.875-.375.375-.875.5-1.375.5-.625 0-1.125-.125-1.5-.5l-7.625-7.625A12.085 12.085 0 0 1 22 50c-6.625 0-12-5.375-12-12Zm20 0c0-4.375-3.625-8-8-8-4.5 0-8 3.625-8 8 0 4.5 3.5 8 8 8 4.375 0 8-3.5 8-8Z"
        />
    </svg>
)
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDesktendUserInfo = /* GraphQL */ `
  query GetDesktendUserInfo($id: ID!) {
    getDesktendUserInfo(id: $id) {
      id
      first_name
      last_name
      desk_title
      desk_id
      user_id
      role
      username
      status
      feed_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDesktendUserInfos = /* GraphQL */ `
  query ListDesktendUserInfos(
    $filter: ModelDesktendUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesktendUserInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first_name
        last_name
        desk_title
        desk_id
        user_id
        role
        username
        status
        feed_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      code
      description
      type
      amount
      percent
      never_expire
      expire_start_date
      product_id
      allowed_email
      expire_end_date
      created_at
      amount_type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        description
        type
        amount
        percent
        never_expire
        expire_start_date
        product_id
        allowed_email
        expire_end_date
        created_at
        amount_type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrders = /* GraphQL */ `
  query GetOrders($id: ID!) {
    getOrders(id: $id) {
      id
      workspace_id
      product_id
      product_plan
      product_quantity
      product_price
      status
      created_at
      subscription_id
      stripe_customer_id
      product_image
      product_name
      product_type
      product_period
      product_sku
      invoice_number
      refund_amount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        workspace_id
        product_id
        product_plan
        product_quantity
        product_price
        status
        created_at
        subscription_id
        stripe_customer_id
        product_image
        product_name
        product_type
        product_period
        product_sku
        invoice_number
        refund_amount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCartItems = /* GraphQL */ `
  query GetCartItems($id: ID!) {
    getCartItems(id: $id) {
      id
      product_id
      image
      name
      period
      price
      type
      user_id
      workspace_id
      plan
      quantity
      sku
      every
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCartItems = /* GraphQL */ `
  query ListCartItems(
    $filter: ModelCartItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        product_id
        image
        name
        period
        price
        type
        user_id
        workspace_id
        plan
        quantity
        sku
        every
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductTags = /* GraphQL */ `
  query GetProductTags($id: ID!) {
    getProductTags(id: $id) {
      id
      name
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductTags = /* GraphQL */ `
  query ListProductTags(
    $filter: ModelProductTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        is_active
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductCategories = /* GraphQL */ `
  query GetProductCategories($id: ID!) {
    getProductCategories(id: $id) {
      id
      name
      status
      is_active
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductCategories = /* GraphQL */ `
  query ListProductCategories(
    $filter: ModelProductCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        is_active
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductVariations = /* GraphQL */ `
  query GetProductVariations($id: ID!) {
    getProductVariations(id: $id) {
      id
      name
      price
      description
      product_id
      period
      signup_fee
      is_active
      is_deleted
      sku
      every
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductVariations = /* GraphQL */ `
  query ListProductVariations(
    $filter: ModelProductVariationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductVariations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        price
        description
        product_id
        period
        signup_fee
        is_active
        is_deleted
        sku
        every
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriberCard = /* GraphQL */ `
  query GetSubscriberCard($id: ID!) {
    getSubscriberCard(id: $id) {
      id
      workspace_id
      email
      subscription_id
      stripe_customer_id
      payment_profile_id
      stripe_payment_profile_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubscriberCards = /* GraphQL */ `
  query ListSubscriberCards(
    $filter: ModelSubscriberCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriberCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        workspace_id
        email
        subscription_id
        stripe_customer_id
        payment_profile_id
        stripe_payment_profile_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserBilling = /* GraphQL */ `
  query GetUserBilling($id: ID!) {
    getUserBilling(id: $id) {
      id
      workspace_id
      address
      card_number
      expiry_month
      expiry_year
      cvc
      first_name
      last_name
      email
      city
      country
      postal_code
      state
      holderName
      paymentMethod_Gateway
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserBillings = /* GraphQL */ `
  query ListUserBillings(
    $filter: ModelUserBillingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserBillings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        workspace_id
        address
        card_number
        expiry_month
        expiry_year
        cvc
        first_name
        last_name
        email
        city
        country
        postal_code
        state
        holderName
        paymentMethod_Gateway
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMyLinks = /* GraphQL */ `
  query GetMyLinks($id: ID!) {
    getMyLinks(id: $id) {
      id
      workspace_id
      name
      url
      image_url
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMyLinks = /* GraphQL */ `
  query ListMyLinks(
    $filter: ModelMyLinksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMyLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        workspace_id
        name
        url
        image_url
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeamInvitation = /* GraphQL */ `
  query GetTeamInvitation($id: ID!) {
    getTeamInvitation(id: $id) {
      id
      email
      workspace
      workspace_id
      status
      workspaceteamID
      role
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeamInvitations = /* GraphQL */ `
  query ListTeamInvitations(
    $filter: ModelTeamInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        workspace
        workspace_id
        status
        workspaceteamID
        role
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriptions = /* GraphQL */ `
  query GetSubscriptions($id: ID!) {
    getSubscriptions(id: $id) {
      id
      product_id
      status
      UserInfo {
        id
        user_id
        user_name_first
        user_name_second
        email
        display_picture
        workspace
        workspaceteamID
        workspace_id
        phone_number
        subscription_id
        stripe_customer_id
        role
        joined_at
        createdAt
        updatedAt
        __typename
      }
      ProductCard {
        id
        name
        description
        image
        categories
        tags
        type
        period
        is_active
        is_deleted
        sku
        price
        slug
        stripe_product_id
        createdAt
        updatedAt
        __typename
      }
      created_at
      workspace_id
      renewal_date
      subscription_id
      stripe_customer_id
      product_image
      product_name
      product_type
      product_period
      product_quantity
      product_plan
      product_price
      product_sku
      invoice_number
      subscription_by
      createdAt
      updatedAt
      subscriptionsUserInfoId
      subscriptionsProductCardId
      __typename
    }
  }
`;
export const listSubscriptions = /* GraphQL */ `
  query ListSubscriptions(
    $filter: ModelSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        product_id
        status
        created_at
        workspace_id
        renewal_date
        subscription_id
        stripe_customer_id
        product_image
        product_name
        product_type
        product_period
        product_quantity
        product_plan
        product_price
        product_sku
        invoice_number
        subscription_by
        createdAt
        updatedAt
        subscriptionsUserInfoId
        subscriptionsProductCardId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWorkspaceTeam = /* GraphQL */ `
  query GetWorkspaceTeam($id: ID!) {
    getWorkspaceTeam(id: $id) {
      id
      workspace_id
      user_id
      joined_at
      status
      workspaceinfoID
      UserInfos {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWorkspaceTeams = /* GraphQL */ `
  query ListWorkspaceTeams(
    $filter: ModelWorkspaceTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspaceTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        workspace_id
        user_id
        joined_at
        status
        workspaceinfoID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const workspaceTeamsByWorkspaceinfoID = /* GraphQL */ `
  query WorkspaceTeamsByWorkspaceinfoID(
    $workspaceinfoID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspaceTeamsByWorkspaceinfoID(
      workspaceinfoID: $workspaceinfoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workspace_id
        user_id
        joined_at
        status
        workspaceinfoID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      user_id
      user_name_first
      user_name_second
      email
      display_picture
      workspace
      workspaceteamID
      workspace_id
      phone_number
      subscription_id
      stripe_customer_id
      WorkspaceInfos {
        nextToken
        __typename
      }
      role
      joined_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserInfos = /* GraphQL */ `
  query ListUserInfos(
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        user_name_first
        user_name_second
        email
        display_picture
        workspace
        workspaceteamID
        workspace_id
        phone_number
        subscription_id
        stripe_customer_id
        role
        joined_at
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userInfosByWorkspaceteamID = /* GraphQL */ `
  query UserInfosByWorkspaceteamID(
    $workspaceteamID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userInfosByWorkspaceteamID(
      workspaceteamID: $workspaceteamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        user_name_first
        user_name_second
        email
        display_picture
        workspace
        workspaceteamID
        workspace_id
        phone_number
        subscription_id
        stripe_customer_id
        role
        joined_at
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWorkspaceInfo = /* GraphQL */ `
  query GetWorkspaceInfo($id: ID!) {
    getWorkspaceInfo(id: $id) {
      id
      name
      url
      logo
      created_at
      WorkspaceTeams {
        nextToken
        __typename
      }
      userinfoID
      workspaceteamID
      user_id
      feed_id
      semrushProjectId
      samrushDomainName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWorkspaceInfos = /* GraphQL */ `
  query ListWorkspaceInfos(
    $filter: ModelWorkspaceInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkspaceInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        logo
        created_at
        userinfoID
        workspaceteamID
        user_id
        feed_id
        semrushProjectId
        samrushDomainName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const workspaceInfosByUserinfoID = /* GraphQL */ `
  query WorkspaceInfosByUserinfoID(
    $userinfoID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWorkspaceInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workspaceInfosByUserinfoID(
      userinfoID: $userinfoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        url
        logo
        created_at
        userinfoID
        workspaceteamID
        user_id
        feed_id
        semrushProjectId
        samrushDomainName
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductCard = /* GraphQL */ `
  query GetProductCard($id: ID!) {
    getProductCard(id: $id) {
      id
      name
      description
      image
      categories
      tags
      type
      period
      is_active
      is_deleted
      sku
      price
      slug
      stripe_product_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductCards = /* GraphQL */ `
  query ListProductCards(
    $filter: ModelProductCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        categories
        tags
        type
        period
        is_active
        is_deleted
        sku
        price
        slug
        stripe_product_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

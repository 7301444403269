import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Grid, TextField } from "@mui/material";
import Footer from "../components/Footer";
import EditIcon from "@mui/icons-material/Edit";
import { uploadData } from "aws-amplify/storage";
import { updateWorkspaceInfo } from "../graphql/mutations";
import { setLoading, setWorkspaceDetails, showToast } from "../redux/userSlice";
import { generateClient } from "aws-amplify/api";
import { useDispatch } from "react-redux";
import { listWorkspaceInfos } from "../graphql/queries";
import { useMediaQuery } from "@mui/material";
const container = {
  margin: "0rem 4rem",
};
const organizationCard = {
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
  minHeight: "70vh",
};
const profileImage = {
  width: "150px",
  height: "150px",
  borderRadius: "50%",
  objectFit: "cover",
  cursor: "pointer",
};
const editIcon = {
  position: "absolute",
  bottom: "30px",
  right: "17px",
  color: "#000",
  transform: "translate(50%, 50%)",
  backgroundColor: "#d7d7d7",
  borderRadius: "50%",
  padding: "4px",
  cursor: "pointer",
  fontSize: "2rem",
};
const profileImageContainer = {
  position: "relative",
  display: "inline-block",
  width: "150px",
  height: "150px",
  borderRadius: "50%",
  backgroundColor: "#bababa",
  cursor: "pointer",
  border: "1px solid #DCE5EC",
};
const WorkspaceProfile = () => {
  const client = generateClient();
  const dispatch = useDispatch();
  const [workspaceDetail, setWorkspaceDetail] = React.useState(null);
  const token = localStorage.getItem("user");
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const userData = JSON.parse(token);
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState("");
  const bucket = process.env.REACT_APP_BUCKET_NAME;
  const [updatedWorkspace, setUpdatedWorkspace] = useState({
    workspaceName: "",
    workspaceUrl: "",
  });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  useEffect(() => {
    fetchWorkspaceData();
  }, []);

  const fetchWorkspaceData = async () => {
    try {
      const variables = {
        filter: {
          id: {
            eq: selectedWorkspaceId,
          },
        },
      };

      const getWorkspaceData = await client.graphql({
        query: listWorkspaceInfos,
        variables: variables,
      });
      const result = getWorkspaceData.data.listWorkspaceInfos.items;
      setWorkspaceDetail(result);
      setUpdatedWorkspace({
        ...updatedWorkspace,
        workspaceName: result?.[0]?.name,
        workspaceUrl: result?.[0]?.url,
      });
    } catch (error) {
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  const handleEditIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const fileInput = event.target;

    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleUpdateWorkspace = async () => {
    try {
      setLoading(true);
      if (selectedImage) {
        const file = fileInputRef.current.files[0];
        try {
          const fileName = file.name.replace(/\s+/g, "");
          const uploadResult = await uploadData({
            key: `${selectedWorkspaceId}/${userData.sub}/${fileName}`,
            data: file,
            options: {
              accessLevel: "public",
            },
          }).uploadResult;

          const variable = {
            id: workspaceDetail?.[0]?.id,
            name: updatedWorkspace.workspaceName,
            url: updatedWorkspace.workspaceUrl,
            logo: `https://${bucket}/public/${selectedWorkspaceId}/${userData.sub}/${fileName}`,
          };

          const updateWorkspaceData = await client.graphql({
            query: updateWorkspaceInfo,
            variables: { input: variable },
          });
          dispatch(
            setWorkspaceDetails(updateWorkspaceData.data.updateWorkspaceInfo)
          );
        } catch (uploadError) {
          console.log("Error uploading image:", uploadError);
        }
      } else {
        const variable = {
          id: workspaceDetail?.[0]?.id,
          name: updatedWorkspace.workspaceName,
          url: updatedWorkspace.workspaceUrl,
        };

        const updateWorkspaceData = await client.graphql({
          query: updateWorkspaceInfo,
          variables: { input: variable },
        });

        const result = updateWorkspaceData.data.updateWorkspaceInfo;
        dispatch(setWorkspaceDetails(result));
      }
      dispatch(
        showToast({
          message: "Workspace updated successfully",
          type: "success",
        })
      );
    } catch (error) {
      console.error("Error updating user:", error);
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{ ...container, margin: isMobile ? "0px 1rem" : container.margin }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card style={organizationCard}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: isMobile ? "60vh" : "70vh",
              }}
            >
              <div style={{ width: isMobile ? "90%" : "40%" }}>
                <Grid container spacing={0}>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <div style={profileImageContainer}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <label htmlFor="fileInput">
                        <img
                          src={selectedImage || workspaceDetail?.[0]?.logo}
                          style={profileImage}
                        />
                        <EditIcon
                          style={editIcon}
                          onClick={handleEditIconClick}
                        />
                      </label>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={5} style={{ marginTop: "0.5rem" }}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Workspace Name"
                          variant="outlined"
                          size="small"
                          value={updatedWorkspace.workspaceName}
                          onChange={(e) =>
                            setUpdatedWorkspace({
                              ...updatedWorkspace,
                              workspaceName: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Workspace Tagline"
                          variant="outlined"
                          size="small"
                          value={updatedWorkspace.workspaceUrl}
                          onChange={(e) =>
                            setUpdatedWorkspace({
                              ...updatedWorkspace,
                              workspaceUrl: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          padding: "0.45rem 0rem",
                          fontSize: "1.1rem",
                          fontWeight: "bold",
                          textTransform: "initial",
                          marginTop: "1rem",
                        }}
                        onClick={handleUpdateWorkspace}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid container spacing={5} className="footer-container">
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default WorkspaceProfile;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  selectToastMessage,
  selectToastType,
  clearToast,
} from "../redux/userSlice.js";

function Toast() {
  const message = useSelector(selectToastMessage);
  const type = useSelector(selectToastType);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (message) {
      toast(message, { type });
      dispatch(clearToast());
    }
  }, [message, type, dispatch]);

  return (
    <ToastContainer
      position="top-center"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Zoom}
    />
  );
}

export default Toast;

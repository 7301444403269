import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddProduct from "../components/addProducts/product_detail";
import { useMediaQuery } from "@mui/material";
import AllCategories from "../components/addProducts/categories_detail";
import AllTags from "../components/addProducts/tags_detail";
import AllCoupon from "../components/addProducts/coupon_detail";

function CustomTabPanel(props) {
  const { children, value, index, isMobile, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: isMobile ? 0 : 3,
            mt: isMobile && 2,
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const token = localStorage.getItem("user");
  const userData = JSON.parse(token);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ marginTop: "2rem", marginLeft: "1rem", marginRight: "1rem" }}>
      <Box sx={{ overflowX: "auto" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant={isMobile ? "scrollable" : "standard"}
          scrollButtons={isMobile ? "auto" : "off"}
          sx={{
            "& .MuiTab-root": isMobile
              ? {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }
              : {},
          }}
        >
          <Tab
            label="Products"
            {...a11yProps(0)}
            style={{ textTransform: "inherit" }}
          />
          <Tab
            label="Categories"
            {...a11yProps(1)}
            style={{ textTransform: "inherit" }}
          />
          <Tab
            label="Brands/Tags"
            {...a11yProps(2)}
            style={{ textTransform: "inherit" }}
          />
          <Tab
            label="Coupon"
            {...a11yProps(3)}
            style={{ textTransform: "inherit" }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} isMobile={isMobile}>
        <AddProduct />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} isMobile={isMobile}>
        <AllCategories />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} isMobile={isMobile}>
        <AllTags />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3} isMobile={isMobile}>
        <AllCoupon />
      </CustomTabPanel>
    </Box>
  );
}

import {
  Alert,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Divider from "@mui/material/Divider";
import { useLocation, useNavigate } from "react-router-dom";
import countriesList from "../../../src/countries.json";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import { useAcceptJs } from "react-acceptjs";
import {
  setLoading,
  showToast,
  setProductCount,
  setUserInfo,
  selectProductCount,
} from "../../../src/redux/userSlice.js";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import { generateClient } from "aws-amplify/api";
import { listUserBillings, listUserInfos } from "../../graphql/queries";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EmailIcon from "@material-ui/icons/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import {
  fetchAuthSession,
  fetchUserAttributes,
  signIn,
} from "aws-amplify/auth";
import ShopFooter from "../../components/ShopFooter.js";
import Logo from "../../Assets/faviconPNG.png";
import { LocalMall } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
const BillingScreenMobileResponsive = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.8rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(270deg)",
    },
    "& .MuiAccordionSummary-content": {
      // marginLeft: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));
  return (
    <>
      {props?.productDetail?.length > 0 && (
        <>
          <div style={{ padding: 5 }}>
            <Accordion
              style={{ marginTop: 10 }}
              expanded={props.expanded}
              onChange={props.handleChangeAccordianButton()}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography
                  sx={{ flexShrink: 0, marginLeft: 1, fontWeight: "600" }}
                >
                  Show Order Summary
                </Typography>
                <Typography
                  sx={{ color: "text.secondary", fontWeight: "bold" }}
                >
                  ${" "}
                  {props?.productCount !== null &&
                  props?.productCount !== undefined
                    ? Number(props.getTotalAmount()).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })
                    : props.totalPrice !== 0
                    ? Number(props.totalPrice).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })
                    : "0"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {props?.productDetail.map((item, index) => (
                  <Card
                    sx={{
                      display: "flex",
                      width: "100%",
                      marginBottom: 2,
                      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{ width: 151 }}
                      image={item?.image}
                      style={{
                        width: "55%",
                        height: "auto",
                        objectFit: "cover",
                      }}
                      alt="Live from space album cover"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <CardContent
                        sx={{ flex: "1 0 auto", padding: 0.5, margin: 0 }}
                      >
                        <Typography component="div" variant="h7">
                          {item?.name}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          Plan{"\t"} : {item?.plan}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          Price :{" "}
                          {`$ ${Number(item.price ?? 0).toLocaleString(
                            "en-US",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}`}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          Total :
                          {` $ ${Number(
                            item.price * item.quantity ?? 0
                          ).toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          })}`}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                        >
                          Quantity : {item.quantity}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      )}
      <Box>
        <Grid item xs={4}>
          <Card style={props.organizationCard}>
            {!props.signedIn && (
              <div
                style={{
                  background: "lightgrey",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 10px 10px 10px",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "0.9rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Already have a WorkSpace? &nbsp;
                <p
                  style={{
                    ...props.signInTextStyle,
                    ...(props.isHovered && props.signInTextHoverStyle), // Merge hover style if hovered
                  }}
                  onMouseEnter={props.handleMouseEnter}
                  onMouseLeave={props.handleMouseLeave}
                  onClick={props.handleClickOpen}
                >
                  Sign in
                </p>
              </div>
            )}

            <div>
              <p
                style={{
                  fontSize: "0.9rem",
                  //    margin: "0.5rem 0rem"
                  fontWeight: "bold",
                }}
              >
                Contact Information
              </p>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        size="small"
                        value={props?.props?.customerDetail.billing.first_name}
                        onChange={(e) =>
                          props.handleFieldChange("first_name", e.target.value)
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        size="small"
                        value={props?.props?.customerDetail.billing.last_name}
                        onChange={(e) => {
                          props.setCustomerDetail((prevCustomerDetail) => ({
                            ...prevCustomerDetail,
                            billing: {
                              ...prevCustomerDetail.billing,
                              last_name: e.target.value,
                            },
                          }));

                          props.setSubscriptionDetail(
                            (prevSubscriptionDetail) => ({
                              ...prevSubscriptionDetail,
                              billing: {
                                ...prevSubscriptionDetail.billing,
                                last_name: e.target.value,
                              },
                            })
                          );
                        }}
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      size="small"
                      value={props?.customerDetail.billing.email}
                      onChange={(e) => {
                        props.setCustomerDetail((prevCustomerDetail) => ({
                          ...prevCustomerDetail,
                          billing: {
                            ...prevCustomerDetail.billing,
                            email: e.target.value,
                          },
                        }));

                        props.setSubscriptionDetail(
                          (prevSubscriptionDetail) => ({
                            ...prevSubscriptionDetail,
                            billing: {
                              ...prevSubscriptionDetail.billing,
                              email: e.target.value,
                            },
                          })
                        );
                      }}
                      style={{ width: "100%", marginBottom: "1rem" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <p
                style={{
                  fontSize: "0.9rem",
                  margin: "0.5rem 0rem",
                  fontWeight: "bold",
                }}
              >
                Billing Information
              </p>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        size="small"
                        value={props?.customerDetail.billing.address_1}
                        onChange={(e) => {
                          props.setCustomerDetail((prevCustomerDetail) => ({
                            ...prevCustomerDetail,
                            billing: {
                              ...prevCustomerDetail.billing,
                              address_1: e.target.value,
                            },
                          }));

                          props.setSubscriptionDetail(
                            (prevSubscriptionDetail) => ({
                              ...prevSubscriptionDetail,
                              billing: {
                                ...prevSubscriptionDetail.billing,
                                address_1: e.target.value,
                              },
                            })
                          );
                        }}
                        multiline
                        rows={2}
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        size="small"
                        value={props?.customerDetail.billing.city}
                        onChange={(e) => {
                          props.setCustomerDetail((prevCustomerDetail) => ({
                            ...prevCustomerDetail,
                            billing: {
                              ...prevCustomerDetail.billing,
                              city: e.target.value,
                            },
                          }));

                          props.setSubscriptionDetail(
                            (prevSubscriptionDetail) => ({
                              ...prevSubscriptionDetail,
                              billing: {
                                ...prevSubscriptionDetail.billing,
                                city: e.target.value,
                              },
                            })
                          );
                        }}
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        select
                        size="small"
                        margin="normal"
                        fullWidth
                        name="Country"
                        label="Select Country"
                        style={{ margin: "0px" }}
                        value={props?.customerDetail.billing.country}
                        onChange={(e) => {
                          props.setCustomerDetail((prevCustomerDetail) => ({
                            ...prevCustomerDetail,
                            billing: {
                              ...prevCustomerDetail.billing,
                              country: e.target.value,
                            },
                          }));

                          props.setSubscriptionDetail(
                            (prevSubscriptionDetail) => ({
                              ...prevSubscriptionDetail,
                              billing: {
                                ...prevSubscriptionDetail.billing,
                                country: e.target.value,
                              },
                            })
                          );
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: "300px",
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem value="" key="">
                          Clear
                        </MenuItem>
                        {props?.reorderedCountriesList?.map((option) => (
                          <MenuItem key={option.name} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="State"
                        variant="outlined"
                        size="small"
                        value={props?.customerDetail.billing.state}
                        onChange={(e) => {
                          props.setCustomerDetail((prevCustomerDetail) => ({
                            ...prevCustomerDetail,
                            billing: {
                              ...prevCustomerDetail.billing,
                              state: e.target.value,
                            },
                          }));

                          props.setSubscriptionDetail(
                            (prevSubscriptionDetail) => ({
                              ...prevSubscriptionDetail,
                              billing: {
                                ...prevSubscriptionDetail.billing,
                                state: e.target.value,
                              },
                            })
                          );
                        }}
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="Postal Code"
                        variant="outlined"
                        size="small"
                        value={props?.customerDetail.billing.postcode}
                        onChange={(e) => {
                          props.setCustomerDetail((prevCustomerDetail) => ({
                            ...prevCustomerDetail,
                            billing: {
                              ...prevCustomerDetail.billing,
                              postcode: e.target.value,
                            },
                          }));

                          props.setSubscriptionDetail(
                            (prevSubscriptionDetail) => ({
                              ...prevSubscriptionDetail,
                              billing: {
                                ...prevSubscriptionDetail.billing,
                                postcode: e.target.value,
                              },
                            })
                          );
                        }}
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <p
                style={{
                  fontSize: "0.9rem",
                  margin: "0rem 0rem",
                  fontWeight: "bold",
                }}
              >
                Payment Methods
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <CreditCardIcon style={{ color: "#3B86E9" }} />
                <p style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                  Debit or Credit Card
                </p>
              </div>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Card Number"
                        variant="outlined"
                        name="cardNumber"
                        size="small"
                        value={props?.cardData.cardNumber}
                        onChange={(event) =>
                          props.setCardData({
                            ...props?.cardData,
                            cardNumber: event.target.value,
                          })
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label={"Month"}
                        variant="outlined"
                        name="month"
                        size="small"
                        value={props?.cardData.month}
                        onChange={(event) =>
                          props.setCardData({
                            ...props?.cardData,
                            month: event.target.value,
                          })
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="Year"
                        variant="outlined"
                        name="year"
                        size="small"
                        value={props?.cardData.year}
                        onChange={(event) =>
                          props.setCardData({
                            ...props?.cardData,
                            year: event.target.value,
                          })
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        id="outlined-basic"
                        label="CVC"
                        variant="outlined"
                        name="cardCode"
                        size="small"
                        value={props?.cardData.cardCode}
                        onChange={(event) =>
                          props.setCardData({
                            ...props?.cardData,
                            cardCode: event.target.value,
                          })
                        }
                        style={{ width: "100%", marginBottom: "1rem" }}
                      />
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={props?.checkbox}
                            onChange={props.handleCheckboxChange}
                          />
                        }
                        label="I agree to the Terms & Conditions"
                      />
                    </FormGroup>
                  </Grid>
                  {props.showAlert && (
                    <Alert variant="filled" severity="error">
                      Please agree to terms & conditions
                    </Alert>
                  )} */}
                  <Grid item xs={12}>
                    <>
                      {/* <Button
                      onClick={props.onSignUp}
                      style={{
                        width: "100%",
                        padding: "0.7rem 0rem",
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        textTransform: "none",
                        marginTop: "1rem",
                      }}
                      variant="contained"
                    >
                      <span>Sign up</span>
                    </Button> */}
                      {props.signedIn ? (
                        <Button
                          // onClick={props.onSignUp}
                          style={{
                            width: "100%",
                            padding: "0.7rem 0rem",
                            fontSize: "1.1rem",
                            fontWeight: "bold",
                            textTransform: "none",
                            marginTop: "1rem",
                          }}
                          variant="contained"
                        >
                          Place Order
                        </Button>
                      ) : (
                        <Button
                          onClick={props.onSignUp}
                          style={{
                            width: "100%",
                            padding: "0.7rem 0rem",
                            fontSize: "1.1rem",
                            fontWeight: "bold",
                            textTransform: "none",
                            marginTop: "1rem",
                          }}
                          variant="contained"
                        >
                          Sign up
                        </Button>
                      )}
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Box>
    </>
  );
};

export default BillingScreenMobileResponsive;

import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Delete, KeyboardArrowUp, LocalMall } from "@mui/icons-material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import "../../Css/team.css";
const CartMobileResponsive = (props) => {
  const [hideDetail, setHideDetail] = useState(true);
  const nameContainerStyle = {
    display: "inline-block",
    maxWidth: "150px", // Adjust the width as needed
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    verticalAlign: "middle",
    fontSize: "1rem",
  };
  const nameContainerStyle1 = {
    display: "-webkit-box",
    maxWidth: "200px", // Adjust the width as needed
    WebkitLineClamp: 2, // Limit to 2 lines
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    verticalAlign: "middle",
    fontSize: "0.9rem",
  };
  return (
    <div style={{ margin: "1rem 1rem", backgroundColor: "white" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          alignItems: "center",
          padding: "0.5rem",
          borderRadius: 8,
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
        }}
      >
        <Typography
          sx={{
            flexShrink: 0,
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {hideDetail ? (
            <KeyboardArrowUp onClick={() => setHideDetail(!hideDetail)} />
          ) : (
            <KeyboardArrowDown onClick={() => setHideDetail(!hideDetail)} />
          )}{" "}
          Show Order Summary
        </Typography>
        <Typography sx={{ color: "text.secondary", fontWeight: "bold" }}>
          ${" "}
          {props?.productCount !== null && props?.productCount !== undefined
            ? Number(props?.getTotalAmount()).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })
            : props?.totalPrice !== 0
            ? Number(props?.totalPrice).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })
            : "0"}
        </Typography>
      </div>
      {hideDetail && (
        <div style={{ marginTop: "1rem" }}>
          {props?.productDetail?.map((item, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
                marginTop: 8,
              }}
            >
              <div>
                <img
                  src={item?.image}
                  style={{
                    height: "150px",
                    width: "150px",
                    borderRadius: "8%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
              <div style={{ marginLeft: 10 }}>
                <div className="td-name">
                  <div className="itemtitle">
                    <div className="td-name">
                      <span style={nameContainerStyle1}>
                        <b style={{ fontSize: "1rem" }}>Name:</b> {item?.name}
                      </span>
                    </div>
                  </div>
                  <div className="itemtitle">
                    <div className="td-name">
                      <b style={{ fontSize: "1rem" }}>Price:</b>{" "}
                      <span style={nameContainerStyle}>{`$ ${Number(
                        item.price ?? 0
                      ).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}`}</span>
                    </div>
                  </div>
                  <div className="itemtitle">
                    <span style={{ ...nameContainerStyle, padding: 0 }}>
                      <b style={{ fontSize: "1rem" }}>Total</b> :
                      {` $ ${Number(
                        item.price * item.quantity ?? 0
                      ).toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      })}`}
                    </span>
                  </div>
                  <div
                    className="itemtitle"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div className="td-name">
                        <b style={{ fontSize: "1rem" }}>Quantity:</b>{" "}
                        <span style={nameContainerStyle}>
                          {item?.quantity}{" "}
                          <IconButton onClick={() => props.handleDelete(index)}>
                            <Delete
                              style={{
                                cursor: "pointer",
                                padding: 0,
                                margin: 0,
                                fontSize: "1.2rem",
                                marginLeft: 4,
                                color: "red",
                              }}
                            />
                          </IconButton>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="itemtitle">
                    <span style={{ padding: 0 }}></span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <Divider />
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p
            style={{
              margin: " 0rem",
              padding: 4,
              fontSize: "0.9rem",
            }}
          >
            Items
          </p>
          <p>
            <b>{props?.productDetail?.length}</b>
          </p>
        </div>
      </div>
      <div style={{}}>
        {props.showPromoCode && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <TextField
              error={props.promoCodeError}
              id="outlined-basic"
              label="Enter Promo Code"
              variant="outlined"
              size="small"
              onChange={(e) => {
                props.setPromoCode(e.target.value);
                props.setTotalDiscount(0);
                props.setPromoCodeError(false);
              }}
              value={props.promoCode}
            />
            <Button
              variant="contained"
              style={{
                // padding: "0.2rem 1rem",
                fontSize: "0.8 rem",
                textTransform: "initial",
              }}
              onClick={props.applyToken}
            >
              Apply
            </Button>
          </div>
        )}
        <Divider />
        {props?.totalDiscount > 0 && props.showPromoCode && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>Discount</p>
            <p>
              <b>
                ${" "}
                {Number(props?.totalDiscount ?? 0).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </b>
            </p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p>Total Cost</p>
          <p>
            <b>
              ${" "}
              {Number(props?.totalPrice ?? 0).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </b>
          </p>
        </div>
        <Button
          variant="contained"
          style={{
            width: "100%",
            padding: "0.5rem 1rem",
            fontSize: "1rem",
            textTransform: "initial",
            // marginTop: "3rem",
          }}
          onClick={props.handleBillingData}
        >
          Checkout
        </Button>
      </div>
      <div style={{ height: "5vh" }}></div>
    </div>
  );
};

export default CartMobileResponsive;

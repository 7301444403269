import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Pagination,
  TextField,
  FormControl,
  FormHelperText,
  Button,
  Box,
  MenuItem,
  IconButton,
  PaginationItem,
  Checkbox,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import {
  LocalMall,
  ArrowDropDown,
  ArrowDropUp,
  KeyboardArrowLeft,
  ArrowRightAlt,
} from "@mui/icons-material";
import Logo from "../Assets/faviconPNG.png";
import { useNavigate } from "react-router-dom";
import "../Css/ProductMain.css";
import { useMediaQuery } from "@mui/material";
import ExternalMobileAppBar from "./Common/ExternalMobileAppBar";
import UnAuthenticatedAppBarComponent from "./unAuthenticatedAppBar";
import UnAuthenticatedDrawer from "./unAuthenticatedDrawer";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
const productDesc = {
  fontSize: "1.1rem",
  fontWeight: "bold",
};
const appBarContainer = {
  margin: "9rem 4rem",
};
const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "initial !important",
    marginRight: "0.4rem !important",
    backgroundColor: "#fff !important",
    color: "#00003F !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#1976d2 !important",
    },
  },
}));
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "2.7rem",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
});
const SearchBarComponent = (props) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#232f3e",
      }}
    >
      <Box>
        <CustomTextField
          fullWidth
          placeholder="Search product"
          variant="outlined"
          type="text"
          value={props.searchQuery}
          onChange={props.handleSearchChange}
          style={{ backgroundColor: "#fff", borderRadius: "5px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon style={{ fontSize: "2rem" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="scrollableContainer" style={{ marginTop: 10 }}>
          {props.categoriesNames?.slice(0, 9).map((name) => (
            <p
              className="listItems"
              style={{
                color: name === props.selectedCategory ? "white" : "lightgrey",
                fontSize: name === props.selectedCategory ? 18 : 14,
              }}
              key={name}
              onClick={() => props.handleCategoryChange(name)}
            >
              {name}
            </p>
          ))}
        </div>
      </Box>
    </div>
  );
};
const ProductMainCard = (props) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const container = {
    margin: !isMobile ? "1.5rem 4rem" : "0rem 0rem",
  };
  const ResponsiveProductCard = ({ product, onItemPressed }) => {
    return (
      <Grid item xs={6} md={4} lg={3}>
        <Card>
          <CardMedia
            component="img"
            image={product?.image}
            alt={product.name}
            sx={{ height: "100%", width: "100%", cursor: "pointer" }}
            onClick={() =>
              onItemPressed(product.slug, product.name, product.price)
            }
          />
          <CardContent>
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: isMobile ? 14 : "1.2rem",
                  minHeight: isMobile && "40px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  cursor: "pointer",
                  margin: 1,
                }}
                onClick={() =>
                  onItemPressed(product.slug, product.name, product.price)
                }
              >
                {product.name}
              </p>
              {product.type === "variable subscription" && (
                <p style={{ ...productDesc, fontSize: isMobile && "0.8rem" }}>
                  <span style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
                    From:
                  </span>{" "}
                  <span style={{ color: "#2895CF", fontWeight: "bold" }}>
                    {product?.plans?.[0]?.price
                      ? `$${Number(product?.plans?.[0]?.price)
                          .toFixed(0)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          .toLocaleString("en-US")}`
                      : "$"}
                  </span>{" "}
                  / {product?.plans?.[0]?.period}
                </p>
              )}
              {product.type === "simple product" && (
                <p style={{ ...productDesc, fontSize: isMobile && "0.8rem" }}>
                  <span style={{ color: "#2895CF", fontWeight: "bold" }}>
                    {`$${Number(product.price)
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      .toLocaleString("en-US")}`}
                  </span>
                </p>
              )}
            </div>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  const AllCategoreisComponet = (props) => {
    return (
      <div style={{ width: "45%" }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={Array.from(
            new Set(
              props?.categoriesList
                ?.flatMap((product) =>
                  Array.isArray(product?.categories)
                    ? product.categories.map((category) => category.name)
                    : []
                )
                ?.filter((category) => typeof category === "string")
            )
          )}
          renderInput={(params) => (
            <TextField disabled={true} {...params} label="Category" />
          )}
          onChange={(event, value) => props.handleCategoryChange(value)}
          value={props.selectedCategory}
        />
      </div>
    );
  };

  const BrandsComponent = (props) => {
    return (
      <div style={{ width: "45%" }}>
        <Autocomplete
          multiple
          id="brands-autocomplete"
          options={Array.from(
            new Set(
              props.products?.flatMap((product) =>
                Array.isArray(product?.tags)
                  ? product.tags.map((tag) => tag.name)
                  : []
              )
            )
          )}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={props.selectedBrands}
          onChange={(event, newValue) => {
            props.handleBrandChangeMobile(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Brands" />
          )}
        />
      </div>
    );
  };
  return (
    <>
      {!isMobile && (
        <div style={appBarContainer}>
          <UnAuthenticatedAppBarComponent
            totalItems={props.totalItems}
            searchQuery={props.searchQuery}
            handleSearchChange={props.handleSearchChange}
            categoriesNames={props.categoriesNames}
            handleCategoryChange={props.handleCategoryChange}
            onHandleCartPressed={props.onHandleCartPressed}
          />
        </div>
      )}
      {isMobile && (
        <ExternalMobileAppBar
          productCount={props.totalItems}
          brandsName={props.brandsName}
          handleBrandChangeMobile={props.handleBrandChangeMobile}
        />
      )}
      <div style={{ ...container, color: "#fff" }}>
        {isMobile && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0.7rem 0.7rem",
              backgroundColor: "#232f3e",
            }}
          >
            <SearchBarComponent
              searchQuery={props.searchQuery}
              handleSearchChange={props.handleSearchChange}
              categoriesNames={props.categoriesNames}
              handleCategoryChange={props.handleCategoryChange}
              onHandleCartPressed={props.onHandleCartPressed}
              selectedCategory={props.selectedCategory}
            />
          </div>
        )}
      </div>

      <div style={{ ...container, marginTop: 10 }}>
        <Grid item xs={12} md={10} lg={12}>
          <Grid container spacing={2} p={isMobile && 1.5}>
            {props.filteredProducts && props.filteredProducts.length > 0
              ? props.filteredProducts
                  .slice((props.page - 1) * 8, props.page * 8)
                  .map((product, index) => (
                    <ResponsiveProductCard
                      key={product?.id}
                      onAddToCart={props.onAddToCart}
                      product={product}
                      index={index}
                      onItemPressed={props.onItemPressed}
                      cartItems={props.cartItems}
                    />
                  ))
              : props.searchQuery
              ? null
              : props.products
                  .slice((props.page - 1) * 8, props.page * 8)
                  .map((product, index) => (
                    <ResponsiveProductCard
                      key={product?.id}
                      onAddToCart={props.onAddToCart}
                      product={product}
                      index={index}
                      onItemPressed={props.onItemPressed}
                      cartItems={props.cartItems}
                    />
                  ))}
          </Grid>
          {!props.isCartPressed &&
            !props.isShopItem &&
            !props.isCheckoutPressed && (
              <Box
                mt={isMobile ? 2 : 4}
                display="flex"
                justifyContent="space-between"
                flexDirection={isMobile ? "column" : "row"}
                alignItems={"center"}
                mb={isMobile && 2}
              >
                {props.filteredProducts?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: isMobile && "1rem",
                    }}
                  >
                    <Typography variant="body2">
                      Showing{" "}
                      {Math.min(
                        (props.page - 1) * 8 + 1,
                        props.filteredProducts?.length
                      )}
                      -
                      {Math.min(props.page * 8, props.filteredProducts?.length)}{" "}
                      of {props.filteredProducts?.length} results
                    </Typography>
                  </div>
                )}
                <div>
                  <Pagination
                    count={Math.ceil(props.filteredProducts?.length / 8)}
                    page={props.page}
                    onChange={props.handleChangePage}
                    variant="outlined"
                    shape="rounded"
                    size="large"
                    color="primary"
                    sx={{
                      "& .MuiPaginationItem-root": { color: "#1976d2" },
                      "& .MuiPaginationItem-page.Mui-selected": {
                        backgroundColor: "#1976d2 !important",
                        color: "white",
                      },
                    }}
                    renderItem={(item) => {
                      if (item.type === "previous")
                        return (
                          <PaginationItem
                            {...item}
                            icon={<KeyboardArrowLeft />}
                          />
                        );
                      if (item.type === "next")
                        return (
                          <PaginationItem {...item} icon={<ArrowRightAlt />} />
                        );
                      return <PaginationItem {...item} />;
                    }}
                  />
                </div>
              </Box>
            )}
        </Grid>
      </div>
    </>
  );
};

export default ProductMainCard;

import { Box, Paper, Typography, LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";

import LanguageIcon from '@mui/icons-material/Language';

import { SemRushChartPopUp, SemRushChartPopUp2, SemRushDollarIconPopUp, SemRushFileIconPopUp, SemRushInfinityIconPopUp, SemRushLinkIconPopUp, SemRushOptimizeIconPopUp, SemRushSEOAuditIconPopUp, SemRushStartsIconPopUp, SemRushUsersIconPopUp } from "../utills/Svgs/svg";
const organizationCard = {
    margin: "0.7rem 0rem",
    padding: "1rem 1.5rem",
    boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
    minHeight: "300px",
    borderRadius: "10px",
};
export const LoadingScreen = ({ websiteUrl }) => {
    const [progress, setProgress] = useState(0);
    const [activeSlide, setActiveSlide] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 900);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveSlide((prevSlide) =>
                prevSlide === slides.length - 1 ? 0 : prevSlide + 1
            );
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const slides = [
        {
            title: `We're accessing the health of "${websiteUrl || 'www.yourdomain.com'}"`,
            subtitle: "Our Semrush SEO audit plans will help drive up to 53% of traffic from organic searches.",
            icon: <SemRushChartPopUp sx={{ fontSize: 40, color: '#2196F3', mb: 5 }} />
        },
        {
            title: "Analyze your website's SEO performance",
            subtitle: "With our targeted audits, we aim to increase your conversion rates to 14.6% through inbound leads.",
            icon: <SemRushChartPopUp2 sx={{ fontSize: 40, color: '#4CAF50', mb: 2 }} />
        },
        {
            title: "Track your website's loading speed",
            subtitle: "We’ll optimize your website to rank on the first page, where 75% of users engage.",
            icon: <SemRushUsersIconPopUp sx={{ fontSize: 40, color: '#FFA726', mb: 2 }} />
        },
        {
            title: "Monitor your backlink profile",
            subtitle: "Our local SEO strategy will drive 28% of nearby searches to convert into sales within a day.",
            icon: <SemRushLinkIconPopUp sx={{ fontSize: 40, color: '#9C27B0', mb: 2 }} />
        },
        {
            title: "Keyword optimization strategies",
            subtitle: "Our SEO audits tap into the 93% of online experiences that begin with a search engine.",
            icon: <SemRushSEOAuditIconPopUp sx={{ fontSize: 40, color: '#F44336', mb: 2 }} />
        },
        {
            title: "Content performance analysis",
            subtitle: "For every $1 invested in our audit plans, you can expect up to $22 in returns.",
            icon: <SemRushDollarIconPopUp sx={{ fontSize: 40, color: '#00BCD4', mb: 2 }} />
        },
        {
            title: "Technical SEO audit",
            subtitle: "By creating optimized content, we’ll help achieve up to 3.5x more traffic with 16+ blog posts monthly.",
            icon: <SemRushOptimizeIconPopUp sx={{ fontSize: 40, color: '#795548', mb: 2 }} />
        },
        {
            title: "Mobile optimization check",
            subtitle: "Our SEO techniques will reduce your lead cost by 61% compared to outbound methods.",
            icon: <SemRushInfinityIconPopUp sx={{ fontSize: 40, color: '#607D8B', mb: 2 }} />
        },
        {
            title: "Competitor analysis tools",
            subtitle: "We’ll build your online reputation, leveraging 88% of consumers who trust online reviews.",
            icon: <SemRushStartsIconPopUp sx={{ fontSize: 40, color: '#FF5722', mb: 2 }} />
        },
        {
            title: "Our Semrush SEO audit plans will help drive up to 53% of traffic from organic searches.",
            subtitle: "Our local SEO services will capture 46% of searches that seek local business information",
            icon: <SemRushFileIconPopUp sx={{ fontSize: 40, color: '#3F51B5', mb: 2 }} />
        }
    ];

    return (
        <Paper
            elevation={1}
            sx={{
                ...organizationCard,
                position: 'relative',
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1
                }}
            >
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                        height: 3,
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: '#1565C0',
                        },
                        backgroundColor: 'transparent'
                    }}
                />
            </Box>

            {/* Header Section */}
            <Box
                sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderBottom: '1px solid #e0e0e0',
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        backgroundColor: '#e0e0e0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <LanguageIcon sx={{ color: '#666' }} />
                </Box>
                <Box display="flex" flexDirection="column">
                    <Typography
                        sx={{
                            color: '#333',
                            fontWeight: 700,
                            fontSize: '16px'
                        }}
                    >
                        We're accessing the health of {websiteUrl || 'www.yourdomain.com'}.
                    </Typography>
                    <Typography
                        sx={{
                            color: '#999999',
                            fontWeight: 700,
                            fontSize: '12px'
                        }}
                    >
                        It will take few minutes. Please check back shortly!
                    </Typography>
                </Box>
            </Box>

            {/* Main Content */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '300px',
                textAlign: 'center',
                transition: 'all 0.3s ease-in-out',
                p: 4
            }}>
                {/* Icon */}
                {slides[activeSlide].icon}

                {/* Content */}
                <Box sx={{ mb: 4 }}>
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 2,
                            fontWeight: 500,
                            color: '#333',
                            fontSize: '1.25rem',
                            mt: 2
                        }}
                    >
                        {slides[activeSlide].title}
                    </Typography>
                    {slides[activeSlide].subtitle && (
                        <Typography
                            variant="body2"
                            sx={{
                                color: '#666',
                                fontSize: '0.875rem',
                                lineHeight: 1.5
                            }}
                        >
                            {slides[activeSlide].subtitle}
                        </Typography>
                    )}
                </Box>

                {/* Navigation Dots */}
                <Box sx={{
                    display: 'flex',
                    gap: 1.5,
                    mt: 'auto',
                    pt: 2
                }}>
                    {slides.map((_, index) => (
                        <Box
                            key={index}
                            onClick={() => setActiveSlide(index)}
                            sx={{
                                width: 10,
                                height: 10,
                                borderRadius: '50%',
                                backgroundColor: activeSlide === index ? '#666' : '#e0e0e0',
                                cursor: 'pointer',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    backgroundColor: activeSlide === index ? '#666' : '#999',
                                    transform: 'scale(1.2)'
                                }
                            }}
                        />
                    ))}
                </Box>
            </Box>
        </Paper>
    );
};
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  headerText: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  link: {
    fontSize: "12px",
    textDecoration: "none",
    color: "#0066cc",
  },
  transactionId: {
    fontWeight: "bold",
  },
  status: {
    marginBottom: "20px",
  },
  section: {
    marginBottom: "20px",
  },
  sectionHeader: {
    backgroundColor: "#e0e0e0",
    padding: "10px",
    fontWeight: "bold",
  },
  sectionContent: {
    padding: "10px",
    borderBottom: "1px solid #e0e0e0",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    marginTop: "20px",
  },
  button: {
    padding: "5px 10px",
    fontSize: "12px",
    cursor: "pointer",
  },
};

const TransactionDetailModal = ({
  handleCloseTransDetailModal,
  openTransDetailModal,
  transDetail,
  formatString,
  formatUtcDate,
}) => {
  return (
    <>
      <Dialog
        open={openTransDetailModal}
        onClose={handleCloseTransDetailModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ color: "#0066cc" }}>
          Transaction Detail
          <IconButton
            aria-label="close"
            onClick={handleCloseTransDetailModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={styles.container}>
              <div style={styles.header}>
                <div style={styles.headerText}>
                  Transaction ID:{" "}
                  <span style={styles.transactionId}>
                    {transDetail?.transId}
                  </span>
                </div>
              </div>
              <div style={styles.status}>
                <strong>Transaction Status:</strong>{" "}
                {formatString(transDetail?.transactionStatus)}
              </div>

              <div style={styles.section}>
                <div style={styles.sectionHeader}>Settlement Information</div>
                <div style={styles.sectionContent}>
                  <p>
                    Settlement Amount:{" "}
                    <b>{`$ ${Number(transDetail?.settleAmount ?? 0)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      .toLocaleString("en-US")}`}</b>
                  </p>
                  <p>
                    Settlement Date and Time:{" "}
                    <b>
                      {formatUtcDate(transDetail?.batch?.settlementTimeLocal)}
                    </b>
                  </p>
                  <p>
                    Batch ID: <b>{transDetail?.batch?.batchId}</b>
                  </p>
                </div>
              </div>

              <div style={styles.section}>
                <div style={styles.sectionHeader}>
                  Authorization Information
                </div>
                <div style={styles.sectionContent}>
                  <p>
                    Authorization Amount:{" "}
                    <b>{`$ ${Number(transDetail?.authAmount ?? 0)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      .toLocaleString("en-US")}`}</b>
                  </p>
                  <p>
                    Submit Date/Time:{" "}
                    <b>{formatUtcDate(transDetail?.submitTimeLocal)}</b>
                  </p>
                  <p>
                    Authorization Code: <b>{transDetail?.authCode}</b>
                  </p>
                  <p>
                    Transaction Type:{" "}
                    <b>{formatString(transDetail?.transactionType)}</b>
                  </p>
                  <p>
                    Market Type: <b>{transDetail?.marketType}</b>
                  </p>
                  <p>
                    Customer IP: <b>{transDetail?.customerIP}</b>
                  </p>
                </div>
              </div>

              <div style={styles.section}>
                <div style={styles.sectionHeader}>Order Information</div>
                <div style={styles.sectionContent}>
                  <p>
                    Invoice #: <b>{transDetail?.order?.invoiceNumber}</b>
                  </p>
                  {transDetail?.order?.description ? (
                    <p>
                      Description: <b>{transDetail?.order?.description}</b>
                    </p>
                  ) : null}
                </div>
              </div>

              {transDetail?.lineItems ? (
                <div style={styles.section}>
                  <div style={styles.sectionHeader}>
                    Itemized Order Information
                  </div>
                  {transDetail?.lineItems?.map((item, index) => (
                    <div style={styles.sectionContent}>
                      <p>
                        Item: <b>{index + 1}</b>
                      </p>
                      <p>
                        Item Name: <b>{item?.name}</b>
                      </p>
                      <p>
                        Item Description: <b>{item?.description}</b>
                      </p>
                      <p>
                        Item Quantity: <b>{item?.quantity}</b>
                      </p>
                      <p>
                        Unit Price: <b>{item?.unitPrice}</b>
                      </p>
                      <p>
                        Taxable: <b>{item?.taxable === false ? "N" : "Y"}</b>
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}

              <div style={styles.section}>
                <div style={styles.sectionHeader}>
                  Customer Billing Information
                </div>
                <div style={styles.sectionContent}>
                  <p>
                    Name:{" "}
                    <b>
                      {transDetail?.billTo?.firstName}{" "}
                      {transDetail?.billTo?.lastName}
                    </b>
                  </p>
                  <p>
                    Address: <b>{transDetail?.billTo?.address}</b>
                  </p>
                  <p>
                    City: <b>{transDetail?.billTo?.city}</b>
                  </p>
                  <p>
                    Country: <b>{transDetail?.billTo?.country}</b>
                  </p>
                  <p>
                    State: <b>{transDetail?.billTo?.state}</b>
                  </p>
                  <p>
                    Zip: <b>{transDetail?.billTo?.zip}</b>
                  </p>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TransactionDetailModal;

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Footer from "../components/Footer";
import { updateSubscriberCard, updateUserBilling } from "../graphql/mutations";
import { setLoading, showToast } from "../redux/userSlice";
import { generateClient } from "aws-amplify/api";
import { useDispatch } from "react-redux";
import { listSubscriberCards, listUserBillings } from "../graphql/queries";
import countriesList from "../../src/countries.json";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PaymentForm from "./credit_card";
import {
  attachPaymentMethod,
  createPaymentMethod,
  createSetupIntent,
  updateCustomerDefaultPaymentMethod,
} from "../utills/stripeApis/stripeApi";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const container = {
  margin: "0rem 4rem",
};
const organizationCard = {
  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px",
  borderRadius: "10px",
};

const reorderedCountriesList = [
  { name: "United States" },
  ...countriesList.filter((country) => country.name !== "United States"),
];

const merchant_name = process.env.REACT_APP_MERCHANT_LOGIN_NAME;
const transaction_key = process.env.REACT_APP_MERCHANT_TRANSACTION_KEY;
const authorize_api = process.env.REACT_APP_AUTHORIZE_API;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const AddressSetting = () => {
  return (
    <Elements stripe={stripePromise}>
      <AddressSettingForm />
    </Elements>
  );
};
const AddressSettingForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const client = generateClient();
  const dispatch = useDispatch();
  const [userBillingDetail, setUserBillingDetail] = React.useState(null);
  const selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
  const [subscriberData, setSubscriberData] = useState(null);
  const [open, setOpen] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState({
    address: "",
    city: "",
    country: "",
    state: "",
    postalCode: "",
    cardNumber: "",
    holderName: "",
    expiry: "",
    expiryYear: "",
    expiryMonth: "",
    cvc: "",
  });

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    fetchWorkspaceData();
  }, []);
  const fetchAllUserBillings = async (selectedWorkspaceId) => {
    let nextToken = null;
    let allItems = [];

    const variables = {
      filter: {
        workspace_id: {
          eq: selectedWorkspaceId,
        },
      },
      limit: 1000, // You can adjust this value
    };

    do {
      const getAddressData = await client.graphql({
        query: listUserBillings,
        variables: {
          ...variables,
          nextToken: nextToken
        },
      });

      const items = getAddressData.data.listUserBillings.items;
      allItems = [...allItems, ...items];
      nextToken = getAddressData.data.listUserBillings.nextToken;
    } while (nextToken);

    return allItems;
  };
  const fetchWorkspaceData = async () => {
    try {
      const result = await fetchAllUserBillings(selectedWorkspaceId);

      setUserBillingDetail(result);
      setUpdatedAddress({
        ...updatedAddress,
        address: result?.[0]?.address,
        city: result?.[0]?.city,
        country: result?.[0]?.country,
        state: result?.[0]?.state || "",
        postalCode: result?.[0]?.postal_code,
        cardNumber: result?.[0]?.card_number,
        holderName: result?.[0]?.holderName,
        expiry: `${result?.[0]?.expiry_month}/${result?.[0]?.expiry_year}`,
        expiryYear: result?.[0]?.expiry_year,
        expiryMonth: result?.[0]?.expiry_month,
        cvc: result?.[0]?.cvc,
      });
      //   dispatch(setLoading(false));
    } catch (error) {
      //   dispatch(setLoading(false));
      dispatch(showToast({ message: error.message, type: "error" }));
      console.error("Error creating todo:", error);
    }
  };

  useEffect(() => {
    const fetchSubscriberData = async () => {
      try {
        setLoading(true);
        const variables = {
          filter: {
            workspace_id: {
              eq: selectedWorkspaceId,
            },
          },
        };
        const getSubData = await client.graphql({
          query: listSubscriberCards,
          variables: variables,
        });
        const res = getSubData.data.listSubscriberCards.items;
        setSubscriberData(res);
        setLoading(false);
      } catch (error) {
        console.error("Error creating todo:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriberData();
  }, []);

  const handleUpdatePaymentAddress = async () => {
    dispatch(setLoading(true));
    let paymentProfileId;
    const createPaymentProfile = {
      createCustomerPaymentProfileRequest: {
        merchantAuthentication: {
          name: merchant_name,
          transactionKey: transaction_key,
        },
        customerProfileId: subscriberData?.[0]?.subscription_id,
        paymentProfile: {
          billTo: {
            firstName: userBillingDetail?.[0]?.first_name,
            lastName: userBillingDetail?.[0]?.last_name,
            address: updatedAddress?.address,
            city: updatedAddress?.city,
            state: updatedAddress?.state,
            zip: updatedAddress?.postalCode,
            country: updatedAddress?.country,
          },
          payment: {
            creditCard: {
              cardNumber: updatedAddress?.cardNumber,
              expirationDate: updatedAddress.expiry.replace("/", "-"),
            },
          },
          defaultPaymentProfile: false,
        },
        validationMode: "liveMode",
      },
    };

    if (!subscriberData?.[0]?.payment_profile_id) {
      try {
        const response = await axios.post(authorize_api, createPaymentProfile);
        paymentProfileId = response.data.customerPaymentProfileId;

        if (response.data.messages.resultCode === "Error") {
          dispatch(
            showToast({
              message: response.data.messages.message[0].text,
              type: "error",
            })
          );
          dispatch(setLoading(false));
          return;
        }

        if (paymentProfileId) {
          const variable = {
            id: subscriberData?.[0]?.id,
            payment_profile_id: paymentProfileId,
          };

          const updateSubscriber = await client.graphql({
            query: updateSubscriberCard,
            variables: { input: variable },
          });
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(showToast({ message: error.message, type: "error" }));
      }
    }

    if (
      userBillingDetail?.[0]?.address !== updatedAddress.address ||
      userBillingDetail?.[0]?.city !== updatedAddress.city ||
      userBillingDetail?.[0]?.state !== updatedAddress.state ||
      userBillingDetail?.[0]?.postal_code !== updatedAddress.postalCode ||
      userBillingDetail?.[0]?.country !== updatedAddress.country ||
      userBillingDetail?.[0]?.card_number !== updatedAddress.cardNumber ||
      userBillingDetail?.[0]?.expiry_month !==
      updatedAddress?.expiry.substring(0, 2) ||
      userBillingDetail?.[0]?.expiry_year !==
      updatedAddress?.expiry.substring(3, 5) ||
      userBillingDetail?.[0]?.cvc !== updatedAddress.cvc
    ) {
      try {
        const updatePaymentData = {
          updateCustomerPaymentProfileRequest: {
            merchantAuthentication: {
              name: merchant_name,
              transactionKey: transaction_key,
            },
            customerProfileId: subscriberData?.[0]?.subscription_id,
            paymentProfile: {
              billTo: {
                firstName: userBillingDetail?.[0]?.first_name,
                lastName: userBillingDetail?.[0]?.last_name,
                address: updatedAddress?.address,
                city: updatedAddress?.city,
                state: updatedAddress?.state,
                zip: updatedAddress?.postalCode,
                country: updatedAddress?.country,
              },
              payment: {
                creditCard: {
                  cardNumber: updatedAddress?.cardNumber,
                  expirationDate: `${updatedAddress?.expiry.substring(
                    0,
                    2
                  )}-${updatedAddress?.expiry.substring(3, 5)}`,
                },
              },
              customerPaymentProfileId:
                subscriberData?.[0]?.payment_profile_id === null
                  ? paymentProfileId
                  : subscriberData?.[0]?.payment_profile_id,
            },
            validationMode: "liveMode",
          },
        };
        const response = await axios.post(authorize_api, updatePaymentData);
        if (response.data.messages.resultCode === "Error") {
          dispatch(setLoading(false));
          dispatch(
            showToast({
              message: response.data.messages.message[0].text,
              type: "error",
            })
          );
          return;
        }
        await handleUpdateWorkspace();
        setOpen(false);
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(showToast({ message: error.message, type: "error" }));
      }
    }
    dispatch(setLoading(false));
    dispatch(
      showToast({
        message: "Billing Address updated successfully",
        type: "success",
      })
    );
  }
  const handleUpdateWorkspace = async () => {
    try {
      dispatch(setLoading(true));
      const variable = {
        id: userBillingDetail?.[0]?.id,
        address: updatedAddress.address,
        city: updatedAddress.city,
        country: updatedAddress.country,
        state: updatedAddress.state,
        postal_code: updatedAddress.postalCode,
        card_number: updatedAddress.cardNumber,
        holderName: updatedAddress.holderName,
        expiry_month: updatedAddress.expiry.substring(0, 2),
        expiry_year: updatedAddress.expiry.substring(3, 5),
        cvc: updatedAddress.cvc,
      };

      const updateUserBillingData = await client.graphql({
        query: updateUserBilling,
        variables: { input: variable },
      });

      const result = updateUserBillingData.data.updateUserBilling;
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error updating user:", error);
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{ ...container, margin: isMobile ? "0px 1rem" : container.margin }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card style={organizationCard}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "2rem 0rem",
              }}
            >
              <div style={{ width: isMobile ? "90%" : "50%" }}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div style={{ fontSize: "1.3rem", margin: "0px" }}>
                          Billing Address
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Address"
                          variant="outlined"
                          size="small"
                          multiline
                          rows={3}
                          value={updatedAddress.address}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              address: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="City"
                          variant="outlined"
                          size="small"
                          value={updatedAddress.city}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              city: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          select
                          size="small"
                          margin="normal"
                          fullWidth
                          label="Country"
                          style={{ margin: "0px" }}
                          value={updatedAddress.country}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              country: e.target.value,
                            })
                          }
                          SelectProps={{
                            IconComponent: updatedAddress.country
                              ? () => null
                              : undefined,
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: "300px",
                                },
                              },
                            },
                          }}
                          InputProps={{
                            endAdornment: updatedAddress.country && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="clear selection"
                                  onClick={() =>
                                    setUpdatedAddress({
                                      ...updatedAddress,
                                      country: "",
                                    })
                                  }
                                  edge="end"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        >
                          {reorderedCountriesList?.map((option) => (
                            <MenuItem key={option.name} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="State"
                          variant="outlined"
                          size="small"
                          value={updatedAddress.state}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              state: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Postal Code"
                          variant="outlined"
                          size="small"
                          value={updatedAddress.postalCode}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              postalCode: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ fontSize: "1.3rem", margin: "0px" }}>
                          Payment Method
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          label="Credit/Debit Card No."
                          variant="outlined"
                          size="small"
                          disabled
                          value={
                            updatedAddress.cardNumber
                              ? `*`.repeat(
                                Math.max(
                                  0,
                                  updatedAddress.cardNumber.length - 4
                                )
                              ) + updatedAddress.cardNumber.slice(-4)
                              : ""
                          }
                          // value={updatedAddress.cardNumber}
                          onChange={(e) =>
                            setUpdatedAddress({
                              ...updatedAddress,
                              cardNumber: e.target.value,
                            })
                          }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          label="Card Holder Name"
                          variant="outlined"
                          size="small"
                          disabled
                          value={updatedAddress.holderName}
                          // onChange={(e) =>
                          //   setUpdatedAddress({
                          //     ...updatedAddress,
                          //     expiryMonth: e.target.value,
                          //   })
                          // }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          label="Valid thru"
                          variant="outlined"
                          size="small"
                          disabled
                          value={updatedAddress.expiry}
                          // onChange={(e) =>
                          //   setUpdatedAddress({
                          //     ...updatedAddress,
                          //     expiryYear: e.target.value,
                          //   })
                          // }
                          style={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="outlined-basic"
                          label="CVC"
                          variant="outlined"
                          size="small"
                          disabled
                          value={
                            updatedAddress.cvc
                              ? `*`.repeat(
                                Math.max(0, updatedAddress.cvc.length)
                              )
                              : ""
                          }
                          // onChange={(e) =>
                          //   setUpdatedAddress({
                          //     ...updatedAddress,
                          //     cvc: e.target.value,
                          //   })
                          // }
                          style={{ width: "100%" }}
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "0.5rem",
                      }}
                    >
                      <Tooltip title={userBillingDetail?.[0]?.paymentMethod_Gateway === "stripe" ? "Stripe Gateway is already configured! If you want to change your card detail, please contact your advisor." : "You can update your credit card detail by entering the new values in the fields below."}>
                        <Button
                          variant="outlined"
                          style={{
                            fontSize: "1rem",
                            textTransform: "initial",
                            marginTop: "1rem",
                          }}
                          onClick={() => setOpen(true)}
                        >
                          Update My Card
                        </Button>

                      </Tooltip>
                      <Button
                        variant="contained"
                        style={{
                          fontSize: "1rem",
                          textTransform: "initial",
                          marginTop: "1rem",
                        }}

                        disabled={userBillingDetail?.[0]?.paymentMethod_Gateway === "stripe" ? true : false}
                        onClick={handleUpdatePaymentAddress}
                      >

                        Save Changes
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Dialog open={open} disableBackdropClick>
              <DialogTitle>Update Credit Card Details</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  You can update your credit card detail by entering the new
                  values in the fields below.
                </DialogContentText>
                <PaymentForm
                  setUpdatedAddress={setUpdatedAddress}
                  updatedAddress={updatedAddress}
                />
              </DialogContent>
              <DialogActions style={{ margin: "0.5rem 0.5rem" }}>
                <Button
                  onClick={() => setOpen(false)}
                  variant="contained"
                  style={{
                    textTransform: "initial",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={userBillingDetail?.[0]?.paymentMethod_Gateway === "stripe" ? true : false}
                  variant="contained"
                  style={{
                    textTransform: "initial",
                  }}
                  onClick={handleUpdatePaymentAddress}
                >
                  Update
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </Grid>
      </Grid>
      {isMobile && <div style={{ height: "10vh" }}></div>}
      {!isMobile && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AddressSetting;
